import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  message,
  Card,
  Select,
  Tooltip,
  Switch,
  Button,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  deleteImage,
  getCustomerByID,
  updateCustomer,
} from "../../../../utils/API";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { IoIosInformationCircle } from "react-icons/io";
import { AuthContext } from "../../../Auth/AuthContext";
import { UploadFile } from "antd/lib/upload";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import { BiSolidInbox, BiUpload } from "react-icons/bi";
import { extendChartView } from "echarts";
import { BsUpload } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import "./Profie.css";
import Dragger from "antd/es/upload/Dragger";
import dummimage from "../../../../assets/frontendassets/profiledummy.png"
import CryptoJS from "crypto-js";


const { Option } = Select;

interface StateData {
  [key: string]: string;
}


// Encryption Key and IV (should be securely managed)
const key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');  // 16 bytes IV

// Decrypt Function
const decryptData = (encryptedData : any) => {
  let decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [apiImageUploaded, setApiImageUploaded] = useState(false);
  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = React.useState(false);
  const user_id = localStorage.getItem("customerId") || "";
  const id = user_id.replace(/["']/g, "");
  const imageUrl = process.env.REACT_APP_Image_URL || "";
  const [country, setCountry] = useState([]);
  const [showAdminContact, setShowAdminContact] = useState(false);

  
  const [panAadharData, setPanAadharData] = useState({
    pan: null,
    aadhar: null,
  });
  const [nodeContact, setNodeContact] = useState()
  const { authData } = useContext(AuthContext);
  const [isKyc, setIsKyc] = useState(0); // State for KYC switch

  const roleId = authData.roleId;
  const getSwitchClass = () => {
    if (isKyc === 1) return 'bg-orange-500';
    if (isKyc === 2) return 'bg-Green-500';
    return 'bg-gray-300';
  };
 



  const handleKycDetails = async (checked: any) => {
    try {
      const fieldsToValidate = [
        "image",
        'firstName',
        'lastName',
        'dob',
        'pan',
        'aadhar',
        'email',
        'mobileNo'
      ];
      await form.validateFields(fieldsToValidate);

      setIsKyc(checked ? 1 : 0);
      const id = form.getFieldValue("_id");
      if (id) {
        try {
          const response = await updateCustomer(id, { isKyc: checked ? 1 : 0 });
          if (response && response.data && response.data.message) {
            message.success(response.data.message);
          } else {
            message.success("KYC status updated successfully");
          }
        } catch (error) {
          message.error("Failed to update KYC status. Please try again.");
        }
      }
    } catch (errorInfo) {
      // If validation fails, show an error message
      message.error("Please fill all required fields for KYC");
    }
  };

  useEffect(() => {
    // Check if the user should see the "Please contact administrator" message
    const nodeLoggedIn = localStorage.getItem("node_logged_in");
    if (nodeLoggedIn && decryptData(nodeLoggedIn) === 1) {
      setShowAdminContact(true);
    }

    showProfile();
  }, []);


  const showProfile = async () => {
    try {
      const existingData = await getCustomerByID(id); // Replace with your actual API call
      // Set form fields and other state based on existingData
      setPanAadharData({
        pan: existingData.pan,
        aadhar: existingData.aadhar,
      });
      form.setFieldsValue({
        ...existingData,
        dob: dayjs(existingData.dob).format("YYYY-MM-DD"),
        address: existingData.address
      });
  
      const nodeLoggedIn = localStorage.getItem("node_logged_in");
      let contactNumber = existingData.mobileNo;
      setNodeContact(contactNumber);
      
      if (nodeLoggedIn && decryptData(nodeLoggedIn) === 1) {
        contactNumber = contactNumber.replace(/\d(?=\d{4})/g, "*");
      }
            // Set form field for contact number
            form.setFieldsValue({ mobileNo: contactNumber });

      setIsKyc(existingData.isKyc);
  
      // Display existing image from API, if available
      if (existingData.image) {
        const imageUrl = `${process.env.REACT_APP_Image_URL}/users/${existingData.image}`;
        setFileList([{
          uid: '1',
          name: existingData.image,
          status: 'done',
          url: imageUrl,
        }]);
        setPreviewImage(imageUrl); // Set preview image if needed
        setApiImageUploaded(true); // Mark that the image is fetched from API
      } else {
        setApiImageUploaded(false); // Reset apiImageUploaded if no image from API
      }
    } catch (error) {
      message.error("Error fetching data. Please try again.");
      navigate("/backend/dashboard");
    }
  };
  

  useEffect(() => {
    if (id) {
    
      showProfile();
    }
  }, [id, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/uploads/country.json");
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        const data = await response.json();
        setCountry(data); // Ensure data is set correctly
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchData();
  }, []);


  const validatePAN = (_: any, value: any) => {
    // Regular expression to match PAN format
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/i;

    // Test the value against the regex
    if (!panRegex.test(value)) {
      return Promise.reject("Please enter a valid PAN number");
    }

    return Promise.resolve();
  };
  const getBase64 = (file: File | Blob) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

    const handleRemove = (event: any) => {
      event.stopPropagation();
      if (apiImageUploaded) {
        deleteImage(id)
          .then(() => {
            setPreviewImage("");
            setFileList([]);
            showProfile();
          })
          .catch((error) => {
            message.error("Failed to delete image. Please try again.");
          });
      } else {
        // Handle for temporarily uploaded images or do nothing
        setPreviewImage("");
        setFileList([]);
      }
    };
    
    const imageProps = {
      
      name: 'image',
      multiple: false, // Only allow single file upload
      beforeUpload(file : any) {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
          message.error('You can only upload image files!');
        }
        const isLt64M = file.size / 1024 / 1024 < 64;
        if (!isLt64M) {
          
          message.error('Image must be smaller than 64MB!');
        }
        return isImage && isLt64M;
      },
      onChange(info : any) {
        const newFileList = [...info.fileList];
        if (newFileList.length > 0) {
          const file = newFileList[0];
          if (!file.url && !file.preview) {
            getBase64(file.originFileObj).then(preview => {
              file.preview = preview;
              setPreviewImage(preview);
            });
          } else {
            setPreviewImage(file.url || file.preview || '');
          }
        } else {
          setPreviewImage('');
        }
        setFileList(newFileList.slice(-1)); // Only keep the last uploaded file
      },
      fileList,
    };

  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue("_id");
    const nodeLoggedIn = localStorage.getItem("node_logged_in");
    if (nodeLoggedIn && decryptData(nodeLoggedIn) === 1) {
      values.mobileNo= nodeContact;
    }
    if (currentId) {
      setIsLoading(true);
      try {
        const formData = new FormData();
  
        // Append all form fields except 'image' to formData
        Object.keys(values).forEach(key => {
          if (key !== 'image') {
            formData.append(key, values[key] || '');
          }
        });
  
  
        if (fileList.length > 0 && fileList[0].originFileObj) {
          formData.append('image', fileList[0].originFileObj as Blob);
        }
  
        formData.append('isKyc', isKyc.toString());
  
        // Make the API call to update customer
        const response = await updateCustomer(currentId, formData); // Adjust your API call to handle FormData
        setIsLoading(false);
  
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
        } else {
          message.success("Profile updated successfully");
          form.setFieldsValue({ password: "" });
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Failed to submit data. Please try again.");
      }
    } else {
      setIsLoading(false);
      message.error("Failed to fetch data. Please try again.");
    }
  };
  


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Profile</h2>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-2 md:p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            {/* Profile image */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white  "
                >
                  <label className="font-bold">
                    Profile 
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="image" 
                    rules={[
                      {
                        required: true,
                        message: "Please upload profile image",
                      },
                    ]}
                  >
                    <Upload.Dragger {...imageProps}>
                      {!previewImage && (
                        <div>
                          <p className="ant-upload-drag-icon d-flex justify-center">
                            <BiSolidInbox />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </div>
                      )}
                      {previewImage && (
                        <div className="relative h-40 w-40">
                          <img src={previewImage} alt="Uploaded preview" className="w-full h-full" />
                          <button
                            className="absolute top-0 right-0 bg-orange-500 text-black p-1"
                            onClick={handleRemove}
                          >
                            <MdDeleteOutline />
                          </button>
                        </div>
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* city */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="pt-4">
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    City
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter city",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* address */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Address
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Address",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      cols={30}
                      size="large"
                      className="rounded border border-1"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* states */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Country
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                <Form.Item
  name="country"
  rules={[
    {
      required: true,
      message: "Please Select country",
    },
  ]}
>
  <Select
    size="large"
    className="rounded border border-1"
    placeholder="Select country"
    allowClear
    showSearch
    optionFilterProp="children"
    filterOption={(input : any, option : any) =>
      (option?.label ?? "")
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  >
    {country.map((c : any ) => (
      <Option key={c.code} value={c.name} label={c.name}>
        {c.name}
      </Option>
    ))}
  </Select>
</Form.Item>

                </Col>
              </Row>
            </Col>


            {/* pincode */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Pin Code</label>
                    <span className="text-danger">*</span>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter pincode",
                      },
                      {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please Enter Valid Contact",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="pin Code"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* KYC Details */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
              <Row className="bg-white">
                <Col xs={19} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <label className="font-bold text-4xl" style={{ fontSize: "20px" }}>
                    KYC DETAILS
                  </label>
                </Col>
                <Col xs={5} sm={10} md={12} className="d-flex justify-content-end align-items-center">
                {
                  roleId == 2 ?   <label className="font-normal text-sm pr-4">{isKyc === 1 ? "KYC PENDING" : null} {isKyc === 2 ? "KYC APPROVED" : null} </label> : null
                }
                 {
                  roleId == 2  ?  <Switch
                  className={`switch ${getSwitchClass()}`} // Apply custom class based on isKyc value
                  checked={isKyc === 1 || isKyc === 2} // Bind switch to state
                  onChange={(checked) => handleKycDetails(checked ? 1 : 0)} // Handle state change and API call
                  disabled={isKyc === 2} // Disable switch based on isKyc value
                />
                : null
                 }
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    First Name
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      disabled={roleId == 2}
                      className="rounded border border-1"
                      placeholder=" Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Last Name */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Last Name
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                    ]}
                  >
                    <Input
                    disabled={roleId == 2}
                      size="large"
                      className="rounded border border-1"
                      placeholder=" Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* PAN Number */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold d-flex align-items-center ">
                    <div>
                      IT PAN
                     
                    </div>
                    {panAadharData.pan !== null && panAadharData.pan !== ""  ? (
                      <div>
                        <Tooltip title="Please Contact Administrator">
                          <IoIosInformationCircle
                            style={{ marginLeft: "5px" }}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="pan"
              
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="format :- xxxxx0000x"
                      disabled={
                        panAadharData.pan !== null &&
                        panAadharData.pan !== "" &&
                        roleId === 2
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Aadhar Number */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold d-flex align-items-center ">
                    <div>
                      Aadhar No
                      <span className="text-danger">*</span>
                    </div>
                    {panAadharData.aadhar !== null &&
                      panAadharData.aadhar !== "" && roleId == 2? (
                      <div>
                        <Tooltip title="Please Contact Administrator">
                          <IoIosInformationCircle
                            style={{ marginLeft: "5px" }}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="aadhar"
                    rules={[
                      { required: true, message: "Please Enter Aadhar no" },
                      {
                        min: 12,
                        max: 12,
                        message: "Please Enter 12 digit Aadhar no",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Aadhar no"
                      disabled={
                        panAadharData.pan !== null &&
                        panAadharData.pan !== "" &&
                        roleId === 2
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Email */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Email
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      disabled={roleId == 2}
                      className="rounded border border-1"
                      placeholder="Email"
                  
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>


            {/* Contact */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                > 
                  <label className="font-bold">
                    Contact
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="mobileNo"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Enter Contact",
                    //   },
                    //   {
                    //     pattern: new RegExp(/^[0-9]+$/),
                    //     message: "Please Enter Valid Contact",
                    //   },
                    // ]}
                  >
                    <Input
                      size="large"
                      disabled={roleId == 2}
                      className="rounded border border-1"
                      placeholder="Contact"
                    />
                    
                  </Form.Item>
                  {showAdminContact && (
          <div style={{ color: 'gray', fontSize: '12px' }}>
            Please contact administrator
          </div>
        )}
                </Col>
              </Row>
            </Col>
           
            {/* end of KYC Details */}

            {/* Bank Details */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
              <Row className="bg-white">
                <label
                  className="font-bold text-4xl"
                  style={{ fontSize: "20px" }}
                >
                  BANK DETAILS
                </label>
              </Row>
            </Col>

            {/* Bank Name */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Name
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="bankName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Bank Address */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Address
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="bankAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Address",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Address"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Bank Account No. */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Account No.
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="bankAccountNo"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Account No.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Account No."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank IFSC Code
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="ifsc"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter IFSC Code",
                      },
                      {
                        validator: (_, value, callback) => {
                          if (value && value.length >= 5 && value[4] !== "0") {
                            callback("5th Character must be Zero");
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="IFSC Code"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
              <Row className="bg-white">
                <label
                  className="font-bold text-4xl"
                  style={{ fontSize: "20px" }}
                >
                  NOMINEE DETAILS
                </label>
              </Row>
            </Col>

            {/* Bank Name */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Nominee Name
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="nomineeName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Nominee Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Nominee Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Relation
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}  >
                  <Form.Item
                    name="nomineeRelation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Relation",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Relation"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn-brand" disabled={isLoading}>
                {isLoading ? "Updating..." : "Update"}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Profile;