import React, { useEffect, useState } from "react";
import { Upload, message, Row, Col, Card, Button, Form, Spin } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BiUpload } from "react-icons/bi";
import { getAllcategories, EditPPT as fetchPPTById, updateppt } from "../../../../../utils/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const EditPPT: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchPPTData = async (pptId: string) => {
    try {
      setLoading(true);
      const response = await fetchPPTById(pptId);
     
      if (response && response.ppt) {
        const pptData = response.ppt;
        form.setFieldsValue({
          category: pptData.category_id,
        });
        setFileList([
          {
            uid: pptData._id,
            name: pptData.ppt,
            status: "done",
            url: pptData.url,
          } as UploadFile,
        ]);
      } else {
        message.error("Unexpected response structure");
      }
    } catch (error) {
      message.error("Error fetching PPT data");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const categoriesData = await getAllcategories("");
      setCategories(categoriesData);
    } catch (error) {
      message.error("Error fetching categories");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPPTData(id);
      fetchCategories();
    }
  }, [id]);

  const MAX_FILE_SIZE = 40 * 1024 * 1024; // 50 MB

  const onFinish = async (values: any) => {
    try {
      setFormLoading(true);
      const file = fileList[0];
      if (!file) {
        message.error("Please upload a PPT file");
        return;
      }

      if (file.size && file.size > MAX_FILE_SIZE) {
        message.error(`File size too large. Maximum allowed size is ${MAX_FILE_SIZE / (1024 * 1024)} MB`);
        return;
      }

      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("ppt", file.originFileObj as any);
      });
      formData.append("category_id", values.category);

      await updateppt(id, formData);
      message.success("PPT updated successfully");
      navigate("/backend/product-ppt");
      setFileList([]);
      form.resetFields();
    } catch (error) {
      message.error("Failed to update PPT");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Product PPT</h2>
        </Col>
        {/* <Col span={12}></Col> */}
        <Col span={8}>
          <Link to={"/backend/product-ppt"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Row className="border border-warning-2 p-5 bg-white rounded-md">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Form form={form} onFinish={onFinish}>
                <Row>
                  <Col xs={24} sm={24} md={4} className="me-4 bg-white">
                    <label className="font-bold">
                      Category <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col
                    xs={24} sm={24} md={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form.Item
                      name="category"
                      rules={[{ required: true, message: "Please select a category" }]}
                    >
                      <select
                        className="form-select border border-warning-2 rounded-md"
                        aria-label="Default select example"
                        onChange={(e) => form.setFieldsValue({ category: e.target.value })}
                      >
                        <option value="">Select Category</option>
                        {categories.map((category: any) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={4} className="me-4 bg-white">
                    <label className="font-bold">
                      Product PPT <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col
                    xs={24} sm={24} md={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form.Item
                      name="file"
                    >
                      <Upload
                        name="ppt"
                        action=""
                        fileList={fileList}
                        onChange={({ fileList }) => setFileList(fileList)}
                        multiple
                        accept=".ppt, .pptx"
                        beforeUpload={() => false} // Prevent default upload behavior
                      >
                        <Button icon={<BiUpload />}>Upload PPT</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={18} className="text-center">
                    <Form.Item>
                    <button
  disabled={fileList.length === 0 && formLoading}
  className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${formLoading ? 'bg-orange-300 text-gray-700' : 'bg-orange-500 text-white '}`}
  type="submit"
>
  {formLoading ? (
    <div className="flex items-center">
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0.5"
        ></path>
      </svg>
      Uploading
    </div>
  ) : (
    "Upload PPT"
  )}
</button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EditPPT;
