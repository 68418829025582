import React, { useContext, useState, useRef } from "react";
import { RiAddBoxFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { FaEdit } from "react-icons/fa";
import * as XLSX from "xlsx";
import {
  deleteseminar,
  getallseminar,
  addseminar,
} from "../../../../../utils/API";
import moment from "moment";
import { AuthContext } from "../../../../../Pages/Auth/AuthContext";

const SeminarsTable: React.FC = () => {
  const [seminarData, setseminarData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const { authData } = useContext(AuthContext);
  const roleId = authData.roleId;
  const navigate = useNavigate();

  const fetchSeminarData = () => {
    const formatTime = (dateTime: string) => {
      const time = moment(
        dateTime,
        ["HH:mm", "h:mm A", "h:mm:ss A"],
        true
      ).format("HH:mm");
      return time;
    };

    setLoading(true);
    getallseminar()
      .then((response: any) => {
        const formattedData = response.map((item: any) => ({
          ...item,
          date: moment(item.date).format("YYYY-MM-DD"), // Format date
          time: formatTime(item.time), // Format time
        }));
        const reversedData = formattedData.reverse();
        console.log(reversedData);
        setseminarData(
          reversedData.filter((item: any) => item.isDeleted === false)
        );
        setLoading(false);
      })
      .catch((error: any) => {
        message.error("Network error. Please try again.");
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchSeminarData();
  }, []);

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/seminar-details/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        const response = await deleteseminar(deleteId).then((response: any) => {
          message.success(response.data.message);
          fetchSeminarData();
        });
      } catch (error) {
        message.error("Failed to delete seminar");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = event.target.files?.[0];
    
    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension !== "xlsx") {
        message.error("Invalid file format. Please upload an XLSX file.");
        setLoading(false);
        return;
      }
      
      try {
        const reader = new FileReader();
        
        reader.onload = async (e) => {
          if (e.target) {
            const data = e.target.result as ArrayBuffer;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            
            const [headerRow, ...rows] = jsonData;
            const header = headerRow as string[];
            const requiredFields = ["presentedBy", "date", "time", "place"];
            
            const isValidHeader = requiredFields.every((field) =>
              header.includes(field)
            );
            
            if (!isValidHeader) {
              message.error(
                "Invalid file format. The file must contain the headers: presentedBy, date, time, place."
              );
              setLoading(false);
              return;
            }
            
            const formattedData = rows
              .map((row: any) => {
                const rowData: { [key: string]: any } = {};
                header.forEach((key, index) => {
                  rowData[key] = row[index];
                });
                
                const hasRequiredFields = requiredFields.every(
                  (field) => rowData[field]
                );
                
                if (hasRequiredFields) {
                  // Process time value correctly
                  let formattedTime: string | undefined = undefined;
                  const timeValue = rowData["time"];
                  
                  if (timeValue) {
                    // Assuming timeValue is a decimal representing fractions of a day
                    const hours = Math.floor(timeValue * 24);
                    const minutes = Math.round((timeValue * 24 - hours) * 60);
                    const period = hours >= 12 ? 'PM' : 'AM';
                    formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                  }
                  
                  // Convert Excel date number to JavaScript Date object
                  const excelDateNumber = rowData["date"];
                  const jsDate = xlsxToDate(excelDateNumber);
                  const formattedDate = moment(jsDate).format("YYYY-MM-DD");
                  
                  return {
                    presentedBy: rowData["presentedBy"],
                    date: formattedDate,
                    time: formattedTime,
                    place: rowData["place"],
                  };
                }
                return null;
              })
              .filter((item) => item !== null);
            
            await addseminar({ seminars: formattedData });
            message.success("Seminars imported successfully.");
            fetchSeminarData();
            setLoading(false);
          }
        };
        
        reader.readAsArrayBuffer(file);
      } catch (error) {
        setLoading(false);
        message.error("Failed to process file.");
      }
    }
  };
  
  // Function to convert Excel date number to JavaScript Date object
  const xlsxToDate = (excelDateNumber: number) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const epochDays = excelDateNumber - 25569; // Excel epoch starts on Jan 1, 1900 (Windows) or Jan 1, 1904 (Mac)
    const millisecondsOffset = epochDays * millisecondsPerDay;
    return new Date(millisecondsOffset);
  };
  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          Presentedby
        </div>
      ),
      dataIndex: "presentedBy",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          Date
        </div>
      ),
      dataIndex: "date",
      render: (date: any) => <span>{moment(date).format("MMMM Do YYYY")}</span>, // e.g., June 17th 2024
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          Time
        </div>
      ),
      dataIndex: "time",
      render: (time: any) => (
        <span>{moment(time, "HH:mm").format("hh:mm A")}</span>
      ), // e.g., 12:00 PM
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          Venue
        </div>
      ),
      dataIndex: "place",
    },
    ...(roleId === 1
      ? [
          {
            title: (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#a6a6a6",
                }}
              >
                Action
              </div>
            ),
            dataIndex: "action",
            render: (text: any, record: any) => (
              <div className="d-flex">
                <FaEdit
                  className="editMenuIcon"
                  onClick={() => handleEditRedirect(record)}
                />
                <AiFillDelete
                  className="editMenuIcon"
                  onClick={() => handleDeleteConfirmation(record._id)}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={14}
          xl={14}
          xxl={18}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Seminars</h2>
        </Col>
        <Col xs={24} sm={24} md={10} xl={10} xxl={6} className="">
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={0} xl={4} xxl={4}></Col> */}
            {/* <Col xs={24} sm={24} md={10} xl={10} xxl={10}>
             
            </Col> */}
            {roleId === 1 && (
              <>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  xxl={12}
                  style={{ paddingRight: "-8px !important" }}
                >
                  <button
                    className="d-flex justify-content-center align-items-center w-100 btn-brand"
                    onClick={handleButtonClick}
                  >
                    <RiAddBoxFill style={{ fontSize: "15px" }} />
                    <div className="ms-2">Import</div>
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  xxl={12}
                  style={{ paddingRight: "-8px !important" }}
                >
                  <Link to={"/backend/seminar-details/add"}>
                    <button className="d-flex justify-content-center align-items-center w-100 btn-brand">
                      <RiAddBoxFill style={{ fontSize: "15px" }} />
                      <div className="ms-2">Add</div>
                    </button>
                  </Link>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <Card className="bg-white border" style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: "auto" }}>
                  <Table
                    columns={columns}
                    dataSource={seminarData}
                    scroll={{ x: true }}
                  />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>

      <Modal title="Confirmation" visible={!!deleteId} footer={null}
      closable={false}
      >
        <div style={{ textAlign: "start", marginBottom: "1rem" }}>
          <p>Are you sure you want to delete this category?</p>
        </div>
        <div style={{ textAlign: "end" }}>
          <Button
            onClick={handleDelete}
            type="primary"
            className="rounded" style={{ backgroundColor: "orange",color: "white", marginRight: "1rem" }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SeminarsTable;
