import React, { useEffect, useState } from "react";
import { Card, Carousel } from "antd";
import { BiHeart, BiStrikethrough, BiSupport } from "react-icons/bi";
import { Row, Col } from "antd";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";


const NaswizAppeals: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const setMobileImage = () => {
      if (window.innerWidth <= 765) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', setMobileImage);
    setMobileImage();

    return () => window.removeEventListener('resize', setMobileImage);
  }, []);
  return (
  <>
  
    {/* <Row className="mt-3 lg:px-20 lg:ps-20   md:px-10 md:ps-10 sm:ps-5 xs:ps-5"  style={{  backgroundImage: `url(${require('../../assets/frontendassets/1120.jpg')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> */}
    <Row className="mt-3 lg:px-20 lg:ps-20   md:px-10 md:ps-10 sm:ps-5 xs:ps-5"  style={{  backgroundImage: `url(${isMobile ? require('../../assets/frontendassets/Frame.jpg') : require('../../assets/frontendassets/1120.jpg') })`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
      <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9} className="  text-center p-4  font-bold text-xl text-white " style={{ marginTop : "70px" , marginBottom : "70px"}}>
     <Col ><span className="text-lg text-black bg-slate-100 p-2 mt-5">Latest Corporate Wear</span></Col> <br />
    <span className=" font-bold text-2xl text-gray-30 pt-2 pb-2  bg-orange-500"> &nbsp; Men's Suit &nbsp; </span>
      </Col>
      <Col xs={24} sm={24} md={3} lg={6} xl={6} xxl={6} className=" text-center font-bold" style={{ margin : "auto"}}>
        <Link to="/products" ><p className="btn bg-black text-white font-extrabold text-sm p-3  border-none">Rapidexie Apparels</p></Link>
      </Col>
      <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9} className="text-center lg:text-right xl:text-end p-4  font-bold text-xl text-white " style={{ marginTop : "70px" , marginBottom : "70px"}}>
     <Col ><span className="text-xl text-white mt-5 ">Men's and Women's corporate Formal Wear </span></Col>
    <Col className="mt-3" >
    <span className=" font-bold text-2xl text-gray-30 p-2 bg-orange-500">Blazer and trouser</span>
    </Col>
      </Col>
    </Row>
  </>
)};

export default NaswizAppeals;
