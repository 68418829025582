import { ProductTable } from "../Components/products";
import AddProductForm from "../Components/products/Add";
import { Categorytable } from "../Pages/backend/Category";
import { AddCategory } from "../Pages/backend/Category/Add";
import { EditCategory } from "../Pages/backend/Category/Edit";
import Profile from "../Pages/backend/Geneology/Profile/Profile";
import BusinessParameter from "../Pages/backend/Graph/BusinessParameter";
import Top10 from "../Pages/backend/Graph/Top10";
import AdminSliderAdd from "../Pages/backend/Sliders/Add";
import AdminSliderEdit from "../Pages/backend/Sliders/Edit";
import SliderTable from "../Pages/backend/Sliders/Index";
import Dashboard from "../Pages/backend/Dashboard/Dashboard";
import CustomerTable from "../Pages/User";
import CustomerMasterForm from "../Pages/User/Add";
import CustomerEditForm from "../Pages/User/Edit";
import ProductSizeTable from "../Pages/backend/ProductSize/Index";
import ProductSizeAddForm from "../Pages/backend/ProductSize/Add";
import ProductSizeEditForm from "../Pages/backend/ProductSize/Edit";
import Setting from "../Pages/backend/Setting/Setting";
import ManagePages from "../Pages/backend/Pages/ManagePages";
import { Newsletter } from "../Pages/backend/NewsLetter/newsLetter";
import AddPages from "../Pages/backend/Pages/Add";
import EditPages from "../Pages/backend/Pages/Edit";
import EditProductForm from "../Components/products/Edit";
import ManageRoles from "../Pages/backend/Permissions/ManageRoles";
import NewSalesEntry from "../Pages/backend/Geneology/Newsales/NewSalesEntry";
import ManagePassword from "../Pages/backend/Geneology/Profile/ManagePassword";
import SeminarMasterForm from "../Pages/backend/Geneology/Download/Seminar_master/Add";
import SeminarsTable from "../Pages/backend/Geneology/Download/Seminar_master/Index";
import SeminarEditForm from "../Pages/backend/Geneology/Download/Seminar_master/Edit";
import DownloadProductPPT from "../Pages/backend/Geneology/Download/PPT/Add";
import TdsCertificateTable from "../Pages/backend/Geneology/Download/Tds_certificate_master";
import AddTdsCerticateForm from "../Pages/backend/Geneology/Download/Tds_certificate_master/Add";
import EditTdsCerticateForm from "../Pages/backend/Geneology/Download/Tds_certificate_master/Edit";
import Clubstatus from "../Pages/backend/Geneology/Status/ClubStatus";
import Direactstatus from "../Pages/backend/Geneology/Status/DireactStatus";
import Purchase from "../Components/common/mypurchase/Purchase";
import Current from "../Pages/backend/Geneology/Accuntdetails/Current";
import {OrderTable} from "../Pages/backend/Order";
import SalesTree from "../Pages/backend/Geneology/Newsales/SalesTree";
import ProductColore from "../Pages/backend/ProductColore/Index";
import ProductColoreAddForm from "../Pages/backend/ProductColore/Add";
import ProductColoreEditForm from "../Pages/backend/ProductColore/Edit";
import IndexBlog from "../Pages/backend/Blog/Index";
import AddBlog from "../Pages/backend/Blog/Add";
import BusinessPPT from "../Pages/backend/Geneology/Download/PPT/BusinessPPT";
import AddPPT from "../Pages/backend/Geneology/Download/PPT/Add";
import IndexPPT from "../Pages/backend/Geneology/Download/PPT";
import EditPPT from "../Pages/backend/Geneology/Download/PPT/edit";
import CurrentFinancialYearData from "../Pages/backend/Geneology/Accuntdetails/CurrentFinancialYearData";
import Previous from "../Pages/backend/Geneology/Accuntdetails/Previous/Previous";
import FeedbackForm from "../Pages/backend/Geneology/Profile/FeedBackForm";
import RewordStatus from "../Pages/backend/Geneology/Status/RewardClub";
import OrderDetail from "../Pages/backend/Order/OrderDetail";
import UserOrderDetail from "../Pages/backend/Order/UserOrderDetail";
import DownloadBusinessPPT from "../Pages/backend/Geneology/Download/PPT/DownloadBusinessPPT";
import Contact from "../Pages/backend/Contactus";
import { AddTestimonials } from "../Pages/backend/Testimonials/Add";
import { useUserRole } from "../utils/useUserRole";
import { Navigate, useLocation } from "react-router-dom";
import Tripod from "../Pages/backend/Support Center/Tripod/Tripod";
import TripodData from "../Pages/backend/Support Center/Tripod/Index";
import SevenpodData from "../Pages/backend/Support Center/Sevenpod/Index";
import TopEarner from "../Pages/backend/Support Center/TopEarner/Index";
import path from "path";
import DirectReportClosingWise from "../Pages/backend/Support Center/Ip Report Closing wise/DirectReportClosingWise";
import LeftRightIpReport from "../Pages/backend/Support Center/Ip Report Closing wise/LeftRightIpReport";
import CurrentPayoutIPList from "../Pages/backend/Support Center/Ip Report Closing wise/CurrentPayoutIPList";
import PayoutwiseIpList from "../Pages/backend/Support Center/Ip Report Closing wise/PayoutwiseIpList";
import DirectIndividualReport from "../Pages/backend/Support Center/Ip Report Closing wise/DirectIndividualReport";
import DownlineOrderStatus from "../Pages/backend/Support Center/Ip Report Closing wise/DownlineOrderStatus";
import RedEntry from "../Pages/backend/Support Center/Ip Report Closing wise/RedEntry";
import BankDetailMissing from "../Pages/backend/Support Center/Ip Report Closing wise/BankDetailMissing";
import UpdateBankDetail from "../Pages/backend/Support Center/Ip Report Closing wise/UpdateBankDetail";
import SearchProfile from "../Pages/backend/Geneology/Profile/SearchProfile";
import ProfileUser from "../Pages/backend/Geneology/Profile/ProfileUser";
import LastWeeekSuportCenterPerformance from "../Pages/backend/Support Center/Support Center Performance/LastWeeekSuportCenterPerformance";
import DownGeneology from "../Pages/backend/Support Center/Downline Geneology/DownlineGeneology";
import FindGeneology from "../Pages/backend/Support Center/Downline Geneology/FindGeneology";
import GeneologySalesTree from "../Pages/backend/Support Center/Downline Geneology/GeneologySalesTree";
import NextWeekClosingStatus from "../Pages/backend/Support Center/Ip Report Closing wise/NextWeekClosingStatus";
import { TestimonialTable } from "../Pages/frontend/Testimonial";
import { EditTestimonial } from "../Pages/backend/Testimonials/Edit";
import SearchUser from "../Pages/backend/Geneology/Profile/SearchUser";
import UpdateBankDetailForm from "../Pages/backend/Support Center/Ip Report Closing wise/UpdateBankDetailForm";
import EditRedEntry from "../Pages/backend/Support Center/RedEntry/Edit";
import CurrentClosing from "../Pages/backend/Geneology/Accuntdetails/CurrentClosing";
import AwardsTable from "../Pages/backend/Awards/index";
import  { AddAward } from "../Pages/backend/Awards/Add";
import { EditAward } from "../Pages/backend/Awards/Edit";

const withRoleBasedComponent = (Component : any, allowedRoles : any ) => {
    return (props : any ) => {
      const roleId = useUserRole();
      const location = useLocation();
      if (allowedRoles.includes(roleId)) {
        return <Component {...props} />;
      } else {
        return <Navigate to="/login" state={{ from: location }} />;
      }
    };
  };

const backendRoutes = [
    {
        path: "/backend/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
   
    {
        path : "/backend/users",
        name : "Users",
        component : CustomerTable,
        exact : true
    },
    {
        path : "/backend/users/add",
        name : "Add User",
        component : CustomerMasterForm,
        exact : true
    },
    {
        path : "/backend/users/:id/edit",
        name : "Edit User",
        component : CustomerEditForm,
        exact : true
    },
    {
        path : "/backend/kyc",
        name : "Profile",
        component : Profile,
        exact : true
    },
    {
        path : "/backend/feedback",
        name : "Feedback",
        component : FeedbackForm,
        exact : true
    },
    {
        path : "/backend/top-10",
        name : "Top 10",
        component : Top10,
        exact : true
    },
    {
        path : "/backend/business-parameter",
        name : "Business Parameter",
        component : BusinessParameter,
        exact : true
    },
    {
        path : "/backend/slider",
        name : "Sliders",
        component : SliderTable,
        exact : true
    },
    {
        path : "/backend/slider/add",
        name : "Add Slider",
        component : AdminSliderAdd,
        exact : true
    },
    {
        path : "/backend/sliders/:id/edit",
        name : "Edit Slider",
        component : AdminSliderEdit,
        exact : true
    },
    {
        path : "/backend/category",
        name : "Category",
        component : Categorytable,
        exact : true
    },
    {
        path  : "/backend/category/add",
        name :"Add Category",
        component : AddCategory,
        exact : true 
    },
    {
        path : "/backend/category/:id/edit",
        name : "Edit Category",
        component : EditCategory,
        exact : true
    },
    {
        path  : "/backend/product/add",
        name : "Add Product",
        component : AddProductForm,
        exact : true
    },
    {
        path : "/backend/product",
        name : "Edit Product",
        component : ProductTable,
        exact : true
    } , 
    {
        path : "/backend/product/:id/edit",
        name : "Edit Product",
        component : EditProductForm,
        exact : true
    },
    {
        path : "/backend/product-size",
        name : "Edit Product",
        component : ProductSizeTable,
        exact : true
    },
    {
        path : "/backend/product-size/add",
        name : "Edit Product",
        component : ProductSizeAddForm,
        exact : true
    },
    {
        path : "/backend/product-size/:id/edit",
        name : "Edit Product",
        component : ProductSizeEditForm,
        exact : true
    },
    // category end
    {
        path : "/backend/categories/:id/edit",
        name : "Edit Product",
        component : EditCategory,
        exact : true
    },
    {
        path : "/backend/categories/add",
        name : "Edit Product",
        component : AddCategory,
        exact : true
    },
    {
        path : "/backend/categories",
        name : "Edit Product",
        component : Categorytable,
        exact : true
    },
    {
        path : "/backend/setting",
        name : "Edit Setting",
        component : Setting,
        exact : true
    },
    {
        path : "/backend/newsletter",
        name : "Edit Newsletter",
        component : Newsletter,
        exact : true
    },
    {
        path : "/backend/pages",
        name : "Edit Pages",
        component : ManagePages,
        exact : true
    },
    {
        path : "/backend/pages/add",
        name : "Edit Pages",
        component : AddPages,
        exact : true
    },
    {
        path : "/backend/pages/:id/edit",
        name : "Edit Pages",
        component : EditPages,
        exact : true
    },
    {
        path : "/backend/role",
        name : "Edit Role",
        component : ManageRoles,
        exact : true
    } , 
    {
        path : "/backend/new-sales-entry"
        , name : "New Sale Entry"
        , component :NewSalesEntry
        , exact : true
    } , 
    {
        path : "/backend/manage-password"
        , name : "New Purchase Entry"
        , component :ManagePassword
        , exact : true
    } ,
    {
        path : "/backend/business-ppt",
        name : "Business PPT",
        component : DownloadBusinessPPT,
        exact : true
    },
    {
        path : "/backend/business-ppt/add",
        name : "PPT Add",
        component : BusinessPPT,
        exact : true
    },
    {
        path : "/backend/product-ppt",
        name : "Product PPT",
        component : IndexPPT,
        exact : true
    },
    {
        path : "/backend/ppt/add",
        name : "PPT Add",
        component : AddPPT,
        exact : true
    },
    {
        path : "/backend/ppt/:id/edit",
        name : "PPT Edit",
        component : EditPPT,
        exact : true
    },
    {
        path : "/backend/seminar-details/add",
        name : "Seminar Add",
        component : SeminarMasterForm,
        exact : true
    },
    {
        path : "/backend/seminar-details",
        name : " Seminars",
        component : SeminarsTable,
        exact : true
    },
    {
        path : "/backend/seminar-details/:id/edit",
        name : "Seminar Edit",
        component : SeminarEditForm,
        exact : true
    } ,
    {
        path : "/backend/tds-certificate",
        name : "TDS Certificate",
        component : TdsCertificateTable,
        exact : true
    } ,
    {
        path : "/backend/tds-certificate/add",
        name : "TDS Certificate Add",
        component : AddTdsCerticateForm,
        exact : true
    } ,
    {
        path : "/backend/tds-certificate/:id/edit",
        name : "TDS Certificate Edit",
        component : EditTdsCerticateForm,
        exact : true
    },
    {
        path : "/backend/club-status",
        name : "Club Status",
        component : Clubstatus,
        exact : true
    },
    {
        path : "/backend/reward-status",
        name : "Reward Status",
        component : RewordStatus,
        exact : true
    },
    {
        path : "/backend/direct-status",
        name : "Direct Status",
        component : Direactstatus,
        exact : true
    },
    {
        path : "/backend/manage-purchase",
        name : "Manage Purchase",
        component : Purchase,
        exact : true
    },
    {
        path : "/backend/account-details/previous",
        name : "Account Details Previous",
        component : Previous,
        exact : true
    },
    {
        path : "/backend/account-details/current",
        name : "Account Details Current",
        component : Current,
        exact : true
    },
    {
        path : "/backend/account-details/current-closing",
        name : "Account Details Current",
        component : CurrentClosing,
        exact : true
    },
    {
        path : "/backend/manage-order",
        name : "Manage Order",
        component : OrderTable,
        exact : true
    },
    {
        path : "/backend/order/:id/view",
        name : "Manage Order",
        component : OrderDetail,
        exact : true
    },
    {
        path : "/backend/user/order/:id",
        name : "My Order Detail",
        component : UserOrderDetail,
        exact : true
    },
    {
        path : "/backend/group-sales-tree",
        name : "User Tree",
        component : SalesTree,
        exact : true
    },
    {
        path : "/backend/color",
        name : "Colors",
        component : ProductColore,
        exact : true
    },
    {
        path : "/backend/color/add",
        name : "Add Color",
        component : ProductColoreAddForm,
        exact : true
    },
    {
        path : "/backend/color/:id/edit",
        name : "Edit Color",
        component : ProductColoreEditForm,
        exact : true
    },
    {
        path : "/backend/blog",
        name : "Blog",
        component : IndexBlog,
        exact : true
    },
    {
        path : "/backend/blog/add",
        name : "Add Blog",
        component : AddBlog,
        exact : true
    },
    {
        path : "/backend/awards",
        name : "Awards",
        component : AwardsTable,
        exact : true
    },
    {
        path : "/backend/awards/add",
        name : "Add Award",
        component : AddAward,
        exact : true
    },
    {
        path : "/backend/awards/:id/edit",
        name : "Edit Award",
        component : EditAward,
        exact : true
    },

    {
        path : "/backend/financial-year/:id",
        name : "financial-year",
        component : CurrentFinancialYearData,
        exact : true
    },
    {
        path : "/backend/contact",
        name : "Contact",
        component : Contact,
        exact : true
    },
    {
        path :"/backend/testimonials",
        name : "Testimonials",
        component : TestimonialTable,
        exact : true
    } , 
    {
        path :"/backend/testimonial/add",
        name : "Add Testimonials",
        component : AddTestimonials,
        exact : true
    } , 
    {
        path :"/backend/testimonial/:id/edit",
        name : "Edit Testimonials",
        component : EditTestimonial,
        exact : true
    },
    {
        path: "/backend/tripod-declaration",
        name: "Tripod Declaration",
        component: withRoleBasedComponent(Tripod, [1, 3]),
        exact: true
      },
      {
        path: "/backend/tripod-declaration-list",
        name: "Tripod Declaration List",
        component: withRoleBasedComponent(TripodData, [1, 3]),
        exact: true
      },
      {
        path: "/backend/sevenpod-declaration-list",
        name: "Pay Online",
        component: withRoleBasedComponent(SevenpodData, [1, 3]),
        exact: true
      } , 
      {
        path: "/backend/top-earner",
        name: "Top Earner",
        component: withRoleBasedComponent(TopEarner, [1, 3]),
        exact: true
      },
      {
        path : "/backend/direct-report-closing-wise",
        name : "Direct Report closing Wise",
        component : withRoleBasedComponent(DirectReportClosingWise, [1, 3]),
        exact : true
      },
      {
        path : "/backend/direct-individual-report",
        name : "Direct Individual Report",
        component : withRoleBasedComponent(DirectIndividualReport, [1, 3]),
        exact : true
      },
      {
        path : "/backend/new-sales-entry",
        name : "New Sales Entry",
        component : withRoleBasedComponent(NewSalesEntry, [1, 3]),
        exact : true
      },
      {
        path : "/backend/left-right-ip-report",
        name : "Left Right IP Report",
        component : withRoleBasedComponent(LeftRightIpReport, [1, 3]),
        exact : true
      },
      {
        path : "/backend/downline-order-status",
        name : "Downline Order Status", 
        component : withRoleBasedComponent(DownlineOrderStatus, [1, 3]),
        exact : true
      },
    {
        path : "/backend/previous-payout-ip-list",
        name : "Previous Payout IP List",
        component : withRoleBasedComponent(PayoutwiseIpList, [1, 3]),
        exact : true
    },
    {
        path : "/backend/current-payout-ip-list",
        name : "Current Payout IP List",
        component : withRoleBasedComponent(CurrentPayoutIPList, [1, 3]),
        exact : true
    },
    {
        path : "/backend/red-entry",
        name : "Red Entry",
        component : withRoleBasedComponent(RedEntry, [1, 3]),
        exact : true
    },
    {
        path : "/backend/red-entry/:id",
        name : "Red Entry",
        component : withRoleBasedComponent(EditRedEntry, [1, 3]),
        exact : true
    },
    {
        path : "/backend/bank-detail-missing",
        name : "Bank Detail Missing",
        component : withRoleBasedComponent(BankDetailMissing, [1, 3]),
        exact : true
    },
    {
        path : "/backend/update-downline-bank-detail",
        name : "Update Downline Bank Detail",
        component : withRoleBasedComponent(UpdateBankDetail, [1, 3]),
        exact : true
    },
    {
        path : "/backend/update-downline-bank-detail/:id",
        name : "Update Downline Bank Detail",
        component : withRoleBasedComponent(UpdateBankDetailForm, [1, 3]),
        exact : true
    },
    {
        path :"/backend/profile/search",
        name : "Profile Search",
        component : withRoleBasedComponent(SearchProfile, [1, 3]),
        exact : true
    } , 
    {
        path :"/backend/search",
        name : "Profile Search",
        component : withRoleBasedComponent(SearchUser, [1, 3]),
        exact : true
    } , 
    {
        path :"/backend/profile/:id",
        name : "Profile Search",
        component : withRoleBasedComponent(ProfileUser, [1, 3]),
        exact : true
    },
    {
        path : "/backend/last-week-suport-center-performance",
        name : "Last Weeek Suport Center Performance",
        component : withRoleBasedComponent(LastWeeekSuportCenterPerformance, [1, 3]),
        exact : true
    },
    {
        path : "/backend/downline-geneology",
        name : "Downline Geneology",
        component : withRoleBasedComponent(DownGeneology, [1, 3]),
        exact : true
    }, 
    {
        path : "/geneology/find/:userId",
        name : "Downline Geneology",
        component : withRoleBasedComponent(GeneologySalesTree, [1, 3]),
        exact : true
    },
    {
        path : "/backend/next-week-closing-status",
        name : "Downline Geneology",
        component : withRoleBasedComponent(NextWeekClosingStatus, [1, 3]),
        exact : true
    }
      
    
];

export { backendRoutes };