import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import image1 from "../../../assets/frontendassets/Aboutus/1.jpg";
import vision from "../../../assets/frontendassets/Aboutus/vision.jpg";
import mission from "../../../assets/frontendassets/Aboutus/mission.jpg";


const Aboutus = () => {
  return (
    <>
      <Row className="lg:mt-60 xl:mt-60 md:mt-60  sm:mt-36 xs:mt-36 mt-36  pt-3 pb-3" align={"middle"} justify={"space-between"} style={{ backgroundColor: "#f1f1f1" }}>
    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="text-xl font-bold d-flex sm:justify-start md:justify-start lg:justify-start xl:justify-start justify-center" >
         About us
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ backgroundColor: "#f1f1f1" }}>
          <Breadcrumb separator=">" className="d-flex justify-center md:justify-end lg:justify-end xl:justify-end font-semibold text-black p-2">
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/aboutus">About us</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
    </Row>
      <Row className=" lg;ps-0 xl:ps-36 xl:pe-36 md:ps-10 sm:ps-5 xs:ps-5  ">
  
        <Col span={24}>
          <div className="text-start pt-5 pb-4 font-extrabold text-3xl text-orange-500">
          Introduction about Rapidexie
          </div>
          <div className="text-2xl text-orange-500 pb-3 font-bold">
            Rapidexie
          </div>
          <div className="text-lg text-black pb-2">
          Rapidexie provide a platform for individuals to build their dreams, develop their skills, and create a lasting legacy for themselves and their families. Through our proven business model, we offer a range of high-quality products/services that not only enrich lives but also provide lucrative opportunities for our independent distributors.
            </div>
        </Col>
        <Col span={24} className="pb-5 pt-5" >
          <Row className="mt-5">
          <Col className=" pl-5" xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
              <p className="text-xl font-bold text-orange-500">
              •	Our Vision 
              </p>
              <p className="mt-3 italic tracking-wide text-base">
              Broaden horizons for determined individuals who epitomize a growth-centric philosophy, providing them with a meticulously crafted framework to earnestly pursue their deepest aspirations and ambitious dreams.
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
              <div className="">
                {" "}
                <img
                  src={vision}
                  alt="Blog"
                  className=" rounded-lg "
                  style={{
                    width: "100%",
                    height: "50vh",
                  }}
                />
              </div>
            </Col>
           
          </Row>
        </Col>
        <Col span={24} >
          <Row className="mt-5">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
              <div className="">
                {" "}
                <img
                  src={mission}
                  alt="Blog"
                  className=" rounded-lg "
                  style={{
                    width: "100%",
                    height: "50vh",
                  }}
                />
              </div>
            </Col>
            <Col className=" pl-5" xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
              <p className="text-xl font-bold text-orange-500">
              •	Our Mission
              </p>
              <p className="mt-3 italic tracking-wide text-base">
              Formulating a business model aimed at facilitating direct access to premium products and services for consumers, thereby presenting them with a lucrative prospect to generate wealth autonomously.
              </p>
            </Col>
          </Row>
        </Col>
       
    
      </Row>
    </>
  );
};

export default Aboutus;
