import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Row
        className="lg:mt-60 xl:mt-60 md:mt-60 sm:mt-36 xs:mt-36 mt-36 pt-3 pb-3"
        align="middle"
        justify="space-between"
        style={{ backgroundColor: "#f1f1f1" }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="text-2xl font-bold d-flex justify-center md:justify-start p-4"
        >
          Privacy Policy
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Breadcrumb
            separator=">"
            className="d-flex justify-center md:justify-end font-semibold text-black p-2 m-4"
          >
            <Breadcrumb.Item className="">
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/aboutus">Privacy Policy</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {/* <div className="space-y-6 max-w-4xl mx-auto px-4 py-8">
        {" "}
        <section className="space-y-4">
          <p className="text-gray-700 text-lg leading-relaxed">
            Dear Sir, we want to make you aware that Rapidexie is not a Pongy
            scheme or quick money-earning shortcut formula. This is a system
            where you, after becoming a part-time direct seller, can sell our
            valuable products & services and earn effort-based high income and
            rewards based on your dedication towards generating more sales.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed">
            Before you proceed with the business opportunity for making a
            parallel part-time income generation by selling our valuable
            products & services with the team of Rapidexie family, it is
            essential to understand and agree to our Code of Ethics. Our
            commitment to ethical behavior is the main pillar of our integrity
            and trustworthiness. By accepting our opportunity, you agree to
            adhere to the following code:
          </p>
        </section>
        <section className="space-y-4">
          <h2 className="text-xl font-semibold text-gray-800">
            Code of Ethics
          </h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>Always conduct business honestly and with integrity.</li>
            <li>Treat all customers and clients with respect and fairness.</li>
            <li>
              Ensure that all representations made regarding products and
              services are accurate and truthful.
            </li>
            <li>
              Comply with all laws and regulations related to business
              activities.
            </li>
            <li>Maintain confidentiality of all customer information.</li>
            <li>
              Avoid any actions that could harm the reputation of Rapidexie.
            </li>
          </ul>
        </section>
      </div> */}

<div className="container mx-auto px-4 py-8">
      <section className="mb-6">
        <h1 className="text-4xl font-bold mb-6 text-center">Welcome to Rapidexie</h1>
        <p className="mb-4 text-base">
          We, Rapidexie as a Company, are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website{" "}
          <a href="https://www.rapidexie.com" className="text-blue-600">www.rapidexie.com</a>, purchase our products, or participate in our RAPIDEXIE program. By using our website and services, you agree to the practices described in this Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">1. Information We Collect</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">1.1 Personal Information</h3>
        <p className="mb-1 text-base ml-6">
          When you interact with us, we may collect personal information, including but not limited to:
        </p>
        <ul className="list-disc list-inside mb-4 text-base ml-6">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>Payment information</li>
        </ul>
        <h3 className="text-lg font-semibold mb-2 ml-2">1.2 Non-Personal Information</h3>
        <p className="mb-4 text-base ml-6">
          We may also collect non-personal information such as:
        </p>
        <ul className="list-disc list-inside mb-4 text-base ml-6">
          <li>IP address</li>
          <li>Browser type</li>
          <li>Operating system</li>
          <li>Usage data (e.g., pages visited, time spent on the site)</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">2. How We Use Your Information</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.1 To Provide Services</h3>
        <p className="mb-4 text-base ml-6">
          We use your personal information to process transactions, deliver products, and provide customer support.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.2 To Improve Our Services</h3>
        <p className="mb-4 text-base ml-6">
          We analyze non-personal information to understand how our website and services are used and to improve user experience.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.3 To Communicate with You</h3>
        <p className="mb-4 text-base ml-6">
          We may use your contact information to send updates, newsletters, and promotional materials related to our products and services. You can opt out of marketing communications at any time.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.4 To Comply with Legal Obligations</h3>
        <p className="mb-4 text-base ml-6">
          We may use your information to comply with legal obligations, respond to requests from law enforcement, or enforce our Terms and Conditions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">3. How We Share Your Information</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.1 Service Providers</h3>
        <p className="mb-4 text-base ml-6">
          We may share your information with third-party service providers who perform services on our behalf, such as payment processors, shipping companies, and marketing partners. These providers are bound by confidentiality agreements and are prohibited from using your information for any purpose other than providing services to us.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.2 Business Transfers</h3>
        <p className="mb-4 text-base ml-6">
          In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity. We will notify you of any such change and how it affects your information.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.3 Legal Requirements</h3>
        <p className="mb-4 text-base ml-6">
          We may disclose your information if required to do so by law or to protect our rights, property, or safety, or the rights, property, or safety of others.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">4. Data Security</h2>
        <p className="mb-4 text-base ml-6">
          We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">5. Your Rights and Choices</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">5.1 Access and Correction</h3>
        <p className="mb-4 text-base ml-6">
          You have the right to access and correct your personal information. If you would like to review or update your information, please contact us using the information provided below.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">5.2 Opt-Out</h3>
        <p className="mb-4 text-base ml-6">
          You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications or by contacting us directly.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">5.3 Data Retention</h3>
        <p className="mb-4 text-base ml-6">
          We retain your personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">6. Children’s Privacy</h2>
        <p className="mb-4 text-base ml-6">
          Our services are not intended for individuals under the age of 18 years. We do not knowingly collect or solicit personal information from children under 18 years. If we become aware that we have collected personal information from a child under 18 years, we will take steps to delete such information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2 ">7. Changes to This Privacy Policy</h2>
        <p className="mb-4 text-base ml-6">
          We may update this Privacy Policy from time to time. Any changes will be posted on our website with an updated effective date. Your continued use of our services after any changes constitutes your acceptance of the updated Privacy Policy.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mt-2">10. Contact Information</h2>
        <p className="mb-6 text-base ml-6">
          For any questions about these Terms, please contact us at:
        </p>
        <address className="not-italic mt-6 pt-6 text-base">
          Rapidexie <br />
          D-9, Vyapar Marg, Sector-3 Noida <br />
          <a href="mailto:info@rapidexie.com" className="text-base text-blue-600">info@rapidexie.com</a> <br />
          <a href="tel:+919818755350" className="text-base text-blue-600">9818755350</a>
        </address>
      </section>
    </div>
    </>
  );
};

export default PrivacyPolicy;
