import React from 'react';
import { Layout, Row } from 'antd';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import { TfiYoutube } from 'react-icons/tfi';
import { GetSettingData } from '../../utils/API';
import { Link } from 'react-router-dom';
import { BsLinkedin } from 'react-icons/bs';
const { Footer } = Layout;

const FooterEnd: React.FC = () => {
  const [settings, setSettings] = React.useState<any>({});

  const fetchSettingsData = async () => {
    try {
      const response = await GetSettingData();
      setSettings(response.settings[0]);
    } catch (error) {
      console.error('Failed to fetch settings data:', error);
    }
  };

  React.useEffect(() => {
    fetchSettingsData();
  }, []);

  return (
    <Footer>
      <Row className='m-1' style={{ fontSize: '12px', color: '#a6a6a6', alignItems: 'center' }}>
        <span>© Copyright {settings.copyrightYear ? settings.copyrightYear : '2024' } All rights reserved.</span>
        <span style={{ margin: '0 10px' }}>|</span>
        <span>STAY CONNECTED</span>
     <Link to={settings.facebookLink ? settings.facebookLink : '#' } target='_blank'>
     <span style={{ marginLeft: '10px', fontSize: '16px' }}>
          <FacebookOutlined style={{ color: '#3b5998' }} />
        </span>
     </Link>
     <Link to={settings.instagramLink ? settings.instagramLink : '#'} target='_blank'>
        <span style={{ marginLeft: '10px', fontSize: '16px' }}>
          <InstagramOutlined style={{ color: '#c13584' }} />
        </span>
     </Link>
     <Link to={settings.twitterLink ? settings.twitterLink : '#'} target='_blank'  >
        <span style={{ marginLeft: '10px', fontSize: '16px' }}>
          <TwitterOutlined style={{ color: '#1da1f2' }} />
        </span>
     </Link>
   
     <span style={{ marginLeft: '10px', fontSize: '16px'  }}>
        <Link to={settings.linkedinLink ? settings.linkedinLink : '#' } target='_blank' >
       
          <BsLinkedin style={{ color: '#0077b5' }} />
         
          </Link>
        
          </span>
      </Row>
    </Footer>
  );
};

export default FooterEnd;
