
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  message,
  Card,
  Spin,
  Select,
  Tooltip,
  Switch,
  Upload,
  Button,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline, IoCloudUploadOutline } from "react-icons/io5";
import dayjs from "dayjs";
import { deleteImage, getCustomerByID, updateCustomer } from "../../utils/API";
import { FaArrowLeft } from "react-icons/fa";
import { AuthContext } from "../Auth/AuthContext";
import { IoIosInformationCircle } from "react-icons/io";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import "./Edit.css"
import { BsUpload } from "react-icons/bs";
import { BiSolidInbox } from "react-icons/bi";
import Dragger from "antd/es/upload/Dragger";
import { MdDeleteOutline } from "react-icons/md";

interface StateData {
  [key: string]: string;
}

const { Option } = Select;

const CustomerEditForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [apiImageUploaded, setApiImageUploaded] = useState(false);
  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = React.useState(false);
  const { id } = useParams();
  const [ country , setCountry]    = React.useState([]);
  const [panAadharData, setPanAadharData] = useState({ pan: null, aadhar: null });
  const { authData } = useContext(AuthContext);
  const [isKyc, setIsKyc] = useState(0); // State for KYC switch
  const roleId = authData.roleId;
  const getSwitchClass = () => {
    if (isKyc === 1) return 'bg-gray-500';
    if (isKyc == 2) return 'bg-Green-500';
    return 'bg-gray-300';
  };


  const handleKycDetails = async (checked: any) => {
    try {
      const fieldsToValidate = [
        "image",
        'firstName',
        'lastName',
        'pan',
        'aadhar',
        'email',
        'mobileNo'
      ];
      await form.validateFields(fieldsToValidate);

      setIsKyc(checked ? 2 : 0);
      const id = form.getFieldValue("_id");
      if (id) {
        try {
          const response = await updateCustomer(id, { isKyc: checked ? 2 : 0 });
          if (response && response.data && response.data.message) {
            message.success(response.data.message);
          } else {
            message.success("KYC status updated successfully");
          }
        } catch (error) {
          message.error("Failed to update KYC status. Please try again.");
        }
      }
    } catch (errorInfo) {
      // If validation fails, show an error message
      message.error("Please fill all required fields for KYC");
    }
  };


  const fetchData = async () => {
    try {
      const existingData = await getCustomerByID(id); // Replace with your actual API call

      // Set form fields and other state based on existingData
      setPanAadharData({
        pan: existingData.pan,
        aadhar: existingData.aadhar,
      });
      form.setFieldsValue({
        ...existingData,
        dob: dayjs(existingData.dob).format("YYYY-MM-DD"),
        address: existingData.address
      });

      setIsKyc(existingData.isKyc);

      // Display existing image from API, if available
      if (existingData.image) {
        const imageUrl = `${process.env.REACT_APP_Image_URL}/users/${existingData.image}`;
        setFileList([{
          uid: '1',
          name: existingData.image,
          status: 'done',
          url: imageUrl,
        }]);
        setPreviewImage(imageUrl); // Set preview image if needed
        setApiImageUploaded(true); // Mark that the image is fetched from API
      } else {
        setApiImageUploaded(false); // Reset apiImageUploaded if no image from API
      }
    } catch (error) {
      message.error("Error fetching data. Please try again.");
      navigate("/backend/dashboard");
    }
  };

  
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/uploads/country.json");
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        const data = await response.json();
        setCountry(data); // Ensure data is set correctly
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchData();
  }, []);

  const getBase64 = (file: File | Blob) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

    const handleRemove = (event: any) => {
      event.stopPropagation();
      if (apiImageUploaded) {
        deleteImage(id)
          .then(() => {
            setPreviewImage("");
            setFileList([]);
            fetchData();
          })
          .catch((error) => {
            message.error("Failed to delete image. Please try again.");
          });
      } else {
        // Handle for temporarily uploaded images or do nothing
        setPreviewImage("");
        setFileList([]);
      }
    };

    const imageProps = {
      name: 'image',
      multiple: false,
      beforeUpload(file: File) {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
          message.error('You can only upload image files!');
          return false;
        }
        const isLt64M = file.size / 1024 / 1024 < 64;
        if (!isLt64M) {
          message.error('Image must be smaller than 64MB!');
          return false;
        }
        return isImage && isLt64M;
      },
      onChange(info: { fileList: UploadFile[] }) {
        let newFileList = [...info.fileList];
  
        // Process the new uploaded file
        if (newFileList.length > 0) {
          const file = newFileList[0];
          if (!file.url && !file.preview) {
            getBase64(file.originFileObj as File).then((preview: string) => {
              file.preview = preview;
              setPreviewImage(preview);
            });
          } else {
            setPreviewImage(file.name || file.preview || '');
          }
        } else {
          setPreviewImage('');
        }
  
        // Update fileList to keep only the last uploaded file
        newFileList = newFileList.slice(-1);
        
        // Update state with the new fileList
        setFileList(newFileList);
      },
      fileList,
    };


  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue("_id");
    if (currentId) {
      setIsLoading(true);
      try {
        const formData = new FormData();
  
        // Append all form fields except 'image' to formData
        Object.keys(values).forEach(key => {
          if (key !== 'image') {
            formData.append(key, values[key] || '');
          }
        });
  
  
        if (fileList.length > 0 && fileList[0].originFileObj) {
          formData.append('image', fileList[0].originFileObj as Blob);
        }
  
        formData.append('isKyc', isKyc.toString());
  
        // Make the API call to update customer
        const response = await updateCustomer(currentId, formData); // Adjust your API call to handle FormData
        setIsLoading(false);
  
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
          navigate("/backend/users");
        } else {
          message.success("Profile updated successfully");
          form.setFieldsValue({ password: "" });
          navigate("/backend/users");
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Failed to submit data. Please try again.");
      }
    } else {
      setIsLoading(false);
      message.error("Failed to fetch data. Please try again.");
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit User</h2>
        </Col>
        
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/users"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
    <Form onFinish={handleSubmit} form={form} className="bg-white">
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Row
          className="border border-warning-2 md:p-5 lg:p-5 p-2 bg-white rounded-md"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          {/* First name */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Profile 
                </label>
              </Col>
              <Col xs={24} sm={4} md={4}>
        <Form.Item name="image">
          <Upload.Dragger {...imageProps}>
            {!previewImage && (
              <div className="w-full">
                <p className="ant-upload-drag-icon d-flex justify-center">
                  <BiSolidInbox />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </div>
            )}
            {previewImage && (
              <div className="relative h-40 w-40">
                <img src={previewImage} alt="Uploaded preview" className="w-full h-full" />
                <button
                  className="absolute top-0 right-0 bg-orange-500 text-black p-1"
                  onClick={handleRemove}
                >
                  <MdDeleteOutline />
                </button>
              </div>
            )}
          </Upload.Dragger>
        </Form.Item>
      </Col>
            </Row>
          </Col>

          {/* city */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  City
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="city"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please Enter city",
                    // },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="City"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* address */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Address
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="address"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Address",
                  //   },
                  // ]}
                >
                  <Input.TextArea
                    rows={4}
                    cols={30}
                    size="large"
                    className="rounded border border-1"
                    placeholder="Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* states */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Country
                </label>
              </Col>
              <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="country"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select country",
                //   },
                // ]}
                >
                <Select
                  size="large"
                  className="rounded border border-1"
                  placeholder="Select country"
                >
                  {country.map((c : any ) => (
                    <Option key={c.code} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
                </Form.Item>

              </Col>
            </Row>
          </Col>


          {/* pincode */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Pin Code</label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="pincode"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter pincode",
                  //   },
                  //   {
                  //     pattern: new RegExp(/^[0-9]+$/),
                  //     message: "Please Enter Valid Contact",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="pin Code"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* KYC Details */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
            <Row className="bg-white">
              <Col span={4}>
                <label className="font-bold text-4xl" style={{ fontSize: "20px" }}>
                  KYC DETAILS
                </label>
              </Col>
              <Col sm={24}  md={12} className="d-flex justify-content-end align-items-center">
                <label className="font-normal text-sm pr-4">{isKyc === 1 ? "KYC PENDING" : null} {isKyc === 2 ? "KYC APPROVED" : null} </label>
                {
  Number(isKyc) === 1 || Number(isKyc) === 2 ? (
    <Switch
      className={`switch ${getSwitchClass()}`} // Apply custom class based on isKyc value
      checked={Number(isKyc) === 2} // Bind switch to state
      onChange={(checked) => handleKycDetails(checked ? 2 : 0)} // Handle state change and API call
      disabled={Number(isKyc) === 2}
    />
  ) : null
}

              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  First Name
                  <span className="text-danger">*</span>
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Name",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder=" Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Last Name */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Last Name
                  <span className="text-danger">*</span>
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Name",
                    },
                  ]}
                >
                  <Input

                    size="large"
                    className="rounded border border-1"
                    placeholder=" Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* PAN Number */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5"
              >
                <label className="font-bold d-flex align-items-center ">
                  <div>
                    IT PAN
                  </div>
                  {panAadharData.pan !== null && panAadharData.pan !== "" && roleId == 2 ? (
                    <div>
                      <Tooltip title="Please Contact Administrator">
                        <IoIosInformationCircle
                          style={{ marginLeft: "5px" }}
                        />
                      </Tooltip>
                    </div>
                  ) : null}
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="pan"
                  // rules={[
                  //   { required: true, message: "Please Enter PAN no" },
                  //   { validator: validatePAN },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="format :- xxxxx0000x"
                    disabled={
                      panAadharData.pan !== null &&
                      panAadharData.pan !== "" &&
                      roleId === 2
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Aadhar Number */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5"
              >
                <label className="font-bold d-flex align-items-center ">
                  <div>
                    Aadhar No
                  </div>
                  {panAadharData.aadhar !== null &&
                    panAadharData.aadhar !== "" && roleId == 2? (
                    <div>
                      <Tooltip title="Please Contact Administrator">
                        <IoIosInformationCircle
                          style={{ marginLeft: "5px" }}
                        />
                      </Tooltip>
                    </div>
                  ) : null}
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="aadhar"
                  // rules={[
                  //   { required: true, message: "Please Enter Aadhar no" },
                  //   {
                  //     min: 12,
                  //     max: 12,
                  //     message: "Please Enter 12 digit Aadhar no",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Aadhar no"
                    disabled={
                      panAadharData.pan !== null &&
                      panAadharData.pan !== "" &&
                      roleId === 2
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Email */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Email
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="email"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Email",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Email"
                    
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>


          {/* Contact */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Contact
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="mobileNo"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Contact",
                  //   },
                  //   {
                  //     pattern: new RegExp(/^[0-9]+$/),
                  //     message: "Please Enter Valid Contact",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    
                    className="rounded border border-1"
                    placeholder="Contact"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* end of KYC Details */}

          {/* Bank Details */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
            <Row className="bg-white">
              <label
                className="font-bold text-4xl"
                style={{ fontSize: "20px" }}
              >
                BANK DETAILS
              </label>
            </Row>
          </Col>

          {/* Bank Name */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Bank Name
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="bankName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Bank Name",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Bank Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Bank Address */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Bank Address
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="bankAddress"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Bank Address",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Bank Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Bank Account No. */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Bank Account No.
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="bankAccountNo"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Bank Account No.",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Bank Account No."
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Bank IFSC Code
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="ifsc"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter IFSC Code",
                  //   },
                  //   {
                  //     validator: (_, value, callback) => {
                  //       if (value && value.length >= 5 && value[4] !== "0") {
                  //         callback("5th Character must be Zero");
                  //       } else {
                  //         callback();
                  //       }
                  //     },
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="IFSC Code"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
            <Row className="bg-white">
              <label
                className="font-bold text-4xl"
                style={{ fontSize: "20px" }}
              >
                NOMINEE DETAILS
              </label>
            </Row>
          </Col>

          {/* Bank Name */}
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Nominee Name
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="nomineeName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Nominee Name",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Nominee Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col
                xs={24} sm={24} md={4}
                className="d-flex justify-content-start me-4 bg-white mb-2 lg:mb-5  "
              >
                <label className="font-bold">
                  Relation
                </label>
              </Col>
              <Col sm={24}  md={12}>
                <Form.Item
                  name="nomineeRelation"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Relation",
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Relation"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            xl={24}
            xxl={24}
            className="d-flex justify-content-center"
          >
            <button className="btn-brand" disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </Col>
        </Row>
      </Card>
    </Form>
  </div>
  );
};

export default CustomerEditForm;