import React, { useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
// import { AiFillDelete } from 'react-icons/ai';
import { Switch, Table, Spin, Card, Col, Row, Input, message, Modal, Button } from 'antd';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { getproductsize, ProductSizeActive, ProductSizeDelete } from '../../../utils/API';

interface SliderData {
  _id: number;
  name: string;
  active: boolean;
  __v: number;
}

const ProductSizeTable: React.FC = () => {
  const [slidersData, setSlidersData] = useState<SliderData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number | null>(null); // State for the ID of the item to be deleted
  const navigate = useNavigate();

  const fetchSizeData = () => {
    setLoading(true);
    getproductsize()
      .then((response: any) => {
        setSlidersData(response.data.sizes);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchSizeData();
  }, []);

  const handleEditRedirect = (record: SliderData) => {
    navigate(`/backend/product-size/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: number) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId !== null) {
      try {
        const response = await ProductSizeDelete(deleteId);
        if (response.status === 200) {
          message.success(response.data.message);
          fetchSizeData();
        } else {
          message.error("Something went wrong");
        }
      } catch (error: any) {
        message.error(error.response.data.message);
      } finally {
        setDeleteId(null);
      }
    }
  };

  const sizeActiveInactive = (record: SliderData) => {
    ProductSizeActive(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          fetchSizeData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Name</div>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Status</div>,
      dataIndex: 'isActive',
      key: 'isActive',
      render: (active: boolean, record: SliderData) => (
        <Switch
          checked={active}
          className={active ? 'bg-orange-500' : 'bg-gray-500'}
          onChange={() => {
            sizeActiveInactive(record);
          }}
        />
      ),
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Action</div>,
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: SliderData) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon'
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            onClick={() => handleDeleteConfirmation(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col xs={16} sm={16} md={18} xl={20} xxl={21}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Sizes</h2>
        </Col>
        <Col xs={8} sm={8} md={6} xl={4} xxl={3} className="">
          <Link to={"/backend/product-size/add"}>
            <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
              <RiAddBoxFill style={{ fontSize: "15px" }} />
              <div className="ms-2">Add Size</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col style={{ backgroundColor: "#ffffff", borderRadius: "12px" }} xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={slidersData || []} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        footer={null}
        cancelText="No"
      >
        <p>Are you sure you want to delete this size?</p>
        <div style={{ textAlign: 'end' }}>
          <Button
            onClick={handleDelete}
            type="primary"
            className="rounded"
            style={{ backgroundColor: "orange",color: "white", marginRight: "1rem" }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProductSizeTable;
