import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  getUsersChildrenLists,
} from "../../../../utils/API";


interface Customer {
  _id: any;
  firstName: string;
  lastName: string;
  username: string;
  userId: any;
  email: string;
  mobileNo: string;
}

const UpdateBankDetail: React.FC = () => {
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchCustomerData = (page: any, searchText: any) => {
    const customerId = localStorage.getItem("customerId") || "";
    const customuserId = customerId.replace(/"/g, "");
  
    setLoading(true);
    getUsersChildrenLists(customuserId, page, searchText)
      .then((response: any) => {
        // Filter out data with non-empty bank-related fields
        const filteredData = response.data.children.filter((item: any) => 
          !item.bankName || 
          !item.bankAccountNo || 
          !item.bankAddress || 
          !item.ifsc
        );
  
        setCustomerData(filteredData.reverse());
  
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error("Failed to fetch customer data. Please try again.");
        setLoading(false);
      });
  };
  

  useEffect(() => {
    fetchCustomerData(currentPage, searchText);
  }, [currentPage, searchText]);



  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) =>
        `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
   
    {
      title : 'Bank Name',
      dataIndex : 'bankName',
      key : 'bankName',
    },
    {
        title : "Account Number",
        dataIndex : "bankAccountNo",
        key : "bankAccountNo",
    },
    {
      title : "Bank Address",
      dataIndex : "bankAddress",
      key : "bankAddress",
  },
  {
    title : "IFSC Code",
    dataIndex : "ifsc",
    key : "ifsc",
  },
  
  {
    title : "City",
    dataIndex : "city",
    key : "city",
  },
  
  {
    title : "Mobile No",
    dataIndex : "mobileNo",
    key : "mobileNo",
  },
  ];

  return (
    <div className="tripod-component">
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={16} xxl={16}>
          <span className="text-black text-lg font-bold">
          Update Downline Bank Detail
          </span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={15}
          xl={8}
          xxl={8}
          className="d-flex justify-end items-center"
        >
          <Input
            placeholder="Search By Name, Username"
            prefix={<SearchOutlined className="mr-1" />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            allowClear
            size="large"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={customerData}
        rowKey={(record) => record.userId}
        loading={loading}
        pagination={{
          current: currentPage,
          total: totalPages * 10,
          onChange: (page) => setCurrentPage(page),
        }}
      />
  
    </div>
  );
};

export default UpdateBankDetail;
