import React, { ReactNode } from "react";
import "./TreeComponent.css"; // Import CSS file for styling
import { Card, Col, Row } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";

interface User {
  SevenTripod: number;
  subSevenTripod: any;
  subTripod: any;
  ismaintriposd: string;
  node: string;
  status: string;
  sevenTripod: number;
  tripod: number;
  active: boolean;
  orderStatus: ReactNode;
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  placement: string;
  matchingPair: string;
  children?: User[];
}

interface TreeComponentProps {
  data: User | User[];
  onNodeClick: (node: User) => void;
  activeNode: User | null;
  onNodeLoginClick: (node: User) => void;
  handleBackClick: () => void;
}

const FindGeneology: React.FC<TreeComponentProps> = ({
  data,
  onNodeClick,
  handleBackClick,
  onNodeLoginClick
}) => {
  const renderTree = (node: User, isFirst: boolean = false) => {
    if (!node) return null;

    const handleClick = (event: any) => {
      event.stopPropagation();
      onNodeClick(node);
    };

    const handleLoginClick = (event: any) => {
      event.stopPropagation();
      onNodeLoginClick(node);
    };

    // Function to find title based on points
    const findTitle = (matchingPair: string) => {
      const matchingPoints = parseInt(matchingPair);
      if (matchingPoints >= 37500) {
        return "👨‍✈️";
      } else if (matchingPoints >= 25000) {
        return "👑";
      } else if (matchingPoints >= 12500) {
        return "💠";
      } else if (matchingPoints >= 6750) {
        return "🌻";
      } else if (matchingPoints >= 5000) {
        return "🌷";
      } else if (matchingPoints >= 2500) {
        return "🌹";
      } else if (matchingPoints >= 1250) {
        return "🟪";
      } else if (matchingPoints >= 250) {
        return "🌺";
      } else if (matchingPoints >= 125) {
        return "🏵️";
      } else if (matchingPoints >= 50) {
        return "🌸";
      } else if (matchingPoints >= 25) {
        return "💮";
      } else if (matchingPoints >= 0) {
        return "⚡";
      } 
    };


    const children = node.children || [];
    const leftChild = children.find((child) => child.placement === "1");
    const rightChild = children.find((child) => child.placement === "2");
    let status = '';
    if (node.active === false) {
      status = '❌';
    } else if (node.tripod === 1 || node.SevenTripod === 1) {
      if (node.tripod === 1) {
        status = '🏰';
      } else {
        status = '👨🏻‍👩🏻‍👦🏻‍👦🏻';
      }
    } else {
      if (node.orderStatus === 0) {
        status = '🔴';
      } else {
        status = '🟢';
      }
    }

    return (
      <div className="tree-node-container">
      
        <div className="node-card" onClick={handleLoginClick}>

          <span className="text-orange-500 d-flex justify-center items-center">
          
          <p className="          pr-2 pl-12 d-flex text-xl justify-end"> { status} </p>
     
             <div className="m-1 text-xl ">{findTitle(node.matchingPair)}</div>
             <p onClick={handleClick} className="cursor-pointer text-lg"> Login </p>
          </span>
       
          <div className="tree-node">
        
            <div className="node-label">
              {node.firstName} <br /> ({node.username}) 
         
            </div>
            <div className="connector">
              {node.children && node.children.length > 0 && (
                <>
                  <div className="vertical-line"></div>
                  <div className="horizontal-line-container ">
                    <div className="vertical-line"></div>
                    <div className="horizontal-line"></div>

                  </div>
                </>
              )}
              {node.children && node.children.length === 2 && (
                <div className="horizontal-line-container">
              
                  <div className="horizontal-line"></div>
                  <div className="vertical-line"></div>
                </div>
              )}
              {node.children && node.children.length === 1 && <>
               
                  <div className="horizontal-line-container">
                 
                    <div className="horizontal-line"></div>
                    <div className="vertical-line"></div>
                  </div>
              </>}
            </div>
            <div className="children">
              <div className="left-child">
                {leftChild ? (
                  renderTree(leftChild)
                ) : (
                  <div className="empty-placeholder"></div>
                )}
              </div>
              <div className="right-child">
                {rightChild ? (
                  renderTree(rightChild)
                ) : (
                  <div className="empty-placeholder"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return Array.isArray(data) ? (
    <>
      <div className="bg-white mt-4">
        <Row justify={"end"}>
          <Col
            span={24}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              className="bg-orange-500 pt-2 mt-2 text-white py-2 px-4 rounded d-flex align-items-center"
              onClick={handleBackClick}
            >
              <IoArrowBackOutline /> Back
            </button>
          </Col>
        </Row>
        {data.map((node) => (
          <div key={node._id}>{renderTree(node)}</div>
        ))}
      </div>
    </>
  ) : (
    <div className="bg-white mt-4">
      <Row justify={"end"}>
        <Col
          span={24}
          style={{
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            className="bg-orange-500 pt-2 mt-2 text-white py-2 px-4  rounded d-flex align-items-center"
            onClick={handleBackClick}
          >
            <IoArrowBackOutline /> Back
          </button>
        </Col>
      </Row>
      {renderTree(data)}
    </div>
  );
};

export default FindGeneology;
