
const PersonalCare = () => {
  return (
        <>
            <p> thisis PersonalCare  page </p>
        </>
    );
};

export default PersonalCare;
