import React, { useEffect, useState } from 'react';
import { downlineOrder } from '../../../../utils/API';
import { message, Table, Tag } from 'antd';
import moment from 'moment';

const DownlineOrderStatus = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPages, setTotalPages] = useState(1);

  const fetchOderDetails = (page : any) => {
    const customerID = localStorage.getItem('customerId') || '';
    const userId = customerID.replace(/"/g, '');

    downlineOrder(userId , page).then((response) => {
      const filteredData = response.results.filter((user : any) => user.orders && user.orders.length > 0);
      setData(filteredData);
      setTotalPages(response.totalPages);
        setCurrentPage(response.currentPage);
        setLoading(false);
    })
    .catch((error) => {
      message.error('Network error. Please try again.');
    });

  }

  const getStatusTag = (status : any) => {
    switch (status) {
      case 0:
        return <Tag color="red">Not Delivered</Tag>;
      case 1:
        return <Tag color="yellow">Out For Delivery</Tag>;
      case 2:
        return <Tag color="green">Delivered</Tag>;
      default:
        return <Tag color="gray">Unknown</Tag>;
    }
  };

  const columns = [
    {
      title: 'Sr No.',
      dataIndex: 'srNo',
      key: 'srNo',
      render: (text : any, record : any, index : any) => index + 1,
    },
    {
      title: 'Order No',
      dataIndex: 'orders',
      key: 'orderNo',
      render: (orders : any) => orders.map((order : any) => order.invoiceNumber).join(', '),
    },
    {
      title: 'Order Date',
      dataIndex: 'orders',
      key: 'orderDate',
      render: (orders : any) => orders.map((order : any) => new Date(order.orderDate).toLocaleDateString()).join(', '),
    },
    {
      title: 'ID No',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
    },
    {
      title: 'Colony',
      dataIndex: 'orders',
      key: 'colony',
      render: (orders : any) => orders.map((order : any) => order.address).join(', '),
    },
    {
      title: 'Amount',
      dataIndex: 'orders',
      key: 'amount',
      render: (orders : any) => orders.map((order : any ) => order.amount).join(', '),
    },
    {
      title: 'Payment Method',
      dataIndex: 'orders',
      key: 'paymentMethod',
      render: (orders : any) => orders.map((order : any ) => order.paymentMethod).join(', '),
    },
   
    {
      title: 'Delivery Option',
      dataIndex: 'orders',
      key: 'deliveryOption',
      render: (orders: any) => orders.map((order: any) => {
        const status = order.status;
        return status === 2 ? "Yes" : "No";
      }).join(', ')
    },
    {
      title: 'Tracking No',
      dataIndex: 'orders',
      key: 'trackingNo',
      render: (orders: any) => orders.map((order: any) => {
        const trackingNo = order.trackingNo;
        return trackingNo ? trackingNo : '-';
      }).join(', ')
    },
     {
      title: 'Dispatch Date',
      dataIndex: 'orders',
      key: 'deliveryDate',
      render: (orders: any) => orders.map((order: any) => {
        const deliveryDate = order.deliveryDate;
        return deliveryDate ? new Date(deliveryDate).toLocaleDateString() : 'N/A';
      })
    },   
  ];  

  useEffect(() => {
    fetchOderDetails(currentPage);
  }, [currentPage])


  return (
    <div className="p-4">
    <div className="bg-red-700 text-white text-center py-2 mb-4">
      <h1 className="text-2xl font-bold">Downline Order Status</h1>
    </div>

    <div className="bg-gray-100 p-4 rounded-lg mb-4">
      <p className="text-gray-700 mb-2">
        Any order takes 2-5 days to process.. But If your is very urgent.. you can collect the products from any of the Naswiz Stores(stock points), or Rohini warehouse.. Also the courier is taking more time than usual, as the routes are closed.. and at some places service is limited.. Pls try and understand the situation and do not panic, or create fuss.. as we are also managing with limited resources and trying to give our best
      </p>
      <p className="text-gray-700">
        We usually courier it via DTDC.. if you want to check status of the courier, you can check using the below link
      </p>
      <a
        href="https://www.dtdc.in/tracking.asp"
        target='_blank'
        className="block bg-blue-500 text-white text-center py-2 mt-2 rounded-md"
      >
        Click here for DTDC Courier Tracking
      </a>
    </div>

    <div className="grid grid-cols-3 gap-4 mb-4">
      <div className="bg-red-500 text-white text-center py-2 rounded-md">Not Deliver</div>
      <div className="bg-yellow-400 text-white text-center py-2 rounded-md">Out For Delivery</div>
      <div className="bg-green-500 text-white text-center py-2 rounded-md">Deliver</div>
    </div>
    <div className="bg-pink-500 text-white text-center py-2 rounded-md mb-4">
      For any queries related to Home Delivery and Courier plz contact  +919818755350
    </div>

    <div className="overflow-x-auto">
      <Table  columns={columns} dataSource={data} rowKey="userId"  loading={loading} 
      scroll={ { x: 1000 } }
        pagination={{
          current: currentPage,
          total: total,
          pageSize: 10  ,
          onChange: (page) => setCurrentPage(page),
        }}

        
      />
    </div>
  </div>
  );
};

export default DownlineOrderStatus;
