import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import Login from "./Pages/Auth/Login";
import NotFound from "./Components/NotFound";
import { backendRoutes } from "./Routes/backendRoutes";
import Master from "./Components/backend/Master";
import DashboardUser from "./Pages/frontend/Dashboard";
import frontendRoutes from "./Routes/frontendRoutes";
import { AuthContext, AuthProvider } from "./Pages/Auth/AuthContext";
import Homepage from "./Pages/frontend/Homepage";
import FrontendMaster from "./Components/frontend/Master";
import Register from "./Pages/Auth/Register";
import { CartProvider } from "./utils/Cartcontext";
import Cart from "./Pages/frontend/Cart/Cart";
import { WishlistProvider } from "./utils/WishListContextr";
import WishList from "./Pages/frontend/WishList/WishList";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import IdleTimerWrapper from "./Pages/Auth/userIdleTimer";
import SupportCenterLogin from "./Pages/Auth/SupportCenterLogin";

const App: React.FC = () => {
  const { authData , setAuthData } = useContext(AuthContext);
  const token = authData.token;

  const logout = () => {
    setAuthData({ token: null, roleId: null });
      const cart = localStorage.getItem('cart');
      const cart_userid = localStorage.getItem('cart_userid');
      localStorage.clear();
      if (cart) localStorage.setItem('cart', cart);
      if (cart_userid) localStorage.setItem('cart_userid', cart_userid);
    
  };

  return (
    <AuthProvider>
      <CartProvider>
        <WishlistProvider>
          <BrowserRouter>
            <IdleTimerWrapper timeoutDuration={5 * 60 * 1000} logoutCallback={logout}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/support-center/login" element={<SupportCenterLogin />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/register" element={<Register />} />
                {/* Private routes */}
                <Route element={<PrivateRoute children={<DashboardUser />} />}>
                  {backendRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<Master children={<route.component />} />}
                    />
                  ))}
                </Route>

                {frontendRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<FrontendMaster children={<route.component />} />}
                  />
                ))}
                {token ? (
                  <Route path="/wishlist" element={<WishList />} />
                ) : (
                  // Redirect to Login page if the user is not authenticated
                  <Route path="/wishlist" element={<Navigate to="/login" />} />
                )}

                <Route path="*" element={<NotFound />} />
              </Routes>
            </IdleTimerWrapper>
          </BrowserRouter>
        </WishlistProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;
