import React from 'react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload, Tooltip, Row, Col } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

interface FormValues {
  users: {
    first: string;
    last: string;
    upload: UploadFile[];
  }[];
}

const onFinish = (values: FormValues) => {
  console.log('Received values of form: ', values);
};

const AddBlog: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Blog</h2>
        </Col>
        {/* <Col span={12}></Col> */}
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/blog"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>

      <div className="flex justify-center min-h-screen items-center bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full ">
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{ users: [{ first: '', last: '', upload: [] }] }}
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField } : any) => (
                    <div key={key} className="bg-gray-50 p-4 rounded-lg shadow-sm space-y-4">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={7}>
                          <label className='font-bold pb-2'>Heading</label>
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            fieldKey={[fieldKey, 'first']}
                            rules={[{ required: true, message: 'Missing heading' }]}
                          >
                            <Input.TextArea placeholder="Heading" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={7}>
                          <label className='font-bold pb-2'>Image</label>
                          <Form.Item
                            {...restField}
                            name={[name, 'upload']}
                            fieldKey={[fieldKey, 'upload']}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => Array.isArray(e) ? e : e?.fileList}
                            rules={[{ required: true, message: 'Missing upload' }]}
                          >
                            <Upload
                              listType="picture-card"
                              beforeUpload={() => false} // Prevent automatic upload
                              showUploadList={{ showPreviewIcon: true }}
                            >
                              <UploadOutlined />
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={7}>
                          <label className='font-bold pb-2'>Description</label>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            fieldKey={[fieldKey, 'last']}
                            rules={[{ required: true, message: 'Missing description' }]}
                          >
                            <ReactQuill
                              theme="snow"
                              className="rounded border border-1"
                              placeholder="Enter Description"
                              style={{ height: '150px' }}
                            
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={3} className="flex justify-end items-center">
                          <div>
                            <Tooltip title="Remove this field">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                                className="text-red-500 text-xl cursor-pointer"
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Form.Item>
                    <Button type="dashed" className='mt-4 ' onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <button type="submit"  className="btn-brand">
                Submit
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
