import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAllOrder, getOrder } from '../../../utils/API';
import { FaEye } from 'react-icons/fa';

interface User {
    _id: string;
    firstName: string;
    lastName: string;
}

interface OrderItem {
    _id: string;
    productId: string;
    quantity: number;
    price: number;
    discountedPrice: number;
    sizeId: string[];
    ipQty: number;
}

interface Order {
    _id: string;
    userId: User;
    orderDate: string;
    orderItems: OrderItem[];
    totalPrice: number;
    totalIp: number;
    address: string;
    deliveryNotes: string;
    paymentMethod: string;
    transactionId: string;
    createdAt: string;
    updatedAt: string;
    invoiceNumber: string;
}

interface Payout {
    orderId:
    { _id: string }
    payoutDate: string;
}

const Purchase: React.FC = () => {
    const [pageData, setPageData] = useState<any[]>([]);
    const [userOders, setuserOders] = useState<any>();
    const navigate = useNavigate();

    const fetchOrderData = async () => {
        try {
            const res = await getOrder();
            // Transform the response data to match the expected format
            const orders = res.orders.map((order: { payoutDate: any; userId: { username: any; }; }) => ({
                ...order,
                payoutDate: order.payoutDate || null,
                userId: {
                    ...order.userId,
                    username: order.userId.username,
                }
            }));
            setPageData(orders.reverse());
        } catch (error) {
            console.error("Failed to fetch order data", error);
        }
    };

    const handleOderDetailRedirect = (record: any) => {
        navigate(`/backend/user/order/${record._id}`, { state: { orderId: record } });
    };


    useEffect(() => {
        fetchOrderData();
    }, []);

    const leaderColumns = [
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>S. No</div>,
            dataIndex: 'no',
            render: (_: any, __: any, index: number) => <span style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>{index + 1}</span>,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Username</div>,
            dataIndex: ['userId', 'username'],
            key: 'username',
            render: (username: string) => <span>{username}</span>,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>IP</div>,
            dataIndex: 'totalIp',
            render: (totalIp: number) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{totalIp}</span>,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Amount</div>,
            dataIndex: 'amount',
            render: (amount: number) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{amount}</span>,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Bill Date</div>,
            dataIndex: 'orderDate',
            render: (orderDate: string) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{new Date(orderDate).toLocaleDateString()}</span>,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Payout Date</div>,
            dataIndex: 'payoutDate',
            render: (payoutDate: string | null) => (
                <span style={{ fontSize: '15px', color: '#a6a6a6' }}>
                    {payoutDate ? new Date(payoutDate).toLocaleDateString() : '-'}
                </span>
            ),
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Order Detail</div>,
            dataIndex: 'oderDetails',
            render: (_: any, record: any) => (<FaEye className='editMenuIcon' onClick={() => handleOderDetailRedirect(record)} />),
        }
    ];


    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const year = currentDate.getFullYear();
    const formattedStartDate = `1/${month}/${year}`;

    return (
        <>
            <Row className="m-2" align={"middle"}>
                <Col xs={24} sm={24} md={9} xl={9} xxl={9} className="d-flex justify-content-start font-bold">
                    <h2 className="text-2xl">My Purchase - From {formattedStartDate}</h2>
                </Col>
            </Row>
            <Row>
                <Col style={{ backgroundColor: "#ffffff", borderRadius: "12px" }} xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Card className="bg-white border" style={{ borderRadius: "12px", margin: "1%" }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <div style={{ overflowX: 'auto' }}>
                                <Row className='m-2' style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>
                                    Paid date- you have not qualified for {month} {year} consistency maturity
                                </Row>
                                <Table columns={leaderColumns} dataSource={pageData} rowKey={(record) => record._id} scroll={{ x: true }}
                                    footer={() => (
                                        <Row>
                                            <Col span={5}></Col>
                                            <Col style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>
                                                <span>Total IP:</span>
                                                <span>{pageData.reduce((total, order) => total + order.totalIp, 0)}</span>
                                            </Col>
                                        </Row>
                                    )}
                                />
                                <Row className='m-2' style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    Jan 2022 Consistency Maturity-For any query WhatsApp - 919818755350
                                </Row>
                                <Row className='m-2' style={{ fontWeight: 'bold', fontSize: "12px" }}>
                                    (All those who had their consistency maturity in the month of Jan 2021., If they do shopping for a
                                    minimum Rs 3000 between 2nd-20th in Oct, Nov'Jan 2022 they will have their next consistency
                                    maturity in the month of Jan 2022.)
                                </Row>
                            </div>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Purchase;
