import React, { useState, useEffect } from 'react';
import { Table, Spin, Card, Col, Row, Input, message, Image } from 'antd';
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { RiAddBoxFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { deleteaward, getaward } from '../../../utils/API'; // Adjust the import path as needed
import { Modal } from 'antd';
interface AwardData {
  [x: string]: any;
  key: number;
  title: string;
  file: string;
}

const AwardsTable: React.FC = () => {
  const [awardsData, setAwardsData] = useState<AwardData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null); // To handle errors
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();
  const imageLink = process.env.REACT_APP_Image_URL;

  const fetchAwards = async (searchText: string = '') => {
    setLoading(true);
    try {
      const response = await getaward(searchText);
      setAwardsData(response.award.map((item: any, index: number) => ({
        id: item._id,
        key: index + 1, // Assign a unique key for each award
        title: item.title,
        file: item.file,
      })));
    } catch (error) {
      setError('Failed to load awards. Please try again later.');
      message.error('Failed to load awards.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAwards(); // Fetch data when the component mounts
  }, []);

  // Handle actions like Edit or Delete
  const handleEdit = (record: any) => {
    navigate(`/backend/awards/${record.id}/edit`);
  };

  const handleDelete = (record: any) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this award?',
      // content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteaward(record.id)
          .then((response: any) => {
            message.success(response.message); // Display success message
            fetchAwards(); // Reload the list of awards after deletion
          })
          .catch((error: any) => {
            message.error(error.response?.data?.message || "Error deleting award"); // Improved error handling
          });
      },
    });
  };

  const columns = [
    {
      title: <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>Title</div>,
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>Image</div>,
      dataIndex: 'file',
      key: 'file',
      render: (text: any) => (
        <Image 
          src={`${imageLink}/awards/${text}`} 
          alt="award"
          style={{ width: "50px", height: "50px" }} 
        />
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
      key: 'action',
      render: (_: any, record: AwardData) => (
        <div className='d-flex'>
          <FaEdit className='editMenuIcon' onClick={() => handleEdit(record)} />
          <AiFillDelete className='editMenuIcon' onClick={() => handleDelete(record)} /> {/* Pass record instead of record.id */}
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col xs={24} sm={24} md={9} xl={6} xxl={10} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Awards</h2>
        </Col>
        <Col xs={24} sm={24} md={15} xl={18} xxl={14}>
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={17} sm={17} md={16} xl={17} xxl={17}>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-full"
                placeholder="Search..."
                allowClear
                autoComplete='off'
                onChange={(e) => setSearchText(e.target.value)} // Capture search input
              />
            </Col>
            <Col xs={7} sm={7} md={8} xl={7} xxl={7} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/awards/add"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Awards</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col style={{ backgroundColor: "#ffffff", borderRadius: "12px" }} xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Card className="bg-white border" style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                {error ? (
                  <div>{error}</div> // Display error message if any
                ) : (
                  <div style={{ overflowX: 'auto' }}>
                    <Table columns={columns} dataSource={awardsData} scroll={{ x: true }} />
                  </div>
                )}
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AwardsTable;