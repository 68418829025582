import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { useLocation } from 'react-router-dom';

const { Header } = Layout;

interface NavbarProps {
  collapsed: boolean;
  toggleSidebar: () => void;
  disableHoverEffect: () => void;
}

interface RouteData {
  title: string;
  symbol: string;
  points: string;
}

const Navbar: React.FC<NavbarProps> = ({ collapsed, toggleSidebar, disableHoverEffect }) => {
  const firstName = localStorage.getItem('user') || ''; // default to empty string if user is not available
  const username = localStorage.getItem('username') || ''; // default to empty string if user is not available
  const formattedFirstName = firstName.replace(/["']/g, '');
  const lastname = localStorage.getItem('lastname') || '';
  const formattedlastname = lastname.replace(/["']/g, '');
  const location = useLocation();
  const [headerName, setHeaderName] = useState('');
  const matchingPoints = parseInt(localStorage.getItem('matchingPair') || "0");
 

  const ButtonStyle = {
    marginTop: '18px',
    background: 'transparent',
  };

  useEffect(() => {
    const getUsername = () => {
      const username = localStorage.getItem('user');
      const user = username?.replace(/"/g, '');
      return user ? user : 'Guest'; // Default to 'Guest' if username is not found
    };

    // Define the data array with thresholds and symbols
    const data: RouteData[] = [
      {
        title: "CROWN AMBASSADOR",
        symbol: "👨‍✈️",
        points: "37500",
      },
      {
        title: "AMBASSADOR",
        symbol: "👑",
        points: "25000",
      },
      {
        title: "SAPPHIRE",
        symbol: "💠",
        points: "12500",
      },
      {
        title: "SUNFLOWER",
        symbol: "🌻",
        points: "6750",
      },
      {
        title: "LOTUS",
        symbol: "🌷",
        points: "5000",
      },
      {
        title: "ROSE",
        symbol: "🌹",
        points: "2500",
      },
      {
        title: "LAVENDER",
        symbol: "🟪",
        points: "1250",
      },
      {
        title: "LILY",
        symbol: "🌺",
        points: "250",
      },
      {
        title: "MARIGOLD",
        symbol: "🏵️",
        points: "125",
      },
      {
        title: "SAFRON",
        symbol: "🌸",
        points: "50",
      },
      {
        title: "JASMINE",
        symbol: "💮",
        points: "25",
      },
      {
        title: "Active",
        symbol: "⚡",
        points: "0-24",
      },
    ];

    // Find the correct symbol based on matchingPoints
    let symbol = "Active"; // Default to 'Active' if no other match is found
    for (let i = 0; i < data.length; i++) {
      const { title, points } = data[i];
      const pointsThreshold = parseInt(points); 

      if (matchingPoints >= pointsThreshold) {
        symbol = data[i].title;
        break;
      }
    }

    // Set headerName to include the symbol and username
    setHeaderName(` ${formattedFirstName} ${formattedlastname} - ${username} - ${symbol}`);

  }, [matchingPoints, formattedFirstName]);

  return (
    <Header className='header'>
      <Row>
        <Col xs={4} md={2}>
          <Button
            type="text"
            icon={<MenuOutlined className='header-icon' />}
            style={ButtonStyle}
            onClick={() => {
              toggleSidebar();
              disableHoverEffect();
            }}
            className='m-2'
          />
        </Col>
        <Col xs={10} md={11} className='mt-1'>
          <span className='font-semibold text-xs sm:text-2xl text-uppercase inline-block'>{headerName}</span>
        </Col>
        <Col xs={10} md={11} className='flex justify-end'>
          <span className='font-semibold text-xs sm:text-2xl p-3 text-gray-500 font-roboto'>
            Hey {formattedFirstName}
          </span>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
