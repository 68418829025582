import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Table, Select, message, Form, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getCommisonDate, getCurrentClosingData, getCustomers } from '../../../../utils/API';
import { AuthContext } from '../../../Auth/AuthContext';
import * as XLSX from 'xlsx';
import logo from "../../../../assets/frontendassets/logo.jpg";
import { FaEdit } from 'react-icons/fa';

const { Option } = Select;

interface User {
  _id: string;
  roleId: number;
  sponsorId: string;
  email: string;
  placement: string;
  productId: string;
  firstName: string;
  lastName: string;
  username: string;
  mobileNo: string;
  pan: string;
  dob: Date | null;
  bankName: string;
  bankAddress: string;
  bankAccountNo: string;
  ifsc: string;
  isKyc: number;
  state: string;
  city: string;
  town: string;
  address: string;
  pincode: number;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  isActive: boolean;
  nomineeName: string;
  nomineeRelation: string;
}

interface Record {
  _id: string;
  incentiveDate: Date;
  updatedAt: Date;
  fromDate: string;
  toDate: string;
  totalEarning: number;
  tds: number;
  otherCharges: number;
  recovery: number;
  netEarning: number;
  userId: User;
}

const CurrentClosing = () => {
  const { authData } = useContext(AuthContext);
  const role_id = authData.roleId;
  const navigate = useNavigate();
  const [data, setData] = useState<Record[]>([]);
  const [userData, setUserData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [kycFilter, setKycFilter] = useState<number | null>(null);
  const [commisonDate, setCommisonDate] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | undefined>('');
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>('');
  const [filteredData, setFilteredData] = useState<Record[]>([]);

  useEffect(() => {
    FetchCommisonDate();
  }, []);

  useEffect(() => {
    FetchCustomerData(searchText, kycFilter);
  }, [searchText, kycFilter]);

  useEffect(() => {
    getData(null);
  }, []);

  const FetchCommisonDate = () => {
    setLoading(true);
    getCommisonDate()
      .then((response: any) => {
        const formattedDates = response.data.date.map((item: any) => {
          const dateObj = new Date(item.date);
          const formattedDate = dateObj.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
          return {
            ...item,
            formattedDate: `${formattedDate}`,
          };
        });

        setCommisonDate(formattedDates);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setLoading(false);
      });
  };

  const FetchCustomerData = (searchText: string, kyc: number | null) => {
    setLoading(true);
    getCustomers(searchText, kyc)
      .then((response: any) => {
        const filteredCustomerData = response.data.filter((customer: any) => !customer.isDeleted);
        setUserData(filteredCustomerData);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };

  const getData = (user: any, date: any = null) => {
    const currentYear = new Date().getFullYear();
    getCurrentClosingData(currentYear, date).then((res: any) => {
      const reversedData = res.financial.reverse();
      setData(reversedData);
      setFilteredData(reversedData);
    });
  };

  const mainColumns = [
    {
      title: 'Si No',
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: ['userId', 'username'],
      key: 'name',
      render: (text: any, record: any, index: number) => record.userId.firstName + " " + record.userId.lastName,
    },
    {
      title: 'User ID',
      dataIndex: ['userId', 'username'],
      key: 'username',
    },
    {
      title: 'Incentive Date',
      dataIndex: 'incentiveDate',
      key: 'incentiveDate',
      render: (text: string | number | Date) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Total Incentive',
      dataIndex: 'totalEarning',
      key: 'totalEarning',
    },
    {
      title: 'TDS(%)',
      dataIndex: 'tds',
      key: 'tds',
    },
    {
      title: 'Other Charges',
      dataIndex: 'otherCharges',
      key: 'otherCharges',
    },
    {
      title: 'Balance Recovery',
      dataIndex: 'recovery',
      key: 'recovery',
    },
    {
      title: 'Net Incentive',
      dataIndex: 'netEarning',
      key: 'netEarning',
    },
  ];

  const formatDateTime = (dateString: string | number | Date) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short', // 'Jan', 'Feb', etc.
      year: 'numeric', // Full year (e.g., 2024)
      hour: '2-digit', // 2-digit hour (e.g., 05)
      minute: '2-digit', // 2-digit minute (e.g., 30)
      hour12: true // 12-hour format with AM/PM
    });
  };

  const handleUserChange = (value: any) => {
    setSelectedUserId(value); // Update selected user ID state
    handleFilterChange(value, selectedDate);
  };

  const handleDateChange = (value: any) => {
    setSelectedDate(value); // Update selected user ID state
    handleFilterChange(selectedUserId, value); // Call filter function with current user ID and updated date
  };


  const handleFilterChange = (userId: any, date: any) => {
    const newData = data.filter((item: Record) => {
      const matchesUser = userId ? item.userId._id === userId : true;
      const matchesDate = date ? formatDateTime(item.incentiveDate) === formatDateTime(date) : true;

      return matchesUser && matchesDate;
    });
    setFilteredData(newData); // Update filtered data
  };

  const handleExport = () => {
    // If filtered data is available, export it. Otherwise, export all data.
    const exportData = filteredData.length > 0 ? filteredData : data;
    const formattedData = exportData.map((record, index) => {
      console.log(record);
      return({
      'Si No': index + 1,
      'Name': record.userId.firstName +" "+ record.userId.lastName,
      'User ID': record.userId.username,
      'Bank Account No': record.userId.bankAccountNo,
      'IFSC Code': record.userId.ifsc,
      'Bank Name': record.userId.bankName,
      'Bank Address': record.userId.bankAddress,
      'isKyc': record.userId.isKyc == 0 ? "No" : record.userId.isKyc == 1 ? "Pending" : "Yes",
      'Incentive Date': new Date(record.incentiveDate).toLocaleDateString(),
      'Total Incentive': record.totalEarning,
      'TDS(%)': record.tds,
      'Other Charges': record.otherCharges,
      'Balance Recovery': record.recovery,
      'Net Incentive': record.netEarning,
    })
  });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Incentive Report');

    XLSX.writeFile(workbook, `Incentive_Report_${filteredData.length > 0 ? 'Filtered' : 'All'}.xlsx`);
  };

  return (
    <>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={9} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Current Closing</h2>
        </Col>
        {/* <Col xs={24} sm={24} md={15} xl={15} className="d-flex justify-content-end">
          {role_id === 1 && (
            <Button onClick={handleExport} className="btn btn-brand text-white">
              Export Data
            </Button>
          )}
        </Col> */}
        <Col xs={24} sm={24} md={15} xl={15} xxl={15} className="d-flex justify-content-end">
          {role_id === 1 && (
            <button onClick={handleExport} className="btn btn-brand text-white">Export Data</button>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col style={{ backgroundColor: '#ffffff', borderRadius: '12px' }} xs={24} sm={24} md={24} xl={24}>
          <Card className="bg-white border " style={{ margin: '1%' }}>
            {authData.roleId === 1 && (
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Form.Item rules={[{ required: true, message: 'Please Select a User!' }]}>
                      <Select
                        size="large"
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option: any) => option?.label?.toLowerCase().includes(input.toLowerCase())}
                        allowClear
                        value={selectedUserId ? selectedUserId : undefined}
                        onChange={handleUserChange}
                        placeholder="Select User"
                        style={{ width: '100%' }}
                      >
                        {userData.map((user: User) => (
                          <Option key={user._id} value={user._id} label={user.username}>
                            {user.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Form.Item rules={[{ required: true, message: 'Please Select a Date!' }]}>
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        value={selectedDate ? selectedDate : undefined}
                        onChange={handleDateChange}
                        placeholder="Select Date"
                        style={{ width: '100%' }}
                      >
                        {commisonDate.map((date: any) => (
                          <Option key={date.formattedDate} value={date.formattedDate}>
                            {date.formattedDate}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Table
              loading={loading}
              columns={mainColumns}
              dataSource={filteredData}
              pagination={{ pageSize: 10 }}
              rowKey={(record: Record) => record._id}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CurrentClosing;
