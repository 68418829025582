import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table } from 'antd';
import axios from 'axios';
import { fetchNextWeekClosingData } from '../../../../utils/API';

const columns = [
    {
        title: 'Sr. No.',
        dataIndex: 'key',
        key: 'key',
        render  : (text : any, record : any, index : any) => index + 1
    },
  {
    title: 'ID No',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Product Name',
    dataIndex: 'orderItems',
    key: 'orderItems',
    render: (items : any) => (
      <ul>
        {items.map((item : any, index : any) => (
          <li key={index}>{item.name}</li>
        ))}
      </ul>
    ),
  },
  {
    title: 'Product Price',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    
  },
  {
    title: 'Payment Received',
    key: 'paymentReceived',
    render: (_: any, record: any) => (record.orderStatus === 1 ? '✔️' : '❌  '),
},
// {
//     title: 'Payment Not Received',
//     key: 'paymentNotReceived',
//     render: (_: any, record: any) => (record.orderStatus === 0 ? '✔️' : ''),
// },
  {
    title: 'ID Status',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    render: (_: any, record: any) => (record.orderStatus == 0 ? ' 🔴' : '🟢'),
  },
];

const NextWeekClosingStatus = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchData = async (page : any) => {
      setLoading(true);
      try {
        const results = await fetchNextWeekClosingData(getLastSundayDate() , page);
        setData(results.results);
        setTotalPages(results.totalPages);
        setCurrentPage(results.currentPage);

        setLoading(false);


      } catch (error) {
        setLoading(false);   
        console.error('Error fetching data:', error);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);


  const getLastSundayDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastSunday = new Date(today.setDate(today.getDate() - dayOfWeek));
    return lastSunday.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  return (
    <div>
    <Row className="m-2" align="middle">
      <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
      <h3 className="text-xl font-bold">Next Week Closing Status</h3>
      </Col>
      <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
        <Row gutter={16} className="float-center xs:m-2">
          <Col xs={0} sm={0} md={0} xl={10} xxl={10}></Col>
          <Col xs={36} sm={36} md={14} xl={9} xxl={9}></Col>
          <Col xs={24} sm={24} md={6} xl={5} xxl={5}></Col>
        </Row>
      </Col>
    </Row>
    <Card>
      <Row gutter={16} className="m-2">
        <Col xs={24} sm={24} md={12} xl={8} xxl={6}>

        </Col>
      </Row>
      <Table
      columns={columns}
      loading={loading}
      dataSource={data}
      rowKey="_id"
      scroll={{ x: 1000 }}
      pagination={{
        current: currentPage,
        total: total,
        pageSize: 10  ,
        onChange: (page) => setCurrentPage(page),
      }}

    />
      
    </Card>
  </div>
  );
};

export default NextWeekClosingStatus;
