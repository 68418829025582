import React, { useState, useEffect } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomers, tdsAdd, tdsEdit, tdsupdate } from "../../../../../utils/API";
import { BiTrash, BiUpload } from "react-icons/bi";
import moment from "moment";
import { UploadFile } from "antd/lib/upload/interface";
import { FaArrowLeft } from "react-icons/fa";
moment.locale('en');


const { Option } = Select;

const EditTdsCerticateForm: React.FC = () => {
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [Updating, setUpdating] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>('');
  const [kycFilter, setKycFilter] = useState<number | null>(null);

 
  const FetchCustomerData = (searchText: string, kyc: number | null) => {
    setLoading(true);
    getCustomers(searchText, kyc)
      .then((response: any) => {
        const filteredCustomerData = response.data.filter((customer: any) => !customer.isDeleted);
        setCustomerData(filteredCustomerData);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    FetchCustomerData(searchText, kycFilter);
  }, [searchText, kycFilter]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const existingData = await tdsEdit(id);
          const formattedDate = new Date(existingData.year).toISOString().substr(0, 10);
          form.setFieldsValue({
            ...existingData,
            year: formattedDate,
            userId: existingData.userId
          });

          // Set fileList state with existing file data
          if (existingData.file) {
            setFileList([{
              uid: '-1',
              name: existingData.file,
              status: 'done',
              url: existingData.file.url,
            }]);
          }
        } catch (error) {
          message.error("Error fetching data. Please try again.");
        }
      };
      fetchData();
    }
  }, [id, form, navigate]);




  const handleSubmit = async (values: any) => {
    try {
      setUpdating(true);
      const id = form.getFieldValue("_id");

      const formData = new FormData();
      formData.append("userId", form.getFieldValue("userId"));
      formData.append("year", values.year);

      // Check if values.file exists and has fileList
      if (values.file && values.file.fileList && values.file.fileList.length > 0) {
        const file = values.file.fileList[0].originFileObj;
        formData.append("file", file);
      }
      const response = await tdsupdate(id, formData);
      if (response.status === 200) {
        message.success(response.data.message);
        navigate("/backend/tds-certificate");
      } else {
        message.error("Form submission failed");
      }
    } catch (error) {
      message.error("Form submission failed");
    }
    setUpdating(false);
  };



  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Certificate</h2>
        </Col>
        {/* <Col span={12} className="text-right"></Col> */}
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/tds-certificate"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white" onFinish={handleSubmit} form={form}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        User <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: "Please Select User",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          optionFilterProp="label"
                          filterOption={(input : any, option  : any) =>
                              option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                          allowClear
                          // style={{ width: 400 }}
                          className="rounded border border-1"
                          placeholder="Select User"
                      >
                          {customerData.map((user : any) => (
                              <Option key={user._id} value={user._id} label={`${user.firstName} - ${user.username}`}>
                                  {user.firstName} - {user.username}
                              </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        Tds File <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="file">
                        <Upload
                          name="file"
                          action=""
                          
                          listType="picture-card"
                          fileList={fileList}
                          onChange={({ fileList }) => setFileList(fileList)}
                          maxCount={1}
                          beforeUpload={() => false} // Prevent default upload behavior

                        >
                          <Button icon={<BiUpload />}>Upload</Button>
                        </Upload>

                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        Year <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Year",
                          },
                        ]}
                      >
                        <Select placeholder="Select Year" size="large" className="w-75">
                          {Array.from({ length: 50 }, (_, i) => moment().year() - i).map(year => (
                            <Option key={year} value={year}>
                              {moment({ year }).format('YYYY')}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={10} className="d-flex justify-content-center">
                  <button className="btn-brand" disabled={Updating}>
                    {Updating ? "Updating..." : "Update"}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default EditTdsCerticateForm;