import { Col, Row } from 'antd';
import React from 'react';

const FeedbackForm = () => {
    return (
        <>
        <Row>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4} ></Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='' >
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100vh' }}>
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSfWaDu-ncFO-jFfFNoNuknd2pzNqRDz2VTl0wHg8bGruvzt0g/viewform?embedded=true"
                width="640" 
                height="700" 
                frameBorder="0" 
                marginHeight={0} 
                marginWidth={0}
                style={{ border: 'none' }}
                title="Google Form"
            >
                Loading…
            </iframe>
        </div>
  
            </Col>
            <Col span={6}></Col>
        </Row>
        </>
    );
};

export default FeedbackForm;
