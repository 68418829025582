import React, { useState, useEffect } from "react";
import { Switch, Button, Table, Modal, Row, Col, message } from "antd";
import { FaEdit } from "react-icons/fa";
import { accessmenuget, accessmenuupdate } from "../../../utils/API";

interface MenuItem {
  _id: string;
  menuKey: string;
  name: string;
  roleId: number[]; // Assuming roleId is an array of numbers
}

const ManageRoles = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null | any>(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
  const [menuitems, setMenuItems] = useState<MenuItem[]>([]);

  const fetchMenuItem = async () => {
    try {
      const response = await accessmenuget();
      setMenuItems(response);
    } catch (error) {
      message.error("Failed to fetch menu items");
    }
  };

  useEffect(() => {
    fetchMenuItem();
  }, [])

  useEffect(() => {
    // Set initial selectedRoleIds based on the first menu item if menuitems is not empty
    if (menuitems.length > 0) {
      const initialRoleIds: string[] = [];
      menuitems.forEach(item => {
        item.roleId.forEach(roleId => {
          if (!initialRoleIds.includes(roleId.toString())) {
            initialRoleIds.push(roleId.toString());
          }
        });
      });
      setSelectedRoleIds(initialRoleIds);
    }
  }, [menuitems]);
  

  const handleSwitchChange = (roleId: string, checked: boolean) => {
    const updatedMenuItem = { ...selectedMenuItem };
  
    if (checked) {
      if (!updatedMenuItem.roleId.includes(parseInt(roleId))) {
        updatedMenuItem.roleId.push(parseInt(roleId));
      }
    } else {
      updatedMenuItem.roleId = updatedMenuItem.roleId.filter(
        (id: number) => id !== parseInt(roleId)
      );
    }
    setSelectedMenuItem(updatedMenuItem);
    setSelectedRoleIds(updatedMenuItem.roleId.map((id : any) => id.toString()));
  };
  


  const showModal = (record : any) => {
    setSelectedMenuItem(record);
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    try {
      if (selectedMenuItem) {
        await accessmenuupdate(selectedMenuItem._id, { roleId: selectedMenuItem.roleId  });
        message.success("Permissions updated successfully!");
        fetchMenuItem(); 
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating menu item:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title:    <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>Index</div>,
      dataIndex: "index",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title:    <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>Name</div>,
      dataIndex: "name",
      key: "name",
    },
    {
      title:    <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>  Action</div>,
      key: "action",
      render: (record: any) => (
    
          <FaEdit onClick={() => showModal(record)} className="editMenuIcon" size={20} style={{ cursor: "pointer" }}/>
        
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={menuitems} />
      <Modal
       title={`Edit permissions for ${selectedMenuItem ? selectedMenuItem.name : "Menu"}`}
        visible={isModalVisible}
        footer={[
          <button key="save" className="btn btn-outline-primary me-2" onClick={handleOk}>
            Ok
          </button>,
          <button key="cancel" className="btn btn-outline-danger" onClick={handleCancel}>
            Cancel
          </button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <label className="form-label p-5">Direct Seller</label>
            <Switch
              checked={selectedMenuItem?.roleId.includes(1)}                
              className={selectedMenuItem?.roleId.includes(1) ? 'bg-orange-500' : 'bg-gray-500'}
              onChange={(checked) => handleSwitchChange("1", checked)}
            />
          </Col>
          <Col span={12}>
            <label className="form-label p-5">Direct Support</label>
            <Switch
              checked={selectedMenuItem?.roleId.includes(2)}
              className={selectedMenuItem?.roleId.includes(2) ? 'bg-orange-500' : 'bg-gray-500'}
              onChange={(checked) => handleSwitchChange("2", checked)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ManageRoles;
