import React, { useEffect, useState } from "react";
import { Col, Row, Carousel, message } from "antd";
import { BiHeart } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { config } from "../../utils/config"
import { getallproduct } from "../../utils/API";
import { Link } from "react-router-dom";

interface Product {
  slug: any;
  _id: any;
  discountedPrice: any;
  ipQty: any;
  categoryId: any;
  isBestSelling: boolean;
  images: any;
  isFeatured: unknown;
  isTopRated: boolean;
  name: string;
  imageUrl: string;
  price: string;
  star: number;
}


const TopProducts: React.FC = () => {

  const [slidesToShow, setSlidesToShow] = useState(2);
  const [products, setProducts] = useState<Product[]>([]);
  const imageUrl = process.env.REACT_APP_Image_URL;


  useEffect(() => {
    fetchProducts();
    const handleResize = () => {
      // Update slidesToShow based on window width
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial state
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getallproduct([], [],[],[], []);
      setProducts(response.products);
      
    } catch (error) {
      message.error("Error fetching products. Please try again later.");
    }
  };

  return (
    <>
      <Col span={24} className="text-center mt-5  font-bold text-xl">
        TOP PRODUCTS
      </Col>
      <Col xs={24} className="mt-2 px-3 md:px-0 text-center">
        <div className="flex flex-col md:flex-row items-center justify-center mb-3">
          <hr className="w-full  border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
          <span className="text-gray-400 tracking-wide md:pr-5 md:pl-5 mt-3 md:mt-0 pb-2">
            Explore our top-performing products, loved by customers and trusted by distributors worldwide
          </span>
          <hr className="w-full  border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
        </div>
      </Col>


      <Row className=" lg:px-0 lg:ps-0 xl:px-36 xl:ps-36  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
        <Col span={24} className="p-3 mt-4">
          <Carousel autoplay slidesToShow={slidesToShow} draggable appendDots={(dots) => <div>{dots}</div>}>
            {products.filter(product => product.isTopRated === true).
              map((product, index) => (
                <div key={index} className="p-2" >
                  <Link to={`/product/${product.slug}`} className="hover:text-orange-500" >
                  <img
                    src={imageUrl + "/products/" + product.images}
                    alt="SliderImage"
                    width="100%"
                    style={{ height: "auto" }}
                  />
                  <Row>
                    <Col xs={20} className="text-orange-300 mt-3">
                      {product.categoryId.name}
                    </Col>
                    {/* <Col xs={4} className="d-flex justify-center mt-1">
                      <BiHeart size={20} color="red" />
                    </Col> */}
                  </Row>
                  <Col span={24} className="mt-1">
                    {product.name}
                  </Col>
                  <Col span={24} className="d-flex mt-2">
                    <FaStar size={13} color="orange" />
                    <FaStar size={13} color="orange" />
                    <FaStar size={13} color="orange" />
                    <FaStar size={13} color="orange" />
                    <FaStar size={13} color="orange" />
                  </Col>
                  <Col span={24} className="mt-1">
                    INCENTIVE POINT (IP) {product.ipQty}
                  </Col>
                  <Col span={24} className="font-semibold mt-2" style={{ display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <div>{config.currencySymbol}  {product.discountedPrice.toFixed(config.currencyDecimals)}&nbsp;</div>
                    <div className="text-gray-400" style={{ textDecoration: "line-through" }}>
                      {config.currencySymbol} {product.price}
                    </div>
                    &nbsp;<span className="text-green-400">20%</span>
                  </Col>
                  </Link>
                </div>
              ))}
          </Carousel>
        </Col>
      </Row>
    </>
  )
}

export default TopProducts;
