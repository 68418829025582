
import { Col, Row, Form, Input, message, Card, DatePicker, TimePicker } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { Select } from "antd";
import { editseminar, updateseminar } from "../../../../../utils/API";
import { useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import "./Seminar.css";
import { FaArrowLeft } from "react-icons/fa";

const { Option } = Select;

const SeminarEditForm: React.FC = () => {
  const [form] = Form.useForm();
    const {id} = useParams();
    const navigate = useNavigate();

 
  useEffect(() => {
    editseminar(id).then((response) => {
      // Convert date and time data if needed
      const formattedData = {
        ...response,
        date: response.date ? dayjs(response.date) : null, // Assuming day is in a format accepted by DatePicker
        time: response.time ? dayjs(response.time, 'HH:mm') : null, // Assuming time is in HH:mm format
      };
      form.setFieldsValue(formattedData);
    });
  }, [id]);

const handleSubmit = (values: any) => {
  const id = form.getFieldValue('_id');
    const seminars = [{
      date: values.date.format('YYYY-MM-DD'),  
      time: values.time.format('HH:mm'),      
      place: values.place,
      presentedBy: values.presentedBy
    }];
  updateseminar(id, {seminars}).then((response : any) => {
    if (response.status === 200) {
      message.success(response.data.message);
      navigate("/backend/seminar-details");
    } else {
      message.error("Something went wrong");
    }
  });
  }


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Seminar</h2>
        </Col>
        {/* <Col xs={24} sm={24} md={12}></Col> */}
        <Col span={8}>
          <Link to={"/backend/seminar-details"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
      <Card style={{ backgroundColor: "#ffffff" }}>
      <Row
        
        className="border border-warning-2 p-5 bg-white rounded-md"
        style={{ marginLeft: 0, marginRight: 0 }}
      >

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white lg:mb-5">
                <label className="font-bold">Presented by <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="presentedBy" 
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter PresentedBy"
                    }
                  ]
                }
                >
                  <Input className="rounded border border-1"  placeholder="PresentedBy" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white lg:mb-5">
                <label className="font-bold">Date  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="date"
                rules={
                  [
                    {
                      required:true,
                      message:"Please Choose Date "
                    }
                  ]
                }
                >
                    <DatePicker   className="rounded border border-1"  placeholder="Date" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
              <Row className="bg-white">
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white lg:mb-5">
                  <label className="font-bold">Time <span className="text-danger">*</span></label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="time"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Select time"
                        },
                      ]
                    }
                  >
                    <TimePicker  popupClassName="custom-time-picker"  className="rounded border border-1" placeholder="Time" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
        
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white lg:mb-5">
                <label className="font-bold">Venue  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="place" 
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter Place"
                    }
                  ]
                }
                >
                  <Input.TextArea rows={4} cols={40} size="large" className="rounded border border-1"  placeholder="Destination" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
     
   
          <Col span={11} className="d-flex justify-content-center">
            <button className="btn-brand">Submit</button>
          </Col>
        </Row>
      </Card>
      </Form>
    </div>
  );
};
export default SeminarEditForm;
