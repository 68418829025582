import React, { useEffect, useState, ChangeEvent } from "react";
import { Form, Input, Button, Row, Col, message, Select } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import "./Contact.css";
import { ContactUsapi } from "../../../utils/API";

const { Option } = Select;

interface Country {
  country_code: string;
  country_code3: string;
  country: string;
  capital: string;
  region: string;
  subregion: string;
  states: State[];
}

interface State {
  country_code: string;
  country: string;
  subdivision: string | null;
  cities: string[];
}

const ContactUs: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [msg, setMsg] = useState("");
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    try {
      const response = await ContactUsapi(values);
      if (response) {
        message.success(response.data.message);
        form.resetFields();
        setMsg(response.data.message);
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    }
  };

  const handleSelectionChange = (
    selectedValue: string,
    type: "country" | "state" | "city"
  ) => {
    switch (type) {
      case "country":
        const selectedCountryData = countries.find(
          (country) => country.country_code === selectedValue
        );
        setStates(selectedCountryData?.states || []);
        break;

      case "city":
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    fetch("/uploads/countries.json")
      .then((response) => response.json())
      .then((data: { countries: Country[] }) => {
        setCountries(data.countries);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  return (
    <div className="flex items-center justify-center mt-16 lg:mt-60 xl:mt-66 md:mt-46 sm:mt-36 xs:mt-36">
      <div className="rounded-lg w-full ">
        <Row>
          <Col xs={24} sm={24} md={10}>
            <h1 className="text-3xl font-bold  mt-5 mb-5">Contact Us</h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={14}>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "First Name is required" },
                    ]}
                  >
                    <Input
                      className="h-14 text-lg antInputClass"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Last Name is required" },
                    ]}
                  >
                    <Input
                      className="h-14 text-lg antInputClass"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Valid email is required",
                      },
                    ]}
                  >
                    <Input
                      className="h-14 text-lg antInputClass"
                      type="email"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: "Phone Number is required" },
                    ]}
                  >
                    <Input
                      className="h-14 text-lg antInputClass"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "Message is required" }]}
                  >
                    <Input.TextArea
                      className="h-32 text-lg antInputClass"
                      placeholder="Write a Message"
                      rows={6}
                    />
                  </Form.Item>
                </Col>
                {msg && <p className="text-orange-600 mb-3">{msg}</p>}
              </Row>
              <Form.Item>
                <Button
                  className="bg-orange-500 h-14 text-black font-bold w-full sm:w-1/2"
                  htmlType="submit"
                  block
                >
                  Send a Message
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={10}>
            <div className="p-4 bg-white rounded-lg shadow-md ml-0 sm:ml-6 sm:mt-0">
              <h1 className="text-3xl font-bold mb-6">Our Office</h1>
              <div className="flex items-center mb-5">
                <a href="tel:+3155222256">
                  <PhoneOutlined className="text-black bg-orange-500 rounded-full p-3 text-xl" />
                </a>
                <div className="ml-3">
                  <strong className="block text-lg font-semibold text-gray-600">
                    Have any questions?
                  </strong>
                  <p className="text-xl text-black font-medium">
                    +91 98187 55350
                  </p>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <a href="mailto:contact@example.com">
                  <MailOutlined className="text-black bg-orange-500 rounded-full p-3 text-xl" />
                </a>
                <div className="ml-3">
                  <strong className="block text-lg font-semibold text-gray-600">
                    Send Email
                  </strong>
                  <p className="text-xl text-black font-medium">
                    info@rapidexie.com
                  </p>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <a href="https://www.google.com/maps/place/80+Broklyn+Road,+New+York">
                  <EnvironmentOutlined className="text-black bg-orange-500 rounded-full p-3 text-xl" />
                </a>
                <div className="ml-3">
                  <strong className="block text-lg font-semibold text-gray-600">
                    Visit any time
                  </strong>
                  <p className="text-xl text-black font-medium">
                    D-9 Vyapar Marg, Block D, Sector 3 Noida, Uttar Pradesh
                    201301
                  </p>
                </div>
              </div>
              <hr className="my-6" />
              <div className="flex justify-around mt-6">
                <a
                  href="https://twitter.com/rapidexie"
                  className="inline-block text-gray-600 p-3 rounded transition hover:bg-orange-500 hover:text-black"
                >
                  <FaTwitter className="text-xl" />
                </a>
                <a
                  href="https://facebook.com/rapidexie"
                  className="inline-block text-gray-600 p-3 rounded transition hover:bg-orange-500 hover:text-black"
                >
                  <FaFacebook className="text-xl" />
                </a>
                <a
                  href="https://instagram.com/rapidexie"
                  className="inline-block text-gray-600 p-3 rounded transition hover:bg-orange-500 hover:text-black"
                >
                  <FaInstagram className="text-xl" />
                </a>
                <a
                  href="https://www.linkedin.com/company/rapidexie/"
                  className="inline-block text-gray-600 p-3 rounded transition hover:bg-orange-500 hover:text-black"
                >
                  <FaLinkedin className="text-xl" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactUs;
