import React, { useContext, useEffect, useState } from "react";
import { DesktopOutlined, EditOutlined } from "@ant-design/icons";
import { Drawer, Layout, Menu, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsSubtract } from "react-icons/bs";
import { BiCalendar, BiCategory, BiDownload, BiGroup, BiSlider, BiSupport, BiUserCheck } from "react-icons/bi";
import { CiLogout, CiSettings } from "react-icons/ci";
import imagelogo from "../../assets/backendassets/logo.png";
import {
  FaBook,
  FaMehRollingEyes,
  FaProductHunt,
  FaUikit,
  FaUser,
  FaUserAstronaut,
} from "react-icons/fa";
import "./Master.css";
import { TbRulerMeasure, TbStatusChange, TbUsersGroup } from "react-icons/tb";
import { MdAccountBalance, MdFeedback, MdGroup, MdGroupWork, MdMeetingRoom, MdOutlineManageAccounts, MdPages, MdRateReview, MdSupport, MdSupportAgent } from "react-icons/md";
import { AuthContext } from "../../Pages/Auth/AuthContext";
import { businesspptdownload, GetSettingData } from "../../utils/API";
import { AiOutlineShopping } from "react-icons/ai";

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
  isSmallScreen: boolean;
  hoverEffectActive: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onCollapse: (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | boolean
  ) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  onCollapse,
  onMouseEnter,
  onMouseLeave,
  hoverEffectActive,
  isSmallScreen,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [activeSubMenuKey, setActiveSubMenuKey] = useState<string | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const settingImageUrl = `${process.env.REACT_APP_Image_URL}/settings/`;
  const [activeMenuItemKey, setActiveMenuItemKey] = useState<string | null>(
    null
  );
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { authData , setAuthData } = useContext(AuthContext);
  const role_id = authData.roleId;
  const navigate = useNavigate();

const collapsedWidth = isSmallScreen ? 0 : 80;
  const FirstStyle = isSmallScreen
    ? "mobile-screen-sidebar"
    : "desktop-screen-sidebar";
  const SecondStyle =
    hoverEffectActive && !isSmallScreen
      ? "mobile-screen-sidebar"
      : "desktop-screen-sidebar";

  const handleClick = async (subMenuKey: string, itemKey: string) => {
    // If the item has children, toggle the active state of its submenu
    const menuItem = menuItems.find((item) => item && item.key === subMenuKey);
    if (menuItem && menuItem.children) {
      // If the submenu is currently open, keep it open; otherwise, open it
      setActiveSubMenuKey(activeSubMenuKey === subMenuKey ? null : subMenuKey);
    } else {
      // If the item doesn't have children, set it as the active menu item
      setActiveMenuItemKey(itemKey);
      if(isSmallScreen){
        onCollapse(true);
      }
      // if (itemKey === "ppt-download") {
      //   try {
      //     const response = await businesspptdownload(); // Fetch the PPTX file from your backend API
      //     window.open(response.pptURL, '_blank');
      //     message.success('PPTX file download initiated successfully!');
      //   } catch (error) {
      //     message.error('Failed to initiate PPTX file download.');
      //   }
      // }

    }

    if (itemKey === "logout") {
      setAuthData({ token: null, roleId: null });
      const cart = localStorage.getItem('cart');
      const cart_userid = localStorage.getItem('cart_userid');
      localStorage.clear();
      if (cart) localStorage.setItem('cart', cart);
      if (cart_userid) localStorage.setItem('cart_userid', cart_userid);
    
      };
    
  };


  const menuItems = [
    // role_id === 1 || role_id === 2
    //   ? {
    //     key: "1",
    //     icon: <DesktopOutlined />,
    //     text: "Dashboard",
    //     link: "/backend/dashboard",
    //   }
    //   : null,
    role_id === 1
      ? {
        key: "4",
        icon: <BsSubtract />,
        text: "Manage Users",
        link: "/backend/users",
      }
      : null,

    role_id === 1
      ? {
        key: "5",
        icon: <BiSlider />,
        text: " Manage Sliders",
        link: "/backend/slider",
      }
      : null,
    role_id === 1
      ? {
        key: "6",
        icon: <BiCategory />,
        text: " Manage Categories",
        link: "/backend/categories",
      }
      : null,
    role_id === 1
      ? {
        key: "7",
        icon: (
          <FaProductHunt />
        ),
        text: " Manage Products",
        link: "/backend/product",
      }
      : null,
    role_id === 1
      ? {
        key: "8",
        icon: (
          <TbRulerMeasure />
        ),
        text: " Manage Sizes",
        link: "/backend/product-size",
      }
      : null,
    role_id === 1
      ? {
        key: "14",
        icon: (
          <EditOutlined />),
        text: "Manage Colors",
        link: "/backend/color",
      } : null,
    role_id === 1
      ? {
        key: "13",
        icon: (
          <AiOutlineShopping />
        ),
        text: " Manage Orders",
        link: "/backend/manage-order",
      }
      : null,
    role_id === 1
      ? {
        key: "16",
        icon: <FaBook />,
        text: "Manage Blogs",
        link: "/backend/blog",
      } : null,
      role_id === 1
      ? {
        key: "61",
        icon: <FaBook />,
        text: "Manage Awards",
        link: "/backend/awards",
      } : null,
      role_id === 1 
      ? {
        key: "managecontact",
        icon: <BiUserCheck />,
        text: "Manage Contacts",
        link: "/backend/contact",
      }
      : null,
      role_id === 1 
      ? {
        key: "testimonials",
        icon: <MdRateReview />,
        text: "Testimonials",
        link: "/backend/testimonials",
      }
      : null,
    role_id === 1
      ? {
        key: "10",
        icon: <FaUikit />,
        text: "Newsletters",
        link: "/backend/newsletter",
      }
      : null,
    // role_id === 1
    //   ? {
    //     key: "11",
    //     icon: <MdPages />,
    //     text: "Manage Pages",
    //     link: "/backend/pages",
    //   }
    //   : null,
    role_id === 1
      ? {
        key: "15",
        icon: <MdGroupWork />,
        text: "Group Sales Tree",
        link: "/backend/group-sales-tree",
      }
      : null,
      role_id === 1 ? {
        
          key: "3.2",
          text: "Account Details",
          icon: <MdAccountBalance />,
          link: "",
          children: [

            { key: "3.2.1", text: "Previous Financial Year", link: "/backend/account-details/previous" },
            { key: "3.2.2", text: "Current Financial Year", link: "/backend/account-details/current" },
            { key: "3.2.3", text: "Current Closing", link: "/backend/account-details/current-closing" },
          ],
        
      } : null ,
    role_id === 2 ?
      {
        key: "Geneology",
        icon: <BiSupport />,
        text: "Geneology",
        children: [
          {
            key: "3.1",
            text: "Profile",
            icon: <FaUser />,
            link: "",
            children: [
              { key: "3.1.1", text: "Manage Profile", link: "/backend/kyc" },
              { key: "3.1.2", text: "Feedback", link: "/backend/feedback" },
              { key: "3.1.3", text: "Manage Password", link: "/backend/manage-password" },
              { key: "3.1.4", text: "My Purchase", link: "/backend/manage-purchase" },
            ],
          },
          {
            key: "3.2",
            text: "Account Details",
            icon: <MdAccountBalance />,
            link: "",
            children: [

              { key: "3.2.1", text: "Previous Financial Year", link: "/backend/account-details/previous" },
              { key: "3.2.2", text: "Current Financial Year", link: "/backend/account-details/current" },
            ],
          },
          {
            key: "3.3",
            text: "Status",
            icon: <TbStatusChange />,
            link: "",
            children: [
              { key: "3.3.1", text: "Direct Status", link: "/backend/direct-status" },
              { key: "3.3.2", text: "Club Status", link: "/backend/club-status" },
              { key: "3.3.3", text: "Reward Status", link: "/backend/reward-status" },
            ],
          },
          {
            key: "3.5",
            text: "Download",
            icon: <BiDownload />,
            link: "",
            children: [
              { key: "ppt-download", text: "Business PPT", link: "/backend/business-ppt" },
              { key: "3.5.4", text: "Product PPT", link: "/backend/product-ppt" },
              { key: "3.5.2", text: "TDS Certificate", link: `/backend/tds-certificate` },
              { key: "3.5.3", text: "Seminar", link: "/backend/seminar-details" },
            ],
          },
          {
            key: "3.6",
            icon: <MdSupportAgent />,
            text: "New Sales Entry",
            link: "/backend/new-sales-entry",
          },
          {
            key: "3.4",
            icon: <MdGroupWork />,
            text: "Group Sales Tree",
            link: "/backend/group-sales-tree",  
          },
        ],
      } : null,

    role_id === 1 || role_id === 3 ? {
      key: "3.1",
      text: "Profile",
      icon: <FaUser />,
      link: "",
      children: [
        { key: "3.1.1", text: "Manage Profile", link: "/backend/kyc" },
        { key: "3.1.3", text: "Manage Password", link: "/backend/manage-password" },
      ],
    } : null,
    role_id === 1 ? {
      key: "3.5",
      text: "Download",
      icon: <BiDownload />,
      link: "",
      children: [
        { key: "3.5.1", text: "Business PPT", link: "/backend/business-ppt/add" },
        { key: "3.5.4", text: "Product PPT", link: "/backend/product-ppt" },
        { key: "3.5.2", text: "TDS Certificate", link: "/backend/tds-certificate" },
        { key: "3.5.3", text: "Seminar", link: "/backend/seminar-details" },
      ],
    } : null,
    role_id === 3 ? {
      key: "4",
      text: "Download",
      icon: <BiDownload />,
      link: "",
      children: [
        { key: "ppt-download", text: "Business PPT", link: "/backend/business-ppt" },
        { key: "4.1", text: "Product PPT", link: "/backend/product-ppt" },
        { key: "4.2", text: "TDS Certificate", link: `/backend/tds-certificate` },
        { key: "4.3", text: "Seminar", link: "/backend/seminar-details" },
      ],
    } : null,
    // role_id === 1
    //   ? {
    //     key: "12",
    //     icon: (
    //       <MdOutlineManageAccounts size={20} />
    //     ),
    //     text: "Permissions",
    //     link: "/backend/role",
    //   }
    //   : null,
    role_id === 1
    ? {
      key: "9",
      icon: <CiSettings />,
      text: "Setting",
      link: "/backend/setting",
    }
    : null,
    role_id === 3 || role_id === 1 ? {
      key: "HSC",
      text: "Happy Support Center",
      icon: <BiCalendar />,
      link: "",
      children: [
        role_id === 3 ? { key: "DirectReportclosingWise", text: "Direct Report closing Wise", link: "/backend/direct-report-closing-wise" } : null,
        role_id === 3 ? { key: "DirectIndividualReport", text: "Direct Individual Report", link: "/backend/direct-individual-report" } : null,
        role_id === 3 ? { key: "NewSalesEntry", text: "New Sales Entry", link: "/backend/new-sales-entry" } : null,
        role_id === 3 ? { key: "TopEarner", text: "Top Earner", link: "/backend/top-earner" } : null,
        role_id === 3 ? { key: "LeftRightIPReport", text: "Left Right IP Report", link: "/backend/left-right-ip-report" } : null,
        role_id === 3 ? { key: "DownlineOrderStatus", text: "Downline Order Status", link: "/backend/downline-order-status" } : null,
        role_id === 3 ? { key: "PreviousPayoutIPList", text: "Previous Payout IP List", link: "/backend/previous-payout-ip-list" } : null,
        role_id === 3 ? { key: "CurrentPayoutIPList", text: "Current Payout IP List", link: "/backend/current-payout-ip-list" } : null,
        role_id === 3 ? { key: "RedEntry", text: "Red Entry", link: "/backend/red-entry" } : null,
        role_id === 3 ? { key: "BankDetailMissing", text: "Update Bank Detail Missing", link: "/backend/bank-detail-missing" } : null,
        role_id === 3 ? { key: "UpdateDownlineBankDetail", text: "Downline bank detail missing", link: "/backend/update-downline-bank-detail" } : null,
        role_id === 3 || role_id === 1 ? { key: "TripodDeclaration", text: "Tripod / Sevenpod Declaration", link: "/backend/tripod-declaration" } : null,
        { key: "TripodDeclarationList", text: "Tripod Declaration List", link: "/backend/tripod-declaration-list" },
        { key: "SevenPodDeclarationList", text: "SevenPod Declaration List", link: "/backend/sevenpod-declaration-list" },
        role_id === 3 ? { key: "Profile", text: "Profile", link: "/backend/profile/search" } : null,
        role_id === 3 ? { key: "Search", text: "Search", link: "/backend/search" } : null,
        role_id === 3 ? { key: "DownlineGeneology", text: "Geneology", link: "/backend/downline-geneology" } : null,
        role_id === 3 ? { key: "NextWeekClosingStatus", text: "Next Week Closing Status", link: "/backend/next-week-closing-status" } : null,
        role_id === 3 ? { key: "LastWeeekSuportCenterPerformance", text: "Support Center Performance", link: "/backend/last-week-suport-center-performance" } : null,
      ].filter(item => item !== null)
      
    } : null,
    role_id == 3
    ? {
      key: "support-center-group-tree",
      icon: <MdGroupWork />,
      text: "Group Sales Tree",
      link: "/backend/group-sales-tree",
    }
    : null,

    {
      key: "logout",
      icon: <FaUser />,
      text: "Logout",
      link: "/login",
    },
  ];

  useEffect(() => {
    const SettingData = async () => {
      try {
        const response = await GetSettingData();
        if (response.settings && response.settings[0] && response.settings[0].logo  ) {
          setLogo(response.settings[0].logo );
        }
      } catch (error) {
        console.error('Error fetching setting data:', error);
      }
    };

    SettingData();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const menuItem = menuItems.find((item) =>
      item?.children
        ? item.children.some((subItem : any) => subItem.link === path)
        : item?.link === path
    );

    if (menuItem) {
      setActiveSubMenuKey(menuItem.key);
      setActiveMenuItemKey(menuItem.key);
      localStorage.setItem("activeSubMenuKey", menuItem.key);

      // Check if the current path matches any child item link
      if (menuItem.children) {
        const childItem = menuItem.children.find(
          (subItem: any) => subItem.link === path
        );
        if (childItem) {
          setActiveMenuItemKey(childItem.key);
        }
      }
    }



    if (menuItem?.key) {
      setOpenKeys([menuItem.key]);
    }
  }, [location.pathname]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  
  const renderSubMenuItems = (items: any[]) => {
    return items.map((subItem) => {
      if (subItem.children && subItem.children.length > 0) {
        return (
          <Menu.SubMenu
            key={subItem.key}
            icon={subItem.icon}
            title={subItem.text}
            className={
              activeSubMenuKey === subItem.key
                ? "sidebar-menu-active"
                : "sidebar-menu"
            }

            style={{ marginLeft: '10px' }}
          >
            {renderSubMenuItems(subItem.children)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item
            key={subItem.key}
            className={
              activeMenuItemKey === subItem.key
                ? "sidebar-menu-active"
                : "sidebar-menu"
            }
            style={{ marginLeft: '20px' }}
          >
            <Link
              to={subItem.link}
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent collapse when clicking on menu items
                handleClick(subItem.key, subItem.key);
              }}
            >
              {subItem.text}
            </Link>
          </Menu.Item>
        );
      }
    });
  };
  


  return (
    <>
      {isSmallScreen ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={onCollapse}
          open={collapsed}
          maskClosable
          style={{ backgroundColor: "#001529" }}
        >
          <div
            style={{
              top: 0,
              position: "sticky",
              display: "block",
              backgroundColor: "#ffffff",
              textAlign: "center",
              zIndex: 99,
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                    src={logo ? settingImageUrl + logo : imagelogo}
                alt="Image Title"
                style={{
                  mixBlendMode: "multiply",
                  maxHeight: "70px",
                  padding: "19px",
                  color: "black",
                  backgroundColor: "#ffffff",
                }}
              />
            </Link>
          </div>
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[activeSubMenuKey, activeMenuItemKey].filter(Boolean) as string[]}
            style={{
              color: "black",
              minHeight: "100vh",
            }}
          >
            {menuItems.map(
              (item) =>
                item &&
                (item.children ? (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.text}
                    onTitleClick={() => handleClick(item.key, "")}
                    className={activeSubMenuKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
                  >
                    {renderSubMenuItems(item.children)}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={() => handleClick("", item.key)}
                    className={activeMenuItemKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
                  >
                    <Link to={item.link} style={{ textDecoration: "none" }}>
                      {item.text}
                    </Link>
                  </Menu.Item>
                ))
            )}
          </Menu>
        </Drawer>
      ) : (
        <Sider
          className={`${FirstStyle} ${SecondStyle}`}
          trigger={null}
          onClick={FirstStyle === "mobile-screen-sidebar" ? onCollapse : undefined}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            style={{
              top: 0,
              position: "sticky",
              display: "block",
              textAlign: "center",
              zIndex: 999,
              backgroundColor: "#ffffff",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                    src={logo ? settingImageUrl + logo : imagelogo}
                alt="ImageLogo"
                style={{
                  padding: "19px",
                  color: "black",
                  backgroundColor: "#ffffff",
                  maxHeight: "70px",
                  margin: "auto",
                }}
              />
            </Link>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[activeSubMenuKey, activeMenuItemKey].filter(Boolean) as string[]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            style={{
              backgroundColor: "#001529",
              minHeight: "calc(100vh)",
              maxWidth: "100%",
            }}
          >
            {menuItems.map(
              (item) =>
                item &&
                (item.children ? (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.text}
                    className={activeSubMenuKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
                    onTitleClick={() => handleClick(item.key, "")}
                  >
                    {renderSubMenuItems(item.children)}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={() => handleClick("", item.key)}
                    className={activeMenuItemKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
                  >
                    <Link to={item.link} style={{ textDecoration: "none" }}>
                      {item.text}
                    </Link>
                  </Menu.Item>
                ))
            )}
          </Menu>
        </Sider>
      )}
    </>
  );
  
  
};

export default Sidebar;