import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, message } from 'antd';
import { Link } from 'react-router-dom';
import MyCard from '../../../Content/Card';
import { getallproducts, getCustomers, getOrder } from '../../../utils/API';
import { AuthContext } from '../../../Pages/Auth/AuthContext';
import "./Dashboard.css";

const Dashboard: React.FC = () => {  
    const { authData } = useContext(AuthContext);
  

    return (
        <>
            {/* <Row gutter={[24, 24]}>
                {authData.roleId === 1 ? (
                    <>
                        <Col xs={24} sm={12} md={8}>
                            <Link to="/backend/users">
                                <MyCard title="Users" value={userCount} />
                            </Link>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Link to="/backend/product">
                                <MyCard title="Product" value={productCount} />
                            </Link>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Link to="/backend/dashboard">
                                <MyCard title="Sales" value={1000} />
                            </Link>
                        </Col>
                    </>
                ) : (
                    <Col xs={24} sm={12} md={8}>
                        <Link to="/backend/manage-purchase">
                            <MyCard title="Orders" value={orderCount} />
                        </Link>
                    </Col>
                )}
            </Row> */}
             <div className="marquee-container">
                <div className="marquee">
                    <p className="text-gray-800 text-lg leading-relaxed inline-block">
                      KYC verification must be completed within 30 days of account creation to avoid payment restrictions.
                      <Link to="/backend/kyc" className="text-orange-500 hover:underline ml-1">Complete your KYC now.</Link>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Dashboard;
