import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, message } from "antd";
import { getClubStatus, getClubdata } from "../../../../utils/API";

const Clubstatus: React.FC = () => {
  const [PageData, setPageData] = useState<any>([]);
  const [Left, setLeft] = useState<number>(0);
  const [Right, setRight] = useState<number>(0);
  const [clubdata, setClubdata] = useState<any[]>([]);
  const [currentYearly, setCurrentYearly] = useState<number>(0);
  const [currentMonthly, setCurrentMonthly] = useState<number>(0);
  const [month, setMonth] = useState<string | null>("");
  const [year, setYear] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const now = new Date();
    setCurrentYearly(now.getFullYear());
    setCurrentMonthly(now.getMonth() + 1);
    fetchclubData();
    fetchdata(now.getFullYear(), now.getMonth() + 1);
  }, []);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  async function fetchdata(year: number, month: number) {
    setYear(year);
    setMonth(monthNames[month - 1])
    try {
      const res = await getClubStatus(year, month);
      setLeft(res.newLeft);
      setRight(res.newRight);
    } catch (error) {
      message.error("Something went wrong");
    }
  }

  async function fetchclubData() {
    try {
      setLoading(true);
      const clubDataRes = await getClubdata();
      setPageData(clubDataRes.eligibleRewards);
      console.log(clubDataRes.Bonusdata);
  
      if (clubDataRes && clubDataRes.Bonusdata && clubDataRes.Bonusdata.length > 0) {
        const reversedBonusData = clubDataRes.Bonusdata.reverse();
        const formattedData = reversedBonusData.map((item : any , index : any ) => {
          const tdsPercentage = item.tds || 0;
          const tdsAmount = item.totalEarning * (tdsPercentage / 100);
  
          return {
            key: index + 1,
            no: index + 1,
            Date: new Date(item.createdAt).toLocaleDateString(),
            lb: item.leadershipBonus,
            CarClub: item.carClub,
            TravelClub: item.travelClub,
            HouseClub: item.houseClub,
            PremiumClub: item.premiumClub,
            TDS: tdsAmount || 0,
            OC: item.otherCharges || 0,
            NetAmount: item.netEarning || 0,
            NeftDetails: item.NeftDetails || "-",
            NeftDate: item.NeftDate ? new Date(item.NeftDate).toLocaleDateString() : "-",
            LeftTotalIp: item.leftTotalIp,
            RightTotalIp: item.rightTotalIp,
            BonusAmount: item.bonusAmount,
            LeadershipMonths: item.leadershipMonths,
          };
        });
        // setPageData(formattedData[0]);
        // setPageData(clubDataRes.eligibleRewards);
        setClubdata(formattedData);
        setLoading(false);
      } else {
        setLoading(false);
        // setPageData([]);
        setClubdata([]);
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  }

  const handleFetchPreviousMonthData = async () => {
    let previousMonth = currentMonthly;
    let previousYear = currentYearly;

    // Calculate the previous month and year
    if (previousMonth === 1) {
      // If the current month is January, set previous month to December and decrement the year
      previousMonth = 12; // December
      previousYear--;
    } else {
      // Otherwise, just decrement the month
      previousMonth--;
    }

    // Update state with the new month and year
    setCurrentMonthly(previousMonth);
    setCurrentYearly(previousYear);

    // Fetch data for the calculated previous month
    await fetchdata(previousYear, previousMonth);
  };

  const positionData = [
    {
      "Ip Left": Left.toString(),
      "Ip Right": Right.toString(),
    },
  ];

  // const pagesdata = [
  //   {
  //     club: "Leadership Bonus",
  //     criteria: "1 (Month)",
  //     eligbility: PageData.lb > 0 ? "Yes" : "In Progress",
  //     ipLeft: 15000,
  //     ipRight: 15000,
  //   },
  //   {
  //     club: "Car Club",
  //     criteria: "3 Months",
  //     eligbility: PageData.CarClub > 0 ? "Yes" : PageData.lb > 0 ? "In Progress" : "No",
  //     ipLeft: 25000,
  //     ipRight: 25000,
  //   },
  //   {
  //     club: "Travel Club",
  //     criteria: "4 Months",
  //     eligbility: PageData.TravelClub > 0 ? "Yes" : PageData.CarClub > 0 ? "In Progress" : "No",
  //     ipLeft: 25000,
  //     ipRight: 25000,
  //   },
  //   {
  //     club: "House Club",
  //     criteria: "5 Months",
  //     eligbility: PageData.HouseClub > 0 ? "Yes" : PageData.TravelClub > 0 ? "In Progress" : "No",
  //     ipLeft: 25000,
  //     ipRight: 25000,
  //   },
  //   {
  //     club: "Premium Club",
  //     criteria: "6 Months",
  //     eligbility: PageData.PremiumClub > 0 ? "Yes" : PageData.HouseClub > 0 ? "In Progress" : "No",
  //     ipLeft: 25000,
  //     ipRight: 25000,
  //   },
  // ];

  const columns = [
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>Club</div>,
      dataIndex: "club",
      render: (text: string) => (
        <span style={{ fontWeight: "bold", fontSize: "15px" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Criteria</div>
      ),
      dataIndex: "criteria",
      render: (text: string) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Eligibility</div>
      ),
      dataIndex: "eligibility",
      render: (text: string) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>New Ip Left</div>
      ),
      dataIndex: "ipLeft",
      render: (text: string) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>New Ip Right</div>
      ),
      dataIndex: "ipRight",
      render: (text: string) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
  ];

  const positionColumns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          New Ip Left
        </div>
      ),
      dataIndex: "Ip Left",
      render: (text: string) => (
        <span
          style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}>
          New Ip Right
        </div>
      ),
      dataIndex: "Ip Right",
      render: (text: string) => (
        <span
          style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}
        >
          {text}
        </span>
      ),
    },
  ];

  const leaderColumns = [
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>S. No</div>,
      dataIndex: "no",
      render: (text: any) => (
        <span
          style={{ fontWeight: "bold", fontSize: "15px", color: "#a6a6a6" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>Date</div>,
      dataIndex: "Date",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>LB</div>,
      dataIndex: "lb",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Car Club</div>
      ),
      dataIndex: "CarClub",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Travel Club</div>
      ),
      dataIndex: "TravelClub",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>House Club</div>
      ),
      dataIndex: "HouseClub",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Premium Club</div>
      ),
      dataIndex: "PremiumClub",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>TDS</div>,
      dataIndex: "TDS",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", fontSize: "15px" }}>OC</div>,
      dataIndex: "OC",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Net Amount</div>
      ),
      dataIndex: "NetAmount",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Neft Details</div>
      ),
      dataIndex: "NeftDetails",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>Neft Date</div>
      ),
      dataIndex: "NeftDate",
      render: (text: any) => (
        <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
      ),
    },
  ];

  const now = new Date();
  let previousMonth = now.getMonth();
  let previousYear = now.getFullYear();

  // console.log(previousMonth);
  // console.log(now.getMonth());

  const monthClosingTitle = `${month}-${year} Month Closing`;

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Club Status</h2>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }}>
                <Table
                  columns={columns}
                  // dataSource={pagesdata || []}
                  dataSource={PageData}
                  scroll={{ x: true }}
                  pagination={false}
                  loading={loading}
                />
              </div>
            </Col>
          </Card>
          <Card
            className="bg-white border "
            style={{ borderRadius: "12px", marginTop: "1%" }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }}>
                <Row className="ml-2 mr-2 mb-4 font-bold text-xl">
                  {monthClosingTitle}
                </Row>
                <Row className="ml-2 mr-2 mb-4 font-bold text-xl">
                  Current Position
                </Row>
                <Table
                  columns={positionColumns}
                  dataSource={positionData || []}
                  pagination={false}
                  scroll={{ x: true }}
                />
                <Row className="d-flex justify-content-center align-items-center mt-4">
                  <button
                    className="btn bg-orange-500 text-white"
                    style={{
                      backgroundColor: "rgb(255, 90, 31)",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handleFetchPreviousMonthData}
                  >
                    CLICK HERE FOR LAST MONTH LEADERSHIP BONUS STATUS
                  </button>
                </Row>
              </div>
            </Col>
          </Card>
          <Card
            className="bg-white border "
            style={{ borderRadius: "12px", marginTop: "1%" }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }}>
                <Row
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#a6a6a6",
                  }}
                >
                  Any direct seller who is entering by completing, 100 IP will
                  not be eligible for leadership bonus. A person will be
                  eligible for leadership bonus only if he is entering with 500
                  IP or more.. he can also be eligible for leadership bonus if
                  he is upgrading from 100 IP to 500 IP or more but in this case
                  he can quality for leadership bonus from the next month of
                  upgrading.
                </Row>
                <Row className="ml-2 mr-2 mb-4 d-flex justify-content-center">
                  <Col className="font-bold text-xl mt-4">
                    Leadership Bonus Status
                  </Col>
                </Row>
                <Table
                  columns={leaderColumns}
                  dataSource={clubdata}
                  scroll={{ x: true }}
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Clubstatus;
