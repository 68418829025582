import React, { useContext, useEffect, useState } from 'react';
import { Select, message, Form, } from 'antd';
import { getCommisonDate } from '../../../../utils/API';


const { Option } = Select;


const PayoutDates = ({ dateChnageFunction }: { dateChnageFunction: (date: string) => void }) => {
  const [loading, setLoading] = useState(false);
  const [commisonDate, setCommisonDate] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | undefined>('');

  const FetchCommisonDate = () => {
    setLoading(true);
    getCommisonDate()
      .then((response: any) => {
        if(response.data && response.data.date ){
          setSelectedDate(response.data.date[0].date);
          dateChnageFunction(response.data.date[0].date);
        }
        const formattedDates = response.data.date.map((item: any) => {
          const dateObj = new Date(item.date);
          const formattedDate = dateObj.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // hour12: true,
          });
          return {
            ...item,
            formattedDate: `${formattedDate}`,
          };
        });

        setCommisonDate(formattedDates);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchCommisonDate();
  }, []);

  const handleDateChange = (value: string) => {
    // console.log(value);
    setSelectedDate(value); 
    dateChnageFunction(value);
  };

  return (
    <>
      <Form.Item rules={[{ required: true, message: 'Please Select a Date!' }]}>
        <Select
          size="large"
          showSearch
          allowClear
          value={selectedDate}
          onChange={handleDateChange}
          placeholder="Select Date"
          style={{ width: '100%' }}
        >
          {commisonDate.map((date: any) => (
            <Option key={date._id} value={date.date}>
              {date.formattedDate}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export default PayoutDates;
