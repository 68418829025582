// ProductSizeAddForm.tsx
import React, { useState } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Input,
  Spin,
} from "antd";
import { ProductSizeAdd } from "../../../utils/API";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const ProductSizeAddForm: React.FC = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values: any) => {
        setLoading(true);
        ProductSizeAdd(values).then((response: any) => {
          if (response.status === 200) {
            message.success(response.data.message);
            navigate("/backend/product-size");
            setLoading(false);
          } else {
            setLoading(false);
            message.error("Something went wrong");
          }
        })
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Size</h2>
        </Col>

        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/product-size"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white" onFinish={handleSubmit}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={24}
                  className="d-flex justify-content-start align-items-center"
                ></Col>
                <Col span={24}>
                  <Row>
                    <Col
                      xs={24} sm={24} md={4}
                      className="d-flex justify-content-start me-4 bg-white lg:mb-5  "
                    >
                      <label className="font-bold">
                        Size <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Size",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Size"
                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="d-flex justify-content-center">
                    <button className="btn-brand" type="submit">
                      {
                        loading ? <Spin tip="Adding..."  /> : "Submit"
                      }
                    </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default ProductSizeAddForm;
