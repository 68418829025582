import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { constants } from 'fs/promises';

export  const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const CommanAPI = axios.create({
  baseURL : process.env.REACT_APP_LOGIN_URL
})


API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response) {
    // Check for invalid token response
    if (error.response.status === 401) {
      message.error('Invalid token. Please login again.');
      localStorage.removeItem('token');
      window.location.href = '/login'; // Adjust the redirect URL as needed
    }
  }
  return Promise.reject(error);
});

export const accessmenuadd = async () => {
  try {
    const response = await API.get('/access');
    return response;
  } catch (error) {
    throw error;
  }
}
export const accessmenuget = async () => {
  try {
    const response = await API.get('/permissions');
    return response.data.permission
  } catch (error) {
    throw error;
  }
}


export const accessmenuupdate = async (id: any, data: any) => {
  try {
    const response = await API.put(`/permissions/${id}/update`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const register = async (data: Record<string, any>) => {
  try {
    const response = await CommanAPI.post('/register', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const login = async (data: {
  username: any;
  password: any;

}) => {
  try {
    const response = await CommanAPI.post( '/login', data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const forgotPassword = async( data:{
  username: any;
  roleId: number | null;
})=>{
try {
  const response = await CommanAPI.post('/forgotpassword',data);
  return response;
} catch (error){
  throw error;
}
}



export const checkUsernameAvailability = async (username: string) => {
  try {
      const response = await CommanAPI.get(`/check-username`, {
          params: { username }
      });
      return response.data.available;
  } catch (error) {
      console.error('Error checking username availability:', error);
      throw error;
  }
};


export const changePassword = async ( id : any , data: any) => {
  try {
    const response = await API.put( `/users/${id}/change-password`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const searchProfile = async (data : any , userId : any  ) => {
  try {
    const response = await API.post(`/users/${userId}/find-user`,  data );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const processPayouts = async () => {
  try {
    const response = await API.post(`/manual-payout` );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getAllcategories = async (searchText : any) => {
  try {
    const response = await CommanAPI.get( "/categories", {
      params: {
        search: searchText
      }
    }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const backendallCategories = async (searchText : any) => {
  try {
    const response = await API.get( "/categories/all", {
      params: {
        search: searchText
      }
    }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const adminallCategories = async (searchText : any) => {
  try {
    const response = await API.get( "/categories", {
      params: {
        search: searchText
      }
    }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getSliders = async () => {
  try {
    const response = await CommanAPI.get(  "/slider");
    return response;
  } catch (error) {
    throw error;
  }
}

export const getproductsize = async () => {
  try {
    const response = await CommanAPI.get(  "/size");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getcolores = async () => {
  try {
    const response = await CommanAPI.get( "/colors");
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getBlogs = async () => {
  try {
    const response = await CommanAPI.get( "/blogs");
    return response.data
  } catch (error) {
    throw error;
  }
}
export const getallproduct = async (categories: string[], priceRange: any, color: string[], size: string[], priceRangeby: any,  searchValue?: string , page?: any , productsPerPage?: any  ): Promise<any> => {
  try {
    const categoryParams = categories.map((categoryId, index) => `categoryId[${index}]=${categoryId}`).join('&');
    const colorParams = color.map((colorId, index) => `colorId[${index}]=${colorId}`).join('&');
    const sizeParams = size.map((sizeId, index) => `sizeId[${index}]=${sizeId}`).join('&');
    const searchParams = searchValue ? `search=${searchValue}` : '';
  
    const url = `/products?${searchParams}&${categoryParams}&${colorParams}&${sizeParams}&price=${priceRange}&sortBy=${priceRangeby}&page=${page}&productsPerPage=${productsPerPage}`

    const response = await CommanAPI.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getHomePageProducts = async () => {
  try {
    const response = await CommanAPI.get( `/products/home-page`);

    return response.data
  } catch (error) {
    throw error;
  }
}

export const getProductBySlug = async (slug: string) => {
  try {
    const response = await CommanAPI.get( `/product/${slug}`);

    return response.data
  } catch (error) {
    throw error;
  }
}

export async function uploadImage(image: Blob, name: string) {
  try {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name);
    
    const response = await API.post('/slider/add', formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const UpdateSlider = async (id: any,  formData : FormData): Promise<any> => {
  try {
    const response = await API.put(`/slider/${id}/update`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const getSliderByID = async (id: any) => {
  try {
    const response = await API.get(`/slider/${id}/edit`)
    return response
  } catch (error) {
    throw error;
  }
}

export const DeleteSlider = async (id: any) => {
  try {
    const response = await API.delete(`/slider/${id}/delete`)
    return response
  } catch (error) {
    throw error;
  }
}

export const addCustomer = async (data: any) => {
  try {
    const response = await API.post('/users/add', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCustomers = async (searchText: any , kyc : any | null ) => {
  try {
    const response = await API.get('/users', {
      params: {
        search: searchText,
        isKyc : kyc
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCommisonDate = async () => {
  try {
    const response = await API.get('/financial-year/user/getDate', {
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCustomers = async (searchText: any , kyc : any | null , page : any ) => {
  try {
    const response = await API.get('/users/admin', {
      params: {
        search: searchText,
        isKyc : kyc , 
        page : page

      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const getChildrenList = async (userId: string) => {
  try {
    const response = await API.get(`/users/${userId}/childrenlist`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const sevenpod = async (userId: string) => {
  try {
    const response = await API.get(`/users/${userId}/sevenpod`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const fetchIpReport = async (userId: any, fromDate: string, toDate: string, page:Number) => {
  try {
    const response = await API.post(`users/${userId}/ipReport`, 
      { page }, // `page` is in the request body
      { params: { fromDate, toDate } } // `fromDate` and `toDate` are in the query parameters
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching IP report:', error);
    throw error;
  }
};


export const fetchIpReportList = async (userId: any, fromDate: string, toDate: string) => {
  try {
    const response = await API.get(`users/${userId}/ipReport-child`, {
      params: { fromDate, toDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching IP report list:', error);
    throw error;
  }
};

export const submitTripodData = async (data: any) => {
  try {
    const response = await API.post('/tripods', data);
    return response.data;
  } catch (error) {
    console.error('Error submitting tripod data:', error);
    throw error;
  }
};



export const getAllTripodData = async (page : any) => {
  try {
    const response = await API.get('/tripods' , {
      params: {
        page : page
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tripod data:', error);
    throw error;
  }
}

export const updateSevenpodStatus = async (id: string, isApprove: number, message: string) => {
  try {
    const response = await API.put(`/sevenpods/${id}/update`, { isApprove, message });
    return response.data;
  } catch (error) {
    console.error('Error updating sevenpod status:', error);
    throw error;
  }
};


export const updateTripodStatus = async (id : any ,isApprove : any , message: any) => {
  try {
    const response = await API.put(`/tripods/${id}/update`,  { isApprove, message });
    return response.data;
  } catch (error) {
    console.error('Error updating sevenpod status:', error);
    throw error;
  }
}


export const submitSevenPodData = async (data: any) => {
  try {
    const response = await API.post('/sevenpods', data);
    return response.data;
  } catch (error) {
    console.error('Error submitting tripod data:', error);
    throw error;
  }
};



export const  getUsersChildrenLists = async (userId: string ,page : any  ,searchText : any ) => {
  try {
    const response = await API.get(`/users/${userId}/userchildrenlist` , {
      params: {
        page : page,
        search : searchText
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const  getUserwiseChildrenLists = async (userId: string  ,searchText : any ) => {
  try {
    const response = await API.get(`/users/${userId}/children` , {
      params: {
        search : searchText
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const DownlineUserLogin = async (username : any) => {
  try {
    const response = await API.post(`/users/downlinelogin` , {username} );
    return response;
  } catch (error) {
    throw error;
  }
}


export const getAllSevenpodData = async (page : any) => {
  try {
    const response = await API.get('/sevenpods' , {
      params: {
        page : page
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getDirectReportClosingWise = async (id: string, date: string, page: number) => {
  try {
    const response = await API.post(`/direct-report/${id}`, {
      date: date,
      page: page
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching direct report closing wise:", error); // Log the error for debugging
    throw error;
  }
}

export const getAllTopEarners = async (page : any , date : any) => {
  try {
    const response = await API.post('/financial-year/top-earning',   {
      date: date,
      page: page
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const deleteCustomer = async (id: number) => {
  try {
    const response = await API.delete(`/uers/${id}/delete`)
    return response
  } catch (error) {
    throw error;
  }
}
export const updateCustomer = async (customerId: number, data: any): Promise<any> => {
  try {
    const response = await API.put(`/users/${customerId}/update`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteImage = async (userId: any) => {
  try {
    const response = await API.post(`/users/image/delete`, {
       userId 
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const MakeSupportCenter = async (id: number ) => {
  try {
    const response = await API.put(`/users/${id}/make-support-center`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const UserActiveInActive = async (id: number ) => {
  try {
    const response = await API.put(`/users/${id}/toggle-active`);
    return response;
  } catch (error) {
    console.error(error);
  }
};


export const getCustomerByID = async (id: any) => {

  try {
    const response = await API.get(`/users/${id}/edit`)
    return response.data
  } catch (error) {
    throw error;
  }
}


export const redEntry = async (searchusername: string | null, userId: any, fromDate: string, toDate: string , page : any) => {
  try {
    const response = await API.post('users/red-entry', {
      searchusername,
      fromDate,
      page,
      toDate
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


export const UpdateFlag = async (id: number, isDeleted: boolean): Promise<AxiosResponse<any>> => {
  try {
    const response = await API.delete(`/users/${id}/delete`, { data: { isDeleted } });
    return response;
  } catch (error) {
    console.error('Error in UpdateFlag:', error);
    throw error; // Optionally rethrow or handle the error further
  }
};
export const ProductSizeActive = async (id: number ) => {
  try {
    const response = await API.put(`/size/${id}/toggle-active`);
    return response;
  } catch (error) {
    console.error(error);
  }
};


export const ProductSizeAdd = async (data: {
  name : string
}) => {
  try {
    const response = await API.post('/size/add', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editProductSize = async (id: any) => {
  try {
    const response = await API.get(`/size/${id}/edit`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const ProductSizeUpdate = async (id: number, data: {
  name : string
}) => {
  try {
    const response = await API.put(`/size/${id}/update`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const ProductSizeDelete = async (id: number ) => {
  try {
    const response = await API.delete(`/size/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const addCategory = async (formData: any) => {
  try {
   const response = await API.post("/categories/add", formData);
    return response;
  } catch (error) {
    throw error;
  }
};


export const deletecategory = async (id: number) => {
  try {
    const response = await API.delete(`/categories/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCategorybyId = async (id: any) => {
  try {
    const response = await API.get(`/categories/${id}/edit`);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


export const updatecategory = async (
  id: number,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/categories/${id}/update`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
}



export const addTestimonials = async (formData: any) => {
  try {
   const response = await API.post("/testimonial/store", formData);
    return response;
  } catch (error) {
    throw error;
  }
};


export const getAllTestimonials = async () => {
  try {
    const response = await API.get("/testimonial");
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getTestimonialById = async (id: any) => {
  try {
    const response = await API.get(`/testimonial/${id}/edit`);  
    return response.data;
  }

  catch (error) {
    throw error;
  }

}

export const deletetestimonial = async (id: number) => {
  try {
    const response = await API.delete(`/testimonial/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};



export const updateTestimonial = async (
  id: number,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/testimonial/${id}/update`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const newsletter = async (values: any) => {
  try {
    const response = await CommanAPI.post(   '/news/add', values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getallnewsletter = async () => {
  try {
    const response = await API.get('/news');
    return response.data.News
  } catch (error) {
    throw error;
  }
}

export const getallproducts = async (filters = {}) => {
  try {
    const query = new URLSearchParams(filters).toString();
    const response = await API.get(`/product?${query}`);
    return response.data.products;
  } catch (error) {
    throw error;
  }
};

export const addProduct = async (formData: FormData) => {
  try {
    const response = await API.post('/product/add', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.products;
  } catch (error) {
    throw error;
  }
};


export const editProduct = async (id: any) => {
  try {
    const response = await API.get(`/product/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const deleteProduct = async (id: any) => {
  try {
    const response = await API.delete(`/product/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const removeProductImage = async (productId: any, imageId: any ) => {
  try {
    const response = await API.post(`/product/image/delete`, { productId, imageId });
    return response.data; // Assuming your API returns a message upon successful deletion
  } catch (error : any) {
    throw error;
  }
};

export const updateProduct = async (
  id: any,
  updatedFormData: any
): Promise<any> => {
  try {
    const response = await API.put(`/product/${id}/update`, updatedFormData);
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const submitReviewAPI = async (data: any) => {
  try {
    const response = await API.post('/product-reviews', data);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


export const GetAllPages = async () => {
  try {
    const response = await API.get('/pages');
    return response.data.Pages
  } catch (error) {
    throw error;
  }
}

export const AddPage = async (PageData : any) => {
  try {
    const response = await API.post('/pages/add' , PageData) 
    return response
  }
  catch(error)
  {
    throw error;
  }
}

export const deletePages = async (id: any) => {
  try {
    const response = await API.delete(`/pages/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetAllPagesById = async (id: any) => {
  try {
    const response = await API.get(`/pages/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}


export const updatePages = async (
  id: number,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/pages/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetSettingData = async () => {
  try {
    const response = await CommanAPI.get(  '/settings');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getsetting = async () => {
  try {
    const response = await API.get( '/settings');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updateSetting = async (
  id: any,
  formData: FormData
): Promise<any> => {
  try {
    const response = await API.put(`/settings/${id}/update`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


/// Seminars
export const addseminar = async (seminarData : any) => {
  try {
    const response = await API.post('/seminars/add' , seminarData) 
    return response
  }
  catch(error)
  {
    throw error;
  }
}

// Get All Seminars
export const getallseminar = async () => {
  try {
    const response = await API.get('/seminars');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const editseminar = async (id : any) => {
  try {
    const response = await API.get(`/seminars/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updateseminar = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/seminars/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteseminar = async (id: any) => {
  try {
    const response = await API.delete(`/seminars/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const storePPT  = async (formData : any) => {
  try {
    const response = await API.post('/product-ppt/store' , formData) 
    return response
  }
  catch(error)
  {
    throw error;
  }
}

export const EditPPT = async (id : any) => {
  try {
    const response = await API.get(`/product-ppt/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const downloadPPT = async () => {
  try {
    const response = await API.get('/product-ppt/download');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getppt = async () => {
  try {
    const response = await API.get('/product-ppt');
    return response.data.ppt
  } catch (error) {
    throw error;
  }
}

export const updateppt = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/product-ppt/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteppt = async (id: any) => {
  try {
    const response = await API.delete(`/product-ppt/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}

//Business ppt

export const getbusinessppt = async () => {
  try {
    const response = await API.get('/business-ppt');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const businesspptEdit = async (id : any) => {
  try {
    const response = await API.get(`/business-ppt/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
} 

export const updatebusinessppt = async (
  id: any,
  values: any 
): Promise<any> => {
  try {
    const response = await API.put(`/business-ppt/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const businesspptdownload = async () => {
  try {
    const response = await API.get('/business-ppt/download');
    return response.data
  } catch (error) {
    throw error;
  }
}


//////////// TDS Certificate Route ////////////////

export const getalltds = async () => {
  try {
    const response = await API.get('/tds-certificate');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updateOrderUserId = async (
  id: any,
  userid : any 

): Promise<any> => {
  try {
    const response = await API.put(`/order/${id}/user`, 
    {
      userId : userid
    }
    );
    return response;
  } catch (error) {
    throw error;
  }
}


export const tdsactive = async (id: number ) => {
  try {
    const response = await API.put(`/tds-certificate/${id}/active`);
    return response;
  } catch (error) {
    console.error(error);
  }
};


export const tdsAdd = async (tdsData : any) => {
  try {
    const response = await API.post('/tds-certificate/store' , tdsData) 
    return response
  }
  catch(error)
  {
    throw error;
  }
}

export const tdsEdit = async (id : any) => {
  try {
    const response = await API.get(`/tds-certificate/${id}/edit`);
    return response.data.tdsCertificate
  } catch (error) {
    throw error;
  }
}

export const tdsupdate = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/tds-certificate/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const tdsdelete = async (id: any) => {
  try {
    const response = await API.delete(`/tds-certificate/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAllOrder = async ( id : any  ) => {
  try {
    const response = await API.get('/order');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getOrder = async (  ) => {
  try {
    const response = await API.get(`/order/user`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const orderUpdate = async (id : any , data : any ) => {
  try {
    const response = await API.put(`/order/${id}/update` , data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const orderstatus = async (id : any , status : any) => {
  try {
    const response = await API.put(`/order/${id}/status` , status);
        return response.data
  } catch (error) {
    throw error;
  }
}

export const orderdelete = async (id: any) => {
  try {
    const response = await API.delete(`/tds-certificate/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getusertree = async (id : any) => {
  try {
    const response = await API.get(`/users/tree/${id}`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const editColor = async (id: any) => {
  try {
    const response = await API.get(`/colors/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const colordelete = async (id: any) => {
  try {
    const response = await API.delete(`/colors/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const colorupdate = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/colors/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const colorAdd = async (colorData : any) => {
  try {
    const response = await API.post('/colors/add' , colorData) 
    return response
  }
  catch(error)  {
    throw error;
  }
}

export const coloreActive = async (id: number ) => {
  try {
    const response = await API.put(`/colors/${id}/toggle-active`);
    return response;
  } catch (error) {
    console.error(error);
  }
};


export const blogAdd = async (blogData : any) => {
  try {
    const response = await API.post('/blogs/add' , blogData) 
    return response
  }
  catch(error)  {
    throw error;
  }
}

export const getBlog = async (id: any) => {
  try {
    const response = await API.get(`/blogs/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const blogdelete = async (id: any) => {
  try {
    const response = await API.delete(`/blogs/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const blogupdate = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/blogs/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

////////////// accouunt detail ///////////////////
/////////////// current year ///////////////////////

export const getCurrentYear = async ( year  :any, date:any = null ) => {
  try {
    const response = await API.get(`/financial-year/user/${year}`); 
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getCurrentClosingData = async ( year  :any, date:any = null ) => {
  try {
    const response = await API.get(`/financial-year/user/${year}/${date}`); 
    return response.data
  } catch (error) {
    throw error;
  }
}


export const updateNeftDetails = async (data:any) => {
  try {
      const response = await API.put(`/financial-year/update`, data);
      return response.data;
  } catch (error) {
      throw error;
  }
}



export const createRazorpayOrder = async (orderData: any) => {
  try {
      const response = await CommanAPI.post(`/create-order`, orderData);
      return response.data;
  } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
  }
};

export const storeOrder = async (orderData: any) => {
  try {
      const response = await CommanAPI.post(`/order/store`, orderData);
      return response.data;
  } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
  }
};


export const downlineOrder = async (id: any , page : any) => {
  try {
      const response = await API.post(`/order/${id}/downline-order` , 
      {
        page
      }
      );
      return response.data;
  } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
  }
  
}

export const payonline = async (orderData: any) => {
  try {
      const response = await API.post(`/order/payonline`, orderData);
      return response.data;
  } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
  }
};


export const verifyPayment = async (paymentData: { razorpay_payment_id: any; razorpay_order_id: any; razorpay_signature: any }) => {
  try {
      const response = await CommanAPI.post(`/verify-payment`, paymentData);
      return response.data;
  } catch (error) {
      console.error('Error verifying payment:', error);
      throw error;
  }
}


//club status
export const getClubStatus = async (year: number, month: number) => {
  try {
    const response = await API.post(`/financial-year/monthly`, {
      
        year,
        month
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//reward status
export const getRewardData = async () => {
  try {
    const response = await API.get(`/reward`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const direactStatus = async (id: any) => {
  try {
    const response = await API.get(`/payout/${id}/direact-status`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClubdata = async () => {
  try {
    const response = await API.get(`/club`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const isFirst = async (id: number ) => {
  try {
    const response = await API.put(`/users/${id}/first-vist`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const ContactUsapi = async (data: any) => {
  try {
    const response = await CommanAPI.post('/contact', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getContacts = async () => {
  try {
    const response = await API.get('/contact');
    return response;
  } catch (error) {
    throw error;
  }
}

export const Testimonials = async () => {
  try {
    const response = await CommanAPI.get('/testimonial');
    return response;
  } catch (error) {
    throw error;
  }
}


export const fetchPayoutData = async (date: string , page : any) => {
  try {
    const response = await API.post(`/financial-year/payout-index` , 
      { date , page },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching payout data:', error);
    throw error;
  }
};

export const fetchReceivedPayments = async (date : any , page : any) => {
  try {
    const response = await API.post('/users/received-payment', {page } ,{ params: { date } });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching received payments:', error);
    throw error;
  }
};

export const fetchNextWeekClosingData = async (date: string, page : any) => {
  try {
    const response = await API.post("/users/next-week-closing" ,  {page } ,{ params: { date } });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const getaward = async (searchText: string) => {
  try {
    const response = await CommanAPI.get( "/awards");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addAward = async (awardsData: any) => {
  try {
    const response = await API.post( "/awards/add" , awardsData);
    return response.data
  } catch (error) {
    throw error;
  }
};
export const getawardbyid = async (id: string,) => {
  try {
    const response = await API.get(`/awards/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
};
  export const updateAward = async (id: any, formData: FormData) => {
    try {
      const response = await API.put(`/awards/${id}/update`, formData);
      return response.data
    } catch (error) {
      throw error;
  }
};
export const deleteaward = async (id: any) => {
  try {
    const response = await API.delete(`/awards/${id}/delete`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};