import React, { useState, useEffect, useContext } from 'react';
import { Radio, Input, Select, Button, Form, message, Spin } from 'antd';
import axios from 'axios';
import { DownlineUserLogin, getUsersChildrenLists, getUserwiseChildrenLists, isFirst, searchProfile } from '../../../../utils/API';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Auth/AuthContext';
import CryptoJS from "crypto-js";

const { Option } = Select;

const key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');  // 16 bytes IV

const encryptData = (data : any) => {
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
  return encrypted.toString();
};


const DownGeneology = () => {
  const [form] = Form.useForm();
  const [searchType, setSearchType] = useState('id');
  const [customerData, setCustomerData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchCustomerData = ( searchText: any) => {
    const customerId = localStorage.getItem("customerId") || "";
    const customuserId = customerId.replace(/"/g, "");

    setLoadingData(true);
    getUserwiseChildrenLists(customuserId, searchText)
      .then((response: any) => {
        setCustomerData(response.data.children.reverse());
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setLoadingData(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error("Failed to fetch customer data. Please try again.");
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomerData( searchText);
  }, [ searchText]);

  const handleSearchTypeChange = (e: any) => {
    setSearchType(e.target.value);
  };

  const handleSearchTextChange = (value: any) => {
    setSearchText(value);
    fetchCustomerData( value); // Fetch customer data with the search text
  };

  const handleSearch = async (values: any) => {
    setLoadingSubmit(true);
    try {
      const customerId = localStorage.getItem('customerId') || '';
      const userId = customerId.replace(/"/g, '');
      const data = await searchProfile({ username: values.search }, userId);
      
      if (data && data._id) {
        DownlineUserLogin(data.username)
        .then(async (response : any) => {
          setAuthData({ token: response.data.token, roleId: response.data.others.roleId });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("username", response.data.others.username ? response.data.others.username : "");      
          localStorage.setItem("address", response.data.others.address);
          localStorage.setItem("user", JSON.stringify(response.data.others.firstName));
          localStorage.setItem("lastname", JSON.stringify(response.data.others.lastName ? response.data.others.lastName : ""));
          localStorage.setItem("customerId", JSON.stringify(response.data.others._id));
          localStorage.setItem("node_logged_in", encryptData(1)); // Store node logged in info in encrypted format
          const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
          localStorage.setItem("cart_userid", JSON.stringify(cartData));
          await isFirst(response.data.others._id);
          message.success(response.data.message);
          navigate("/backend/kyc");
        })
        .catch((error : any ) => {
          // message.error(error.response.data.message);
          console.error("API error:", error);
        });
      } else {
        message.error('Profile not found');
      }
    } catch (error: any) {
      message.error(error.response.data.message);
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <div className="bg-orange-500 text-white text-center p-2 font-bold">
        DOWNLINE GENEALOGY
      </div>
      <Form
        form={form}
        onFinish={handleSearch}
        className="border p-4 mt-4"
      >
        <Form.Item className="text-center">
          <Radio.Group onChange={handleSearchTypeChange} value={searchType}>
            <Radio value="id">ID No.</Radio>
            <Radio value="username">User Name</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="search"
          className="items-center"
          rules={[{ required: true, message: 'Please enter a value' }]}
        >
          {searchType === 'id' ? (
            <Input className="border-gray-500 rounded-md" placeholder="ID No." />
          ) : (
            <Select
              showSearch
              className="border-gray-500 rounded-md"
              placeholder="Select User Name"
              onSearch={handleSearchTextChange}
              filterOption={false}
              options={customerData.map((user: any) => ({
                value: user.username,
                label: `${user.username} - ${user.firstName} ${user.lastName}`,
              }))}
            />
          )}
        </Form.Item>
        <Form.Item className="flex justify-center">
          <button
            type="submit"
            className="mx-2 bg-orange-500 text-white px-3 py-2 rounded-md"
            disabled={loadingSubmit}
          >
            {loadingSubmit ? (
              <>
                <Spin />
                <span className="ml-2">Submitting...</span>
              </>
            ) : (
              'Submit'
            )}
          </button>
          <button  className="mx-2 border-2 bg-white text-black px-3 py-2 rounded-md" onClick={() => form.resetFields()}>
            Cancel
          </button>
        </Form.Item>
      </Form>
   
    </div>
  );
};

export default DownGeneology;
