import { Navigate, useLocation } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import PersonalCare from "../Pages/frontend/PersonalCare";
import Register from "../Pages/Auth/Register";
import Homepage from "../Pages/frontend/Homepage";
import Products from "../Pages/frontend/ProductListing/Products";
import ViewBlog from "../Pages/frontend/ProductListing/ViewAllBogs";
import ViewBlogDetail from "../Pages/frontend/ProductListing/ViewBlogDetail";
import Aboutus from "../Pages/frontend/Aboutus/Aboutus";
import ProductDetail from "../Pages/frontend/ProductListing/ProductDetail";
import Cart from "../Pages/frontend/Cart/Cart";
import WishList from "../Pages/frontend/WishList/WishList";
import NotFound from "../Components/NotFound";
import { useUserRole } from "../utils/useUserRole";
import path from "path";
import Payonline from "../Pages/backend/Geneology/Newsales/PayOnline";
import ContactUs from "../Pages/frontend/Contactus/Contact";
import PayonlineUser from "../Pages/frontend/PayOnlineUser";
import PrivacyPolicy from "../Pages/Auth/PrivacyPolicy";
import TermConditions from "../Pages/Auth/TermConditions";
import RefundCancellationPolicy from "../Pages/Auth/RefundCancellationPolicy";
import SupportCenterLogin from "../Pages/Auth/SupportCenterLogin";
import Awards from "../Pages/frontend/Awards";
// import Awards from "../Pages/frontend/Awards";

// This function will wrap around the route configuration
const withRoleBasedComponent = (Component : any, allowedRoles : any ) => {
  return (props : any ) => {
    const roleId = useUserRole();
    const location = useLocation();
    if (allowedRoles.includes(roleId)) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  };
};

const frontendRoutes = [
  {
    path: "/register",
    name: "Register",
    component: Register,
    exact: true
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    exact: true,
  },
  {
    path: "/",
    name: "Home",
    component: Homepage,
    exact: true,
  },
  {
    path: "/personal-care",
    name: "Personal Care",
    component: PersonalCare,
    exact: true
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    exact: true
  },
  {
    path: "/products/:slug",
    name: "Products",
    component: Products,
    exact: true
  },
  {
    path: "/blog",
    name: "Blog",
    component: ViewBlog,
    exact: true
  },
  {
    path: "/view-blog",
    name: "View Blog",
    component: ViewBlogDetail,
    exact: true
  },
  {
    path: "/product/:slug",
    name: "Products",
    component: ProductDetail,
    exact: true
  },
  {
    path: "/wishlist",
    name: "WishList",
    component: withRoleBasedComponent(WishList, [1, 2, 3]),
    exact: true
  },
  {
    path: "/cart",
    name: "Cart",
    component:  Cart,
    exact: true
  },
  {
    path : "/pay-online-user",
    name :"Pay Online",
    component : PayonlineUser ,
    exact : true
  },

  {
    path: "/aboutus",
    name: "About Us",
    component: Aboutus,
    exact: true
  },
  {
    path : "/contact",
    name :"Contact Us",
    component : ContactUs ,
    exact : true
  },
  {
    path: "/pay-online",
    name: "Pay Online",
    component: withRoleBasedComponent(Payonline, [1, 2]),
    exact: true
  }, 
   {
    path: "/privacy-policy",
    name: "Pay Online",
    component: PrivacyPolicy,
    exact: true
  } ,
    {
      path :'/terms-and-conditions',
      name : 'Terms and Conditions',
      component : TermConditions ,
      exact : true
    },
    {
      path :'/refund-and-cancellation-policy',
      name : 'Refund and Cancellation Policy',
      component : RefundCancellationPolicy,
      exact : true
    },
    {
      path :'/awards',
      name : 'Awards',
      component : Awards,
      exact : true
    },
    {
      path :"/blog/:slug",
      name :"View Blog",
      component : ViewBlogDetail ,
    }
];

export default frontendRoutes;
