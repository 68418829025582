import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  message,
  Card,
  Select,
  Tooltip,
  Switch,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteImage,
  getCustomerByID,
  updateCustomer,
} from "../../../../utils/API";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { IoIosInformationCircle } from "react-icons/io";
import { AuthContext } from "../../../Auth/AuthContext";
import { UploadFile } from "antd/lib/upload";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import { BiSolidInbox, BiUpload } from "react-icons/bi";
import { extendChartView } from "echarts";
import { BsUpload } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import Dragger from "antd/es/upload/Dragger";
import dummimage from "../../../../assets/frontendassets/profiledummy.png"

const { Option } = Select;

interface StateData {
  [key: string]: string;
}

const UpdateBankDetailForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = React.useState(false);
  const user_id = localStorage.getItem("customerId") || "";
  const id = useParams().id;
  const { authData } = useContext(AuthContext);
  const roleId = authData.roleId;
 

  const showProfile = async () => {
    try {
      const existingData = await getCustomerByID(id); 
      form.setFieldsValue({
        ...existingData
      });
    } catch (error) {
      message.error("Error fetching data. Please try again.");
      navigate("/backend/dashboard");
    }
  };
  

  useEffect(() => {
    if (id) {
    
      showProfile();
    }
  }, [id, form]);

  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue("_id");
    if (currentId) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
          if (key !== 'image') {
            formData.append(key, values[key] || '');
          }
        });

        // Make the API call to update customer
        const response = await updateCustomer(currentId, formData); // Adjust your API call to handle FormData
        setIsLoading(false);
  
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
        } else {
          message.success("Profile updated successfully");
          form.setFieldsValue({ password: "" });
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Failed to submit data. Please try again.");
      }
    } else {
      setIsLoading(false);
      message.error("Failed to fetch data. Please try again.");
    }
  };
  


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Profile</h2>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            {/* First name */}
           

            {/* Bank Details */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="mb-5">
              <Row className="bg-white">
                <label
                  className="font-bold text-4xl"
                  style={{ fontSize: "20px" }}
                >
                  BANK DETAILS
                </label>
              </Row>
            </Col>

            {/* Bank Name */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Name
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="bankName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Bank Address */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Address
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="bankAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Address",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Address"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Bank Account No. */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank Account No.
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="bankAccountNo"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bank Account No.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Bank Account No."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Bank IFSC Code
                    <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="ifsc"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter IFSC Code",
                      },
                      {
                        validator: (_, value, callback) => {
                          if (value && value.length >= 5 && value[4] !== "0") {
                            callback("5th Character must be Zero");
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="IFSC Code"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn-brand" disabled={isLoading}>
                {isLoading ? "Updating..." : "Update"}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default UpdateBankDetailForm;