import React, { useContext, useEffect, useRef, useState } from "react";
import sliderImage from "../../../assets/frontendassets/1.jpg";
import {
  Breadcrumb,
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Collapse,
  Grid,
  Image,
  message,
  Modal,
  notification,
  Pagination,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import {
  BiCheck,
  BiChevronDown,
  BiChevronRight,
  BiCloset,
  BiDownArrow,
  BiHeart,
  BiMenu,
  BiMinus,
  BiPlus,
  BiSearch,
} from "react-icons/bi";
import { FaFacebook, FaFacebookF, FaHeart, FaInstagram, FaLinkedin, FaRegHeart, FaRegStar, FaShoppingBag, FaStar, FaStarHalfAlt, FaTwitter, FaWhatsapp } from "react-icons/fa";
import imageproduct1 from "../../../assets/frontendassets/Top products/3.jpg";
import { IoIosArrowRoundForward, IoMdClose } from "react-icons/io";
import "./ProductListing.css";
import type { CollapseProps } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllcategories,
  getallproduct,
  getcolores,
  getproductsize,
} from "../../../utils/API";
import { config } from "../../../utils/config";
import { AiOutlineHeart, AiOutlineMenu, AiOutlineShopping } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useCart } from "../../../utils/Cartcontext";
import { useWishlist } from "../../../utils/WishListContextr";
import { AuthContext } from "../../Auth/AuthContext";
const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const { Option } = Select;

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [color, setColor] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]); // Initialize with categoryId from URL if available
  const [selectedPriceRange, setSelectedPriceRange] = useState([100, 1500]);
  const [selectedColor, setSelectedColor] = useState<string[]>([]);
  const [selectedSizeData, setSelectedSizeData] = useState<string[]>([]);
  const [priceRangeby, setPriceRangeby] = useState("");
  const imageUrl = process.env.REACT_APP_Image_URL;
  const [cart, setCart] = useState<any>([]);
  const [showSizes, setShowSizes] = useState(false);
  const [isProductViewModalOpen, setIsProductViewModalOpen] = useState(false);
  const [records, setRecords] = useState<any>(productData);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const isLargeScreen = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const { xs, sm, md, lg } = useBreakpoint();
  const [menuVisible, setMenuVisible] = useState(false);
  const { addToCart } = useCart();
  const { wishlist, addToWishlist } = useWishlist();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
const [totalReviews, setTotalReviews] = useState(0);

const latestRequestRef = useRef(0); // A ref to store the latest request identifier
  const isInitialRender = useRef(true); // Ref to track initial render

const calculateAverageRating = (reviews : any) => {
  if (reviews.length === 0) {
    return 0;
  }
  const totalRating = reviews.reduce((sum : any,  review : any) => sum + review.rating, 0);
  return totalRating / reviews.length;
};

const renderStars = (rating : any, onSelectRating : any) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;
  const handleStarClick = (starIndex : any) => {
    // if (onSelectRating) {
    //   // alert(starIndex);
    //   // console.log(onSelectRating);
    //   onSelectRating(starIndex);
    // }
  };

  return (
    <div className="flex">
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar
          size={15}
          key={index}
          className="text-orange-500"
          onClick={() => handleStarClick(index + 1)}
        />
      ))}
      {halfStars === 1 && (
        <FaStarHalfAlt
          size={15}
          className="text-orange-500"
          onClick={() => handleStarClick(fullStars + 0.5)}
        />
      )}
      {Array.from({ length: emptyStars }, (_, index) => (
        <FaRegStar
          size={15}
          key={fullStars + index + halfStars}
          className="text-orange-500"
          onClick={() => handleStarClick(fullStars + index + 1)}
        />
      ))}
    </div>
  );
};

  const getCategoryIdFromPathname = (pathname : any) => {
    const parts = pathname.split('/');
    return parts[2]; 
  };

  const categoryId = getCategoryIdFromPathname(location.pathname);
  const [error, setError] = useState<string | null>(null);

  const { authData } = useContext(AuthContext);
  const { state } = location;

  useEffect(() => {
    if (categoryId) {
      setSelectedCategories([categoryId]);
    }
    if (location.state && location.state.searchValue) {
      setSearchValue(location.state.searchValue);
    }
  }, [categoryId, location.state]);
  
  let modalWidth;
  
  const handleAddToCart = (product: any) => {
    if (selectedSize === "") {
  message.error('Please select size');
      return;
    } 
  
    addToCart(product, selectedSize);
  
    notification.open({
      message: 'Success',
      icon: <BiCheck style={{ color: '#52c41a' }} />,
      description: 'Cart updated successfully!',
      placement: 'topRight',
      duration: 3, 
    });
  };
  

  const handleWishlist = (product: { _id: any; }) => {
    if (wishlist.some((item) => item.product._id === product._id)) {
      notification.open({
        message: 'Already in Wishlist',
        icon: <BiCheck style={{ color: 'red' }} />,
        description: 'This product is already in your wishlist.',
        placement: 'topRight',
        duration: 3,
      });
      return;
    }

    if (authData.token) {
      notification.open({
        message: 'Success',
        icon: <BiCheck style={{ color: '#52c41a' }} />,
        description: 'Added to wishlist successfully!',
        placement: 'topRight',
        duration: 3,
      });
      addToWishlist(product, selectedSize);
    } else {
      notification.open({
        message: 'Error',
        icon: <BiCheck style={{ color: 'red' }} />,
        description: 'Please login to add to wishlist',
        placement: 'topRight',
        duration: 3,
      });
    }
  };

  const handlenavigate = (records : any) => {
    
    navigate(`/product/${records}`);
  }

  if (isLargeScreen) {
    modalWidth = '50%';
  } else if (isTablet) {
    modalWidth = '70%';
  } else {
    modalWidth = '90%';
  }
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleSortChange = (value : any ) => {
    if (value === "TopRated") {
      // Sort by top rated
      handleSortByTopRated();
    } else if (value === "TopFeatured") {
      // Sort by top featured
      handleSortByTopFeatured();
    } else {
      // Sort by default
      fetchProductData([] , [] , [] , [] , []  , "");
    }
  };


  const handleSortByTopRated = () => {
    // Sort productData by rating
    const sortedProducts = productData.sort((a : any, b : any) => {
      // Your sorting logic based on product rating
      return b.rating.length - a.rating.length; // Example: Sort by number of ratings
    });
    setProductData([...sortedProducts]);
  };

  const handleSortByTopFeatured = () => {
    // Filter out top featured products
    const topFeaturedProducts = productData.filter((product : any) => product.isFeatured);
    setProductData([...topFeaturedProducts]);
  };



  useEffect(() => {
    // Check if records exists and has images
    if (records && records.images && records.images.length > 0) {
      // Update mainImage to the first image in records.images
      setMainImage(records.images[0]);
    } else {
      // If records doesn't have images, set mainImage to null
      setMainImage(null);
    }
  }, [records]);

  // Function to handle clicking on carousel images
  const handleImageClick = (image: string) => {
    // Update mainImage state when another image is clicked
    setMainImage(image);
  };


  const showModal = (productId: string) => {
    const selectedProduct = productData.find(
      (product: any) => product._id === productId
    );
    if (selectedProduct) {
      setIsProductViewModalOpen(true);
      setRecords(selectedProduct);
    }
  };

  const handleOk = () => {
    setIsProductViewModalOpen(false);
  };

  const handleCancel = () => {
    setRecords("");
    setIsProductViewModalOpen(false);
  };

  const handleProductsPerPageChange = (value : any) => {
    setProductsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  // Function to paginate
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const fetchProductData = (
    selectedCategories: string[],
    selectedPriceRange: any,
    selectedColor: any,
    selectedSizeData: any,
    priceRangeby: any,
    searchValue: string ,
    page: number = 1,
      productsPerPage: number = 12
  ): void => {
    setLoading(true);
    const requestId = ++latestRequestRef.current; // Increment the request ID for every call
    getallproduct(
      selectedCategories,
      selectedPriceRange,
      selectedColor,
      selectedSizeData,
      priceRangeby,
      searchValue,
      page,
      productsPerPage
    )
      .then((response: any) => {
        if (requestId === latestRequestRef.current) { // Only set state if it's the latest request
          setProductData(response.products);
          setTotalProducts(response.totalItems);
          setError(null); // Reset error state before fetching
        }
          // setProductData(response.products);
          // setTotalProducts(response.totalItems);
          // setError(null); // Reset error state before fetching
      })
      .catch((error : any) => {
        setError("Error fetching products. Please try again later.");
         setTotalProducts(0); 
        console.error();
      })
      .finally(() => {
        if (requestId === latestRequestRef.current) { // Only set loading to false if it's the latest request
          setLoading(false);
        }
        // setLoading(false);
      });
  };

  const handleFetchCategory = () => {
    getAllcategories("").then((response) => {
      // const filteredCategories = response.filter((category : any) => category.categoryId === null || category.categoryId === "");
      const filteredCategories = response.filter((category : any) => category);
      setCategoryData(filteredCategories);
    });
  };
  

  const handleFetchSizes = () => {
    getproductsize().then((response) => {
      setSizeData(response.data.sizes.filter((size : any) => size.sizeId === null || size.sizeId === "" || size.isActive === true && !size.isDeleted));
    });
  };

  const handleFetchColors = () => {
    getcolores().then((response) => {
      setColor(response.colors.filter((size : any) =>  size.isActive === true && !size.isDeleted));
    });
  };

    // Effect to reset filters when searchValue changes
    useEffect(() => {
      if (!isInitialRender.current) {
        // Reset filters only if it's not the initial render
        setSelectedCategories([]);
        setSelectedColor([]);
        setSelectedSizeData([]);
        setSelectedPriceRange([100, 1500]);
        setPriceRangeby('');
      } else {
        // Skip resetting on initial render
        isInitialRender.current = false; // Set to false after the first render
      }
    }, [searchValue]);
    
  useEffect(() => {
    handleFetchCategory();
    handleFetchSizes();
    handleFetchColors();
    fetchProductData(
      selectedCategories,
      selectedPriceRange,
      selectedColor,
      selectedSizeData,
      priceRangeby ,
      searchValue,
      currentPage,
      productsPerPage
    );
  }, [
    selectedCategories,
    selectedPriceRange,
    selectedColor,
    currentPage,
    productsPerPage,
    selectedSizeData,
    priceRangeby,
    searchValue
  ]);

  const handleCategoryChange = (categoryId: string) => {
    // If the clicked category is already selected, remove it from the selected categories
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== categoryId)
      );
    } else {
      // Otherwise, add it to the selected categories
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  const handleColorChange = (colorId: string) => {
    // If the clicked color is already selected, remove it from the selected colors
    if (selectedColor.includes(colorId)) {
      setSelectedColor(selectedColor.filter((color) => color !== colorId));
    } else {
      // Otherwise, add it to the selected colors
      setSelectedColor([...selectedColor, colorId]);
    }
  };

  const handleSizeChange = (sizeId: string) => {
    if (selectedSizeData.includes(sizeId)) {
      setSelectedSizeData(selectedSizeData.filter((size) => size !== sizeId));
    } else {
      setSelectedSizeData([...selectedSizeData, sizeId]);
    }
  };

  const handlePriceRangeChange = (value: any) => {
    // Update selected price range
    setSelectedPriceRange(value);
  };

  const handlePageChange = (page : any, pageSize : any ) => {
    setCurrentPage(page);
    setProductsPerPage(pageSize);
  };

  const handlePriceRangeby = (priceRangeSort: string) => {
    setPriceRangeby(priceRangeSort);
  };

  const handleRadioChange = (e: any) => {
    const { value } = e.target;
    handlePriceRangeby(value);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedColor([]);
    setSelectedSizeData([]);
    setSelectedPriceRange([100, 1500]);
    setPriceRangeby("");
    setSearchValue("");
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <p className="font-bold text-base p-2 ">CATEGORY</p>
        </>
      ),
      children: (
        <>
          <div className="">
            {categoryData.map((category: any) => (
              <div key={category.id} className="d-flex justify-start">
                <input
                  type="checkbox"
                  onChange={() => handleCategoryChange(category._id)}
                  checked={selectedCategories.includes(category._id)}
                  className={`pl-3 ${selectedCategories.includes(category._id)
                      ? "text-orange-500 rounded-sm border-gray-800 border-2 focus-visible:outline-none focus:ring-0"
                      : "border-gray-400 border-1 rounded-sm focus-visible:outline-none focus:ring-0"
                    }`}
                />
                <p
                  className={`pl-3 ${selectedCategories.includes(category._id) ? "font-bold" : ""
                    }`}
                >
                  {category.name}
                </p>
              </div>
            ))}
          </div>
        </>
      ),
    },
    // {
    //   key: '2',
    //   label: <>
    //   <p className="font-bold text-base p-2 " >Price Range</p>
    //   </>,
    //   children: <>
    //  <Select defaultValue={[100, 1500]} onChange={handlePriceRangeChange}>
    //         <Select.Option value={[100, 500]}>$100 - $500</Select.Option>
    //         <Select.Option value={[500, 1000]}>$500 - $1000</Select.Option>
    //         <Select.Option value={[1000, 1500]}>$1000 - $1500</Select.Option>
    //       </Select>

    //   </>,
    // },
    {
      key: "3",
      label: (
        <>
          <p className="font-bold text-base p-2 ">SIZE</p>
        </>
      ),
      children: (
        <>
          <div className="">
            {sizeData.map((item: any) => (
              <div key={item._id} className="d-flex justify-start ">
                <input
                  type="checkbox"
                  onChange={() => handleSizeChange(item._id)}
                  checked={selectedSizeData.includes(item._id)}
                  className={`pl-3 ${selectedSizeData.includes(item._id)
                      ? "text-orange-500 rounded-sm border-gray-800 border-2 focus-visible:outline-none focus:ring-0"
                      : "border-gray-400 border-1 rounded-sm focus-visible:outline-none focus:ring-0"
                    }`}
                />{" "}
                <p
                  className={`pl-3 ${selectedSizeData.includes(item._id) ? "font-bold" : ""
                    }`}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <p className="font-bold text-base p-2 ">COLOR</p>
        </>
      ),
      children: (
        <>
          <div className="">
            {color.map((item: any) => (
              <div key={item._id} className="d-flex justify-start">
                <input
                  type="checkbox"
                  checked={selectedColor.includes(item._id)} // Check if the current color is selected
                  onChange={() => handleColorChange(item._id)}
                  className={`pl-3 ${selectedColor.includes(item._id)
                      ? "text-orange-500 rounded-sm border-gray-800 border-2 outline-none focus-visible:outline-none focus:ring-0"
                      : "border-gray-400 border-1 rounded-sm appearance-none focus:clear-none focus-visible:outline-none focus:ring-0"
                    }`}

                />
                <p
                  className={`pl-3 ${selectedColor.includes(item._id) ? "font-bold" : ""
                    }`}
                >
                  {item.color}
                </p>
              </div>
            ))}
          </div>
        </>
      ),
    },
    // {
    //   key: '5',
    //   label: <>
    //   <p className="font-bold text-base p-2 " >Occasion</p>
    //   </>,
    //   children: <div className="d-flex justify-start">
    //   <Checkbox /> <p className="pl-3">Festival</p>
    // </div>,
    // },
    {
      key: "6",
      label: (
        <>
          <p className="font-bold text-base p-2">PRICE RANGE BY</p>
        </>
      ),
      children: (
        <>
          <div>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio  text-orange-500 text-bold focus-visible:outline-none focus:ring-0"
                value="priceLowToHigh"
                checked={priceRangeby === "priceLowToHigh"}
                onChange={handleRadioChange}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight:
                    priceRangeby === "priceLowToHigh" ? "bold" : "normal",
                }}
              >
                Price: Low to High
              </span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio  text-orange-500 focus-visible:outline-none focus:ring-0"
                value="priceHighToLow"
                checked={priceRangeby === "priceHighToLow"}
                onChange={handleRadioChange}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight:
                    priceRangeby === "priceHighToLow" ? "bold" : "normal",
                }}
              >
                Price: High to Low
              </span>
            </label>
          </div>
        </>
      ),
    },
    {
      key: "7",
      label: (
        <>
          <p className="font-bold text-base p-2 ">By Collection</p>
        </>
      ),
      children: (
        <div className="">
          <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio  text-orange-500 text-bold focus-visible:outline-none focus:ring-0"
                value="oldest"
                checked={priceRangeby === "oldest"}
                onChange={handleRadioChange}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight:
                    priceRangeby === "priceLowToHigh" ? "bold" : "normal",
                }}
              >
                Oldest
              </span>
            </label>
          <div className="d-flex justify-start">
          <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio  text-orange-500 text-bold focus-visible:outline-none focus:ring-0"
                value="newest"
                checked={priceRangeby === "newest"}
                onChange={handleRadioChange}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight:
                    priceRangeby === "priceLowToHigh" ? "bold" : "normal",
                }}
              >
                Newest
              </span>
            </label>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="lg:mt-72 xl:mt-72 md:mt-36 sm:mt-36   mt-3 xl:px-20 xl:ps-20 lg:px-0 lg:ps-0  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
        <img
          src={sliderImage}
          alt="sliderImage"
          width="100%"
          className=" lg:mt-60 xl:mt-60 md:mt-36 sm:mt-36 mt-24 bg-white"
        />
        <div className=" md:px-0 md:ps-0 bg-white ">
          <div className="pt-3 pb-3">
            <Breadcrumb className="mt-3 font-semibold text-black p-2">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/products">Products</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
      

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 5 }}
              className="p-2 "

            >
              <div className="border border-gra-500">
                <Row className="p-4" gutter={16}>
                  <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
                    <button className="btn w-100 text-white bg-orange-500 mb-2 rounded-none">
                      Filter
                    </button>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
                    <button
                      className="btn text-white bg-orange-500 mb-2 rounded-none w-full"
                      onClick={() => clearFilters()}
                    >
                      Clear
                    </button>
                  </Col>
                  {categoryData.length > 0 &&
                    selectedCategories.map((categoryId: string) => {
                      const category = categoryData.find(
                        (category: any) => category._id === categoryId
                      );

                      if (category) {
                        return (
                          <div
                            key={categoryId}
                            className="bg-gray-200 px-2 m-1 py-1 rounded-md"
                          >
                            <p className="text-sm">{category.name}</p>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Row>
                <Row className="">
                  <Collapse
                    items={items}
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <BiMinus style={{ marginTop: "27px" }} />
                      ) : (
                        <BiPlus style={{ marginTop: "27px" }} />
                      )
                    }
                    style={{ width: "100%" }}
                    className="rounded-none border-l-0 border-r-0 align-items-center"
                  />
                </Row>
              </div>
            </Col>

            <Col xs={24} sm={12} md={18} lg={18} xl={18} xxl={19}>
              <Row gutter={[16, 16]} className="p-2">
                <Col span={18}>
                  Sort by :-{" "}
                  <Select
                    defaultValue="Default"
                    onChange={handleSortChange}
                    className="rounded-none text-orange-500  w-full"
                    style={{ width: 200 }}
                  >
                    <Option value="Default" className="text-orange-500">
                      Default
                    </Option>
                    <Option value="TopRated" className="text-orange-500">
                      Top Rated
                    </Option>
                    <Option value="TopFeatured" className="text-orange-500">
                      Top Featured
                    </Option>
                  </Select>
                </Col>

                <Col span={6} className="text-right">
                  Show :-{" "}
                  <Select
                    defaultValue="12"
                    className="rounded-none"
                    onChange={handleProductsPerPageChange}
                  >
                    <Option className="w-full" value="6">
                      6
                    </Option>
                    <Option value="12">12</Option>
                  </Select>
                </Col>
              </Row>
              {loading ? (
                <div className="text-center mt-4">
                  <Spin size="large" />
                </div>
              ) : (
                <Row gutter={[16, 32]} className="mt-4">
          {!loading && !error && productData && productData.length > 0 ? (
      productData.map((product : any) => {
        const averageRating = calculateAverageRating(product.reviews || []);
        const totalReviews = (product.reviews || []).length;

        return (
          <Col
            key={product._id}
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            xxl={8}
            onMouseLeave={() => setShowSizes(false)}
          >
            <div className="m-1 border-gray-200 border-2 hover:shadow-lg hover:shadow-gray-200"
            // onClick={() => {
            //   handlenavigate(product.slug);
            //   setShowSizes(false);
            // }}
            >
              <div className="wrapper relative">
                <div className="card border-0">
                  <img
                    className="cursor-pointer"
                    src={`${imageUrl}/products/${product.images[0]}`}
                    alt={product.title}
                    style={{ objectFit: 'contain' }}
                    onClick={() => {
                      handlenavigate(product.slug);
                      setShowSizes(false);
                    }}
                  />
                  <button className="card w-auto p-1 absolute top-2 left-2 bg-orange-500 rounded-none text-white border-none">
                    {product.discount}%
                  </button>
                  <div className="info">
                    {!showSizes && product.sizeId && product.sizeId.length > 0 && (
                      <>
                        <BiSearch
                          className="text-orange-500 rounded-full p-1 absolute z-1 search-icon cursor-pointer"
                          onClick={() => showModal(product._id)}
                        />
                        <button
                          className="absolute w-full bottom-2 pt-2 pb-2 bg-orange-500 rounded-none text-white border-none px-2 py-1"
                          onClick={() => setShowSizes(true)}
                        >
                          Add Size
                        </button>
                      </>
                    )}
                    {showSizes && (
                      <div
                        className="bg-white w-full"
                        style={{ position: 'absolute', left: 0 }}
                      >
                        <p className="font-semibold mt-2 ml-1">Select Size:</p>
                        <div className="flex flex-wrap gap-2 mt-3 mb-48 xl:mb-48 md:mb-36">
                          {product?.sizeId?.filter((size : any) => size.isDeleted === false && size.isActive === true).map((size : any) => (
                            <p
                              key={size._id}
                              className={`border border-gray-400 p-1 m-1 ${
                                selectedSize === size._id
                                  ? 'bg-orange-500 text-white cursor-pointer'
                                  : 'border-1 border-black cursor-pointer'
                              }`}
                              onClick={() => setSelectedSize(size._id)}
                            >
                              {size.name}
                            </p>
                          ))}
                        </div>
                        <div className="flex pt-10 mt-10 bottom-0 absolute mb-2 w-full">
                          <p
                            className="bg-orange-500 text-white mr-2 p-2 cursor-pointer"
                            onClick={() => {
                              handlenavigate(product.slug);
                              setShowSizes(false);
                            }}
                          >
                            VIEW DETAILS
                          </p>
                          <p
                            className={`${product.qty > 0 ? 'bg-orange-500 text-white p-2 cursor-pointer' : 'bg-gray-400 p-2 text-white cursor-not-allowed'}`}
                            onClick={() => {
                              handleAddToCart(product);
                              setShowSizes(false);
                            }}
                          >
                            {product.qty > 0 ? "ADD TO CART" : "OUT OF STOCK"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Row className="p-2 " 
              // onClick={() => {
              //   handlenavigate(product.slug);
              //   setShowSizes(false);
              // }}
              >
                <Col className="text-orange-300 mt-4 d-flex justify-between" span={24}>
                  <div>{product.categoryId && product.categoryId.name}</div>
                  {wishlist.some((item) => item.product._id === product._id) ? (
                    <div className="pt-2">
                      <FaHeart
                        className="cursor-pointer text-orange-500 mt-1"
                        size={20}
                        onClick={() => handleWishlist(product)}
                      />
                    </div>
                  ) : (
                    <div className="pt-2">
                      <FaRegHeart
                        className="cursor-pointer text-orange-500 mt-1"
                        size={20}
                        onClick={() => handleWishlist(product)}
                      />
                    </div>
                  )}
                </Col>
                <Col span={24} className="mt-1 text-xl cursor-pointer">
                  <Link
                    to={`/product/${product.slug}`}
                    key={product._id}
                    className="hover:text-orange-500"
                  >
                    {product.name.slice(0, 20)}...
                  </Link>
                </Col>
                {!product.isUpcoming && (
                  <>
                    <Col className="d-flex mt-2"></Col>
                    <Col span={24} className="mt-1 d-flex">
                    
                      <p className="d-flex align-items-center pt-2 pb-2">
                        {renderStars(averageRating || 0 , totalReviews || 0)}
                        <span className="pl-2">({totalReviews}) Reviews</span>
                      </p>
                    </Col>
                    <Col span={24} className="mt-1">
                      INCENTIVE POINT (IP) {product.ipQty}
                    </Col>
                    <Col
                      className="font-semibold mt-1"
                      style={{ display: 'flex', fontSize: '17px' }}
                    >
                      <div className="text-sm">
                        {config.currencySymbol} {product.discountedPrice} &nbsp;
                      </div>
                      <div
                        className="text-red-400 text-sm"
                        style={{ textDecoration: 'line-through' }}
                      >
                        {config.currencySymbol} {product.price}
                      </div>
                      &nbsp;
                      {product.discount > 0 && (
                        <div className="text-red-400 text-sm">
                          ({product.discount}%)
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
        );
      })
    ) : 
    (
      <Col span={24} className="d-flex justify-center" style={{ minHeight: '55vh' }}>No Products Found</Col>
    )
    }
              </Row>
              )}
               <ul className="d-flex justify-end">
    
          <Pagination
            current={currentPage}
            pageSize={productsPerPage}
            total={totalProducts}
            onChange={handlePageChange}
            showSizeChanger
            className="custom-pagination"
            onShowSizeChange={handlePageChange}
          />
        
      </ul>
              
            </Col>
          </Row>
        </div>
     
        <Modal
          width={modalWidth}
          closable={true}
          title=""
          visible={isProductViewModalOpen}
          closeIcon={
            <>
              <IoMdClose className="text-orange-500" size={20} onClick={handleCancel} />
            </>
          }
          footer={
            <>
              <div className="mt-2">
                <div className="d-flex align-items-center justify-end">
                  <div className="border-l-1 border-gray-600 p-2 md:pt-4  lg:mt-2 xl:pt-2  " />

                  <a
                    href="https://www.instagram.com/rapidexie/"
                    className="mr-3 text-gray-400 font-semibold text-xs"
                  >
                    <FaInstagram size={15}
                      className="text-4xl mr-1 text-orange-700  bg-slate-100  border-gray-400 "
                      style={{ height: "20px", width: "20px" }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/rapidexie"
                    className="mr-3 text-gray-400 font-semibold text-xs"
                  >
                    {" "}
                    <FaTwitter size={15}
                      className="text-4xl mr-1 text-sky-500  bg-slate-100  border-gray-400 "
                      style={{ height: "20px", width: "20px" }}
                    />{" "}
                  </a>
                  <a
                    href="https://www.facebook.com/rapidexie"
                    className="mr-3 text-gray-400 font-semibold text-xs"
                  >
                    {" "}
                    <FaFacebook
                      className="text-4xl mr-1 text-blue-500  bg-slate-100  border-gray-400 "
                      style={{ height: "20px", width: "20px" }}
                      size={15} />{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/rapidexie/"
                    className="mr-3 text-gray-400 font-semibold text-xs"
                  >
                    <FaLinkedin
                      className="text-4xl mr-1 text-blue-400  bg-slate-100  border-gray-400 "
                      style={{ height: "20px", width: "20px" }}
                      size={15} />{" "}
                  </a>
                
                  <button disabled={wishlist.some((item: any) => item.product._id === records._id)}  className="btn pt-2 pb-2 justify-start bg-orange-500  d-flex w-auto align-items-center  btn-lg"   onClick={(e: any) => handleWishlist(records)}>
                    {" "}
                    <AiOutlineHeart
                      className="text-4xl  text-white   "
                      style={{ fontSize: "20px" }}
                    />{" "}
                    <span className="text-sm font-bold"  >Add To WishList</span>
                  </button>
                </div>
              </div>
            </>
          }
        >

          {records && records.images && records.images.length > 0 ? (
            <div key={records._id}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <div
                    className="relative overflow-hidden"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    {mainImage && (
                      <img
                        src={imageUrl + "/products/" + mainImage}
                        alt="Product Image"
                        className={`transition-transform duration-300 ${isHovered ? 'scale-150' : 'scale-100'}`}
                        style={{ height: isHovered ? '200%' : 'auto' }}
                      />
                    )}
                  </div>
                  {records.images && records.images.length > 1 && (
                    <Carousel draggable autoplay slidesToShow={4} dots={false}>
                      {records.images.map((image: any, index: any) => (
                        <div
                          key={index}
                          onClick={() => handleImageClick(image)}
                          className="cursor-pointer p-1"
                        >
                          <img
                            className={`rounded-lg ${image === mainImage ? 'border-1 border-orange-500' : ''}`}
                            src={imageUrl + "/products/" + image}
                            alt={"Product Image " + index}
                          />
                        </div>
                      ))}
                    </Carousel>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className="p-2" >
                  <p className="font-extrabold sm:text-sm md:text-md xl:text-3xl  text-5xl">
                    {" "}
                    {records.name}
                  </p>
                  <p className="d-flex align-items-center pt-2 pb-2">
                    <FaStar className="text-orange-500" />
                    <FaStar className="text-orange-500" />
                    <FaStar className="text-orange-500" />
                    <FaStar className="text-orange-500" />
                    <FaStar className="text-orange-500" />
                    <span className="pl-2">(0) Reviews</span>
                  </p>

                  <p className="font-semibold text-red-900 text-lg">
                    Incentive Points (IP) {records.ipQty}
                  </p>
                  <p className="font-bold text-xl">
                    {" "}
                    {config.currencySymbol} {records.price}{" "}
                    <span
                      className="text-red-400 text-lg"
                      style={{ textDecoration: "line-through" }}
                    >
                      {" "}
                      {config.currencySymbol} {records.discountedPrice}{" "}
                    </span>{" "}
                    &nbsp;{" "}
                    <span className="text-green-400 text-sm">
                      {" "}
                      ({records.discount}% OFF)
                    </span>{" "}
                  </p>
                  {/* <p>{records.description}</p> */}
                  <p className="mt-2">
                    Sizes :
                    <div className="flex flex-wrap   gap-2 mt-3  ">
                    {records.sizeId.filter((size: any) => size.isDeleted === false && size.isActive === true).map((size: any) => (
                            <p
                              key={size._id}
                              className={`border border-gray-400 p-1 m-1 ${
                                selectedSize === size._id
                                  ? 'bg-orange-500 text-white cursor-pointer'
                                  : 'border-1 border-black cursor-pointer'
                              }`}
                              onClick={() => setSelectedSize(size._id)}
                            >
                              {size.name}
                            </p>
                          ))}
                    </div>
                  </p>
                  <p className="btn pt-2 pb-2 pl-8 pr-8 m-2 mt-4 bg-orange-500 text-white d-flex w-50 align-items-center  btn-lg" onClick={() => handleAddToCart(records)}>
                    {" "}
                    <AiOutlineShopping
                      className="text-white  pr-1"
                      style={{ fontSize: "20px" }}
                    />{" "}
                    <span className="text-base font-bold">Add To Cart</span>
                  </p>


                </Col>
              </Row>
            </div>
          ) : (
            "No records found"
          )}
        </Modal>
      
      </div>
    </>
  );
};


export default Products;