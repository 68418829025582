import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
import blogData from "../ProductListing/blogData.json";
import Blog1 from "../../../assets/frontendassets/Blog/BlogDetailSection/1.jpg";
import Blog2 from "../../../assets/frontendassets/Blog/BlogDetailSection/2.jpg";
import Blog3 from "../../../assets/frontendassets/Blog/BlogDetailSection/3.jpg";
import Blog4 from "../../../assets/frontendassets/Blog/BlogDetailSection/4.jpg";
import Blog5 from "../../../assets/frontendassets/Blog/BlogDetailSection/5.jpg";
import Blog6 from "../../../assets/frontendassets/Blog/BlogDetailSection/6.jpg";
import Blog7 from "../../../assets/frontendassets/Blog/BlogDetailSection/7.jpg";
import Blog8 from "../../../assets/frontendassets/Blog/BlogDetailSection/8.jpg";

// Map image names to their imported versions
const imageMap: { [key: string]: string } = {
  'Blog1.jpg': Blog1,
  'Blog2.jpg': Blog2,
  'Blog3.jpg': Blog3,
  'Blog4.jpg': Blog4,
  'Blog6.jpg': Blog6,
  'Blog5.jpg': Blog5,
  'Blog7.jpg': Blog7,
  'Blog8.jpg': Blog8,
};

const ViewBlogDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blogDetail, setBlogDetail] = useState<any | null>(null);

  useEffect(() => {
    if (slug) {
      const blog = blogData.find((item: any) => item.slug === slug);
      setBlogDetail(blog || null);
    }
  }, [slug]);

  if (!blogDetail) {
    return <div className="mt-36 py-3 text-center text-lg font-semibold">Blog not found</div>;
  }

  const contentSections = blogDetail.content.filter((section: any) => section.section !== 'Conclusion');
  const conclusionSection = blogDetail.content.find((section: any) => section.section === 'Conclusion');

  return (
    <>
      <Row className="p-2 lg:mt-60 xl:mt-68 md:mt-52 sm:mt-32 xs:mt-32 mt-36 pt-3 pb-3" align={"middle"} justify={"space-between"} style={{ backgroundColor: "#f1f1f1" }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="text-lg text-left font-bold">
        {blogDetail.title}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ backgroundColor: "#f1f1f1" }}>
        <Breadcrumb separator=">" className="d-flex justify-center md:justify-end lg:justify-end xl:justify-end font-semibold text-black p-2">
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/blog">Blog</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>

  <Row > 

      <div className="py-8">
        <div className="text-center mb-6">
          <h2 className="text-orange-500 font-bold text-xl xl:text-3xl  lg:text-2xl">{blogDetail.title}</h2>
          <p className="text-left text-gray-700 font-medium text-xs xl:text-2xl lg:text-lg mt-2" style={{ fontSize: "16px", textAlign: "justify" }} >{blogDetail.description}</p>
        </div>

        <div className="space-y-12">
          {contentSections.map((section: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'} items-center space-y-6 lg:space-y-0 lg:space-x-8`}
            >
              {section.image && (
                <img
                  src={imageMap[section.image]}
                  alt={`Blog detail ${index}`}
                  className="w-full lg:h-96 xl:h-96 xl:w-1/2 h-80 object-cover rounded-lg shadow-lg"
                />
              )}
              <div className="text-left  w-full lg:w-1/2">
                <h3 className="text-center text-xl xl:text-3xl lg:text-2xl font-bold text-orange-500 mb-4">{section.section}</h3>
                <p className={`text-gray-700 xl:text-2xl lg:text-xl ${index % 2 === 0 ? '' : 'lg:mr-8 '} `} style={{ fontSize: "16px", textAlign: "justify" }} >{section.description}</p>
              </div>
            </div>
          ))}
        </div>

        {conclusionSection && (
          <div className="mt-12">
            <h3 className="text-xl font-bold text-orange-500 text-center  xl:text-3xl mb-4">{conclusionSection.section}</h3>
            <p className="text-gray-700 text-left xl:text-2xl lg:text-xl" style={{ fontSize: "16px", textAlign: "justify" }} >{conclusionSection.description}</p>
          </div>
        )}
      </div>
    </Row>
    </>
  );
};

export default ViewBlogDetail;
