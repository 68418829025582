import React, { useContext, useState } from "react";
import { Form, Input, message, Modal, Button } from "antd";
import { login, isFirst } from "../../utils/API";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import CryptoJS from "crypto-js";
import "./Auth.css";
import Logo from "../../assets/backendassets/logo.jpg";

const key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');  // 16 bytes IV

const encryptData = (data : any) => {
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
  return encrypted.toString();
};

const decryptData = (encryptedData : any) => {
  let decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const SupportCenterLogin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loginData, setLoginData] = useState<any>(null);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const onFinish = (values: any) => {
    setIsLoading(true);

    const data = {
      ...values,
      roleId:3
    }
    login(data)
      .then((res) => {
        if (res.status === 200 && res.data && res.data.others) {
          setLoginData(res.data); 
          handleNavigate(res.data);
          setIsLoading(false);
        } else {
          throw new Error("Invalid response structure");
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        setIsLoading(false);
        message.error(error.response?.data?.message || "An error occurred");
      });
  };

  const handleAccept = async () => {
    if (loginData && loginData.others) {
      setAuthData({ token: loginData.token, roleId: loginData.others.roleId });
      localStorage.setItem("token", loginData.token);
      localStorage.setItem("username", loginData.others.username ? loginData.others.username : "");      
      localStorage.setItem("address", loginData.others.address);
      localStorage.setItem("pincode", loginData.others.pincode);
      localStorage.setItem("user", JSON.stringify(loginData.others.firstName));
      localStorage.setItem("lastname", JSON.stringify(loginData.others.lastName ? loginData.others.lastName : ""));
      localStorage.setItem("customerId", JSON.stringify(loginData.others._id));
      localStorage.setItem("logged_by", encryptData(1));
      const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
      localStorage.setItem("cart_userid", JSON.stringify(cartData));
      await isFirst(loginData.others._id);
      message.success(loginData.message);
      navigate(from, { replace: true, state: { roleId: loginData.others.roleId } });
    }
    setIsModalVisible(false);
  };

  const handleNavigate = (data: any) => {
    if (data && data.others) {
      setAuthData({ token: data.token, roleId: data.others.roleId });
      localStorage.setItem("matchingPair", data.matchingPair);
      localStorage.setItem("token", data.token);
      localStorage.setItem("username", data.others.username || ""); // Ensure data.others.username is used
      localStorage.setItem("address", data.others.address);
      localStorage.setItem("pincode", data.others.pincode);
      localStorage.setItem("isSupportCenterFirst", data.others.isSupportCenterFirst);
      localStorage.setItem("user", JSON.stringify(data.others.firstName));
      localStorage.setItem("lastname", JSON.stringify(data.others.lastName || ""));
      localStorage.setItem("customerId", JSON.stringify(data.others._id));
      localStorage.setItem("logged_by", encryptData(1));
      const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
      localStorage.setItem("cart_userid", JSON.stringify(cartData));
      message.success(data.message);

      if(data.others.isSupportCenterFirst === 0 && data.others.roleId === 3){
        navigate('/backend/manage-password');
      }
      
      navigate(from, { replace: true, state: { roleId: data.others.roleId } });
    }
  };
  
  const handleDecline = () => {
    localStorage.clear();
    setIsModalVisible(false);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl border border-blue-500 rounded-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl d-flex justify-center">
          <Link to="/" className="hover:text-orange-500">
            <img
              src={Logo}
              alt=""
              style={{ width: "250px", height: "50px", marginTop: "20px" }}
            />
          </Link>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-xl">
          <Form initialValues={{ remember: true }} onFinish={onFinish} className="m-4">
            <label className="font-bold m-2">Username</label>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please enter your username!" }]}
            >
              <Input placeholder="Enter Username" className="antInputClass rounded" />
            </Form.Item>
            <label className="font-bold m-2">Password</label>
            <Form.Item
              className="mt-2 mb-2"
              name="password"
              rules={[{ required: true, message: "Please enter your password!" }]}
            >
              <Input.Password placeholder="Enter Password" size="large" className="antInputClass rounded" />
            </Form.Item>

            <Form.Item>
              <button
                style={{ width: "100%", marginTop: "10px" }}
                disabled={isLoading}
                className="flex w-full p-3 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isLoading ? 'Sign in...' : 'Sign in'}
              </button>
            </Form.Item>
          </Form>
          <div className="flex justify-between mb-2 pr-6 ml-6">
            {/* <Link to="/forgot-password" className="text-orange-500">Forgot password?</Link> */}
            <p onClick={() => navigate("/forgot-password", {state: {roleId: 3}})} className="cursor-pointer	text-orange-500">Forgot password?</p>
            <p>
              Don't have an account? <Link to="/register" className="text-orange-500">Register</Link>
            </p>
          </div>
        </div>
      </div>

      <Modal
        title="Welcome to Rapidexie"
        visible={isModalVisible}
        className="rounded-lg " // Rounded corners and padding
        width={1000}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <button
            className="bg-red-500 hover:bg-red-600 p-2 mr-2 rounded-md text-white transition-colors duration-300 ease-in-out"
            key="decline"
            onClick={handleDecline}
          >
            Decline
          </button>,
          <button
            className="bg-orange-500 hover:bg-orange-600 text-white p-2 rounded-md transition-colors duration-300 ease-in-out"
            key="accept"
            onClick={handleAccept}
          >
            Accept
          </button>,
        ]}
      >
        <div className="space-y-4"> {/* Space between paragraphs */}
          <p className="text-gray-700 leading-relaxed">
            Dear Sir, we want to make you aware that Rapidexie is not a Pongy scheme or quick
            Money Earning Shortcut formula, this is a System where you after becoming a Part time
            Direct seller can sell our Value-able Products & services and earn effort based high
            income and rewards also based on your dedication towards growing yourself by generating
            more sales.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Before you proceed with the Business Opportunity for making a Parallel Part time Income
            generation by selling our valuable Products & services with the Team of Rapidexie family,
            it is essential to understand and agree to our Code of Ethics. Our commitment to ethical
            behaviour is the Main Pillar of our integrity and trustworthiness. By accepting our
            opportunity, you agree to adhere to the following code:
          </p>
          <ul className="list-disc list-inside text-gray-700 space-y-2"> {/* Space between list items */}
            <li>Always conduct business honestly and with integrity.</li>
            <li>Treat all customers and clients with respect and fairness.</li>
            <li>Ensure that all representations made regarding products and services are accurate and truthful.</li>
            <li>Comply with all laws and regulations related to business activities.</li>
            <li>Maintain confidentiality of all customer information.</li>
            <li>Avoid any actions that could harm the reputation of Rapidexie.</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default SupportCenterLogin;
