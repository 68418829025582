import React, { ReactNode } from "react";
import { Card, Col, Row } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";

interface User {
  SevenTripod: number;
  subSevenTripod: any;
  subTripod: any;
  ismaintriposd: string;
  node: string;
  status: string;
  sevenTripod: number;
  tripod: number;
  active: boolean;
  orderStatus: ReactNode;
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  placement: string;
  matchingPair: string;
  children?: User[];
}

interface TreeComponentProps {
  data: User | User[];
  isFirst: boolean;
  onNodeClick: (node: User) => void;
  activeNode: User | null;
  handleBackClick: () => void;
}

const TreeComponent: React.FC<TreeComponentProps> = ({
  data,
  isFirst,
  onNodeClick,
  handleBackClick,
}) => {
  const renderTree = (node: User, isFirst: boolean = false) => {
    if (!node) return null;

    const handleClick = (event: any) => {
      event.stopPropagation();

      onNodeClick(node);
    };

    const findTitle = (matchingPair: string) => {
      const matchingPoints = parseInt(matchingPair);
      if (matchingPoints >= 37500) {
        return "👨‍✈️";
      } else if (matchingPoints >= 25000) {
        return "👑";
      } else if (matchingPoints >= 12500) {
        return "💠";
      } else if (matchingPoints >= 6750) {
        return "🌻";
      } else if (matchingPoints >= 5000) {
        return "🌷";
      } else if (matchingPoints >= 2500) {
        return "🌹";
      } else if (matchingPoints >= 1250) {
        return "🟪";
      } else if (matchingPoints >= 250) {
        return "🌺";
      } else if (matchingPoints >= 125) {
        return "🏵️";
      } else if (matchingPoints >= 50) {
        return "🌸";
      } else if (matchingPoints >= 25) {
        return "💮";
      } else if (matchingPoints >= 0) {
        return "⚡";
      }
    };

    const children = node.children || [];
    const leftChild = children.find((child) => child.placement === "1");
    const rightChild = children.find((child) => child.placement === "2");
    let status = '';
    if (node.active === false) {
      status = '❌';
    } else if (node.tripod === 2 || node.sevenTripod === 2) {
      if (node.tripod === 2) {
        status = '🏰';
      } else {
        status = '👨🏻‍👩🏻‍👦🏻‍👦🏻';
      }
    } else {
      if (node.orderStatus === 0) {
        status = '🔴';
      } else {
        status = '🟢';
      }
    }

    return (
      <div className="tree-node-container">
        <div className="node-card" onClick={handleClick}>
          <span className="text-orange-500 d-flex justify-center items-center">

            <p className="pr-2 pl-12 d-flex text-xl justify-end"> {status} </p>
            <div className="m-1 text-xl ">{findTitle(node.matchingPair)}</div>
          </span>
          <div className="tree-node">

            <div className="node-label">
              {node.firstName} ({node.username}) <br />
            </div>
            <div className="connector">
              {node.children && node.children.length > 0 && (
                <>
                  <div className="vertical-line"></div>
                  <div className="horizontal-line-container ">
                    <div className="vertical-line"></div>
                    <div className="horizontal-line"></div>

                  </div>
                </>
              )}
              {node.children && node.children.length === 2 && (
                <div className="horizontal-line-container">

                  <div className="horizontal-line"></div>
                  <div className="vertical-line"></div>
                </div>
              )}
              {node.children && node.children.length === 1 &&
                <>
                  <div className="horizontal-line-container">

                    <div className="horizontal-line"></div>
                    <div className="vertical-line"></div>
                  </div>
                </>}
            </div>
            <div className="children">
              <div className="left-child">
                {leftChild ? (
                  renderTree(leftChild)
                ) : (
                  <div className="empty-placeholder"></div>
                )}
              </div>
              <div className="right-child">
                {rightChild ? (
                  renderTree(rightChild)
                ) : (
                  <div className="empty-placeholder"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return Array.isArray(data) ? (
    <>
      <Row justify={"end"}>
        <Col
          span={24}
          className="flex justify-end items-center"
        >
          <button
            className="bg-orange-500 pt-2 mt-3 text-white py-2 px-4 rounded flex items-center absolute top-4 right-4"
            onClick={handleBackClick}
          >
            <IoArrowBackOutline /> Back
          </button>
        </Col>
      </Row>
      <div className="bg-white mt-4 overflow-x-auto overflow-y-auto p-4">

        {data.map((node) => (
          <div key={node._id} className="inline-block md:block lg:block xl:block 2xl:block min-w-[200px]">
            {renderTree(node)}
          </div>
        ))}
      </div>
    </>
  ) : (
    <div className="relative bg-white mt-4 overflow-x-auto overflow-y-auto p-4">
      {/* <button
  className="bg-orange-500 text-white py-2 px-4 rounded flex items-center fixed bottom-4 right-4 z-10"
  onClick={handleBackClick}
>
  <IoArrowBackOutline className="mr-2" /> Back
</button> */}

      <div className="whitespace-nowrap">
        {Array.isArray(data) ? (
          <div className="flex">
            {data.map((node) => (
              <div key={node._id} className="inline-block min-w-[200px]">
                {renderTree(node)}
              </div>
            ))}
          </div>
        ) : (
          <div className="inline-block min-w-[200px]">
            {renderTree(data)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TreeComponent;
