import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Card, message, notification, Modal } from "antd";
import { Select } from "antd";
import { checkUsernameAvailability, createRazorpayOrder, getallproduct, getallproducts, getCustomerByID, getHomePageProducts, payonline, storeOrder, verifyPayment } from "../../utils/API";
import { useNavigate } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import logo from "../../assets/frontendassets/logo.jpg";
import ThankYou from "./Cart/ThankYou";

const { Option } = Select;

const PayonlineUser: React.FC = () => {
  const [productData, setProductData] = React.useState<any>([]);
  const [form] = Form.useForm();
  const [sponsorId, setSponsorId] = React.useState<string | null>(null);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    const storedPincode = localStorage.getItem("pincode");

    if (storedAddress) setAddress(storedAddress);
    if (storedPincode) setPincode(storedPincode);
  }, []);

  useEffect(() => {

    getHomePageProducts()
      .then((response) => {
        setProductData(response.products.filter((product: any) => product.isUpcoming === false));
      })
      .catch((error) => {
        message.error("Network error. Please try again.");
      });


  }, [form]);

  useEffect(() => {
    if (username) {
      const checkAvailability = async () => {
        try {
          const available = await checkUsernameAvailability(username);
          setIsUsernameAvailable(available);
        } catch (error) {
          console.error('Error checking username availability:', error);
        }
      };

      const delayDebounceFn = setTimeout(() => {
        checkAvailability();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setIsUsernameAvailable(null);
    }
  }, [username]);

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
    form.setFieldsValue({ username: e.target.value });
  };

  const validateUsername = (value : any) => {
    const regex = /^RPDXA/;
    if (regex.test(value)) {
      setIsUsernameAvailable(true);  // Mock availability check
    } else {
      setIsUsernameAvailable(false);
    }
  };

  const validateFormat = (_ : any, value : any) => {
    const regex = /^RPDXA/;
    if (value && !regex.test(value)) {
      return Promise.reject(new Error('Username must start with "RPDXA"'));
    }
    return Promise.resolve();
  };

  const handlePayment = async (value: any) => {
    setIsLoading(true);
    const product = productData.find((p: any) => p._id === value.productId);

    if (!product) {
      setIsLoading(false);
      return message.error('Invalid product selected');
    }

    if (!value.address || !value.pincode) {

      return message.error('Please enter address and pincode');
    }

    if (value.amount <= 0) {
      setIsLoading(false);
      return message.error('Amount cannot be zero');
    }

    const orderData = {
      amount: parseFloat(value.amount) * 100,
      currency: 'INR',
      receipt: `receipt_${Date.now()}`,
      orderItems: [{
        productId: value.productId,
        quantity: 1,
        amount: parseFloat(product.price),
      }],

      deferAmount : product.discountedPrice * 1 - value.amount ? product.discountedPrice * 1 - value.amount : 0,
      userId: value.username,
      totalMrp: product.price * 1,
      totalDiscount: product.discountedPrice,
      email : value.email,
      totalIp: product.ipQty,
      address: value.address,
      pincode: value.pincode,
      paymentMethod: "Razorpay",
      transactionId: transactionId,
      // placement: value.placement,
      firstName: value.firstName,
      lastName: value.lastName,
      // email: value.email,
      mobileNo: value.mobileNo
    };

    try {
      console.log(orderData)
      const order = await createRazorpayOrder(orderData);
      const { id: order_id, currency, amount } = order;

      const options = {
        key: process.env.RAZOR_PAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        image: logo,
        name: 'Rapidexie',
        description: 'Test Transaction',
        order_id: order_id,
        handler: async function (response: any) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature
          };

          try {
            const verifyResult = await verifyPayment(paymentData);
            if (verifyResult === "OK") {
              let transactionId = response.razorpay_payment_id;
              try {
                const response = await storeOrder({ ...orderData, transactionId });
                setTransactionId(transactionId);

                setIsModalVisible(true);
              } catch (error) {
                console.error("Error storing order:", error);
                message.error("An error occurred while processing your payment. Please try again.");
              }
            } else {
              alert("Payment verification failed. Please try again.");
            }
          } catch (error) {
            message.error('Error verifying payment. Please try again.');
          }
        },
        prefill: {
          name: 'Your Name',
          email: 'youremail@example.com',
          contact: '9999999999'
        },
        theme: {
          color: '#F37254'
        }
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
      setIsLoading(false);
    } catch (error) {
      notification.open({
        message: 'Error',
        icon: <BiCheck style={{ color: 'red' }} />,
        description: 'Something went wrong. Please try again.',
        placement: 'topRight',
        duration: 3,
      });
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = () => {
    setIsLoginModalVisible(false);
    handlePayment({});
  };

  return (
    <div className="lg:mt-48 xl:mt-46 md:mt-36 sm:mt-36 xs:mt-12 mt-12 md:pl-40 md:pr-40  pt-12 pb-3">
      <Modal
        footer={null}
        title=""
        closable={false}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <ThankYou transactionId={transactionId} />
      </Modal>
      <Form onFinish={handlePayment} form={form} className="bg-white">
        <Row className="m-2" align={"middle"}>
          <Col
            xs={24}
            sm={24}
            md={9}
            xl={6}
            xxl={6}
            className="d-flex justify-content-start font-bold"
          >
            <h2 className="text-2xl">Pay Online</h2>
          </Col>
        </Row>
        <Row justify={"center"} className=" pt-5 border border-warning-2 bg-white rounded-md">

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  User Id
                </label>
              </Col>
              <Col span={12}>
                <Form.Item name="username" 
                   rules={[

                    { validator: validateFormat }
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Your Direct Seller Id"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  {isUsernameAvailable !== null && (
                    <div style={{ color: isUsernameAvailable ? 'green' : 'red' }}>
                      {isUsernameAvailable ? 'Id is Valid' : 'Id is invalid'}
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  First Name <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "Please enter your First Name!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter First Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Last Name <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Please enter your Last Name!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Email id <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Please enter your Email!" }]}>
                  <Input
                    type="email"
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Email id"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Mobile Number <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mobileNo"
                  rules={[
                    { required: true, message: "Please enter your Mobile Number!" },

                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Mobile Number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Amount <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="amount"
                  rules={[
                    { required: true, message: "Please enter the Amount!" },

                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Amount"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Product <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="productId"
                  rules={[
                    { required: true, message: "Please Select a Product!" },
                  ]}
                >
                  <Select size="large" className="rounded border border-1"
                   allowClear
                   showSearch
                   optionFilterProp="label"
                   filterOption={(input: any, option: any) =>
                     option.label.toLowerCase().includes(input.toLowerCase())
                   }
                    placeholder="Select Product">
                    {productData.map((product: any) => (
                      <Option key={product._id} value={product._id} label={product.name}>
                        {product.name} - ₹{product.discountedPrice}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Address <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address"
                  rules={[
                    { required: true, message: "Please enter your Address!" },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Pincode <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pincode"
                  rules={[
                    { required: true, message: "Please enter your Pincode!" },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Pincode"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>



          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row justify={"center"} className="bg-white">
              <Col
                xs={18}
                sm={18}
                md={12}
                xl={8}
                xxl={6}
                className="bg-white mb-3 d-flex justify-content-center"
              >
                <Form.Item>
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded ${isLoading
                        ? 'bg-gray-500 text-white cursor-not-allowed'
                        : isUsernameAvailable === false
                          ? 'bg-orange-300 text-white cursor-not-allowed'
                          : 'bg-orange-500 text-white hover:bg-blue-600'
                      }`}
                    disabled={isLoading || isUsernameAvailable === false}
                  >
                    {isLoading ? 'Processing...' : 'Pay Now'}
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PayonlineUser;
