import { Col, Row, Form, Input, Button, message, Spin } from "antd";
import { CiMail } from "react-icons/ci";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { GetSettingData, newsletter } from "../../utils/API";
import { useEffect, useState } from "react";

const Footeruser = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settings, setSettings] = useState<any>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubscribe = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await newsletter(values);
      if (response.status === 200) {
        form.resetFields();
        message.success("Subscribed successfully!");

      }
    } catch (error) {
      message.error("Subscription failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchSettingsData = async () => {
    try {
      const response = await GetSettingData();
      setSettings(response.settings[0]);
    } catch (error) {
      console.error('Failed to fetch settings data:', error);
    }
  };

  useEffect(() => {
    fetchSettingsData();
  }, []);

  return (
    <>
      <div className="">
        <div style={{ marginBottom: "-60px", zIndex: "50" }}>
          <span
            className="text-white "
            style={{
              position: "relative",
              display: "inline-block",
              width: "0",
              height: "17px",
              top: "9px",
              borderTop: "30px solid ",
              marginLeft: "50px",
              borderRight: "22px solid rgb(255 90 31)", // Adjust this value to change the width of the triangle
            }}
          >
            &nbsp;
          </span>
          <span className="text-white p-3 text-xl bg-orange-500">
            Get in Touch
          </span>
        </div>
        <Row className="mt-5 p-12" style={{ backgroundColor: "#222522" }}>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={6}
            xl={6}
            xxl={6}
            className="text-white"
          >
            <p className="mt-3 text-lg font-bold">CONTACT INFO</p>
            <p className="mt-1 text-md font-semibold">ADDRESS</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">Rapidexie</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">D-9</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">Vyapar Marg,</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">
              Block D, Sector 3
            </p>
            <p className="mt-1 text-sm text-gray-400 font-thin">
              Noida, Uttar Pradesh 201301
            </p>
            <p className="mt-1 text-md font-semibold mt-4">PHONE</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">
              {settings.phone ? settings.phone : '+91 98187 55350'}
            </p>
            <p className="mt-1 text-md font-semibold mt-4">WHATSAPP</p>
            <p className="mt-1 text-sm text-gray-400 font-thin">
              {settings.phone ? settings.phone : '+91 98187 55350'}
            </p>
            <p className="mt-1 text-md font-semibold mt-4">EMAIL </p>
            <p
              className="mt-1 text-sm text-gray-400 font-thin"
              style={{ fontWeight: "lighter" }}
            >
              {settings.adminEmail ? settings.adminEmail : "info@rapidexie.com"}
            </p>
            <div className="d-flex">
              <Link to={settings.instagramLink ? settings.instagramLink : '#'} target='_blank'>
                <FaInstagram className="mt-3 mr-3" size={23} />
              </Link>
              <Link to={settings.twitterLink ? settings.twitterLink : '#'} target='_blank'>
                <FaTwitter className="mt-3" size={23} />
              </Link>
              <Link to={settings.facebookLink ? settings.facebookLink : '#'} target='_blank'>
                <FaFacebook className="mt-3 ml-3" size={23} />
              </Link>
              <Link to={settings.linkedinLink ? settings.linkedinLink : '#'} target='_blank'>
                <FaLinkedin className="mt-3 ml-3" size={23} />
              </Link>
            </div>
          </Col>
          <Col
            xs={24}
            sm={16}
            md={16}
            lg={18}
            xl={18}
            xxl={18}
            className="text-white"
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="pt-10"
              >
                <p className="mt-3 text-lg font-bold">SUBSCRIBE NEWSLETTER</p>
                <p className="mt-2 text-sm text-gray-400 font-thin mb-3">
                  Get all the latest information on Events, Sales and Offers.
                  Sign up for newsletter today
                </p>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="d-flex align-items-end"
              >
                <Form className="w-full" form={form} onFinish={handleSubscribe}>
                  <Row>
                    <Col span={18}>
                      <Form.Item
                        rules={[{
                          required: true,
                          message: 'Please input your email!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        }
                        ]}
                        name="email">
                        <Input
                          type="email"
                          className="p-2 w-full rounded-full"
                          prefix={<CiMail className="site-form-item-icon" />}
                          placeholder="Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <button
                        className="rounded-full text-white p-2 ml-2 border-0 bg-orange-500"
                        type="submit"
                      >
                        {isSubmitting ? <Spin /> : 'Submit'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <p className="mt-3 text-lg font-bold">THE COMPANY</p>
                <Link to="/aboutus">
                  <p className="mt-2 text-sm text-gray-400 font-thin hover:text-orange-500">
                    About Rapidexie
                  </p>
                </Link>
                <Link to="/contact">
                  <p className="mt-2 text-sm text-gray-400 font-thin hover:text-orange-500">
                    Contact
                  </p>
                </Link>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Consistency Offer
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin">Download</p>
                <Link to="/privacy-policy" className="">
                  <p className="mt-2 text-sm text-gray-400 hover:text-orange-500 font-thin">
                    Privacy Policy
                  </p>
                </Link>
                <Link to="/terms-and-conditions" className="">
                  <p className="mt-2 text-sm text-gray-400 hover:text-orange-500 font-thin">
                    Terms and Conditions
                  </p>
                </Link>
                <Link to="/refund-and-cancellation-policy" className="">
                  <p className="mt-2 text-sm text-gray-400 hover:text-orange-500 font-thin">
                   Refund & Cancellation Policy
                  </p>
                </Link>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Certificates
                </p>
                <Link to="/pay-online-user" className="">
                  <p className="mt-2 text-sm text-gray-400 hover:text-orange-500 font-thin">
                    Payment Online
                  </p>
                </Link>
                <Link to="/awards" className="">
                  <p className="mt-2 text-sm text-gray-400 hover:text-orange-500 font-thin">
                  Awards and Accolades
                  </p>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <p className="mt-3 text-lg font-bold">STOCK POINT</p>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Stock point Login
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <p className="mt-3 text-lg font-bold">DIRECT SELLER</p>
                <p className="mt-2 text-sm text-gray-400 font-thin hover:text-orange-500">
                  <Link to="/pay-online-user" className="hover:text-orange-500">
                    Pay Online
                  </Link>
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin hover:text-orange-500">
                  <Link to="/login" className="hover:text-orange-500">
                    Direct Seller Login
                  </Link>
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin hover:text-orange-500">
                  <Link to="/support-center/login" className="hover:text-orange-500">
                  Support Center Login
                  </Link>
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Remuneration System
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Process of Becoming A Direct Seller
                </p>
                <p className="mt-2 text-sm text-gray-400 font-thin">
                  Grievance Redressal
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footeruser;
