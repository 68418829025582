import React, { useState } from 'react';
import { forgotPassword } from '../../utils/API';
import { Link, useLocation } from 'react-router-dom';
import { notification } from 'antd';

function ForgotPassword() {
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event : any) => {
    event.preventDefault(); // Prevent form from submitting the default way
    setLoading(true);

    try {
      const response = await forgotPassword({
        username,
        roleId: location.state && location.state.roleId != null ? location.state.roleId : null,  // Use nullish coalescing operator
      });
      notification.success({
        message: 'Success',
        description: response.data.message || 'An email to reset your password has been sent.',
        placement: 'topRight',
      });
    } catch (error : any) {
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'Invalid username',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-white-100 to-white-200">
      <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-md border border-black-400">
        <h1 className="text-3xl font-semibold text-center text-orange-500">Forgot Password</h1>
        <p className="mt-4 text-sm text-center text-gray-600">
          Please enter the username associated with your account, and we will send you an email with instructions to reset your password.
        </p>
        <form className="mt-6" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-gray-700">Username</span>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="block w-full mt-1 p-2 border border-gray-400 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              placeholder="Enter your username"
              required
            />
          </label>
          <button
            type="submit"
            className="w-full mt-6 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
        <p className="mt-6 text-sm text-center text-gray-600">
          Don't have an account? <Link to="/login" className="text-orange-600 hover:underline">Sign in</Link>
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;
