import { Card, Row, Table, Col, Input, message, Spin, Modal, Button, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { RiAddBoxFill } from "react-icons/ri";
import { deletecategory, deletetestimonial, getAllTestimonials } from "../../utils/API";

interface Category {
  _id: string;
  name: string;
  categoryId: string | null;
}

export const TestimonialTable = () => {
  const [datasource, setDatasource] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<string | any>(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const imageLink = process.env.REACT_APP_Image_URL;

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getAllTestimonials();
      setDatasource(response.filter((item : any) => item.isDeleted === false));
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleEditRedirect = (record: Category) => {
    navigate(`/backend/testimonial/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await deletetestimonial(deleteId);
        message.success("Testimonial deleted successfully");
        fetchCategories();
      } catch (error) {
        message.error("Failed to delete Testimonial");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Images
        </div>
      ),
      dataIndex: "image",
      width: "10%",
      key: "id",
      render: (image: any) => (
        <div>
          {image.length > 0 && (
            <Image
              src={imageLink + "/testimonial/" + image}
              alt={`Thumbnail 1`}
              style={{ width: "50px", height: "50px", marginRight: "5px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Category) => text || "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "50%",
      key: "description",
      render: (text: string, record: Category) => text || "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: Category) => (
        <div className="d-flex">
          <FaEdit className="editMenuIcon" onClick={() => handleEditRedirect(record)} />
          <AiFillDelete className="editMenuIcon" onClick={() => handleDeleteConfirmation(record._id)} />
        </div>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={11} xl={11} xxl={11} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Testimonial</h2>
        </Col>
        <Col xs={24} sm={24} md={13} xl={13} xxl={13}>
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={4} xl={7} xxl={7}></Col> */}
            <Col xs={15} sm={15} md={17} xl={17} xxl={17} className="">
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end"
                placeholder="Search..."
                allowClear
                autoComplete="off"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={9} sm={9} md={7} xl={7} xxl={7} style={{ paddingRight: "-8px !important" }}>
              <Link to={"/backend/testimonial/add"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Testimonial</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Card className=" w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table scroll={{ x: 1000 }} columns={columns} dataSource={datasource} />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        footer={null}
        cancelText="No"
      >
        <p>Are you sure you want to delete this category?</p>
        <div style={{ textAlign: "end" }}>
          <Button onClick={handleDelete}  className="rounded" style={{ backgroundColor: "orange",color: "white", marginRight: "1rem" }}>
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
