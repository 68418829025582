import React, { useState } from 'react';
import { RiAddBoxFill, RiImageEditFill } from 'react-icons/ri';
import { AiFillDelete, AiOutlineExport, AiOutlineImport } from 'react-icons/ai';
import { Button, Card, Col, DatePicker, Form, Image, Input, Modal, Popover, Row, Select, Spin, Switch, Table, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FaEdit, FaKey } from "react-icons/fa";
import { deletePages, DeleteSlider, GetAllPages, getSliders } from '../../../utils/API';
import { title } from 'process';
const { Option } = Select;

interface SlidersProps {
  _id: number;
  name: string;
  image: string;
  __v: number;
}



const ManagePages: React.FC = () => {
  // const customerData: Customer[] = require('./CustomerMasterTable.json');
  const [PageData, setPageData] = useState<SlidersProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const imageLink = process.env.REACT_APP_Image_URL;
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const navigate = useNavigate();


  const fetchPagesData = () => {
    setLoading(true);
    GetAllPages()
      .then((response: any) => {
        const data = response.filter((item: any) => item.isDeleted === false);
        setPageData(data);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };


  React.useEffect(() => {
    fetchPagesData();
  }, []);

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/pages/${record._id}/edit`);
  };
  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        const response = await deletePages(deleteId).then((response: any) => {
          message.success(response.data.message);
          fetchPagesData();
        })
      } catch (error) {
        message.error('Failed to delete category');
      } finally {
        setDeleteId(null);
      }
    }
  };



  const columns = [

    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Page Name</div>,
      dataIndex: 'pageTitle',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Page Slug</div>,
      dataIndex: 'pageSlug',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Meta Title</div>,
      dataIndex: 'metaTitle',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Meta Description</div>,
      dataIndex: 'metaDescription',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Tags</div>,
      dataIndex: 'tags',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Description</div>,
      dataIndex: 'description',
      render: (text: any) => {
        return <p dangerouslySetInnerHTML={{ __html: `${text}` }}></p>;
      }
    },

    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon'
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            onClick={() => handleDeleteConfirmation(record._id)}
          />
        </div>
      ),
    },
  ]


  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Manage Pages</h2>

        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={7} xxl={7}>
            </Col>
            <Col xs={24} sm={24} md={10} xl={12} xxl={12} className=''>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-75"
                placeholder="Search..."
                allowClear
                autoComplete='off'
              // onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            <Col xs={24} sm={24} md={6} xl={5} xxl={5} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/pages/add"} >
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Page</div>
                </button>
              </Link>
            </Col>

          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={PageData} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>

      <div>
        <Modal
          title="Confirmation"
          visible={!!deleteId}
          footer={null}
        >
          <div style={{ textAlign: 'start', marginBottom: '1rem' }}>
            <p >Are you sure you want to delete this Page?</p>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button
              onClick={handleDelete}
              type="primary"
              className="rounded"
              style={{ borderColor: '#dc3545', color: 'inherit', marginRight: '1rem' }}
            >
              Yes
            </Button>

            <Button onClick={() => setDeleteId(null)} type="primary" ghost>
              No
            </Button>
          </div>
        </Modal>

      </div>
    </>
  );
};

export default ManagePages;
