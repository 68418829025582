import React, { useContext, useEffect, useState } from "react";
import { Upload, message, Row, Col, Card, Table, Button, Modal } from "antd";
import { getppt, getAllcategories, deleteppt } from "../../../../../utils/API";
import { Link, useNavigate } from "react-router-dom";
import { RiAddBoxFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { AuthContext } from '../../../../../Pages/Auth/AuthContext';
import { FilePptOutlined } from "@ant-design/icons";


const IndexPPT: React.FC = () => {
    const [categories, setCategories] = useState<any[]>([]);
    const [pptData, setPptData] = useState<any[]>([]);
    const { authData } = useContext(AuthContext);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const imageLink = process.env.REACT_APP_Image_URL;
    const roleId = authData.roleId;
    const navigate = useNavigate();

    const fetchPptData = async () => {
        try {
            const response = await getppt();
            
            setPptData(response.reverse());
        } catch (error) {
            message.error("Error fetching PPT data");
        }
    };

    const fetchCategories = async () => {
        try {
            const categoriesData = await getAllcategories("");
          
            setCategories(categoriesData);
        } catch (error) {
            message.error("Error fetching categories");
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchPptData();
    }, []);
    const showDeleteConfirmation = (id: string) => {
        setDeleteId(id);
        Modal.confirm({
            title: 'Confirm Deletion',
            content: 'Are you sure you want to delete this customer?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => handleDelete(id),
            onCancel: () => handleDeleteConfirmation('0'), // Pass string '0' instead of number 0
        });
    };

    const handleDeleteConfirmation = (id: string) => {
        setDeleteId(id);
    };

    const handleEditRedirect = (record: any) => {
        navigate(`/backend/ppt/${record._id}/edit`);
    };

    const columns = [
        {
            title:  <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Category</div>,
            dataIndex: 'category',
            key: 'category',
            render: (text: string, record: any) => {
                const category = categories.find(cat => cat._id === record.category_id);
                return category ? category.name : 'Unknown';
            }
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>PPT</div>,
            dataIndex: 'ppt',
            render: (ppt: any) => (
                <div>
                    <a
                        href={`${imageLink}/product-ppt/${ppt}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'red' }} // Change color to red
                    >
                        <button className="bg-orange-500 text-white rounded-md p-2">Download</button>
                    </a>
                </div>
            ),
        },
        ...(roleId === 1
            ? [{
                title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
                dataIndex: 'action',
                render: (text: any, record: any) => (
                    <div className='d-flex'>
                        <FaEdit
                            className='editMenuIcon'
                            onClick={() => handleEditRedirect(record)}
                        />
                        <AiFillDelete
                            className='editMenuIcon'
                            onClick={() => showDeleteConfirmation(record._id)}
                        />
                    </div>
                ),
            }] : []),
    ];

    const handleDelete = async (id: string) => {
        try {
            await deleteppt(id); // Call the API endpoint to delete the PPT record
            message.success('PPT deleted successfully');
            fetchPptData(); // Refresh the PPT data after deletion
        } catch (error) {
            message.error('Failed to delete PPT');
        }
    };


    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={12}>
                    <h2 className="text-2xl font-bold">Product PPT</h2>
                </Col>
                {roleId === 1 && (
                    <Col span={12} className="d-flex justify-content-end">
                        <Link to={"/backend/ppt/add"}>
                            <button className="d-flex justify-content-center align-items-center      border btn-brand">
                                <RiAddBoxFill style={{ fontSize: "15px" }} />
                                <div className="ms-2">Add PPT</div>
                            </button>
                        </Link>
                    </Col>
                )}
            </Row>
            <Card style={{ backgroundColor: "#ffffff" }}>
                <Table scroll={{ x: 400 }} columns={columns} dataSource={pptData} rowKey="_id" />
            </Card>
        </div>
    );
};

export default IndexPPT;