import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Upload, message } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BsUpload } from "react-icons/bs";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { useForm } from "antd/es/form/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getawardbyid, updateAward } from "../../../utils/API"; // Update to your API method
import { FaArrowLeft } from "react-icons/fa";





interface EditAward {
  id: string;
  name: string;
  file: string;
}
export const EditAward: React.FC = () => {
  const [awards, setAwards] = useState<EditAward[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const existingData = await getawardbyid(id); 
          // console.log("Fetched award data: ", existingData);
          if (existingData && existingData.award) {
            // console.log("Fetched award data: ", existingData.award);
            form.setFieldsValue(existingData.award); 
            
            if (existingData.award.file) {
              const imageUrl = `${process.env.REACT_APP_Image_URL}/awards/${existingData.award.file}`;
              setFileList([{ uid: '1', name: existingData.award.image, status: 'done', url: imageUrl }]);
            }
          }
        }
      } catch (error) {
        message.error("Error fetching award data");
        navigate("/backend/awards");
      }
    };
    fetchData();
  }, [id, form, navigate]);
  
  

  const handleEditAward = async () => {
    setLoading(true);
  
    try {
      // console.log("Form values: ", form.getFieldsValue()); 
      // console.log("ID from params: ", id); 
  
      const formData = new FormData();
      formData.append("title", form.getFieldValue("title"));
  
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("file", fileList[0].originFileObj as RcFile);
      }
  
      if (id) {
        const response = await updateAward(id, formData); 
  
        if (response.message) {
          message.success(response.message);
          // Refetch data to reflect the changes
          const updatedData = await getawardbyid(id);
          form.setFieldsValue(updatedData.award);
          // console.log("Updated award data: ", updatedData.award); // Debugging check
          navigate("/backend/awards");
        } else {
          message.success("Award updated successfully");
          form.resetFields();
        }
      } else {
        message.error("Failed to submit award data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating award: ", error);
      message.error("Failed to update award. Please try again.");
    }
  
    setLoading(false);
  };
  
  
  

  const handlefile = ({ fileList }: UploadChangeParam<UploadFile>) => {
    // console.log("FileList from upload: ", fileList);
    setFileList(fileList);
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Award</h2>
        </Col>
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/awards"}>
            <button className="d-flex justify-content-start align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row align={"middle"} gutter={[16, 16]}>
        <Col span={24}>
          <Card className="bg-white" style={{ minHeight: "80vh" }}>
            <Form form={form} onFinish={handleEditAward}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="title">
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Enter name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">Is Home?</label>
                </Col>
               
              </Row> */}
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold" style={{ paddingRight: "73px" }}>
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12} className="d-flex justify-content-start">
                  <Upload
                    name="file"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handlefile}
                    maxCount={1}
                    accept=".png, .jpg, .jpeg"
                  >
                    <div>
                      <BsUpload style={{ fontSize: "20px" }} />
                    </div>
                  </Upload>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="d-flex justify-content-center pt-4">
                  <button className="btn-brand" type="submit">
                    {loading ? "Updating..." : "Update"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditAward;