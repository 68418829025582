import React, { useEffect, useRef, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface IdleTimerProps {
  timeoutDuration: number;
  logoutCallback: () => void;
  children: ReactNode;
}

const useIdleTimer = (timeoutDuration: number, logoutCallback: () => void) => {
  const navigate = useNavigate();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastActivityTime = useRef<number>(Date.now());

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const now = Date.now();
      const idleDuration = now - lastActivityTime.current;
      if (idleDuration >= timeoutDuration) {
        logoutCallback();
        navigate('/login');
      }
    }, timeoutDuration);
  };

  const handleUserActivity = () => {
    lastActivityTime.current = Date.now();
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return null;
};

const IdleTimerWrapper: React.FC<IdleTimerProps> = ({ timeoutDuration, logoutCallback, children }) => {
  useIdleTimer(timeoutDuration, logoutCallback);

  return <>{children}</>;
};

export default IdleTimerWrapper;
