import React, { useState } from 'react';
import { Input, Button, Table, Form, Row, Col, Card } from 'antd';
import { searchProfile } from '../../../../utils/API';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

interface Profile {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  // Add more fields as needed
}

const BankDetailMissing: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Profile | null>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();


  const handleSearch = async () => {
    setLoading(true);
    try {
      const customerId = localStorage.getItem('customerId') || '';
      const userId = customerId.replace(/"/g, '');
      const data = await searchProfile({ username }, userId);

      if(data.error){
        setSearchResult(null);
      }else{
        setSearchResult(data);
        form.resetFields();
      }
    } catch (error) {
      console.error('Error searching profile:', error);
      setSearchResult(null);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id: string) => {
    navigate(`/backend/update-downline-bank-detail/${id}`);
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
   
    {
      title : 'Bank Name',
      dataIndex : 'bankName',
      key : 'bankName',
    },
    {
        title : "Account Number",
        dataIndex : "bankAccountNo",
        key : "bankAccountNo",
    },
    {
      title : "Bank Address",
      dataIndex : "bankAddress",
      key : "bankAddress",
  },
  {
    title : "IFSC Code",
    dataIndex : "ifsc",
    key : "ifsc",
  },
  
  {
    title : "City",
    dataIndex : "city",
    key : "city",
  },
  
  {
    title : "Mobile No",
    dataIndex : "mobileNo",
    key : "mobileNo",
  },
  
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: Profile) => (
        <FaEdit className='editMenuIcon' onClick={() => handleEdit(record._id)} />
      ),
    },
  ];

  return (
    <Card>
          <Row className="m-2" align="middle">
        <Col span={24}>
          <h2 className="text-2xl font-bold">Update bank Detail</h2>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col span={24}>
          <Input
            // style={{ marginRight: 10 }}
            value={username}
            className='w-2/3 border border-gray-300 rounded-md mb-3 px-3 py-2'
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
          />
          <button
            className='bg-orange-500 text-white px-3 py-2 rounded-md'
            onClick={handleSearch}
          >
            Search
          </button>
          <Table
            scroll={{ x: 1000 }}
            dataSource={searchResult ? [searchResult] : []}
            columns={columns}
            pagination={false}
            locale={{ emptyText: loading ? 'Loading...' : 'No Data Available' }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default BankDetailMissing;
