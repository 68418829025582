import { Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { addCategory, getAllcategories } from "../../../utils/API";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { UploadFile } from "antd/lib/upload";
import { BiUpload } from "react-icons/bi";
import { RcFile } from "antd/es/upload";
import { FaArrowLeft } from "react-icons/fa";

interface Category {
  _id: string;
  name: string;
  categoryId: string | null;
}

export const AddCategory = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isHome, setIsHome] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const categoriesData = await getAllcategories("");

      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleAddCategory = (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    if (values.categoryId !== "null") {
      formData.append("categoryId", values.categoryId);
    }
    formData.append("isHome", String(isHome));

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("image", file.originFileObj as Blob);
      });
    }

    addCategory(formData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Category added successfully");
        }
        navigate("/backend/categories");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleImage = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    // Display preview for the first file in fileList
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setPreviewImage(null as any); // Clear preview if fileList is empty
    }
    setFileList(newFileList); // Update fileList state
  };


  return (
    <div>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Category</h2>
        </Col>
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/categories"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row align={"middle"} gutter={[16, 16]}>
        <Col span={24}>
          <Card className="bg-white" style={{ minHeight: "80vh" }}>
            <Form
              onFinish={handleAddCategory}
              initialValues={{ categoryId: "null" }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">Category </label>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="categoryId">
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select a category"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      <Select.Option value="null">Select a category</Select.Option>
                      {categories.map((category) => (
                        <Select.Option key={category._id} value={category._id}>
                          {category.name}
                        </Select.Option>
                      ))}
                    </Select>

                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please input name!" }]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      name="name"
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    isHome ?
                  </label>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item name="isHome" valuePropName="checked">
                    <input
                      type="checkbox"
                      checked={isHome} // Use `checked` instead of `value`
                      className={`rounded-md border border-1 w-6 h-6 ${isHome ? "bg-orange-500" : "bg-white"}`}
                      onChange={(e) => setIsHome(e.target.checked)}
                    />
                  </Form.Item>

                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col
                  xs={24} sm={24} md={8}
                  className="d-flex justify-content-start"
                >
                  <Upload
                    listType="picture-card"
                    name="image"
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    fileList={fileList}
                    onChange={handleImage}
                    maxCount={1}
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={(file: { type: string; size: number; }) => {
                      // Validate file type and size
                      const isJpgOrPng =
                        file.type === "image/jpeg" ||
                        file.type === "image/png";
                      if (!isJpgOrPng) {
                        message.error("You can only upload JPG/PNG file!");
                      }
                      const isLt2M = file.size / 1024 / 1024 < 1;
                      if (!isLt2M) {
                        message.error("Image must be smaller than 1MB!");
                      }
                      return isJpgOrPng && isLt2M;
                    }}
                  >
                    {fileList.length === 0 ? (
                      <div>
                        <BiUpload />
                      </div>
                    ) : (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </Upload>
                  <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancelPreview}
                  >
                    <img
                      alt="Preview"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>

              </Row>
              <Row>
                <Col span={24} className="d-flex justify-content-center">
                  <button
                    className="btn-brand"
                    type="submit"
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};