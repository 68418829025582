import React, { useState, useEffect } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Select,
  Button,
  DatePicker,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getCustomers, tdsAdd } from "../../../../../utils/API";
import { BiUpload } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";

const { Option } = Select;

const AddTdsCerticateForm: React.FC = () => {
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  
  const [searchText, setSearchText] = useState<string>('');
  const [kycFilter, setKycFilter] = useState<number | null>(null);

 
  const FetchCustomerData = (searchText: string, kyc: number | null) => {
    setLoading(true);
    getCustomers(searchText, kyc)
      .then((response: any) => {
        const filteredCustomerData = response.data.filter((customer: any) => !customer.isDeleted);
        setCustomerData(filteredCustomerData);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    FetchCustomerData(searchText, kycFilter);
  }, [searchText, kycFilter]);



  const handleSubmit = async (values: any) => {
    await form.validateFields(); // Wait for form validation    
    const formData = new FormData();
    formData.append("userId", form.getFieldValue("userId"));
    formData.append("year", values.year.format("YYYY"));

    if (values.file) {
      formData.append("file", values.file.fileList[0].originFileObj);
    }
    const response = await tdsAdd(formData);
    if (response.status === 200) {
      message.success(response.data.message);
      navigate("/backend/tds-certificate");
    } else {
      message.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Certificate</h2>
        </Col>
        {/* <Col span={8} ></Col> */}
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/tds-certificate"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white" onFinish={handleSubmit} form={form}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        User <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: "Please Select User",
                          },
                        ]}
                      >
                         <Select
                          size="large"
                          showSearch
                          optionFilterProp="label"
                          filterOption={(input : any, option  : any) =>
                              option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                          allowClear
                          // style={{ width: 400 }}
                          className="rounded border border-1"
                          placeholder="Select User"
                      >
                          {customerData.map((user : any) => (
                              <Option key={user._id} value={user._id} label={`${user.firstName} - ${user.username}`}>
                                  {user.firstName} - {user.username}
                              </Option>
                          ))}
                      </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        Year <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Year",
                          },
                        ]}
                      >
                        <DatePicker picker="year" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={24} md={4} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        File <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="file"
                        rules={[
                          {
                            required: true,
                            message: "Please Select File",
                          },
                        ]}
                      >
                        <Upload name="file" listType="picture-card" accept="application/pdf" beforeUpload={() => false} maxCount={1} className="w-50">
                          <Button icon={<BiUpload />}>Upload</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={10} className="d-flex justify-content-center">
                  <button className="btn-brand" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default AddTdsCerticateForm;