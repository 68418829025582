import React, { useEffect } from "react";
import { Col, Row, Form, Input, Card, message, Modal } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { Select } from "antd";
import { addCustomer, getallproducts } from "../../../../utils/API";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const { Option } = Select;

const NewSalesEntry: React.FC = () => {
  const [productData, setProductData] = React.useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    getallproducts()
      .then((response) => {
        setProductData(response);
      })
      .catch((error) => {
        message.error("Network error. Please try again.");
      });
  }, []);

  const handleSubmit = (values: any) => {
    // Retrieve the userid from local storage
    const customerId = localStorage.getItem('customerId') || '';
const userId = customerId.replace(/"/g, '');
  
    // Add the entryBy field to the values object
    const updatedValues = { ...values, entryBy: userId };
  
    addCustomer(updatedValues)
      .then((response: any) => {
        message.success(response.data.message);
        form.resetFields();
        setUsername(response.data.EmailData.username); // Assuming API returns the username
        setPassword(response.data.EmailData.password); // Assuming API returns the password
        setIsModalVisible(true);
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };
  

  const handleModalOk = () => {
    setIsModalVisible(false);
    // Navigate to another page if needed
    // navigate("/some-page");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={24}>
          <h2 className="text-2xl font-bold">New Sales Entry</h2>
        </Col>
        {/* <Col span={12}></Col>
        <Col span={2} style={{ textAlign: "right" }}>
        
        </Col> */}
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Sponsor ID <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="sponsorId"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter User name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Sponsor ID"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Placement <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="placement"
                    rules={[
                      {
                        required: true,
                        message: "Please Select your Position!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      className="rounded border border-1"
                      placeholder="Placement"
                    >
                      <Option value="1">Left</Option>
                      <Option value="2">Right</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Product <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="Product"
                    rules={[
                      {
                        required: true,
                        message: "Please Select your Position!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      className="rounded border border-1"
                      placeholder="Product"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {productData.map((product: any) => (
                        <Option key={product._id} value={product._id}>
                          {product.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    MobileNo (10 Digit) <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="mobileNo"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Mobile Number",
                      },
                      {
                        pattern: new RegExp(/^[0-9\b]+$/),
                        message: "Only Numbers",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Mobile No"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Email <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Customer First Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter First Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Last Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Last Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
         

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn-brand">Submit</button>
            </Col>
          </Row>
        </Card>
      </Form>

      <Modal
        title="Customer Details"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={null}
      >
        <p>Username: {username}</p>
        <p>Password: {password}</p>
      </Modal>
    </div>
  );
};

export default NewSalesEntry;
