import React, { useState, useEffect } from "react";
import { Row, Col, Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import blogData from "./ProductListing/blogData.json";
import Blog1 from "../../assets/frontendassets/Blog/1.jpg";
import Blog2 from "../../assets/frontendassets/Blog/2.jpg";
import Blog3 from "../../assets/frontendassets/Blog/3.jpg";
import Blog4 from "../../assets/frontendassets/Blog/4.jpg";
// Map image names to their imported versions

// Map image names to their imported versions
const imageMap: { [key: string]: string } = {
  'Blog1.jpg': Blog1,
  'Blog2.jpg': Blog2,
  'Blog3.jpg': Blog3,
};

const Blog: React.FC = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [blogItems, setBlogItems] = useState<any[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setBlogItems(blogData);
  }, []);

  const navigate = useNavigate();
  const redirectToBlog = (slug: number) => {
  navigate(`/blog/${slug}`);
  };

  return (
    <Row className="mt-2 lg:px-0 xl:px-36 lg:ps-0 xl:ps-36 md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
      <Col span={24} className="text-center font-bold text-2xl mb-4">
        BLOG
      </Col>
      <Col xs={24} className="mt-2 px-3 md:px-0 text-center">
        <div className="flex flex-col md:flex-row items-center justify-center mb-3">
          <hr className="w-full border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
          <span className="text-gray-400 tracking-wide md:pr-5 md:pl-5 mt-3 md:mt-0 pb-2">
            Your go-to resource for all things network marketing and MLM
          </span>
          <hr className="w-full border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
        </div>
      </Col>
      <Col span={24}>
        <Carousel autoplaySpeed={3000} slidesToShow={slidesToShow} draggable>
          {blogItems.map((blogPost, index) => (
            <div key={index} className="rounded-lg bg-white p-2">
              <div className="shadow-md">
                <img
                  src={imageMap[blogPost.content[0]?.image]} // Use the mapped image
                  alt="SliderImage"
                  className="object-cover w-full h-64 rounded-lg rounded-bl-none rounded-br-none"
                />
                <div className="pt-4 p-2" style={{ backgroundColor: "#F1F1F1" }}>
                  <h3 className="text-xl font-bold mb-3">{blogPost.title}</h3>
                  <p className="text-sm text-gray-700 mb-4 line-clamp-3">
                    {blogPost.content[0]?.description.slice(0, 200)}...
                  </p>
                  <button
                    onClick={redirectToBlog.bind(null, blogPost.slug)}
                    className="text-sm pb-2 text-orange-500 underline hover:text-orange-600 transition duration-300 ease-in-out"
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
};

export default Blog;
