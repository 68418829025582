import React, { useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import { Switch, Table, Spin, Card, Col, Row, Input, message } from 'antd';
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { getBlogs, ProductSizeActive, ProductSizeDelete } from '../../../utils/API';

interface SliderData {
  _id: number;
  name: string;
  active: boolean;
  __v: number;
}

const IndexBlog: React.FC = () => {
  const [slidersData, setSlidersData] = useState<SliderData[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Initially set loading to true
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();

  const fetchSizeData = () => {
    setLoading(true);
    getBlogs()
      .then((response: any) => {
        setSlidersData(response.blogs);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchSizeData();
  }, []);

  const handleEditRedirect = (record: SliderData) => {
    navigate(`/backend/product-size/${record._id}/edit`);
  };

  const HandleDelete = (record: SliderData) => {
    ProductSizeDelete(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          fetchSizeData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };

  const sizeActiveInactive = (record: SliderData) => {
    ProductSizeActive(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          fetchSizeData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    {
      title: <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
        title
      </div>,
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Description
        </div>
      ),
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => (
        <span title={text}>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
      ),
    },    
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Action</div>,
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: SliderData) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon'
            // onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            // onClick={() => HandleDelete(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={10}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Blogs</h2>

        </Col>
        <Col xs={24} sm={24} md={15} xl={18} xxl={14} className="">
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={0} xl={7} xxl={7}>
            </Col> */}
            <Col xs={17} sm={17} md={16} xl={17} xxl={17} className=''>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-full"
                placeholder="Search..."
                allowClear
                autoComplete='off'
              // onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            <Col xs={7} sm={7} md={8} xl={7} xxl={7} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/blog/add"} >
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Blogs</div>
                </button>
              </Link>
            </Col>

          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={{ backgroundColor: "#ffffff", borderRadius: "12px" }} xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={slidersData || []} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IndexBlog;