import React, { useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popover, Row, Select, Spin, Switch, Table, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx"; // Import xlsx library
import { MdAssignmentAdd } from 'react-icons/md';
import { FaEdit, FaKey, FaSuperpowers } from "react-icons/fa";
import { MakeSupportCenter, UpdateFlag, UserActiveInActive, getAllCustomers } from '../../utils/API';
const { Option } = Select;

interface Customer {
  _id: number;
  id: number;
  customerName: string;
  customerCode: number;
  band: string;
  join_date: string;
  contact: string;
  address: string;
  city: string;
  state: string;
  country: string;
  superwiser_emp_code: string;
  emergency_contact: string;
  __v: number;
  assignedInventory?: number[];
  isActive: boolean;
}

interface Inventory {
  _id: number;
  inventoryId: number;
  inventoryName: string;
  Assetcode: string;
}

const CustomerTable: React.FC = () => {
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [deleteFlag, setDeleteFlag] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [kycFilter, setKycFilter] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();

  const FetchCustomerData = (searchText: string, kyc: number | null, page: number) => {
    setLoading(true);
    getAllCustomers(searchText, kyc, page)
      .then((response: any) => {
        const filteredCustomerData = response.data.users.filter((customer: any) => !customer.isDeleted).reverse();
        setCustomerData(filteredCustomerData);
        setTotal(response.data.totalItems);
        setCurrentPage(response.data.currentPage);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    FetchCustomerData(searchText, kycFilter, currentPage);
  }, [searchText, kycFilter, currentPage]);

  const showDeleteConfirmation = (id: number) => {
    setDeleteId(id);
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this customer?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleDeleteConfirmation(1),
      onCancel: () => handleDeleteConfirmation(0),
      okButtonProps: { style: { backgroundColor: 'orange', borderColor: 'orange', color: 'white' } },
    });
  };

  const showRoleChangeConfirmation = (id: number) => {
    setDeleteId(id);
    Modal.confirm({
      title: 'Confirm Change Role',
      content: 'Are you sure you want to update this customer role to support center?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => MakeSupportCenter(id)
                  .then((response: any) => {
                    if (response.status === 200) {
                      console.log(response.data);
                      message.success(response.data.message);
                      FetchCustomerData(searchText || '', kycFilter || null, currentPage);
                    } else {
                      message.error("Something went wrong");
                    }
                  })
                  .catch((error: any) => {
                    message.error(error.response.data.message);
                  }),
      // onCancel: () => ,
      okButtonProps: { style: { backgroundColor: 'orange', borderColor: 'orange', color: 'white' } },
    });
  };

  const handleDeleteConfirmation = (flag: number) => {
    if (flag === 1) {
      setDeleteFlag(flag);
    } else {
      setDeleteFlag(0);
      setDeleteId(null);
    }
  };

  React.useEffect(() => {
    if (deleteId !== null && deleteFlag === 1) {
      UpdateFlag(deleteId, true).then(() => {
        message.success('Customer Deleted successfully');
        FetchCustomerData(searchText || '', kycFilter || null, currentPage);
        setDeleteFlag(0);
      });
    }
  }, [deleteFlag, deleteId]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleKycFilterChange = (value: number) => {
    setKycFilter(value);
  };

  const handleEditRedirect = (record: Customer) => {
    navigate(`/backend/users/${record._id}/edit`);
  };

  const UserActiveInactive = (record: Customer) => {
    UserActiveInActive(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          FetchCustomerData(searchText, kycFilter, currentPage);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>First Name</div>,
      dataIndex: 'firstName',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Last Name</div>,
      dataIndex: 'lastName',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Username</div>,
      dataIndex: 'username',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Email</div>,
      dataIndex: 'email',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Contact</div>,
      dataIndex: 'mobileNo',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Role</div>,
      dataIndex: 'role',
      render: (text: any, record: any) => {
        console.log(record);
        return(
            <div className='d-flex'>
              <p>
                {record.roleId.includes(1) ? "Admin" : record.roleId.includes(3) ? "Support Center" : "Direct Seller"}
              </p>
            </div>
          )},
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Status</div>,
      dataIndex: 'isActive',
      key: 'isActive',
      render: (active: boolean, record: Customer) => (
        <Switch
          checked={active}
          className={active ? 'bg-orange-500' : 'bg-gray-500'}
          style={{ marginRight: '10px' }}
          onChange={() => {
            UserActiveInactive(record);
          }}
        />
      ),
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Is Support Center</div>,
      dataIndex: 'IsSupportCenter',
      render: (text: any, record: any) => {
        if(!record.roleId.includes(3)){
          return (
            <div className='d-flex'>
              <p
                className='cursor-pointer text-orange-500'
                onClick={() => showRoleChangeConfirmation(record._id)}
              > Mark Support Center</p>
            </div>
          )
        }
        },
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon'
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            onClick={() => showDeleteConfirmation(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={6}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Users</h2>
        </Col>
        <Col xs={24} sm={24} md={18} xl={18} xxl={18} className="">
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={12} sm={12} md={10} xl={12} xxl={12}>
              <Select
                placeholder="Select KYC Status"
                className="w-100"
                size='large'
                onChange={handleKycFilterChange}
                allowClear
              >
                <Option value={""}>All</Option>
                <Option value={1}>Pending</Option>
                <Option value={2}>Completed</Option>
              </Select>
            </Col>
            <Col xs={12} sm={12} md={8} xl={8} xxl={8} className=''>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end"
                placeholder="Search..."
                allowClear
                autoComplete='off'
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={6} xl={4} xxl={4} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/users/add"} >
                <button className=" d-flex justify-content-center align-items-center w-100 border btn-brand" >
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="fw-bold text-center" style={{ paddingLeft: '5px' }}>Add Customer</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Card className="border mt-2 mx-2">
        <Table
          columns={columns}
          dataSource={customerData}
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            onChange: (page) => setCurrentPage(page),
          }}
          loading={loading}
          rowKey="_id"
        
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
};

export default CustomerTable;
