import { Card, Row, Table, Col, Input, message, Spin, Modal, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { adminallCategories, deletecategory, getAllcategories } from "../../../utils/API";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { RiAddBoxFill } from "react-icons/ri";

interface Category {
  _id: string;
  name: string;
  categoryId: string | null;
}

export const Categorytable = () => {
  const [datasource, setDatasource] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<string | any>(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const fetchCategories = async (searchText: string) => {
    setLoading(true);
    try {
      const response = await adminallCategories(searchText);
      setDatasource(response);
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories(searchText);
  }, [searchText]);

  const handleEditRedirect = (record: Category) => {
    navigate(`/backend/category/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await deletecategory(deleteId);
        message.success("Category deleted successfully");
        fetchCategories(searchText);
      } catch (error:any) {
        const errorMessage = error.response?.data?.message || "Failed to delete category";
        message.error(errorMessage);
      }  finally {
        setDeleteId(null);
      }
    }
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Category) => text || "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: Category) => (
        <div className="d-flex">
          <FaEdit className="editMenuIcon" onClick={() => handleEditRedirect(record)} />
          <AiFillDelete className="editMenuIcon" onClick={() => handleDeleteConfirmation(record._id)} />
        </div>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={12} md={8} xl={12} xxl={12} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Category</h2>
        </Col>
        <Col xs={24} sm={16} md={16} xl={12} xxl={12}>
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col> */}
            <Col xs={17} sm={17} md={16} xl={16} xxl={16} className="">
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-100 "
                placeholder="Search..."
                allowClear
                autoComplete="off"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={7} sm={7} md={8} xl={8} xxl={8} style={{ paddingRight: "-8px !important" }}>
              <Link to={"/backend/category/add"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Category</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Card className="w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={datasource} />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        footer={null}
        cancelText="No"
      >
        <p>Are you sure you want to delete this category?</p>
        <div style={{ textAlign: "end" }}>
          <Button onClick={handleDelete}  className="rounded" style={{ backgroundColor: "orange",color: "white", marginRight: "1rem" }}>
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
