import { Card, Row, Table, Col, Input, message, Spin, Modal } from "antd";
import { AiFillDelete, AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import { RiAddBoxFill, RiImageEditFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { deletecategory, getAllcategories, getallnewsletter } from "../../../utils/API";
import { FaEdit } from "react-icons/fa";
import * as XLSX from "xlsx";
import React from "react";

interface Category {
  id: string;
  _id: string;
  name: string;
  category_id: string | null;
}

export const Newsletter = () => {
  
  const [datasouce, setDatasouce] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchNewsLetter = async () => {
    setLoading(true);
    getallnewsletter()
      .then((response) => {
        setDatasouce(response);
      })
      .catch((error) => {
        message.error("Network error. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  useEffect(() => {
    fetchNewsLetter();
  }, []);



  const handleEditRedirect = (record: any) => {
    navigate(`/backend/category/${record._id}/edit`);
  };




  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Users email
        </div>
      ),
      dataIndex: "email",
      key: "name",
      render: (text: string) => text || "-",
    },
    
  ];

  return (
    <div>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">NewsLetters</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
          
        </Col>
      </Row>
      <Row>
        <Card className=" w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={datasouce} />
            </Spin>
          </Col>
        </Card>
      </Row>
   
    </div>
  );
};
