import React, { useState } from 'react';
import { Col, DatePicker, Input, Button, Row, Table, Card, message } from 'antd';
import dayjs from 'dayjs';
import { fetchIpReport, fetchIpReportList } from '../../../../utils/API';

interface DataItem {
  key: string;
  index: number;
  username: string;
  name: string;
  totalIp: number;
  totalIpPair: number;
  leftIp: number;
  rightIp: number;
  totalPages:number;
  createdAt: string; // Date in string format 'YYYY-MM-DD'
}

const LeftRightIpReport: React.FC = () => {
  const [fromDate, setFromDate] = useState<string | null>(null); // fromDate as string or null
  const [toDate, setToDate] = useState<string | null>(null); // toDate as string or null
  const [leftIp, setLeftIp] = useState<string>(""); // leftIp as string
  const [rightIp, setRightIp] = useState<string>(""); // rightIp as string
  const [tableData, setTableData] = useState<DataItem[]>([]); // State to store table data
  const [loading, setLoading] = useState<boolean>(false); // State to manage loading spinner
  const [total, setTotal] = useState<number>(0); // State to store total number
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPage, setTotalPage] = useState<number>(0); // Total page

  const columns = [
    { title: 'Sr No', dataIndex: 'index', key: 'index' },
    { title: 'ID No', dataIndex: 'username', key: 'username' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Total Id', dataIndex: 'totalIp', key: 'totalIp' },
    { title: 'Total Id Pair', dataIndex: 'totalIpPair', key: 'totalIpPair' },
    { title: 'Left Id', dataIndex: 'leftIp', key: 'leftIp' },
    { title: 'Right Id', dataIndex: 'rightIp', key: 'rightIp' },
    { title: 'Date', dataIndex: 'createdAt', key: 'createdAt', render: (text: any) => dayjs(text).format('DD-MM-YYYY') },
  ];

  const handleToDateChange = async (date: any, dateString: string) => {
    setToDate(dateString);

    if (!fromDate) {
      message.error("Please select From Date first.");
      return;
    }
    const customerId = localStorage.getItem("customerId");
    const userid = customerId?.replace(/"/g, "");
    try {
      const data = await fetchIpReportList(userid, fromDate, dateString);
      setLeftIp(data.leftchildcount.toString());
      setRightIp(data.rightchildcount.toString());
    } catch (error) {
      message.error("Failed to fetch data from the API.");
      console.error(error);
    }
  };

  const handleViewList = async (page: number) => {
    if (!fromDate || !toDate) {
      message.error("Please select both From Date and To Date.");
      return;
    }
    const customerId = localStorage.getItem("customerId");
    const userid = customerId?.replace(/"/g, "");
    try {
      setLoading(true);
      // Fetch data for the current page
      const response = await fetchIpReport(userid, fromDate, toDate, page);
      const { total, page: currentPage, totalPages, results } = response;

      // Map API data to table format
      const tableData = results.map((item: any, index: number) => ({
        key: item.userId,
        index: index + 1, // Adjust index if needed
        username: item.username,
        name: item.firstName,
        totalIp: item.totalchild,
        totalIpPair: item.pair,
        leftIp: item.leftchildcount,
        rightIp: item.rightchildcount,
        createdAt: item.createdAt,
      }));

      // Update state with new data
      setTotal(total);
      setCurrentPage(currentPage);
      setTableData(tableData);
    } catch (error) {
      message.error("Failed to fetch data from the API.");
      console.error("API Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="p-4">
      <Row className="mb-4" align="middle">
        <Col xs={24}>
          <h2 className="text-2xl font-bold">Left Right IP Report</h2>
        </Col>
      </Row>
      <Card>
        <Row className="" gutter={16} justify="center" align="middle">
          <Col span={12} >
            <Col xs={24} sm={24} md={10}>
              <label className="block text-sm font-bold mb-2">From Date (mm/dd/yyyy)*</label>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <DatePicker
                className='w-full'
                format="MM/DD/YYYY"
                size='large'
                value={fromDate ? dayjs(fromDate) : null} // Convert fromDate to dayjs object for DatePicker
                onChange={(date, dateString) => setFromDate(dateString)} // Use dateString as fromDate
              />
            </Col>
          </Col>
          <Col span={12}>
            <Col xs={24} sm={24} md={10}>
              <label className="block text-sm font-bold mb-2">To Date (mm/dd/yyyy)*</label>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <DatePicker
                className='w-full'
                size='large'
                format="MM/DD/YYYY"
                value={toDate ? dayjs(toDate) : null} // Convert toDate to dayjs object for DatePicker
                onChange={handleToDateChange} // Trigger handleToDateChange on date change
              />
            </Col>
          </Col>
        </Row>

        <Row className="mt-4" gutter={16} >
          <Col span={12} >
            <Col xs={24} sm={24} md={10}>
              <label className="block text-sm font-bold mb-2">Number of Id Left</label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Input
                type="number"
                disabled
                value={leftIp}
                onChange={(e) => setLeftIp(e.target.value)}
                className="rounded-md border-gray-300 focus-visible:outline-none focus:ring-0"
                readOnly // Make it read-only if you don't want the user to edit
              />
            </Col>
          </Col>

          <Col span={12}>
            <Col xs={24} sm={24} md={10} className='ml-4'>
              <label className="block text-sm font-bold mb-2">Number of Id Right</label>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <Input
                disabled
                type="number"
                value={rightIp}
                onChange={(e) => setRightIp(e.target.value)}
                className="rounded-md border-gray-300 focus-visible:outline-none focus:ring-0"
                readOnly // Make it read-only if you don't want the user to edit
              />
            </Col>  
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} className="flex justify-center mt-3 mb-2">
            <button className='bg-orange-500 text-white px-3 py-2' onClick={() => handleViewList(currentPage)}>
              View List
            </button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={tableData} scroll={{x: 1000}}  pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            onChange: (page) => handleViewList(page),
          }} loading={loading} /> {/* Add loading prop */}
      </Card>
    </div>
  );
};

export default LeftRightIpReport;

