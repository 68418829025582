import React, { useEffect, useState } from "react";
import { Card, Carousel } from "antd";
import { getSliders } from "../../utils/API";
import BigSaleBanner from "./BigsaleBanner";
import NaswizAppeals from "./NaswizAppeals";
import Blog from "./Blog";
import AllProductsBottom from "./AllProductBottom";
import BrowseCategories from "./BrowseCategories";
import WhatweOffer from "./WhatweOffer";
import ClientReview from "./ClientReview";
import Services from "./Services";
import Upcomingproducts from "./Upcoming_products";
import TopProducts from "./TopProducts";

const contentStyle: React.CSSProperties = {};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
};

const Homepage: React.FC = () => {
  const [sliders, setSliders] = useState<string[]>([]);
  const imageUrl = process.env.REACT_APP_Image_URL ;

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await getSliders();
        setSliders(response.data.sliders);
      } catch (error) {
        console.error("Error fetching sliders:", error);
      }
    };
    fetchSlider();
  }, []);

  return (
    <>
       <Carousel autoplay dots={false} draggable className="lg:mt-[12rem] xl:mt-48 2xl:mt-[12.5rem] md:mt-[9.5rem] sm:mt-20 xs:mt-20 mt-20">
        {sliders.map((data : any , index) => (
          <div key={index}>
            <h3 style={{ ...contentStyle, textAlign: "center" }}>
              <img
                src={imageUrl  + "/sliders/" + data.image}
                alt={`SliderImage-${index}`}
                width="100%"
                // className="h-46 lg:h-[32rem] xl:h-[53rem]"
                className="w-full h-46 lg:h-auto xl:h-auto 2xl:mt-auto"
              />
            </h3>
          </div>
        ))}
      </Carousel>

      <Services />
      <TopProducts />
      <Upcomingproducts />
      <BrowseCategories />
      <NaswizAppeals />
      <BigSaleBanner />
      <Blog />
      <WhatweOffer />
      <AllProductsBottom />
      <div id="testimonial-section">
  <ClientReview />
</div>

    </>
  );
};

export default Homepage;
