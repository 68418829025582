import React, { useEffect, useState } from "react";
import { Card, Carousel } from "antd";
import { BiHeart, BiStrikethrough, BiSupport } from "react-icons/bi";
import { Row, Col } from "antd";
import { FaShippingFast, FaStar } from "react-icons/fa";
import { TbClock24 } from "react-icons/tb";
import { MdDiscount } from "react-icons/md";
import { RiHandCoinFill } from "react-icons/ri";
import { GiSkills, GiStabbedNote } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";


const Services: React.FC = () => {
  const [slidesToShow, setSlidesToShow] = useState(2.5);

  useEffect(() => {
    const handleResize = () => {
      // Update slidesToShow based on window width
      if (window.innerWidth > 1200) {
        setSlidesToShow(1);
      }
      if (window.innerWidth <= 1170 && window.innerWidth >= 1060) {
        setSlidesToShow(1);
      }
      else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 768) {
        setSlidesToShow(1);
      }
    };
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial state
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  return (
    <>
    <Row className="mt-2 lg:px-0 xl:px-36 lg:ps-0 xl:ps-36  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
      <Row className="mt-2 lg:px-10 xl:px-20 lg:ps-10 xl:ps-20 w-full  md:px-10 md:ps-10 sm:ps-5 xs:ps-5" >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="p-3 mt-4">
          <Carousel autoplay slidesToShow={slidesToShow} draggable dots={false}>
          <div>
            <Row>
                <Col span={4} className="d-flex justify-content-center align-items-center w-full">
                  <RiHandCoinFill size={30} />   </Col>
                    <Col span={18} className="justify-content-center align-items-center">
                    <p className="font-extrabold" >Assured Quality</p>
                    <p className="text-orange-500" >Premium products</p>
                    </Col>
                <div className="border-l-2 border-gray-300"></div>
            </Row>
          </div>
          <div>
            <Row>
                <Col span={4} className="d-flex justify-content-center align-items-center">
                  <TbClock24  size={30} />    </Col>
                    <Col span={18} className="justify-content-center align-items-center">
                    <p className="font-extrabold" >Online Support 24*7</p>
                    <p className="text-orange-500" >+91 93192 70033
                    </p>
                    </Col>
                <div className="border-l-2 border-gray-300"></div>
            </Row>
          </div>  <div>
            <Row>
                <Col span={4} className="d-flex justify-content-center align-items-center">
                  <GiStabbedNote  size={30} />   </Col>
                    <Col span={18} className="justify-content-center align-items-center">
                    <p className="font-extrabold" >Lucrative Compensation Plan</p>
                    <p className="text-orange-500" >Unlimited Earning
</p>
                    </Col>
                <div className="border-l-2 border-gray-300"></div>
            </Row>
          </div>  
          <div>
            <Row>
                <Col span={4} className="d-flex justify-content-center align-items-center">
                  <GiSkills  size={30} />   </Col>
                    <Col span={18} className="justify-content-center align-items-center">
                    <p className="font-extrabold" >Ongoing Training Access</p>
                    <p className="text-orange-500" >Develop Skill </p>
                    </Col>
                <div className="border-l-2 border-gray-300"></div>
            </Row>
          </div>
          <div>
            <Row>
                <Col span={4} className="d-flex justify-content-center align-items-center w-full">
                  <HiUserGroup size={30} />   </Col>
                    <Col span={18} className="justify-content-center align-items-center">
                    <p className="font-extrabold" >Global Community</p>
                    <p className="text-orange-500" >Connection|Collaboration|Support</p>
                    </Col>
                <div className="border-l-2 border-gray-300"></div>
            </Row>
          </div>
            {/* Add more divs like the one above for additional slides */}
          </Carousel>
        </Col>
      </Row>
    </Row>
  </>
  )
}

export default Services;
