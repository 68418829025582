// EditPages.tsx
import React, { useEffect, useState } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Input,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetAllPagesById, updatePages } from "../../../utils/API";

const EditPages: React.FC = () => {

    const navigate = useNavigate();
    const { id} = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
      GetAllPagesById(id).then((response) => {
          form.setFieldsValue(response);
      });
  }, []);

  const handleSubmit = (values: any) => {
    const id = form.getFieldValue('_id');
    updatePages(id, values).then((response : any) => {
      if (response.status === 200) {
        message.success(response.data.message);
        navigate("/backend/pages");
      } else {
        message.error("Something went wrong");
      }
    });
    }

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Manage Pages</h2>
           
        </Col>
      </Row>
      <Form form={form} className="bg-white" onFinish={handleSubmit}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={24}
                  className="d-flex justify-content-start align-items-center"
                ></Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Page Title <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="pageTitle"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Page Title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Enter Page Title"
                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Page Slug <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="pageSlug"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Page Slug",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Enter Page Slug"
                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Meta Title <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="metaTitle"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Meta Title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Enter Meta Title"
                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Meta Description <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="metaDescription"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Meta Description",
                          },
                        ]}
                      >
                        <Input.TextArea rows={4} size="large" className="rounded border border-1" placeholder="Enter Meta Description"                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Meta Tag <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="tags"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Meta Tag",
                          },
                        ]}
                      >
                        <Input.TextArea rows={4} size="large" className="rounded border border-1" placeholder="Enter Meta Tag"                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                       Description<span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Description",
                          },
                        ]}
                      >
                      <ReactQuill
                      theme="snow"
                      className="rounded border border-1"
                      placeholder="Enter Description" 
                      style={{ height: '200px' }}
                      
                    />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24} className="d-flex mt-5 justify-content-center">
                    <button className="btn-brand" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default EditPages;
