import React, { useEffect, useState } from 'react';
import { getaward } from '../../utils/API';

const Awards = () => {
  const [awards, setAwards] = useState([]); // State to store fetched awards
  const [loading, setLoading] = useState(true); // Loading state
  const imageUrl = `${process.env.REACT_APP_Image_URL}/awards/`;

  useEffect(() => {
    const fetchAwards = async () => {
      try {
        const data = await getaward(""); // Call the API function
        // Transform the data to match the structure needed for rendering
        const formattedAwards = data.award.map((item: any) => ({
          id: item._id,
          imgSrc: item.file, // Assuming you store image URLs in the "file" property
          title: item.title,
          description: item.description || 'No description available', // Handle description
          link: item.file, // Assuming the file is the link to the image
        }));
        setAwards(formattedAwards);
      } catch (error) {
        console.error("Error fetching awards:", error);
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchAwards();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator
  }

  return (
    <div className="py-12 mt-40">
      <h2 className="text-4xl font-bold text-start mb-8 text-orange-500 mt-10 ml-32 mb-10">Awards and Accolades</h2>
      <div className="flex flex-wrap justify-center gap-10">
        {awards.map((award: any) => (
          <div
            key={award.id}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-white shadow-md rounded-lg border border-2 border-gray-200 overflow-hidden"
          >
            <div className="h-72 w-full relative">
              <a href={imageUrl + award.imgSrc} target="_blank" rel="noopener noreferrer">
                <img
                  src={imageUrl + award.imgSrc} // Use imgSrc from the fetched data
                  alt={award.title}
                  className="object-contain w-full h-full absolute inset-0"
                />
              </a>
            </div>
            <div className="flex flex-col">
              <a
                href={award.link} // Use link from the fetched data
                className="mt-4 block w-full bg-orange-500 text-white px-4 py-2 rounded text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-full text-lg font-bold whitespace-nowrap">
                  {award.title}
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Awards;
