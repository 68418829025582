// src/HelloWorld.tsxCopy code
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  message,
  Card,
  Checkbox,
  Select,
  Upload,
  InputNumber,
  Spin,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  addProduct,
  getAllcategories,
  getproductsize,
  getcolores,
} from "../../utils/API";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { BsUpload } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import ReactQuill from "react-quill";
const { Option } = Select;

interface ProductSize {
  size: string;
  name: string;
  _id: any;
  // Other properties if available
}

const AddProductForm: React.FC = () => {
  const [productsizedata, setproductsize] = useState<ProductSize[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false); // Initially set loading to true
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [colorData, setColorData] = useState<any[]>([]);
  const [category, setCategory] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [qty, setQty] = useState<string>("");
  const [ipQty, setipQty] = useState<Number>(0);
  const [size, setSize] = useState<any[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [is_bestseller, setIsBestseller] = useState<any>(false);
  const [is_featured, setIsFeatured] = useState<any>(false);
  const [is_upcoming, setIsUpcoming] = useState<any>(false);
  const [is_top_rated, setIsTopRated] = useState<any>(false);
  const [discount, setDiscount] = useState<string>("");
  const [discountedPrice, setDiscountedPrice] = useState<string>("");
  const [addpLoading , setAddpLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const fetchSizeData = () => {
    setLoading(true);
    getproductsize()
      .then((response: any) => {
        const filteredSizes = response.data.sizes.filter(
          (size: any) => !size.isDeleted && size.isActive
        );
        setproductsize(filteredSizes);
      })
      .catch((error: any) => {
        message.error("Network error. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Turn off loading regardless of success or failure
      });
  };
  
  const fetchCategories = async () => {
    try {
      const categoriesData = await getAllcategories("");

      setCategoryData(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchColores = async () => {
    try {
      const response = await getcolores(); 
      const colorsData = response.colors;
      setColorData(colorsData);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };


  React.useEffect(() => {
    fetchSizeData();
    fetchCategories();
    fetchColores();
  }, []);

  const handleSubmit = async () => {
    setAddpLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("discount", discount);
      formData.append("discountedPrice", discountedPrice);
      fileList.forEach((file, index) => {
        formData.append("images", fileList[index].originFileObj as RcFile);
      });
      size.forEach((item, index) => {
        formData.append("sizeId", size[index]);
      })
      colors.forEach((color) => {
        formData.append("colorId", color);
      });
      formData.append("qty", qty);
      formData.append("categoryId", category);
      formData.append("isFeatured", String(is_featured));
      formData.append("isBestSelling", String(is_bestseller));
      formData.append("isUpcoming", String(is_upcoming));
      formData.append("isTopRated", String(is_top_rated));
      formData.append("ipQty", String(ipQty));
      await addProduct(formData);

      message.success("Product added successfully");
      navigate("/backend/product");
      setAddpLoading(false);
    } catch (error) {
      setAddpLoading(false);
      message.error("Failed to add product. Please try again.");
    }
  };

  const handleImage = ({ fileList }: UploadChangeParam<UploadFile>) => {
    setFileList(fileList);
  };

  const validateDiscount = (_ : any, value : any) => {
    if (value > 100) {
      return Promise.reject(new Error('Discount cannot be more than 100%'));
    }
    return Promise.resolve();
  };


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Product</h2>
        </Col>
        {/* <Col xs={0} sm={0} md={12}></Col> */}
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/product"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white" onFinish={handleSubmit}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Category<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="categoryId"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Category ",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      placeholder="Select a category"
                      optionFilterProp="children"
                      filterOption={(input : any, option : any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => setCategory(value)}
                    >
                      {categoryData.map((item) => (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    {" "}
                    Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Product name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      value={name}
                      placeholder="Product Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Product Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col
                  xs={24} sm={24} md={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Form.Item
                    name="images"
                    rules={[
                      {
                        required: true,
                        message: "Please upload at least one image",
                      },
                    ]}
                  >
                  <Upload
                    multiple
                    name="images"
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={handleImage}
                    maxCount={5} // Adjust the maximum number of images you want to allow
                    accept=".png, .jpg, .jpeg"
                  >
                    <div>
                      <BsUpload style={{ fontSize: "20px" }} />
                    </div>
                  </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white mb-3">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Description<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Description",
                          },
                        ]}
                      >
                      <ReactQuill
                      value={description}
                      onChange={(value) => setDescription(value)}
                      theme="snow"
                      className="rounded pb-2 mb-7"
                      placeholder="Enter Description" 
                      style={{ height: '200px' }}
                      
                    />
                      </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white mt-3">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    MRP<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter MRP ",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="MRP"
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Discount % <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="discount"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Discount %",
                      },
                      {
                        validator: validateDiscount,
                      },
                    ]}
                  >
                    <Input
                    type="number"
                      size="large"
                      className="rounded border border-1"
                      placeholder="Discount %"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Selling price<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="discountedPrice"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Selling price ",
                      },
                    ]}
                  >
                    <Input
                    type="number"
                      size="large"
                      className="rounded border border-1"
                      placeholder="Selling price"
                      value={discountedPrice}
                      onChange={(e) => setDiscountedPrice(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Sizes
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="sizeId"
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input : any, option : any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                        placeholder="Choose Size"
                      onChange={(value) => setSize(value)}
                    >
                      {productsizedata.map((item) => (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Colors
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="color"
                  >
                    <Select
                      size="large"
                      placeholder="Choose Colors"
                      onChange={(value) => setColors([value])} // Convert single value to array
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input : any, option : any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      value={colors.length > 0 ? colors[0] : undefined} // Set the value based on the first element of colors array
                    >
                      {colorData.map((item: any) => (
                        <Option key={item._id} value={item._id}>
                          {item.color}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    {" "}
                    Quantity
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="qty"
                    
                  >
                    <Input
                    type="number"
                      size="large"
                      className="rounded border border-1"
                      value={qty}
                      placeholder="Quantity"
                      onChange={(e) => setQty(e.target.value)}
                    />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    {" "}
                    IP Quantity <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="ipQty"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Ip Quantity",
                      },
                    ]}
                  >
                    <InputNumber
                    type="number"
                      size="large"
                      className="rounded border border-1 w-full lg:w-1/4"
                      value={qty}
                      placeholder="Ip Quantity"
                      onChange={(value) => setipQty(value as unknown as number)}
                    />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                 xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5"
                >
                  <label className="font-bold">
                    Featured Product
                  </label>
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Form.Item name="isFeatured" valuePropName="checked">
                    <input type="checkbox"
                      style={{
                        borderColor: "orange", 
                        color: is_featured ? "orange" : "transparent"
                      }}
                      value={is_featured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    Best Selling
                  </label>
                </Col>
                <Col span={3}>
                  <Form.Item name="isBestSelling" valuePropName="checked">
                  <input type="checkbox"
                    style={{
                      borderColor: "orange", 
                      color: is_bestseller ? "orange" : "transparent"
                    }}
                    value={is_bestseller}
                    onChange={(e) => setIsBestseller(e.target.checked)}
                  />

                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5"
                >
                  <label className="font-bold">
                    Upcoming Product
                  </label>
                </Col>
                <Col span={3}>
                  <Form.Item name="isUpcoming" valuePropName="checked">
                    <input type="checkbox"
                      style={{
                        borderColor: "orange", 
                        color: is_upcoming ? "orange" : "transparent"
                      }}
                      value={is_upcoming}
                      onChange={(e) => setIsUpcoming(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    Top Rated
                  </label>
                </Col>
                <Col span={3}>
                  <Form.Item name="isTopRated" valuePropName="checked">
                    <input type="checkbox"
                      style={{
                        borderColor: "orange", 
                        color: is_top_rated ? "orange" : "transparent"
                      }}
                      value={is_top_rated}
                      onChange={(e) => setIsTopRated(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            ><button className='btn-brand'>
              {
                loading ? 
                (
                  <div className="d-flex">
                          <Spin /> 
                          <span className="ms-2">Updating...</span>
                        </div>
                )
                : "Add Product"
              }
            </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default AddProductForm;