import NavbarUser from "../common/Navbar";
import HeaderUser from "../common/Header";
import Footeruser from "../common/Footer";
import Home from "./Homepage";

const DashboardUser = () => {
  return (
    <>
    <div className="sticky top-0 left-0 right-0 z-10 bg-white md:px-0 md:ps-0 sm:px-0 sm:ps-0 xs:px-0 xs:ps-0 " >
      <HeaderUser />
        </div>
      
         <div >
      <Footeruser />
      </div>
      </>
  );
};

export default DashboardUser;






