import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Descriptions,
  Button,
  Row,
  Col,
  Image,
  Divider,
  Modal,
  Form,
  InputNumber,
  Select,
  Input,
  message,
  notification,
  DatePicker,
} from "antd";
import {
  getallproducts,
  editProduct,
  orderUpdate,
  orderstatus,
  getCustomers,
  updateOrderUserId,
} from "../../../utils/API"; // Assuming you have these API functions
import { FaArrowLeft } from "react-icons/fa";
import { config } from "../../../utils/config";
import { BiCheck } from "react-icons/bi";
import moment from 'moment';
import dayjs from 'dayjs';

const { Option } = Select;

type OrderStatusUpdateData = {
  status: number;
  deliveryOption?: string;
  trackingNo?: string;
};

const OrderDetail = () => {
  const location = useLocation();
  const { orderId: orderInfo } = location.state || {};
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>("0");
  const [searchText, setSearchText] = useState("");
  const [kycFilter, setKycFilter] = useState<number | null>(null);
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<any>(null);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedOrderItemId, setSelectedOrderItemId] = useState<any>(null);
  const [orderItems, setOrderItems] = useState(orderInfo.orderItems);
  const [previousStatus, setPreviousStatus] = useState(orderInfo.status);
  const [userId, setUserId] = useState(null);
  const [deliveryOption, setdeliveryOption] = useState(null);
  const [deliveryDate, setdeliveryDate] = useState<string | null>(dayjs().format("YYYY-MM-DD"));
  const [trackingNo, settrackingNo] = useState("");
  const [isStatusModalVisible, setIsStatusModalVisible] =
    useState<boolean>(false);
  const [tempStatus, setTempStatus] = useState<any>(null);

  const imageUrl = process.env.REACT_APP_Image_URL + "/products/";
  const [form] = Form.useForm();

  // console.log(location.state);

  const navigate = useNavigate();

  const FetchCustomerData = (searchText: string, kyc: number | null) => {
    setLoading(true);
    getCustomers(searchText, kyc)
      .then((response: any) => {
        const filteredCustomerData = response.data
          .filter(
            (customer: any) => !customer.isDeleted && customer.orderStatus == 0
          )
          .reverse();

        setCustomerData(filteredCustomerData);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error("Network error. Please try again.");
        setLoading(false);
      });
  };

  React.useEffect(() => {
    FetchCustomerData(searchText, kycFilter);
  }, [searchText, kycFilter]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getallproducts(); // Assuming getallproducts fetches all products
      setProducts(response);
    } catch (error) {
      message.error("Error fetching products:");
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchProductById(selectedProduct);
    }
  }, [selectedProduct]);

  const fetchProductById = async (id: string) => {
    try {
      const response = await editProduct(id);
      setProduct(response);
    } catch (error) {
      message.error("Error fetching product:");
    }
  };

  if (!orderInfo) {
    return <div className="text-center p-4">No order details available.</div>;
  }

  const handleEditProduct = (id: string | null, orderItemId: string | null) => {
    setSelectedProduct(id);
    setSelectedOrderItemId(orderItemId);
    setIsModalVisible(true);
  };


  const handleFormFinish = async (updatedItem: any) => {
    const newOrderItems = orderItems.map((item: any) => {
      if (item._id === updatedItem.orderItemId) {
        return {
          ...item,
          productId: products.find(
            (product) => product._id === updatedItem.productId
          ),
          quantity: updatedItem.qty,
        };
      }
      return item;
    });

    setOrderItems(newOrderItems);

    const newTotalMrp = newOrderItems.reduce((total: any, item: any) => {
      return total + item.productId.price * item.quantity;
    }, 0);

    const newTotalAmount = newOrderItems.reduce((total: any, item: any) => {
      return total + item.productId.discountedPrice * item.quantity;
    }, 0);

    const newTotalIp = newOrderItems.reduce((total: any, item: any) => {
      return total + item.productId.ipQty * item.quantity;
    }, 0);

    let recoveryAmount = orderInfo.recoveryAmount;

    if (updatedItem.productId != product._id) {
      recoveryAmount = newTotalAmount - orderInfo.amount;
    }

    const formData = {
      orderId: orderInfo._id,
      orderItems: newOrderItems.map((item: any) => ({
        orderItemId: item._id,
        productId: item.productId._id,
        quantity: item.quantity,
      })),
      userId: orderInfo.userId._id,
      totalMrp: newTotalMrp,
      amount: orderInfo.amount,
      totalIp: newTotalIp,
      recoveryAmount: recoveryAmount,
    };


    try {
      const response = await orderUpdate(orderInfo._id, formData);
      if (response) {
        notification.open({
          message: "Success",
          icon: <BiCheck style={{ color: "#52c41a" }} />,
          description: "Product updated successfully!",
          placement: "topRight",
          duration: 3,
        });
        navigate("/backend/manage-order");
      }
    } catch (error) {
      message.error("Error updating order:");
    }

    setIsModalVisible(false);
  };


  const handleOrderUpdate = async () => {
    try {
      const response = await updateOrderUserId(orderInfo._id, userId);
      if (response) {
        notification.open({
          message: "Success",
          icon: <BiCheck style={{ color: "#52c41a" }} />,
          description: "Order updated successfully!",
          placement: "topRight",
          duration: 3,
        });
        navigate("/backend/manage-order");
      }
    } catch (error) {
      message.error("Error updating order:");
    }

    setIsModalVisible(false);
  }

  const handleStatusChangeWithValidation = (value: number) => {
    if (value === 1 && orderInfo.userId === null) {
      message.error(
        "Customer name and email must be available to mark the order as Shipped."
      );
      form.setFieldsValue({ status: previousStatus });
      return;
    }

    setPreviousStatus(value);
    setdeliveryOption(null);
    settrackingNo("");
    handleStatusChange(value);
  };

  const handleStatusChange = (value: number) => {
    const firstName = orderInfo.userId?.firstName || "";
    const lastName = orderInfo.userId?.lastName || "";

    if (value === 2 && (!firstName || !lastName)) {
      message.error(
        "Customer name and email must be available to mark the order as Delivered."
      );
      return;
    }
    setTempStatus(value);
    setIsStatusModalVisible(true);
  };

  const handleOk = () => {
    if (tempStatus === 2 && (!deliveryOption || (deliveryOption === "1" && !trackingNo))) {
      message.error("Please fill in all required fields!");
      return;
    }

    if (tempStatus === 2) {
      if (orderInfo.status !== 1) {
        message.error("Order must be dispatched before it can be delivered!");
        return;
      }
    }

    if (tempStatus === 1) {
      if (!deliveryDate) {
        message.error("Please select a delivery date!");
        return;
      }
    }

    let statusUpdateData: any = { status: tempStatus };

    if (tempStatus === 2) {
      statusUpdateData.deliveryOption = deliveryOption;
      if (deliveryOption === "") {
        statusUpdateData.trackingNo = trackingNo;
      }
    }

    if (tempStatus === 1) {
      statusUpdateData.deliveryDate = deliveryDate;
    }

    console.log(tempStatus)
    console.log(statusUpdateData)
    setSelectedStatus(tempStatus);
    handleUpdateorderstatus(statusUpdateData);
    setIsStatusModalVisible(false);
  };

  const handleCancel = () => {
    setIsStatusModalVisible(false);
  };

  const handleUpdateorderstatus = async (statusUpdateData: OrderStatusUpdateData) => {
    try {
      const response = await orderstatus(orderInfo._id, statusUpdateData);
      if (response) {
        notification.open({
          message: "Success",
          icon: <BiCheck style={{ color: "#52c41a" }} />,
          description: "Order status updated successfully!",
          placement: "topRight",
          duration: 3,
        });
        navigate("/backend/manage-order");
      }
    } catch (error: any) {
      message.error("Error updating order status: " + error.message);
    }
  };


  const handleChange = (value: any) => {
    setUserId(value);
  };

  return (
    <>
      <div className="container mx-auto my-2 p-4">
        <Card className="shadow-lg">
          <div className="d-flex justify-between items-baseline">
            <div className="d-flex justify-around">
              <div className="pr-3">
                <h2 className="text-2xl font-bold">Order Details</h2>
              </div>
              {orderInfo.userId?.firstName ? (
                <></>
              ) : (
                <>
                  <div className="d-flex">
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input: any, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      disabled={orderInfo.status === 2 || orderInfo.status === 3}
                      style={{ width: 400 }}
                      value={userId}
                      onChange={handleChange}
                      onClear={() => setUserId(null)}
                      className="rounded border border-1"
                      placeholder="Select User"
                    >
                      {customerData.map((user) => (
                        <Select.Option key={user._id} value={user._id} label={`${user.firstName} - ${user.username}`}>
                          {user.firstName} - {user.username}
                        </Select.Option>
                      ))}
                    </Select>

                    <button
                      className="bg-orange-500 text-white rounded-md p-2 h-10"
                      onClick={handleOrderUpdate}
                    >
                      Update User
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <Link to={"/backend/manage-order"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <FaArrowLeft className="me-2" />
                  <div className="ms-2">Back</div>
                </button>
              </Link>
            </div>
          </div>

          <div className="p-2 bg-white shadow-md rounded-lg">
            <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-6">
              <div className="block w-full">
                <p className="text-base">Customer Name:</p>
                <p className="font-bold mb-3">
                  {`${orderInfo.userId?.firstName || ""} ${orderInfo.userId?.lastName || "No name provided"
                    }`}
                </p>
                <p className="text-base">Email:</p>
                <p className="font-bold mb-3">
                  {orderInfo.userId?.email || "No email provided"}
                </p>

                <p className="text-base">Total MRP:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol}
                  {orderInfo.totalMrp}
                </p>
                <p className="text-base">Total IP:</p>
                <p className="font-bold mb-3">{orderInfo.totalIp}</p>
              </div>
              <div>
                <p className="text-base">Amount Paid:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol} {orderInfo.amount}
                </p>
                <p className="text-base">Payment Method:</p>
                <p className="font-bold mb-3">{orderInfo.paymentMethod}</p>
                <p className="text-base">Delivery Address:</p>
                <p className="font-bold mb-3">{orderInfo.address}</p>
                <p className="text-base">Delivery Notes:</p>
                <p className="font-bold mb-3">{orderInfo.deliveryNotes}</p>
              </div>
              <div>
                <p className="text-base">Invoice Number:</p>
                <p className="font-bold mb-3">{orderInfo.invoiceNumber}</p>
                <p className="text-base">Recovery Amount:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol} {orderInfo.recoveryAmount}
                </p>
                <p className="text-base">Order Status :</p>
                <p className="font-bold mb-3 mt-2">
                  {orderInfo.status === 2 ? (
                    <>
                      <span className="text-green-500">Delievered</span>
                    </>
                  ) : (
                    <>
                      <Form form={form}>
                        <Form.Item name="status" rules={[{ required: true }]}>
                          <Select
                            defaultValue={orderInfo.status}
                            value={orderInfo.status}
                            size="large"
                            style={{ width: 120 }}
                            onChange={handleStatusChangeWithValidation}
                          >
                            <Option value={0} disabled={orderInfo.status >= 0}>Pending</Option>
                            <Option value={1} disabled={orderInfo.status >= 1}>Dispatch</Option>
                            <Option value={2}>Deliever</Option>
                            <Option value={3}>Cancelled</Option>
                          </Select>
                        </Form.Item>
                      </Form>
                    </>
                  )}
                </p>
                {
                  orderInfo.status === 1 || orderInfo.status === 2 && (
                    <>
                      <p className="text-base">Delivery Method:</p>
                      <p className="font-bold mb-3">
                        {orderInfo.deliveryOption === 0 ? "By Hand" : "Courier"}
                      </p>
                      <p className="text-base">Tracking Number:</p>
                      <p className="font-bold mb-3">{orderInfo.trackingNo}</p>
                    </>
                  )
                }
              </div>
            </div>
            <Modal
              closable={false}
              title="Change Order Status"
              visible={isStatusModalVisible}
              footer={[
                <Button key="cancel" onClick={handleCancel} >
                  Cancel
                </Button>,
                <Button key="update" className="bg-orange-500" onClick={handleOk}>
                  Update
                </Button>,
              ]}
            >
              <p className="text-base mb-2">Are you sure you want to change the order status ?</p>
              {tempStatus === 2 && (
                <Form form={form}>
                  <Form.Item
                    name="deliveryOption"
                    rules={[{ required: true, message: 'Please select a delivery method!' }]}
                  >
                    <Select
                      placeholder="Select Delivery Method"
                      onChange={(value) => setdeliveryOption(value)}
                    >
                      <Option value="0">By Hand</Option>
                      <Option value="1">Courier</Option>
                    </Select>
                  </Form.Item>

                  {deliveryOption === "1" && (
                    <Form.Item
                      name="trackingNo"
                      rules={[{ required: true, message: 'Please enter a tracking number!' }]}
                    >
                      <Input
                        className="rounded-md border-gray-300 focus-visible:outline-none focus:ring-0"
                        placeholder="Enter Tracking Number"
                        onChange={(e) => settrackingNo(e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Form>

              )}
              {tempStatus === 1 && (
                <Form form={form}>
                  <Form.Item
                    name="deliveryDate"
                    initialValue={dayjs()}  // Set the initial value to today
                    rules={[{ required: true, message: 'Please select a delivery date!' }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => setdeliveryDate(dateString)}
                      defaultValue={dayjs()}  // Auto select today’s date
                    />
                  </Form.Item>
                </Form>
              )}

            </Modal>
          </div>
          <Divider orientation="left" className="my-4">
            Order Items
          </Divider>

          {orderItems.map((item: any) => (
            <div key={item._id} className="mb-4 shadow-sm">
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={4} className="d-flex justify-center">
                  <Image
                    width={100}
                    src={imageUrl + item.productId.images[0]}
                    alt="Product"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Descriptions column={1} size="small">
                    <Descriptions.Item label="Product Name">
                      {item.productId.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Price">
                      {config.currencySymbol} {item.productId.price}
                    </Descriptions.Item>
                    <Descriptions.Item label="Discounted Price">
                      {config.currencySymbol}
                      {item.productId.discountedPrice}
                    </Descriptions.Item>
                    <Descriptions.Item label="Quantity">
                      {item.quantity}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xs={24} sm={24} md={4}>
                  <button
                    onClick={() =>
                      handleEditProduct(item.productId._id, item._id)
                    }
                    className={`w-full bg-orange-500 text-white py-2 mt-4 rounded-lg ${orderInfo.status === 2 || orderInfo.userId === null
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                    disabled={orderInfo.status === 2 || orderInfo.userId === null} // Disable the button if order status is 2 (Delivered)
                  >
                    Edit
                  </button>
                </Col>
              </Row>
            </div>
          ))}
          <Modal
            closable={false}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
          >
            <EditProductForm
              product={product}
              orderId={orderInfo._id}
              orderItems={orderInfo.orderItems}
              orderitemid={selectedOrderItemId}
              products={products}
              setIsModalVisible={setIsModalVisible}
              handleFormFinish={handleFormFinish}
            />
          </Modal>
        </Card>
      </div>
    </>
  );
};

const EditProductForm = ({
  product,
  products,
  orderId,
  orderItems,
  orderitemid,
  setIsModalVisible,
  handleFormFinish,
}: {
  product: any;
  products: any[];
  orderitemid: string;
  orderId: string;
  orderItems: any;
  setIsModalVisible: (visible: boolean) => void;
  handleFormFinish: (values: any) => void;
}) => {
  const [form] = Form.useForm();
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [initialProductValues, setInitialProductValues] = useState<any>(product);
  const [selectedProductImage, setSelectedProductImage] = useState<string | null>(null);

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        productId: product._id,
        price: product.price,
        discountedPrice: product.discountedPrice,
        qty: orderItems[0]?.quantity,
      });
      setSelectedProductImage(product.images ? product.images[0] : null);
    }
  }, [product, form]);



  const handleProductSelect = (productId: string) => {
    setSelectedProductId(productId);
    const selectedProduct = products.find((product) => product._id === productId);

    if (selectedProduct) {
      form.setFieldsValue({
        productId: selectedProduct._id,
        price: selectedProduct.price,
        discountedPrice: selectedProduct.discountedPrice,
        qty: orderItems[0].quantity,
      });
      setSelectedProductImage(selectedProduct.images ? selectedProduct.images[0] : null);
    }
  };


  const onFinish = (values: any) => {
    handleFormFinish({ ...values, orderItemId: orderitemid });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="orderId" initialValue={orderId} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="productId" hidden>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <span> Product</span>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="productId">
            <Select
              placeholder="Select a product"
              onChange={handleProductSelect}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {products.map((product: any) => (
                <Option key={product._id} value={product._id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {selectedProductImage && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <span>Product Image</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Image
                width={100}
                src={process.env.REACT_APP_Image_URL + "/products/" + selectedProductImage}
                alt="Product"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <span>Price</span>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="price" rules={[{ required: true, message: "Please input the price!" }]}>
            <InputNumber disabled style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <span>Discounted Price</span>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="discountedPrice" rules={[{ required: true, message: "Please input the discounted price!" }]}>
            <InputNumber disabled style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <span>Quantity</span>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="qty" rules={[{ required: true, message: "Please input the quantity!" }]}>
            <InputNumber disabled style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end">
        <button className="bg-orange-500 pt-2 pb-2 pl-4 pr-4 text-white">Save</button>
        <input type="button" value="Cancel" className="bg-red-500 ml-2 pt-2 pb-2 pl-4 pr-4 text-white" onClick={() => setIsModalVisible(false)} />
      </div>
    </Form>
  );
};


export default OrderDetail;