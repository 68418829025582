import React from "react";
import { Card, Carousel } from "antd";
import { BiHeart, BiStrikethrough, BiSupport } from "react-icons/bi";
import { Row, Col } from "antd";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

const BigSaleBanner: React.FC = () => (
  <>
  
    <Row            className="mt-3 lg:px-36 lg:ps-36  md:px-10 md:ps-10 sm:ps-5 xs:ps-5"  style={{     backgroundImage: `url(${require('../../assets/frontendassets/BigSaleBanner/20210308102454069500NvkLq.jpg')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> 
      <Col xs={16} sm={20} md={20} lg={20} xl={20} className="text-start  d-flex align-items-center font-bold text-xl text-white">
     <span className="text-white  p-2 bg-black mr-2 font-semibold text-lg" >BIG SALE </span>
     <span className="text-lg">Get 20% off on all latest corporate wear suits
</span>
      </Col>
      <Col xs={8} sm={4} md={4} lg={4} xl={4} className="d-flex justify-center mt-3 mb-3">
  <Link to="/products" >
  <button className="btn bg-slate-100 font-extrabold text-sm xl:p-5 lg:p-5 p-2 xxl:p-5 hover:bg-white ">VIEW SALE</button>
  </Link>
      </Col>
    </Row>
    <Row className="mt-3 mb-3 md:h-[5rem] sm:h-[4rem] lg:h-[8rem]" style={{
    backgroundImage: `url(${require('../../assets/frontendassets/1564.jpg')})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
}}>
    <Col span={5} className="text-end p-4  font-bold text-xl text-white ">
     <Col ></Col> <br />
      </Col>
      <Col span={12} className=" text-center font-bold" style={{ margin : "auto"}}>
      <Link to="/products" >
  <button className="btn bg-orange-500 text-white font-extrabold text-sm xl:p-5 lg:p-5 p-2 xxl:p-5 hover:bg-orange-500 ">View Products</button>
  </Link>
      </Col>
      <Col span={5} className="text-start p-2  font-bold text-xl text-white ">
      <Col ></Col> <br />
      </Col>
    </Row>
  </>
);

export default BigSaleBanner;
