import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  message,
  notification,
} from "antd";
// import { useForm } from "antd/es/form/Form";
import { SearchOutlined } from "@ant-design/icons";
import {
  getCustomers,
  UpdateFlag,
  getChildrenList,
  submitTripodData,
  submitSevenPodData,
  getUsersChildrenLists,
} from "../../../../utils/API";
import { RiCloseLine } from "react-icons/ri";
import { MdCheckBox } from "react-icons/md";
import { format } from "util";

const { Option } = Select;

interface Customer {
  _id: any;
  firstName: string;
  lastName: string;
  username: string;
  userId: any;
  email: string;
  mobileNo: string;
}

const Tripod: React.FC = () => {
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [kycFilter, setKycFilter] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalUser, setModalUser] = useState<Customer | null>(null);
  const [isTripodModalOpen, setisTripodModalOpen] = useState<boolean>(false);
  const [isSevenpodModalOpen, setisSevenpodModalOpen] = useState<boolean>(false);
  const [dropdownCount, setDropdownCount] = useState<number | null>(null);
  const [leftChildrens, setLeftChildrens] = useState<Customer[][]>([]);
  const [rightChildrens, setRightChildrens] = useState<Customer[][]>([]);
  const [childrenLeft1, setChildrenLeft1] = useState<Customer[][]>([]);
  const [childrenRight1, setChildrenRight1] = useState<Customer[][]>([]);
  const [childrenLeft2, setChildrenLeft2] = useState<Customer[][]>([]);
  const [childrenRight2, setChildrenRight2] = useState<Customer[][]>([]);
  const [mainId, setMainId] = useState<string | null>(null);
  const [dropdownIds, setDropdownIds] = useState<{
    [key: number]: {
      leftId: string | null;
      rightId: string | null;
      leftChildrens?: Customer[];
      rightChildrens?: Customer[];
    };
  }>({});

  const [tripodForm] = Form.useForm();
  const [sevenpodForm] = Form.useForm();
  // const [form] = useForm();


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tripodLoading, setTripodLoading] = useState(false);
  const [sevenpodLoading, setSevenpodLoading] = useState(false);
  const [tripodOptionLoading, setTripodOptionLoading] = useState(false);
  const [sevenpodOptionLoading, setSevenpodOptionLoading] = useState(false);
  const [subLeftChildrenLoding, setSubLeftChildrenLoding] = useState(false);
  const [subRightChildrenLoding, setSubRightChildrenLoding] = useState(false);

  const fetchCustomerData = (page: any, searchText: any) => {
    const customerId = localStorage.getItem("customerId") || "";
    const customuserId = customerId.replace(/"/g, "");

    setLoading(true);
    getUsersChildrenLists(customuserId, page, searchText)
      .then((response: any) => {
        setCustomerData(response.data.children.reverse());
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error("Failed to fetch customer data. Please try again.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomerData(currentPage, searchText);
  }, [currentPage, searchText]);

  const openModal = (
    user: Customer,
    dropdownCount: number | null,
    type: string
  ) => {
    setModalUser(user);
    // setIsModalOpen(true);
    // setDropdownCount(dropdownCount);
    setMainId(user.userId || user._id);
    fetchChildrenList(user.userId || user._id);
    // initializeDropdownIds(dropdownCount);
    // setActionType(type);
    if (dropdownCount == 1) {
      setisTripodModalOpen(true);
    } else {
      setisSevenpodModalOpen(true);
    }
  };

  const handleSearch = (value: any) => {
    setSearchText(value);
  };


  const fetchChildrenList = (userId: any) => {
    setTripodOptionLoading(true);
    setSevenpodOptionLoading(true);
    getChildrenList(userId)
      .then((response: any) => {
        const { leftchildren, rightchildren } = response.data;
        setLeftChildrens(leftchildren.flat());
        setRightChildrens(rightchildren.flat());
        setTripodOptionLoading(false);
        setSevenpodOptionLoading(false);
      })
      .catch((error: any) => {
        console.error("Error occurred:", error);
        message.error("Failed to fetch children list. Please try again.");
      });
  };

  const getChildrenOfLeft = (value: any) => {
    if (value != null || value == "") {
      setSubLeftChildrenLoding(true);
      sevenpodForm.resetFields(['leftId1', 'rightId1']);
      getChildrenList(value)
        .then((response: any) => {
          const { leftchildren, rightchildren } = response.data;
          setChildrenLeft1(leftchildren.flat());
          setChildrenRight1(rightchildren.flat());
          setSubLeftChildrenLoding(false);
        })
        .catch((error: any) => {
          setSubLeftChildrenLoding(false);
          console.error("Error occurred:", error);
          message.error("Failed to fetch children list. Please try again.");
        });
    }
  };

  const getChildrenOfRight = (value: any) => {
    if (value != null || value == "") {
      setSubRightChildrenLoding(true);
      sevenpodForm.resetFields(['leftId2', 'rightId2']);
      getChildrenList(value)
        .then((response: any) => {
          const { leftchildren, rightchildren } = response.data;
          setChildrenLeft2(leftchildren.flat());
          setChildrenRight2(rightchildren.flat());
          setSubRightChildrenLoding(false);

        })
        .catch((error: any) => {
          setSubRightChildrenLoding(false);

          console.error("Error occurred:", error);
          message.error("Failed to fetch children list. Please try again.");
        });
    }
  };



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) =>
        `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Status",
      key: "Status",
      render: (_: any, record: any) => (
        <div className="d-flex">
          <p
            className={`rounded-md ${record.tripod === 0 || record.sevenPod === 0
              ? "text-gray-500"
              : record.tripod === 1 || record.sevenPod === 1
                ? "cursor-not-allowed text-orange-500 opacity-50"
                : record.tripod === 2 || record.sevenPod === 2
                  ? "text-blue-500"
                  : "text-green-500"
              }`}
          >
            {record.tripod === 0 && record.sevenPod === 0
              ? "Not Applied"
              : record.tripod === 1 || record.sevenPod === 1
                ? "Pending from Admin Approval"
                : record.tripod === 2 || record.sevenPod === 2
                  ? "Approved"
                  : "Not Applied"}
          </p>

        </div>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="d-flex">
          <button
            className={`bg-orange-500 text-white px-3 py-2 rounded-md ${(record.sevenPod === 1 || record.tripod === 1 || record.sevenPod === 2 || record.tripod === 2) ? "cursor-not-allowed opacity-50" : ""
              }`}
            onClick={() => openModal(record, 1, "tripod")}
            // onClick={() => setisTripodModalOpen(true)}
            disabled={record.sevenPod === 1 || record.tripod === 1 || record.sevenPod === 2 || record.tripod === 2}
          >
            Tripod
          </button>

          <button
            className={`bg-orange-500 text-white px-3 py-2 rounded-md ml-2 ${(record.sevenPod === 1 || record.tripod === 1 || record.sevenPod === 2 || record.tripod === 2) ? "cursor-not-allowed opacity-50" : ""
              }`}
            onClick={() => openModal(record, 2, "sevenpod")}
            // onClick={() => setisSevenpodModalOpen(true)}
            disabled={record.sevenPod === 1 || record.tripod === 1 || record.sevenPod === 2 || record.tripod === 2}
          >
            SevenPod
          </button>
        </div>

      ),
    },
    {
      title: "Tripod / Sevenpod",
      key: "ApprovedPod",
      render: (_: any, record: any) => (
        <div className="d-flex text-center">
          {record.tripod === 2
            ? <p className="text-green-500 text-center">Tripod</p>
            : record.sevenPod === 2
              ? <p className="text-green-500 text-center">SevenPod</p>

              : <p className="text-gray-500 text-center">
                {record.tripod === 1
                  ? <p className="text-red-500 text-center">Tripod </p>
                  : record.sevenPod === 1
                    ? <p className="text-red-500 text-center">SevenPod </p>
                    : "Not Applied"
                }
              </p>
          }
        </div>
      ),
    },
  ];

  const handleTripodModalOk = async (values: any) => {
    if (mainId !== null) {
      try {
        const { leftId, rightId } = values;
        const userId = localStorage.getItem("customerId") || "";
        const submitedId = userId.replace(/"/g, "");

        const data = {
          mainId,
          submitedId,
          leftId,
          rightId,
        };
        setTripodLoading(true);

        // Submit the data
        await submitTripodData(data);
        fetchCustomerData(currentPage, searchText);

        tripodForm.resetFields();
        setisTripodModalOpen(false);
        setisSevenpodModalOpen(false);

        // Show success notification
        notification.success({
          message: "Data submitted successfully.",
          description: "Your data has been submitted successfully.",
          icon: <MdCheckBox style={{ color: "#52c41a" }} />,
        });
      } catch (error) {
        // Handle validation error or other error
        console.error("Error submitting data:", error);
        message.error("Please fill in all required fields.");
      } finally {
        setTripodLoading(false);
        setSevenpodLoading(false);
      }
    } else {
      message.error("Please select values for all dropdowns.");
    }
  };

  const handleSevenpodModalOk = async (values: any) => {
    if (mainId !== null) {
      const userId = localStorage.getItem("customerId") || "";
      const submitedId = userId.replace(/"/g, "");

      const { leftId1, rightId1, leftId2, rightId2, leftId, rightId } = values;

      const data = {
        mainId,
        submitedId,
        leftId1: leftId,
        rightId1: rightId,
        leftId2: leftId1,
        rightId2: rightId1,
        leftId3: leftId2,
        rightId3: rightId2,
      }

      try {
        let result;

        setSevenpodLoading(true);
        result = await submitSevenPodData(data).then((response: any) => {
          fetchCustomerData(currentPage, searchText);
        });

        fetchCustomerData(currentPage, searchText);
        sevenpodForm.resetFields();
        setisSevenpodModalOpen(false);
        notification.success({
          message: "Data submitted successfully.",
          description: "Your data has been submitted successfully.",
          icon: <MdCheckBox style={{ color: "#52c41a" }} />,
        });
      } catch (error) {
        message.error("Failed to submit data.");
      } finally {
        setisSevenpodModalOpen(false);
        setSevenpodLoading(false);
      }
    } else {
      message.error("Please select values for all dropdowns.");
    }
  };

  const handleModalCancel = () => {
    sevenpodForm.resetFields();
    tripodForm.resetFields();
    setLeftChildrens([]);
    setRightChildrens([]);
    setChildrenLeft1([]);
    setChildrenRight1([]);
    setChildrenLeft2([]);
    setChildrenRight2([]);
    // setIsModalOpen(false);
    setisTripodModalOpen(false);
    setisSevenpodModalOpen(false);
  };

  return (
    <div className="tripod-component">
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={16} xxl={16}>
          <span className="text-black text-lg font-bold">
            Tripod Declaration
          </span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={15}
          xl={8}
          xxl={8}
          className="d-flex justify-end items-center"
        >
          <Input
            placeholder="Search By Name, Username"
            prefix={<SearchOutlined className="mr-1" />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            allowClear
            size="large"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={customerData}
        rowKey={(record) => record.userId}
        loading={loading}
        pagination={{
          current: currentPage,
          total: totalPages * 10,
          onChange: (page) => setCurrentPage(page),
        }}
      />

      <Modal className="w-3/4" title={`Tripod - ${modalUser?.username}`} visible={isTripodModalOpen} footer={null}
        closeIcon={
          <RiCloseLine
            className="cursor-pointer"
            onClick={handleModalCancel}
            style={{ fontSize: "20px", color: "black" }}
          />
        }
      >
        <Spin spinning={tripodLoading}>
          {modalUser && (
            <>
              <Form
                className="bg-white"
                form={tripodForm}
                onFinish={(values) => {
                  handleTripodModalOk(values);
                }}
              >
                <hr className="w-full border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-700 mt-3 mb-3" />

                <div className="d-flex">
                  <div className={`w-1/2 p-1`}>
                    <p className="mr-2 mb-1">Left User</p>
                    <Form.Item
                      name="leftId"
                      rules={[{ required: true, message: "Please Select Left User" }]}
                    >
                      <Select
                        placeholder="Select Left User"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={tripodOptionLoading}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                        className="mr-2"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        {leftChildrens && leftChildrens.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="w-1/2 p-1">
                    <p className="mr-2 mb-1">Right User</p>
                    <Form.Item
                      name="rightId"
                      rules={[{ required: true, message: "Please Select Right User" }]}
                    >
                      <Select
                        placeholder="Select Right User"
                        style={{ width: "100%" }}
                        size="large"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={tripodOptionLoading}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {rightChildrens && rightChildrens.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="d-flex justify-end pt-10">
                  <Button
                    className="rounded"
                    htmlType="submit"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      marginRight: "1rem",
                    }}
                    loading={tripodLoading || sevenpodLoading}
                  >
                    {tripodLoading ? "Submitting..." : "Yes"}
                  </Button>
                  <Button
                    onClick={handleModalCancel}
                    className="rounded bg-red-500 text-white"
                    disabled={tripodLoading}
                  >
                    No
                  </Button>
                </div>
              </Form>

            </>
          )}
        </Spin>

      </Modal>

      <Modal className="w-3/4" title={`Tripod - ${modalUser?.username}`} visible={isSevenpodModalOpen} footer={null}
        closeIcon={
          <RiCloseLine
            className="cursor-pointer"
            onClick={handleModalCancel}
            style={{ fontSize: "20px", color: "black" }}
          />
        }
      >
        <Spin spinning={sevenpodLoading}>
          {modalUser && (
            <>
              <Form
                form={sevenpodForm}
                className="bg-white"
                onFinish={(values) => {
                  handleSevenpodModalOk(values);
                }}
              >
                <hr className="w-full border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-700 mt-3 mb-3" />

                <div className={`w-full p-1`}>
                  <p className="mr-2 mb-1">Left User</p>
                  <Form.Item
                    name="leftId"
                    rules={[{ required: true, message: "Please Select Left User" }]}
                  >
                    <Select
                      placeholder="Select Left User"
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      loading={sevenpodOptionLoading} // Show loading state in Select

                      filterOption={(input: any, option: any) =>
                        option?.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        getChildrenOfLeft(value);
                      }}
                      className="mr-2"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      {leftChildrens && leftChildrens.map((child: any) => (
                        <Option
                          key={child._id}
                          value={child._id}
                          label={`${child.firstName} - ${child.username}`}
                        >
                          {child.firstName} - {child.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="d-flex">
                  <div className={`w-1/2 p-1`}>
                    <p className="mr-2 mb-1">Left User</p>
                    <Form.Item
                      name="leftId1"
                      rules={[{ required: true, message: "Please Select Left User" }]}
                    >
                      <Select
                        placeholder="Select Left User"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={subLeftChildrenLoding}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                        className="mr-2"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        {childrenLeft1 && childrenLeft1.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="w-1/2 p-1">
                    <p className="mr-2 mb-1">Right User</p>
                    <Form.Item
                      name="rightId1"
                      rules={[{ required: true, message: "Please Select Right User" }]}
                    >
                      <Select
                        placeholder="Select Right User"
                        style={{ width: "100%" }}
                        size="large"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={subLeftChildrenLoding}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {childrenRight1 && childrenRight1.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>


                <hr className="w-full border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-700 mt-3 mb-3" />

                <div className={`w-full p-1`}>
                  <p className="mr-2 mb-1">Right User</p>
                  <Form.Item
                    name="rightId"
                    rules={[{ required: true, message: "Please Select Right User" }]}
                  >
                    <Select
                      placeholder="Select Right User"
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      onChange={getChildrenOfRight}
                      loading={sevenpodOptionLoading} // Show loading state in Select
                      filterOption={(input: any, option: any) =>
                        option?.label.toLowerCase().includes(input.toLowerCase())
                      }
                      className="mr-2"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      {rightChildrens && rightChildrens.map((child: any) => (
                        <Option
                          key={child._id}
                          value={child._id}
                          label={`${child.firstName} - ${child.username}`}
                        >
                          {child.firstName} - {child.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>


                <div className="d-flex">
                  <div className={`w-1/2 p-1`}>
                    <p className="mr-2 mb-1">Left User</p>
                    <Form.Item
                      name="leftId2"
                      rules={[{ required: true, message: "Please Select Left User" }]}
                    >
                      <Select
                        placeholder="Select Left User"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={subRightChildrenLoding}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                        className="mr-2"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        {childrenLeft2 && childrenLeft2.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="w-1/2 p-1">
                    <p className="mr-2 mb-1">Right User</p>
                    <Form.Item
                      name="rightId2"
                      rules={[{ required: true, message: "Please Select Right User" }]}
                    >
                      <Select
                        placeholder="Select Right User"
                        style={{ width: "100%" }}
                        size="large"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        loading={subRightChildrenLoding}
                        filterOption={(input: any, option: any) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {childrenRight2 && childrenRight2.map((child: any) => (
                          <Option
                            key={child._id}
                            value={child._id}
                            label={`${child.firstName} - ${child.username}`}
                          >
                            {child.firstName} - {child.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>



                <div className="d-flex justify-end pt-10">
                  <Button
                    className="rounded"
                    htmlType="submit"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      marginRight: "1rem",
                    }}
                    loading={tripodLoading || sevenpodLoading}
                  >
                    {tripodLoading ? "Submitting..." : "Yes"}
                  </Button>
                  <Button
                    onClick={handleModalCancel}
                    className="rounded bg-red-500 text-white"
                    disabled={tripodLoading}
                  >
                    No
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Spin>

      </Modal>

    </div>
  );
};

export default Tripod;