import React, { useContext, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaRegHeart,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import {
  AiOutlineDelete,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { IoSearchOutline, IoSearchSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
// import emptycart from "../../assets/frontendassets/emptycart.png";
import emptycart from "../../assets/frontendassets/emptycart2.png";
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Modal,
  Grid,
  notification,
  message,
  Form,
} from "antd";
import { MdMic } from "react-icons/md";
import { BiCheck, BiLogIn, BiUser } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import imagelogo from "../../assets/frontendassets/logo.png";
import {
  getAllcategories,
  getproductsize,
  GetSettingData,
} from "../../utils/API";

import { useCart } from "../../utils/Cartcontext";
import { AuthContext } from "../Auth/AuthContext";
import Login from "../frontend/Cart/LoginModal";
const { useBreakpoint } = Grid;

interface Size {
  _id: string;
  name: string;
}

const HeaderUser = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [sizes, setSizes] = useState<any>([]);
  const [logo, setLogo] = useState<string | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { xs, sm, md, lg } = useBreakpoint();
  const imageUrl = `${process.env.REACT_APP_Image_URL}/products/`;
  const settingImageUrl = `${process.env.REACT_APP_Image_URL}/settings/`;
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [cartDataForCartPage, setCartDataForCartPage] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [redirectToWishlist, setRedirectToWishlist] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const SettingData = async () => {
      try {
        const response = await GetSettingData();
        if (
          response.settings &&
          response.settings[0] &&
          response.settings[0].logo
        ) {
          setLogo(response.settings[0].logo);
        }
      } catch (error) {
        console.error("Error fetching setting data:", error);
      }
    };

    SettingData();
  }, []);

  const handleMicClick = () => {
    console.log(searchValue);
    if (searchValue) {
      setSearchValue("");
      navigate(`/products?search=${searchValue}`, { state: { searchValue } });
      setIsModalVisible(false);
    }
  };

  const handleVoiceInput = () => {
    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.onresult = (event: any) => {
      const spokenText = event.results[0][0].transcript;
      setSearchValue(spokenText);
      navigate(`/products?search=${spokenText}`, {
        state: { searchValue: spokenText },
      });
      setIsModalVisible(false);
    };
    recognition.start();
  };

  const { cart: cartContext, updateQuantity, removeFromCart } = useCart();
  const { authData, setAuthData } = useContext(AuthContext);
  const cartData = authData.roleId
    ? localStorage.getItem("cart_userid")
    : localStorage.getItem("cart");
  const cart = cartData ? JSON.parse(cartData) : [];

  const handleLogout = () => {
    setAuthData({ token: null, roleId: null });
    const cart = localStorage.getItem("cart");
    const cart_userid = localStorage.getItem("cart_userid");
    localStorage.clear();
    if (cart) localStorage.setItem("cart", cart);
    if (cart_userid) localStorage.setItem("cart_userid", cart_userid);

    // Navigate to login
    navigate("/login");
  };

  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const response = await getproductsize();
        setSizes(response.data.sizes);
      } catch (error) {
        message.error("Error fetching sizes");
      }
    };

    fetchSizes();
    GetSettingData();
  }, []);

  const getSizeName = (sizeId: any) => {
    const size = sizes.find((size: any) => size._id === sizeId);
    return size ? size.name : "Unknown Size";
  };

  const handleProceedToCart = () => {
    setCartDataForCartPage(cart);

    setIsDropdownVisible(false);
    navigate("/cart", { state: { cartData: cart } });
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const showwhatsappmessagemodal = () => {
    if (authData.roleId) {
      navigate("/wishlist");
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleDropdownVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsDropdownVisible(visible);
  };

  const showModal = () => {
    setIsModalVisible(true);
    setIsInputVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsInputVisible(false);
    setIsLoginModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsInputVisible(false);
    setIsLoginModalVisible(false);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleSearchInput = () => {
    setShowSearchInput(!showSearchInput);
  };

  const [loading, setLoading] = useState(true);

  const loginMenu = (
    <>
      {authData.roleId ? (
        <>
          <Menu>
            <Menu.Item key="profile">
              <Link to="/backend/kyc">Profile</Link>
            </Menu.Item>
            <Menu.Item key="dashboard">
              <Link to="/backend/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="logout">
              <span onClick={handleLogout}>Logout</span>
            </Menu.Item>
          </Menu>
        </>
      ) : (
        <Link to="/login">
          <button className="pt-2 pb-2 pl-4 pr-4 rounded-sm bg-orange-500 text-white m-2 d-flex align-items-center justify-content-center text-lg">
            <BiLogIn size={15} className="mr-2" /> Login or Signup
          </button>
        </Link>
      )}
    </>
  );

  const SubcategoryMenu: React.FC<{ subcategories: any[] }> = ({
    subcategories,
  }) => {
    if (subcategories.length === 0) return null; // Skip rendering if there are no subcategories

    return (
      <Menu>
        {subcategories.map((subcategory: any) => (
          <Menu.Item
            key={subcategory._id}
            className="hover:text-orange-500"
            onMouseLeave={() => setMenuVisible(false)}
          >
            <Link to={`/products/${subcategory._id}`}>{subcategory.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const getTotalPrice = () => {
    return cart
      .reduce(
        (total: any, item: any) =>
          total + item.product.discountedPrice * item.quantity,
        0
      )
      .toFixed(2);
  };

  const getTotalIPPoints = () => {
    return cart.reduce(
      (total: any, item: any) => total + item.product.ipQty * item.quantity,
      0
    );
  };

  const handleUpdateQuantity = (
    productId: any,
    size: any,
    newQuantity: any
  ) => {
    if (newQuantity > 0) {
      notification.open({
        message: "Success",
        icon: <BiCheck style={{ color: "#52c41a" }} />,
        description: "Cart updated successfully!",
        placement: "topRight",
        duration: 3, // duration in seconds, set to null to disable auto close
      });
      updateQuantity(productId, size, newQuantity);
    } else {
      removeFromCart(productId, size);
      notification.open({
        message: "Success",
        description: "Cart updated successfully!",
        placement: "topRight",
        duration: 3, // duration in seconds, set to null to disable auto close
      });
    }
  };

  const isHiddenOnSmallScreens = "hidden lg:flex";

  useEffect(() => {
    getAllcategories("").then((res) => {
      setCategories(res);
    });
  }, []);

  const handleLoginSuccess = () => {
    setIsLoginModalVisible(false);
    navigate("/wishlist");
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlewhatsappLink = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=919818755350&text=";
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Modal
        title="Search"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          suffix={
            <>
              <IoSearchOutline
                onClick={() => {
                  handleMicClick();
                }}
                style={{ cursor: "pointer" }}
                className="float-left"
                size={20}
              />
              <MdMic
                onClick={() => {
                  handleVoiceInput();
                }}
                style={{ cursor: "pointer" }}
                size={20}
              />
            </>
          }
          className="border-gray-200 rounded-full pr-4"
          placeholder="Search "
          allowClear
          size="large"
          onPressEnter={handleMicClick}
        />
      </Modal>
      <Modal
        footer={null}
        title=""
        closable={false}
        visible={isLoginModalVisible}
        onCancel={() => setIsLoginModalVisible(false)}
      >
        <Login
          onSuccess={handleLoginSuccess}
          onClose={() => setIsLoginModalVisible(false)}
        />
      </Modal>
      <div className="fixed z-50 top-0 left-0 right-0 ">
        <div
          className={`transition-all duration-500 ${
            isScrolled ? "hidden" : "block"
          } bg-white justify-end`}
          style={{ fontSize: "5px !important" }}
        >
          <Row
            justify={"end"}
            style={{ fontSize: "5px !important" }}
            className=" bg-white pt-2 pb-2 "
          >
            <Col
              sm={0}
              xs={0}
              md={4}
              lg={3}
              xl={2}
              xxl={2}
              className="d-flex justify-end align-items-center"
            >
              <Link
                to="/aboutus"
                className="pr-3  hover:text-gray-500 text-gray-400 text-xs font-semibold 
              hidden lg:block   "
              >
                About Rapidexie
              </Link>
            </Col>
            <Col
              sm={0}
              xs={0}
              md={2}
              lg={2}
              xl={1}
              xxl={1}
              className="d-flex justify-center align-items-center"
            >
              <Link
                to="/blog"
                className="pr-3  hover:text-gray-500 text-gray-400 text-xs font-semibold 
              hidden lg:block"
              >
                Blog
              </Link>
            </Col>
            <Col
              sm={0}
              xs={0}
              md={4}
              lg={2}
              xl={1}
              xxl={1}
              className="d-flex justify-center align-items-center"
            >
              <Link
                to="/products"
                className="pr-3  hover:text-gray-500 text-gray-400 text-xs font-semibold 
              hidden lg:block   "
              >
                Products
              </Link>
            </Col>
            <Col
              sm={0}
              xs={0}
              md={4}
              lg={2}
              xl={2}
              xxl={2}
              className="d-flex justify-center align-items-center"
            >
              <Link
                to="#testimonial-section"
                onClick={() => {
                  const testimonialSection = document.getElementById(
                    "testimonial-section"
                  );
                  if (testimonialSection) {
                    testimonialSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="pr-3 hover:text-gray-500 text-gray-400 text-xs font-semibold hidden lg:block xl:block"
              >
                Testimonials
              </Link>
            </Col>

            <Col
              sm={24}
              xs={24}
              md={6}
              lg={5}
              xl={5}
              xxl={5}
              className="d-flex align-items-center xl:justify-start lg:justify-start md:justify-start justify-end "
            >
              <div className="d-flex align-items-center justify-end">
                <div className="border-l-1 border-gray-600 p-2 md:pt-4  lg:mt-2 xl:pt-2  " />
                <a className="mr-3 text-gray-400 font-semibold text-xs"></a>
                <a
                  href="https://www.instagram.com/rapidexie/"
                  className="mr-3 text-gray-400 font-semibold text-xs"
                >
                  <FaInstagram size={15} />
                </a>
                <a
                  href="https://twitter.com/rapidexie"
                  className="mr-3 text-gray-400 font-semibold text-xs"
                >
                  {" "}
                  <FaTwitter size={15} />{" "}
                </a>
                <a
                  href="https://www.facebook.com/rapidexie"
                  className="mr-3 text-gray-400 font-semibold text-xs"
                >
                  {" "}
                  <FaFacebookF size={15} />{" "}
                </a>
                <a
                  href="https://www.linkedin.com/company/rapidexie/"
                  className="mr-3 text-gray-400 font-semibold text-xs"
                >
                  <FaLinkedin size={15} />{" "}
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <header className="  bg-white md:px-0 md:ps-0 sm:px-0 sm:ps-0 xs:px-0 xs:ps-0  ">
          <hr className=" border-gray-400 sm:px-auto dark:border-gray-400 lg:py-1" />
          <div className="">
            <Row className="pl-4  items-center  lg:ps-4 xl:px-4 xl:ps-0  md:px-5 md:py-5 xs:px-5 xs:ps-5 shadow-s- sm:px-5 sm:ps-5 pt-2 pb-3 xl:pl-10 lg:pl-10">
              {(xs || sm) && !md && (
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  lg={0}
                  xl={0}
                  xxl={0}
                  className="pr-2 d-flex justify-center"
                >
                  <button
                    onClick={toggleMenu}
                    className="block btn btn-lg xl:hidden"
                  >
                    <AiOutlineMenu size={15} />
                  </button>
                </Col>
              )}

              <Col
                xs={9}
                sm={9}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="xl:pl-10"
              >
                <Link to="/">
                  <img
                    src={logo ? settingImageUrl + logo : imagelogo}
                    alt="Logo"
                  />
                </Link>
              </Col>
              <Col
                xs={3}
                sm={3}
                md={9}
                lg={9}
                xl={10}
                xxl={9}
                className="pl-4 d-flex w-full"
              >
                <Input
                  suffix={
                    <>
                      <IoSearchOutline
                        onClick={handleMicClick}
                        style={{ cursor: "pointer" }}
                        className="float-left"
                        size={20}
                      />
                      <MdMic
                        onClick={handleVoiceInput}
                        style={{ cursor: "pointer" }}
                        size={20}
                      />
                    </>
                  }
                  className={`h-14 border-gray-200 rounded-full w-full pr-4 ${isHiddenOnSmallScreens}`}
                  placeholder="Search products ...."
                  allowClear
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onPressEnter={handleMicClick}
                />
              </Col>

              <Col xs={9} sm={9} md={9} lg={9} xl={7} xxl={7}>
                <Row className=" items-center text-base  align-items-center justify-center sm:justify-end">
                  <Col
                    xs={5}
                    sm={3}
                    md={3}
                    lg={0}
                    xl={0}
                    xxl={0}
                    className="  "
                  >
                    <IoSearchSharp onClick={showModal} size={25} />
                  </Col>
                  <Col
                    xs={0}
                    sm={0}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={10}
                    className=" hover:text-gray-500 text-gray-400 hidden   lg:block xl:block font-semibold ms-2 text-xs"
                  >
                    <Row>
                      <Col
                        span={4}
                        className="d-flex align-items-center"
                        style={{ marginTop: "3px" }}
                      >
                        <FaWhatsapp
                          size={25}
                          className=" xl:hidden block md:block lg:hidden  cursor-pointer text-gray-400 "
                          color="black"
                          onClick={handlewhatsappLink}
                        />
                        <FaWhatsapp
                          size={40}
                          className="text-gray-400 hidden xl:block lg:block"
                          color="black"
                          onClick={handlewhatsappLink}
                        />
                      </Col>
                      <Col span={20} className="d-flex align-items-center ">
                        <Row
                          className="font-bold align-items-center cursor-pointer"
                          onClick={handlewhatsappLink}
                        >
                          <Col span={24} className="text-orange-500">
                            <h6 className="sm:text-xs xl:text-sm xl:pl-1">
                              {" "}
                              Chat now
                            </h6>
                          </Col>
                          <Col
                            span={24}
                            className="sm:text-xs xl:text-lg lg:text-base text-black"
                          >
                            +919818755350
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={5}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    className=" text-black font-semibold text-xs"
                  >
                    <Dropdown overlay={loginMenu} trigger={["hover"]}>
                      <BiUser size={25} />
                    </Dropdown>
                  </Col>
                  <Col
                    xs={5}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    className=" text-black font-bold text-xs"
                  >
                    <FaRegHeart
                      onClick={showwhatsappmessagemodal}
                      className="font-bold"
                      size={25}
                    />
                  </Col>
                  <Col xs={5} sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <div className="relative">
                      <a
                        onClick={(e) => {
                          toggleDropdown();
                        }}
                        className="text-gray-400 font-semibold text-xs"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <span
                          className="text-gray-400 bg-orange-500 font-semibold"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            margin: "-6px",
                            marginLeft: "15px",
                            color: "white",
                            borderRadius: "50%",
                            padding: "2px 5px",
                          }}
                        >
                          {cart.length}
                        </span>
                        <AiOutlineShoppingCart
                          color="black"
                          className="cursor-pointer mt-1 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
                          size={30}
                        />
                      </a>

                      {isDropdownVisible && (
                        <div
                          className="absolute bg-white border shadow-lg mt-2 right-0 z-50 overflow-hidden w-80 md:w-96"
                          onMouseLeave={() => setIsDropdownVisible(false)}
                        >
                          <div className="bg-black text-white p-3">
                            <div className="text-lg font-semibold">My Cart</div>
                          </div>
                          <div
                            className="overflow-y-auto"
                            style={{ maxHeight: "400px" }}
                          >
                            {cart.length > 0 ? (
                              <>
                                <div className="p-3 text-md border-b">
                                  <div className="flex justify-between">
                                    <div>Sub Total</div>
                                    <div>₹{getTotalPrice()}</div>
                                  </div>
                                  <div className="flex justify-between">
                                    <div>Delivery Charges</div>
                                    <div>₹0</div>
                                  </div>
                                </div>
                                <div className="p-3 border-b d-flex justify-between">
                                  <div>Total IP points</div>
                                  <div>{getTotalIPPoints()}</div>
                                </div>
                              </>
                            ) : null}

                            {cart.length > 0 ? (
                              <>
                                {cart.map((item: any, index: any) => (
                                  <div
                                    key={index}
                                    className="flex justify-between items-start p-2 border-b"
                                  >
                                    <img
                                      src={imageUrl + item.product.images[0]}
                                      alt={item.product.name}
                                      className="w-16 h-16 object-cover d-flex align-items-start"
                                    />
                                    <div className="flex-1 ml-2">
                                      <div className="text-white d-flex bg-orange-500 p-1 text-xs w-24 justify-center">
                                        IP Point: {item.product.ipQty}
                                      </div>
                                      <Link
                                        to={`/product/${item.product.slug}`}
                                        className="hover:text-gray-500"
                                      >
                                        <div className="font-semibold pt-2 pb-2 text-base">
                                          {item.product.name}
                                        </div>
                                      </Link>
                                      {item.sizeId ? (
                                        <>
                                          <div>
                                            Size:{" "}
                                            <span className="font-bold">
                                              {getSizeName(item.sizeId)}
                                            </span>
                                          </div>
                                        </>
                                      ) : null}
                                      <div className="flex items-center mt-2">
                                        <button
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleUpdateQuantity(
                                              item.product._id,
                                              item.sizeId,
                                              item.quantity - 1
                                            );
                                          }}
                                          className="p-1 border rounded-full text-orange-500"
                                        >
                                          <AiOutlineMinus />
                                        </button>
                                        <p className="mx-2 text-sm">
                                          {item.quantity}
                                        </p>
                                        <button
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleUpdateQuantity(
                                              item.product._id,
                                              item.sizeId,
                                              item.quantity + 1
                                            );
                                          }}
                                          className="p-1 border rounded-full text-orange-500"
                                        >
                                          <AiOutlinePlus />
                                        </button>
                                        <button
                                          onClick={() =>
                                            removeFromCart(
                                              item.product._id,
                                              item.sizeId
                                            )
                                          }
                                          className="text-orange-500 ml-2 mr-2"
                                        >
                                          <AiOutlineDelete size={20} />
                                        </button>
                                        <span className="ml-2 text-black font-semibold">
                                          ₹
                                          {item.product.discountedPrice.toFixed(
                                            2
                                          )}
                                        </span>
                                        <span className="line-through text-gray-400 ml-2">
                                          ₹{item.product.price}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="p-2">
                                <center>
                                <img
                                  src={emptycart}
                                  alt="Empty cart"
                                  className="w-46 h-42"
                                />
                                </center>
                              </div>
                            )}
                          </div>
                          {cart.length > 0 && (
                            <div
                              className="p-3 text-base m-2  rounded-sm flex justify-between items-center bg-orange-500 cursor-pointer text-white"
                              onClick={handleProceedToCart}
                            >
                              <div className="font-semibold text-sm">
                                Proceed to Cart:
                              </div>
                              <div className="font-semibold d-flex align-items-center">
                                ₹{getTotalPrice()}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <hr className=" border-gray-400 sm:px-auto dark:border-gray-400" />
          {menuVisible && (
            <nav className="bg-white border-gray-200 dark:bg-gray-900 d-flex justify-center">
              <div className=" flex flex-col w-90 bg-orange-500 ">
                {categories.map((category) => {
                  // Filter subcategories for the current category
                  const subcategories = categories.filter(
                    (subcategory) => subcategory.categoryId === category._id
                  );

                  if (subcategories.length > 0) {
                    return (
                      <Dropdown
                        overlay={
                          <SubcategoryMenu subcategories={subcategories} />
                        }
                        trigger={["click"]}
                        key={category._id}
                      >
                        <Link
                          to={`/products/${category._id}`}
                          className="text-white text-sm me-3 ms-3 d-flex justify-center mt-2 mb-2"
                          onClick={toggleMenu}
                        >
                          {category.name}{" "}
                          <DownOutlined className="space-x-2" size={5} />
                        </Link>
                      </Dropdown>
                    );
                  } else {
                    // Render simple link if there are no subcategories
                    return (
                      <Link
                        to={`/products/${category._id}`}
                        key={category._id}
                        className="text-white text-sm me-3 ms-3 d-flex justify-center mt-2 mb-2"
                        onClick={toggleMenu}
                      >
                        {category.name}
                      </Link>
                    );
                  }
                })}
              </div>
            </nav>
          )}
        </header>
        <nav className="hidden md:block xl:block xxl:block bg-white border-gray-200 drop-shadow-md dark:bg-gray-900 lg:ps-10 xl:px-36 xl:ps-36 md:px-5 md:py-5 xs:px-5 xs:ps-5 sm:px-0 sm:ps-0">
          <div className="flex flex-wrap items-center justify-between mx-auto font-bold">
            <div className="w-full" id="navbar-dropdown">
              {categories.map((category) => {
                if (!category.categoryId) {
                  const subcategories = categories.filter(
                    (subcategory) => subcategory.categoryId === category._id
                  );
                  return (
                    <Dropdown
                      overlay={
                        <SubcategoryMenu subcategories={subcategories} />
                      }
                      trigger={["hover"]}
                      key={category._id}
                    >
                      <Link
                        to={`/products/${category._id}`}
                        className="text-gray hover:text-orange-500 text-sm me-3 ms-3"
                      >
                        {category.name}
                        {subcategories.length > 0 ? (
                          <DownOutlined
                            style={{ marginLeft: "5px" }}
                            size={5}
                          />
                        ) : null}
                      </Link>
                    </Dropdown>
                  );
                } else {
                  return null; 
                }
              })}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderUser;
