import React, { createContext, useState, ReactNode, useEffect } from 'react';
import CryptoJS from 'crypto-js';

interface AuthData {
  token: any | null;
  roleId: any | null;
}

export interface AuthContextProps {
  authData: AuthData;
  setAuthData: (data: AuthData) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  authData: { token: null, roleId: null },
  setAuthData: () => {},
});

const encryptionKey = 'my-secret-key@123'; // Use a more secure key in a real application

const encryptData = (data: AuthData): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

const decryptData = (ciphertext: string): AuthData => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [authData, setAuthDataState] = useState<AuthData>(() => {
    const storedAuthData = localStorage.getItem('authData');
    return storedAuthData ? decryptData(storedAuthData) : { token: null, roleId: null };
  });

  const setAuthData = (data: AuthData) => {
    setAuthDataState(data);
    const encryptedData = encryptData(data);
    localStorage.setItem('authData', encryptedData);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('authData');
    };
  }, []);

  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};
