import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row, Table, message } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { DownlineUserLogin, getusertree, isFirst, login } from "../../../../utils/API";
import FindGeneology from "./FindGeneology";
import { AuthContext } from "../../../Auth/AuthContext";
import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');  // 16 bytes IV


const encryptData = (data : any) => {
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
  return encrypted.toString();
};

const decryptData = (encryptedData : any) => {
  let decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};


const GeneologySalesTree: React.FC = () => {
  const { userId } = useParams<{ userId: string }>(); // Get userId from URL
  const [productData, setProductData] = useState<any[]>([]);
  const [activeNode, setActiveNode] = useState<any | null>(null);
  const [history, setHistory] = useState<any[]>([]); // History stack
  const [nodeCache, setNodeCache] = useState<{ [key: string]: any }>({}); // Cache for nodes
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (userId) {
      fetchNodeData(userId, true);
    }
  }, [userId]);

  const fetchNodeData = (nodeId: string, isRoot: boolean = false) => {
    if (nodeCache[nodeId]) {
      // If the node data is in the cache, use it
      setProductData([nodeCache[nodeId]]);
      if (isRoot) {
        setActiveNode(null);
      }
    } else {
      getusertree(nodeId)
        .then((response) => {
          setNodeCache((prevCache) => ({
            ...prevCache,
            [nodeId]: response,
          }));
          setProductData([response]);
          if (isRoot) {
            setActiveNode(null);
          }
        })
        .catch((error) => {
          message.error("Network error. Please try again.");
        });
    }
  };

  const handleNodeClick = (node: any) => {
    setHistory((prevHistory) => [...prevHistory, activeNode]);
    setActiveNode(node);
    fetchNodeData(node._id);
    console.log("node", node.username);
    // Call login function
    DownlineUserLogin(node.username)
      .then(async (response : any) => {
        setAuthData({ token: response.data.token, roleId: response.data.others.roleId });
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", response.data.others.username ? response.data.others.username : "");      
        localStorage.setItem("address", response.data.others.address);
        localStorage.setItem("user", JSON.stringify(response.data.others.firstName));
        localStorage.setItem("lastname", JSON.stringify(response.data.others.lastName ? response.data.others.lastName : ""));
        localStorage.setItem("customerId", JSON.stringify(response.data.others._id));
        localStorage.setItem("node_logged_in", encryptData(1)); // Store node logged in info in encrypted format
        const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
        localStorage.setItem("cart_userid", JSON.stringify(cartData));
        await isFirst(response.data.others._id);
        message.success(response.data.message);
        navigate("/backend/kyc");
      })
      .catch((error : any ) => {
        message.error(error.response.data.message);
        console.error("API error:", error);
      });
  };

  const onNodeLoginClick = (node: any) => {
    setHistory((prevHistory) => [...prevHistory, activeNode]);
    setActiveNode(node);
    fetchNodeData(node._id);
  }
  const getPreviousNode = () => {
    const previousNode = history[history.length - 1];
    setHistory((prevHistory) => prevHistory.slice(0, -1));
    return previousNode;
  };

  const handleBackClick = () => {
    const previousNode = getPreviousNode();
    if (previousNode) {
      setActiveNode(previousNode);
      fetchNodeData(previousNode._id);
    } else if (userId) {
      fetchNodeData(userId, true);
    }
  };

  const data = [
    {
      title: "👨‍✈️ CROWN AMBASSADOR",
      points: "37500 Pair points",
    },
    {
      title: "👑 AMBASSADOR",
      points: "25000 Pair points",
    },
    {
      title: "💠 SAPPHIRE",
      points: "12500 Pair points",
    },
    {
      title: "🌻 SUNFLOWER",
      points: "6750 Pair points",
    },
    {
      title: "🌷 LOTUS",
      points: "5000 Pair points",
    },
    {
      title: "🌹 ROSE",
      points: "2500 Pair points",
    },
    {
      title: "🟪 LAVENDER",
      points: "1250 Pair points",
    },
    {
      title: "🌺 LILY",
      points: "250 Pair points",
    },
    {
      title: "🏵️ MARIGOLD",
      points: "125 Pair points",
    },
    {
      title: "🌸 SAFRON",
      points: "50 Pair points",
    },
    {
      title: "💮 JASMINE",
      points: "25 Pair points",
    },
    {
      title: "⚡ Active",
      points: "0-24 Pair points",
    },
  ];

  const DS_data = [
    {
      title: " Without Payment ",
      label: "🔴",
    },
    {
      title: "Blocked",
      label: "❌",
    },
    {
      title: "Payment done",
      label: "🟢",
    },
    {
      title: "Tripod",
      label: "🏰",
    },
    {
      title: "Sevenpod ",
      label: "👨🏻‍👩🏻‍👦🏻‍👦🏻",
    },
  ];

  return (
    <>
      <Row className="m-2" align="middle">
        <Col span={10}>
          <h2 className="text-2xl font-bold">Sales tree</h2>
        </Col>
      </Row>
      <div className="flex justify-between p-3">
        {data.map((item, index) => (
          <div key={index} className="flex-1">
            <div className="bg-white border p-2 text-center h-full">
              <span className="font-bold">{item.title}</span> <br /> {item.points}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between p-3">
        {DS_data.map((item, index) => (
          <div key={index} className="flex-1">
            <div className="bg-white border p-2 text-center h-full">
              <span className="font-bold">{item.title}</span> <br /> {item.label}
            </div>
          </div>
        ))}
      </div>

      <FindGeneology
        onNodeLoginClick={onNodeLoginClick}
        data={productData}
        onNodeClick={handleNodeClick}
        handleBackClick={handleBackClick}
        activeNode={activeNode}
      />
    </>
  );
};

export default GeneologySalesTree;
