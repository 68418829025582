import React, { useContext, useState } from "react";
import { Row, Col, Card, Table, Input, message, Form, DatePicker } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { AuthContext } from "../../../Auth/AuthContext";
import moment from "moment";
import { updateNeftDetails } from "../../../../utils/API";
import dayjs from "dayjs";

const CurrentFinancialYearData: React.FC = () => {
  const location = useLocation();
  const userData = location.state ? location.state.userData : null;
  
  const address = userData ? userData.address : "";
  const [form] = Form.useForm();
  const toDate = new Date(userData.incentiveDate);
  const day = toDate.getDate().toString().padStart(2, '0');
  const month = (toDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = toDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const fromDate = new Date(userData.fromDate);
  const day1 = fromDate.getDate().toString().padStart(2, '0');
  const month1 = (fromDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year1 = fromDate.getFullYear();
  const formattedDate1 = `${day1}-${month1}-${year1}`;
  const { authData } = useContext(AuthContext);
  const [neftDetail, setNeftDetail] = useState(userData.neftDetails || "");
  const [neftDetailUpdated, setNeftDetailUpdated] = useState(userData.neftDetails !== "");
  const [neftDate, setNeftDate] = useState<any>(
    userData.neftDate ? dayjs(userData.neftDate) : dayjs()
  );

  const navigate = useNavigate();
  const handleUpdate = async () => {

    const customerId = localStorage.getItem("customerId");
    const userid = customerId?.replace(/"/g, "");

    try {
      const data = {
        entryBy: userid,
        _id: userData._id,
        neftDetails: neftDetail,
        neftDate: neftDate.format("YYYY-MM-DD") // Format date for API
      };
      const response = await updateNeftDetails(data);
      message.success("Neft details updated successfully");
      setNeftDetailUpdated(true); // Set to true after successful update
      navigate("/backend/account-details/current");
    } catch (error) {
      message.error('Error updating NEFT details:');
    }
  };

  const salesData = userData
    ? {
        newPoint: userData.newPoint,
        broughtPoint: userData.broughtPoint,
        totalPoint: userData.totalPoint,
        calculatedPoint: userData.calculatedPoint,
        carriedPoint: userData.carriedPoint,
        netPoint: userData.netPoint,
      }
    : {};

    const handleDateChange = (date : any, dateString : any) => {
      if (date) {
        setNeftDate(date);
      } else {
        // Set to today's date if date is cleared
        setNeftDate(dayjs());
      }
    };

  const salesRows = [
    {
      key: "newPoint",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>New Point</div>,
      left: salesData.newPoint?.left,
      right: salesData.newPoint?.right,
    },
    {
      key: "broughtPoint",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Brought Forwarded Point</div>,
      left: salesData.broughtPoint?.left,
      right: salesData.broughtPoint?.right,
    },
    {
      key: "totalPoint",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Total Point</div>,
      left: salesData.totalPoint?.left,
      right: salesData.totalPoint?.right,
    },
    {
      key: "calculatedPoint",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Calculated Point</div>,
      left: salesData.calculatedPoint?.left,
      right: salesData.calculatedPoint?.right,
    },
    {
      key: "carriedPoint",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Carried Forwarded Point</div>,
      left: salesData.carriedPoint?.left,
      right: salesData.carriedPoint?.right,
    },
    {
      key: "neftdate",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>NEFT Date</div>,
      
        left: authData.roleId === 1 ? (
          <DatePicker
            format="YYYY-MM-DD"
            value={dayjs(neftDate)}
            onChange={handleDateChange}
            style={{ marginRight: "10px" }}
            allowClear
          />
        ) : (
          userData.neftDate ? dayjs(userData.neftDate).format("DD-MM-YYYY") : "-"
        ),
      right: "",
    
  },
    {
      key: "neftdetails",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>NEFT Details</div>,
      left: authData.roleId === 1 ? (
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col xs={24} sm={24} md={10} className="d-flex justify-content-center align-items-center me-4 bg-white mb-2">
              <Form form={form} initialValues={{ neftDetails: neftDetail }}>
                <Form.Item
                  className="d-flex justify-content-center align-items-center me-4 bg-white mb-2"
                  name="neftDetails"
                  rules={[{ required: true, message: "Please enter NEFT Details" }]}
                >
                  <Input
                    value={neftDetail}
                    className="rounded border border-1"
                    onChange={(e) => setNeftDetail(e.target.value)}
                  />
                </Form.Item>
              </Form>
              </Col>
              <Col xs={24} sm={24} md={10} className="d-flex justify-content-center me-4 bg-white mb-2">
                <button
                  className={`d-flex justify-content-center px-3 align-items-center border  rounded ${neftDetailUpdated ? "cursor-not-allowed bg-orange-500" : "bg-orange-500"}`}
                  onClick={handleUpdate}
                  style={{ marginLeft: "10px" }}
                >
                  Update
                </button>
              </Col>
            </Row>
          </Col>
      ) : (
        userData.neftDate ? moment(userData.neftDate).format("DD-MM-YYYY") : "-"
      ),
      right: "",
    },
  
  ];

  const incentiveRows = [
    {
      key: "groupSales",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Group Sales incentives</div>,
      amount: userData.groupSales,
    },
    {
      key: "directSales",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Direct Sales incentives</div>,
      amount: userData.directSales,
    },
    {
      key: "leadershipBonus",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Leadership Bonus </div>,
      amount: userData.leadershipBonus,
    },
    {
      key: "clubBonus",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Car Club Bonus </div>,
      amount: userData.carClub,
    },
    {
      key: "travelClub",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Travel Club Bonus </div>,
      amount: userData.travelClub,
    },
    {
      key: "houseClub",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>House Club Bonus </div>,
      amount: userData.houseClub,
    },
    {
      key: "premiumClub",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Premium Club Bonus </div>,
      amount: userData.premiumClub,
    },
    {
      key: "totalEarning",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Total Earning</div>,
      amount: userData.totalEarning,
    },
    {
      key: "tds",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>TDS</div>,
      amount: userData.groupSales === 0 ? 0 : (userData.tds / 100) * userData.totalEarning,
    },
    {
      key: "otherCharges",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Other Charges</div>,
      amount: userData.otherCharges,
    },
    {
      key: "recovery",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Recovery</div>,
      amount: userData.recovery,
    },
    {
      key: "netEarning",
      label: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Net Incentive</div>,
      amount: userData.netEarning,
    },
  ];

  const mainColumns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "13px", color: "#a6a6a6" }}>Sales Calculation</div>,
      dataIndex: "label",
      key: "label",
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "13px", color: "#a6a6a6" }}>Left</div>,
      dataIndex: "left",
      key: "left",
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "13px", color: "#a6a6a6" }}>Right</div>,
      dataIndex: "right",
      key: "right",
    },
  ];

  const incentiveColumns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "13px", color: "#a6a6a6" }}>Incentive Calculation</div>,
      dataIndex: "label",
      key: "incentiveItem",
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "13px", color: "#a6a6a6" }}>Amount</div>,
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Incentive Statement</h2>
        </Col>

        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/account-details/current"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <h2 className="text-xl font-semibold m-2 pl-4">
            Name: {userData ? userData.userId.firstName : ""} {
              userData ? userData.userId.lastName : ""}
          </h2>
          <h2 className="text-xl font-semibold m-2 pl-4">Address: { userData ? userData.userId.address : ""}</h2>
          <h2 className="text-xl font-semibold m-2 pl-4">
            Incentive Date: {formattedDate1} - {formattedDate}
          </h2>

          <Card className="bg-white border" style={{ margin: "1%" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }}>
                <Row
                  className="m-2"
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  Sales Calculation
                </Row>
                <Table
                  columns={mainColumns}
                  dataSource={salesRows}
                  pagination={false}
                />
              </div>
            </Col>
          </Card>
          <Card className="bg-white border" style={{ margin: "1%" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }}>
                <Row
                  className="m-2"
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  Incentive Calculation
                </Row>
                <Table
                  columns={incentiveColumns}
                  dataSource={incentiveRows}
                  pagination={false}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <div style={{ overflowX: "auto" }} className="font-bold pb-8  pl-4">
                Total IP: {userData ? userData.totalPair : ""}
              </div>
            </Col>
            {/* <button
              className="btn-brand btn btn-md m-2"
              style={{ backgroundColor: "rgb(255, 90, 31)", color: "#fff" }}
              onClick={() => window.print()}
            >
              PRINT
            </button> */}
          </Card>
          <Row className="font-bold text-l m-6">
            Looking forward to creating a mutually beneficial long term working
            relationship with you and we assure you of our full cooperation in
            making this tie-up a success for both of us.
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CurrentFinancialYearData;
