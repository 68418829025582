import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tailwindcss/tailwind.css';
import { GetSettingData } from './utils/API';

const updateFavicon = async () => {
  try {
    const settings = await GetSettingData();
    const faviconUrl = settings.settings[0].favicon ; // Adjust according to your API response structure
    if (faviconUrl) {
      const faviconElement = document.getElementById('dynamic-favicon') as HTMLLinkElement;
      if (faviconElement) {
        faviconElement.href = `${process.env.REACT_APP_Image_URL}/settings//${faviconUrl}`; // Adjust the path to match where your images are served
      }
    }
  } catch (error) {
    console.error('Error fetching favicon:', error);
  }
};

const Main = () => {
  useEffect(() => {
    updateFavicon();
  }, []);

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
