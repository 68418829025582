import React, { useContext, useState, useEffect } from 'react';
import { Col, Input, message, Modal, notification, Row } from 'antd';
import { useCart } from '../../../utils/Cartcontext';
import { AiOutlineDelete, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { createRazorpayOrder, getproductsize, storeOrder, verifyPayment } from '../../../utils/API';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Auth/AuthContext';
import ThankYou from './ThankYou';
// import emptyCartImage from '../../../assets/frontendassets/emptycart.png';
// import emptyCartImage from '../../../assets/frontendassets/emptycart2.png';
import emptyCartImage from '../../../assets/frontendassets/emptycart3.png';
import logo from '../../../assets/frontendassets/logo.jpg';
import { BiCheck } from 'react-icons/bi';
import LoginForm from './LoginModal';

const Cart = () => {
    const { cart: cartContext, updateQuantity, removeFromCart } = useCart();
    const { authData, setAuthData } = useContext(AuthContext); // Assume setAuthData is available in AuthContext
    const cartData = authData.roleId ? localStorage.getItem("cart_userid") : localStorage.getItem("cart");
    const [transactionId, setTransactionId] = useState<string>("");
    const cart = cartData ? JSON.parse(cartData) : [];
    const imageUrl = `${process.env.REACT_APP_Image_URL}/products/`;
    const [sizes, setSizes] = useState<any>([]);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const storedAddress = localStorage.getItem("address");
        const storedPincode = localStorage.getItem("pincode");

        if (storedAddress) setAddress(storedAddress);
        if (storedPincode) setPincode(storedPincode);
    }, []);

    useEffect(() => {
        const fetchSizes = async () => {
            try {
                const response = await getproductsize(); // Call the imported API function
                setSizes(response.data.sizes); // Adjust based on your API response structure
            } catch (error) {
                console.error('Error fetching sizes:', error);
            }
        };

        fetchSizes();
    }, []);

    const getSizeName = (sizeId: any) => {

        const size = sizes.find((size: any) => size._id === sizeId);
        return size ? size.name : 'Unknown Size';
    };

    const getTotalPrice = () => {
        return cart.reduce((total: any, item: any) => total + (item.product.discountedPrice * item.quantity), 0).toFixed(2);
    };

    const getTotalMRP = () => {
        return cart.reduce((total: any, item: any) => total + (item.product.price * item.quantity), 0).toFixed(2);
    };

    const getTotalDiscount = () => {
        const totalMRP = cart.reduce((total: any, item: any) => total + (item.product.price * item.quantity), 0);
        const totalDiscountedPrice = cart.reduce((total: any, item: any) => total + (item.product.discountedPrice * item.quantity), 0);
        return (totalMRP - totalDiscountedPrice).toFixed(2);
    };

    const getTotalipQty = () => {
        return cart.reduce((total: any, item: any) => total + (item.product.ipQty * item.quantity), 0);
    };

    const handleUpdateQuantity = (productId: any, size: any, newQuantity: any) => {
        if (newQuantity > 0) {
            notification.open({
                message: 'Success',
                icon: <BiCheck style={{ color: '#52c41a' }} />,
                description: 'Cart updated successfully!',
                placement: 'topRight',
                duration: 3,
            });
            updateQuantity(productId, size, newQuantity);
        } else {
            removeFromCart(productId, size);
            notification.open({
                message: 'Success',
                description: 'Cart updated successfully!',
                placement: 'topRight',
                duration: 3,
            });

        }
    };

    const handlePayment = async () => {
        if (!address || !pincode) {
            message.error('Please fill in both address and pincode.');
            return;
        }

        if (pincode.length !== 6) {
            message.error('Please enter a valid pincode.');
            return;
        }

        const username = localStorage.getItem("username");
        const userId = username?.replace(/"/g, '');

        if (!userId) {
            message.error('Please login to proceed.');
            navigate('/login');
            return;
        }

        // Validate quantity
        // for (const item of cart) {
        //     if (item.quantity <= 0) {
        //         message.error('Invalid quantity for item: ' + item.product.name);
        //         return;
        //     }
        //     // Assuming you have a way to check available stock
        //     if (item.quantity > item.product.qty) {
        //         message.error(`maximum quantity allowed is ${item.product.qty}`);
        //         return;
        //     }
        // }

        setIsLoading(true);

        const orderItems = cart.map((item: any) => ({
            productId: item.product._id,
            quantity: item.quantity,
            amount: item.product.discountedPrice,
            sizeId: item.sizeId,
        }));

        const paymentMethod = "Razorpay";

        const orderData = {
            amount: parseFloat(getTotalPrice()) * 100,
            currency: 'INR',
            receipt: `receipt_${Date.now()}`,
            userId,
            orderItems,
            totalMrp: getTotalMRP(),
            totalDiscount: getTotalDiscount(),
            totalIp: getTotalipQty(),
            address,
            pincode,
            deliveryNotes: "Any notes for delivery",
            paymentMethod,
            transactionId: transactionId
        };

        try {
            const order = await createRazorpayOrder(orderData);
            const { id: order_id, currency, amount } = order;

            const options = {
                key: process.env.RAZOR_PAY_KEY_ID,
                amount: amount.toString(),
                image: logo,
                currency: currency,
                name: 'Rapidexie',
                description: 'Test Transaction',
                order_id: order_id,
                handler: async function (response: any) {
                    const paymentData = {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature
                    };

                    try {
                        const verifyResult = await verifyPayment(paymentData);
                        if (verifyResult === "OK") {
                            storeOrder({ ...orderData, transactionId: response.razorpay_payment_id });
                            setTransactionId(response.razorpay_payment_id);
                            localStorage.removeItem("cart");
                            localStorage.removeItem("cart_userid");
                            setIsLoading(false);
                            setIsModalVisible(true);
                        } else {
                            message.error('Payment failed. Please try again.');
                        }
                    } catch (error) {
                        message.error('Error verifying payment. Please try again.');
                    }
                },
                prefill: {
                    name: "",
                    email: address,
                    contact: '9999999999'
                },
                theme: {
                    color: '#F37254'
                }
            };

            const rzp = new (window as any).Razorpay(options);
            rzp.open();
            setIsLoading(false);
        } catch (error: any) {
            notification.open({
                message: 'Error',
                icon: <BiCheck style={{ color: 'red' }} />,
                description: 'Please Login to continue!',
                placement: 'topRight',
                duration: 3,
            });
            setIsModalVisible(true);
            setIsLoading(false);
        }
    };



    const handleLoginSuccess = () => {
        setIsLoginModalVisible(false);
        handlePayment();
    };

    return (
        <div className="lg:mt-52 xl:mt-46 md:mt-36 sm:mt-36 xs:mt-12 mt-12 md:pl-12 lg:pl-12 lg:pr-12 xl:pr-40 xl:pl-40 lg md:pr-12 pt-12 pb-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                    <Col span={24} className='d-flex justify-between p-2 font-bold '>
                        {
                            cart.length > 0 ? (
                                <>
                                    <h1 className="text-xl font-semibold mb-6">My Basket ({cart.length} {cart.length === 1 ? 'item' : 'items'})</h1>
                                    <div>₹{getTotalPrice()}</div>
                                </>
                            ) : null
                        }
                    </Col>
                    {cart.length > 0 ? (
                        <>
                            {cart.map((item: any, index: any) => (
                                <>
                                    <Row className='bg-gray-100 mb-2' key={index}>
                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} xxl={3} className='d-flex justify-center items-center  '>
                                            <img src={imageUrl + item.product.images[0]} alt={item.product.name} className="w-16 h-20 object-cover rounded-lg d-flex justify-center" />
                                        </Col>
                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                            <div className="text-2xl font-semibold text-orange-500 pt-2 pb-2">
                                                <Link to={`/product/${item.product.slug}`}>{item.product.name}</Link>
                                            </div>
                                            <div className="text-black text-base font-semibold pb-2">₹{item.product.discountedPrice.toFixed(2)} <del>₹{item.product.price.toFixed(2)}</del> <span className="text-orange-500">You save</span> ₹{(item.product.price - item.product.discountedPrice).toFixed(2)}</div>
                                            {
                                                item.sizeId ? (
                                                    <>
                                                        <div>Size: <span className='font-bold'>{getSizeName(item.sizeId)}</span></div>
                                                    </>
                                                ) : null
                                            }
                                            <div className="text-gray-600 text-sm pt-2 pb-2 md:pb-5">IP Points: <span className='font-bold'> {item.product.ipQty * item.quantity} </span> </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3} className='d-flex text-lg justify-center align-items-center'>
                                            <div className="flex items-center justify-end mt-2">
                                                <button onClick={() => handleUpdateQuantity(item.product._id, item.sizeId, item.quantity - 1)} className="p-1 border rounded-full bg-orange-500 text-white">
                                                    <AiOutlineMinus />
                                                </button>
                                                <p className="p-3 ">{item.quantity}</p>
                                                <button onClick={() => handleUpdateQuantity(item.product._id, item.sizeId, item.quantity + 1)} className="p-1 border  rounded-full bg-orange-500 text-white">
                                                    <AiOutlinePlus />
                                                </button>
                                                <button onClick={() => removeFromCart(item.product._id, item.sizeId)} className="text-orange-500 ml-2 mr-2">
                                                    <AiOutlineDelete size={20} />
                                                </button>
                                            </div>
                                        </Col>


                                    </Row>

                                </>
                            ))}
                            <div className="mb-4">
                                <label className="block text-gray-700">Address</label>
                                <input
                                    type="text"
                                    value={address}
                                    required
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        localStorage.setItem("address", e.target.value);
                                    }}

                                    className="w-full p-2 border rounded-lg"
                                    placeholder="Enter your address"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700">Pincode</label>
                                <input
                                    type="number"
                                    minLength={6}
                                    maxLength={6}
                                    required
                                    value={pincode}
                                    onChange={(e) => {
                                        setPincode(e.target.value);
                                        localStorage.setItem("pincode", e.target.value);
                                    }}
                                    className="w-full p-2 border rounded-lg"
                                    placeholder="Enter your pincode"
                                />
                            </div>
                        </>
                    ) : (
                        <Row>
                            <Col span={10}>
                            </Col>
                            <Col span={14} className='mt-22'>
                                <center>
                                    <div className="p-4 text-center">
                                        <img src={emptyCartImage} alt="Empty Cart" className="mx-auto" />
                                    </div>

                                </center>
                            </Col>
                        </Row>

                    )}
                </Col>
                {
                    cart.length > 0 && (
                        <>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                                <div className="mt-8 p-4 border rounded-lg shadow-sm">
                                    <h2 className="text-xl font-semibold mb-4">Payment Details</h2>


                                    <div className="flex justify-between mb-2">
                                        <span>IP Points</span>
                                        <span>{getTotalipQty()}</span>
                                    </div>
                                    <hr className='p-2' />
                                    <div className="flex justify-between mb-2">
                                        <span>MRP Total</span>
                                        <span>₹{getTotalMRP()}</span>
                                    </div>
                                    <hr className='p-2' />
                                    <div className="flex justify-between mb-2">
                                        <span>Discount</span>
                                        <span className="text-green-600">- ₹{getTotalDiscount()}</span>
                                    </div>
                                    <hr className='p-2' />
                                    <div className="flex justify-between mb-2 font-semibold">
                                        <span>Total Price</span>
                                        <span>₹{getTotalPrice()}</span>
                                    </div>
                                    <hr className='p-2' />
                                    <div className="flex justify-between mb-2">
                                        <span>Delivery charges</span>
                                        <span>₹0</span>
                                    </div>
                                    <div className="flex justify-between mt-4 border-t pt-4 font-semibold">
                                        <span>Total Amount</span>
                                        <span>₹{getTotalPrice()}</span>
                                    </div>
                                    <div className="flex justify-between mt-2">
                                        <span>Total IP</span>
                                        <span>{getTotalipQty()}</span>
                                    </div>

                                    <button
                                        onClick={handlePayment}
                                        className={`w-full bg-orange-500 text-white py-2 mt-4 rounded-lg ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Placing Order...' : 'Place Order'}
                                    </button>
                                </div>
                            </Col>


                        </>
                    )
                }
            </Row>
            <Modal

                footer={null}
                title=""
                closable={false}
                visible={isLoginModalVisible}
                onCancel={() => setIsLoginModalVisible(false)}
            >
                <LoginForm onSuccess={handleLoginSuccess} onClose={() => setIsLoginModalVisible(false)} />
            </Modal>
            <Modal
                footer={null}
                title=""
                closable={false}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
            >
                <ThankYou transactionId={transactionId} />
            </Modal>
        </div>
    );
};

export default Cart;
