import React from 'react'
import { Row, Col } from 'antd';
import { TbDiscount2 } from 'react-icons/tb';
import image1 from '../../assets/frontendassets/wha we offer/626 x417.jpg';
import { Link } from 'react-router-dom';
const WhatweOffer = () => {
  return (
    <>
   <Row  className="mt-5 xl:px-36 lg:px-0 xl:ps-36 lg:ps-0  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
  <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={12} className='pt-3 md:pt-24 pb-3 md:pb-36'>
    <p className='font-bold text-md mb-3 tracking-widest text-orange-500'>WHAT WE OFFER</p>
    <p className='text-5xl font-extrabold tracking-tight'>Access premium products with
<span className='text-orange-500'>Rapidexie</span></p>
    <p className='pt-3 text-md text-gray-500'>In Rapidexie, we offer a comprehensive suite of products and services designed to empower our network of independent distributors to succeed in the dynamic world of network marketing.</p>
    <span className='d-flex justify-between p-4 mt-4 mb-4' style={{ backgroundColor : "#f5f5f5" }}>
      <div className='font-bold text-lg text-orange-500'>Presenting them with a lucrative prospect to generate wealth autonomously.
</div>
      <div className='border-l-2 border-orange-500'></div>
    </span>
    <div className='d-flex align-items-center'>
      <p className='text-black tracking-wider font-bold text-lg pl-2'>Here's what sets us apart:</p>
    </div>
    <Link to="/products" >
    <button 
    className="btn btn-lg text-sm font-bold md:pl-8 md:pt-2 md:pr-8  mt-6 md:mt-10 bg-orange-500" 
    style={{ color: "white" }}>
    Check Our Products
</button>

    </Link>
  </Col>
  <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={12} className='pt-6 md:block xl:block lg:block md:pt-12 xl:pt-36 lg:pt-36   pb-6 sm:hidden xs:hidden md:pb-12 pl-6 md:pl-24 pr-6 md:pr-24'>
    <img src={image1} className='rounded-full w-full' style={{ maxWidth : "100%", backgroundPosition : "center" }} alt="Family spending time together outdoors" />
  </Col>
</Row>

    </>
  )
}

export default WhatweOffer