import React, { useEffect, useState } from "react";
import {
  message,
  Row,
  Col,
  Card,
  Form,
  Input,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { editColor, colorupdate } from "../../../utils/API";
import { FaArrowLeft } from "react-icons/fa";

const ProductColoreEditForm: React.FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    editColor(id).then((response) => {
      form.setFieldsValue(response.colors);
    });
  }, []);

  const handleSubmit = (values: any) => {
    const id = form.getFieldValue('_id');
    colorupdate(id, values).then((response: any) => {
      if (response.status === 200) {
        message.success(response.data.message);
        navigate("/backend/color");
      } else {
        message.error("Something went wrong");
      }
    });
  }


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Color</h2>
        </Col>
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/color"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white" form={form} onFinish={handleSubmit}>
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={24}
                  className="d-flex justify-content-start align-items-center"
                ></Col>
                <Col span={24}>
                  <Row>
                    <Col
                      xs={24} sm={24} md={4}
                      className="d-flex justify-content-start me-4 bg-white lg:mb-5  "
                    >
                      <label className="font-bold">
                        Color <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="color"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Color",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Color"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="d-flex justify-content-center">
                  <button className="btn-brand" type="submit">Submit</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default ProductColoreEditForm;