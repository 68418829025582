import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Select, Upload, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsUpload } from "react-icons/bs";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { getAllcategories, getCategorybyId, updatecategory } from "../../../utils/API";
import { useForm } from "antd/es/form/Form";
import { FaArrowLeft } from "react-icons/fa";

interface Category {
  _id: string;
  name: string;
  category_id: string | null;
  isHome: boolean;
}

export const EditCategory: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isHome, setIsHome] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const existingData = await getCategorybyId(id);
          form.setFieldsValue(existingData);
          setIsHome(existingData.isHome); // Set isHome state based on the API response
          if (existingData.image) {
            const imageUrl = `${process.env.REACT_APP_Image_URL}/categories/${existingData.image}`;
            setFileList([{ uid: '1', name: existingData.image, status: 'done', url: imageUrl }]);
          }
        }

        const categoriesData = await getAllcategories("");
        setCategories(categoriesData);
      } catch (error) {
        message.error("Error fetching data");
        if (id) {
          navigate("/backend/category");
        } else {
          navigate("/backend/categories");
        }
      }
    };

    fetchData();
  }, [id, form, navigate]);

  const handleEditCategory = async () => {
    setLoading(true);
  
    try {
      const currentId = form.getFieldValue("_id");
      const formData = new FormData();
      
      formData.append("name", form.getFieldValue("name"));
      formData.append("categoryId", form.getFieldValue("categoryId") || "");
      formData.append("isHome", isHome.toString());
  
      // Check if fileList contains files
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("image", fileList[0].originFileObj as RcFile);
      }
  
      if (currentId) {
        const response = await updatecategory(currentId, formData);
  
        if (response.message) {
          message.success(response.message);
          navigate("/backend/category");
        } else {
          message.success("Category updated successfully");
          form.resetFields();
        }
      } else {
        message.error("Failed to submit category data. Please try again.");
      }
    } catch (error) {
      message.error("Failed to update category. Please try again.");
    }
  
    setLoading(false);
  };
  

  const handleImage = ({ fileList }: UploadChangeParam<UploadFile>) => {
    setFileList(fileList);
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Category</h2>
        </Col>
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/category"}>
            <button className="d-flex justify-content-start align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row align={"middle"} gutter={[16, 16]}>
        <Col span={24}>
          <Card className="bg-white" style={{ minHeight: "80vh" }}>
            <Form form={form} onFinish={handleEditCategory}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">Category </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="categoryId">
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select a category"
                      optionFilterProp="children"
                      filterOption={(input : any, option : any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) =>
                        form.setFieldsValue({ categoryId: value })
                      }
                    >
                      <Select.Option value={""}>
                        Select a category
                      </Select.Option>
                      {categories.map((category) => (
                        <Select.Option key={category._id} value={category._id}>
                          {category.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="name">
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Enter name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">is Home ?</label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="isHome">
                    <input type="checkbox"
                      className={`rounded-md border border-1 w-6 h-6 ${isHome ? "bg-orange-500" : "bg-white"}`}
                      checked={isHome}
                      onChange={(e) => setIsHome(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row  gutter={[16, 16]}>
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold" style={{ paddingRight: "73px" }}>
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}  className="d-flex justify-content-start">
                  <Upload
                    name="image"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleImage}
                    maxCount={1} 
                    accept=".png, .jpg, .jpeg"
                  >
                    <div>
                      <BsUpload style={{ fontSize: "20px" }} />
                    </div>
                  </Upload>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="d-flex justify-content-center pt-4">
                  <button
                    className="btn-brand"
                    type="submit"                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
