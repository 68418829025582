import React from 'react'
import { Link } from 'react-router-dom';


interface ThankYouProps {
  transactionId: string;
}

const ThankYou: React.FC<ThankYouProps> = ({ transactionId }) => {
  return (
    <>
      <div className="flex  items-center justify-center ">
        <div className="bg-gradient-to-r from-orange-500 to-orange-400 p-10 rounded-lg shadow-lg text-center">
          <div className="flex flex-col items-center space-y-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-28 w-28 text-green-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h1 className="text-4xl font-bold text-gray-800">Thank You!</h1>
            <h2 className="text-black text-lg">
              Your Transaction ID: 
             <span className="text-white"> {transactionId} </span>
            </h2>
            <p className="text-white text-lg">
              We appreciate your order and your trust in us.
            </p>
            <p className="text-white text-lg">
              Your purchase helps us continue to create amazing products.
            </p>
            <Link
              to="/"
              className="inline-flex items-center rounded border border-orange-600 bg-orange-400 px-4 py-2 text-white   focus:outline-none focus:ring transition duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              <span className="text-sm font-medium">Back to Home</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThankYou
