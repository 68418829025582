import React, { useEffect, useState } from "react";
import { Upload, message, Row, Col, Card, Button, Form, Spin } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BiUpload } from "react-icons/bi";
import { getppt, storePPT, getAllcategories } from "../../../../../utils/API";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaTrash } from "react-icons/fa";

const AddPPT: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<any[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const categoriesData = await getAllcategories("");
      setCategories(categoriesData);
    } catch (error) {
      message.error("Error fetching categories");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onRemove = (file: UploadFile) => {
    setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
  };

  const MAX_FILE_SIZE = 40 * 1024 * 1024; // 50 MB

  

const onFinish = async (values: any) => {
  try {
    const file = fileList[0];
    if (!file) {
      message.error("Please upload a PPT file");
      return;
    }

    // Check if file size is defined and within the allowed limit
    if (file.size && file.size > MAX_FILE_SIZE) {
      message.error(`File size too large. Maximum allowed size is ${MAX_FILE_SIZE / (1024 * 1024)} MB`);
      return;
    }

    setLoading(true); // Start loading

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("ppt", file.originFileObj as any);
    });
    formData.append("category_id", values.category_id);

    await storePPT(formData);
    message.success("PPT uploaded successfully");
    navigate("/backend/product-ppt");
    setFileList([]);
    form.resetFields();
  } catch (error) {
    message.error("Failed to upload PPT");
  } finally {
    setLoading(false);
  }
};


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Add Product PPT</h2>
        </Col>
        <Col span={6}>
          <Link to={"/backend/product-ppt"}>
            <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>


            </button>
          </Link>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Row className="border border-warning-2 p-5 bg-white rounded-md">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col span={24}>
                <Form form={form} onFinish={onFinish}>
                  <Row>
                    <Col xs={24} sm={24} md={3} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        Select Category <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Form.Item
                        name="category_id"
                        rules={[{ required: true, message: "Please select a category" }]}
                      >
                        <select
                          className="form-select border border-warning-2 rounded-md"
                          aria-label="Default select example"
                        >
                          <option value="">Select Category</option>
                          {categories.map((category: any) => (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} xs={24}  md={2} className="me-4 bg-white lg:mb-5">
                      <label className="font-bold">
                        Product PPT <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col
                      xs={24} sm={24} md={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Form.Item
                        name="file"
                        rules={[{ required: true, message: "Please upload a PPT file" }]}
                      >
                        <Upload
                          name="ppt"
                          action=""
                          multiple
                          fileList={fileList}
                          onChange={({ fileList }) => setFileList(fileList)}
                          onRemove={onRemove}
                          accept=".ppt, .pptx"
                          beforeUpload={() => false} // Prevent default upload behavior
                          itemRender={(originNode, file) => (
                            <div className="d-flex  align-items-center">
                              {originNode}
                              <FaTrash
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => onRemove(file)}
                              />
                            </div>
                          )}
                        >
                          <Button icon={<BiUpload />}>Upload PPT</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16} className="text-center">
                      <Form.Item>
                      <button
                        disabled={fileList.length === 0 && loading}
                        className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${loading ? 'bg-orange-300 text-gray-700' : 'bg-orange-500 text-white '}`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="flex items-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0.5"
                              ></path>
                            </svg>
                            Uploading
                          </div>
                        ) : (
                          "Upload PPT"
                        )}
                      </button>

                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AddPPT;
