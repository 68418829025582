import { Button, Card, Col, Form, Input, Modal, Row, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UploadFile } from "antd/lib/upload";
import { BiUpload } from "react-icons/bi";
import { RcFile } from "antd/es/upload";
import { FaArrowLeft } from "react-icons/fa";
import { addTestimonials, getTestimonialById, updateTestimonial } from "../../../utils/API";

export const EditTestimonial = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const imageLink = process.env.REACT_APP_Image_URL;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id) {
      // Fetch testimonial data for editing
      getTestimonialById(id)
        .then((response: any) => {
            const { name, description, image } = response;
            form.setFieldsValue({ _id: id, name, description });

            if (image) {
              setFileList([{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: imageLink + "/testimonial/" + image,
              }]);
              setPreviewImage(imageLink + "/testimonial/" + image);
            }
          })
        .catch((error: any) => {
          console.error("Error fetching testimonial data:", error); // Log the error
          message.error(error.response?.data?.message || "Failed to fetch data");
        });
    }
  }, [id]); // Only depend on id

  const handleAddTestimonial = async (values: any) => {
    setLoading(true);
  
    try {
      const currentId = form.getFieldValue("_id");
      const formData = new FormData();
      
      formData.append("name", form.getFieldValue("name"));
      formData.append("description", form.getFieldValue("description"));
    
      // Check if fileList contains files
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("image", fileList[0].originFileObj as RcFile);
      }
  
      if (currentId) {
        const response: any = await updateTestimonial(currentId, formData);
  
        if (response) {
          message.success(response.message || "Testimonial updated successfully");
          navigate("/backend/testimonials");
          form.resetFields();
        } else {
          message.error("Failed to submit Testimonial data. Please try again.");
        }
      } else {
        message.error("Current ID not found. Please try again.");
      }
    } catch (error) {
      console.error("Error updating Testimonial:", error); // Log the error for debugging
      message.error("Failed to update Testimonial. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleImage = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result as string);
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setPreviewImage(null as any);
    }
    setFileList(newFileList);
  };

  return (
    <div>
  <Row className="m-2" align="middle">
    <Col span={16}>
      <h2 className="text-xl sm:text-2xl font-bold text-center sm:text-left">
        {id ? "Edit Testimonial" : "Add Testimonial"}
      </h2>
    </Col>
    {/* <Col xs={0} sm={12}></Col> */}
    <Col span={8} className="d-flex justify-end">
      <Link to={"/backend/testimonials"}>
        <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
          <FaArrowLeft className="me-2" />
          <div className="ms-2">Back</div>
        </button>
      </Link>
    </Col>
  </Row>
  <Row align={"middle"} gutter={[16, 16]}>
    <Col span={24}>
      <Form form={form} onFinish={handleAddTestimonial}>
        <Card className="bg-white" style={{ minHeight: "80vh" }}>
          <Row className="border border-warning-2 p-5 bg-white rounded-md" style={{ marginLeft: 0, marginRight: 0 }}>
            <Col span={24}>
              <Row gutter={[16, 16]} className="flex-col sm:flex-row">
                <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">Name</label>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item name="name">
                    <Input
                      size="large"
                      className="rounded border border-1"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]} className="flex-col sm:flex-row">
                <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={8} className="d-flex justify-content-start">
                  <Upload
                    listType="picture-card"
                    name="image"
                    fileList={fileList}
                    onChange={handleImage}
                    maxCount={1}
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={() => false}
                  >
                    {fileList.length === 0 ? (
                      <div>
                        <Button icon={<BiUpload />}>Upload</Button>
                      </div>
                    ) : (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </Upload>
                  <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancelPreview}
                  >
                    <img
                      alt="Preview"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]} className="flex-col sm:flex-row">
                <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Description <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={8} className="pt-2">
                  <Form.Item
                    name="description"
                    rules={[{ required: true, message: "Please input description!" }]}
                  >
                    <Input.TextArea
                      size="large"
                      className="rounded border border-1 pt-2"
                      name="description"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]} justify={"center"}>
                <Col xs={24} className="d-flex justify-content-center">
                  <button
                    className="btn-brand w-full sm:w-auto"
                    type="submit"
                  >
                    {loading ? "Saving..." : id ? "Update" : "Add"}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </Col>
  </Row>
</div>

  );
};
