import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, DatePicker } from 'antd';
import { Dayjs } from 'dayjs'; // Import Dayjs types
import dayjs from 'dayjs';
import { fetchPayoutData } from '../../../../utils/API';
import PayoutDates from '../../Geneology/Accuntdetails/PayoutDates';

interface PayoutData {
  key: string;
  username: string;
  firstName: string;
  paidDate: string;
  totalIp: number;
  entryBy: string;
}

const CurrentPayoutwiseIpList: React.FC = () => {
  const calculatePreviousSunday = (): string => {
    const today = dayjs();
    const previousSunday = today.subtract(today.day(), 'days');
    return previousSunday.format('YYYY-MM-DD');
  };

  const [data, setData] = useState<PayoutData[]>([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<string>(calculatePreviousSunday()); // State for selected date, defaulting to the previous Sunday
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const handleDateChange = (date: Dayjs | null, dateString: string): void => {
  //   setDate(dateString || calculatePreviousSunday()); // Update selected date or set to the previous Sunday if null
  // };
  const handleDateChange = (date: string) => {
    setDate(date); // Update selected date in state
  };

  const disabledDate = (current: Dayjs): boolean => {
    // Disable future dates and only allow Sundays
    const today = dayjs().endOf('day');
    return current && (current.isAfter(today) || current.day() !== 0);
  };

  useEffect(() => {
    const loadData = async (page : any) => {
      setLoading(true);
      try {
        const response = await fetchPayoutData(date , page);
        const transformedData = response.results.map((item: any, index: any) => {
          // Check if neftDate is null or invalid
          const formattedDate = item.neftDate ? dayjs(item.neftDate).isValid() ? dayjs(item.neftDate).format('YYYY-MM-DD') : "-" : "-";
        
          return {
            key: (index + 1).toString(),
            username: item.userId.username,
            firstName: item.userId.firstName,
            paidDate: formattedDate, // Use the formattedDate variable
            totalIp: item.calculatedPoint.left + item.calculatedPoint.right,
            entryBy: item.entryBy ? item.entryBy.username : "-"
          };
        });
        setTotalPages(response.totalPages);
        setCurrentPage(response.currentPage);

        setData(transformedData);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData(currentPage);
  }, [date , currentPage]);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'ID No',
      dataIndex: 'username',
      key: 'idNo',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Total IP',
      dataIndex: 'totalIp',
      key: 'totalIp',
    },
    {
      title: 'Bill Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
    },
    {
      title: 'Entry Done By',
      dataIndex: 'entryBy',
      key: 'entryBy',
    },
  ];

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Previous payout IP List</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={10} xxl={10}></Col>
            <Col xs={36} sm={36} md={14} xl={9} xxl={9}></Col>
            <Col xs={24} sm={24} md={6} xl={5} xxl={5}></Col>
          </Row>
        </Col>
      </Row>
      <Card>
        <Row gutter={16} className="m-2">
          <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
            {/* <DatePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              allowClear
              disabledDate={disabledDate}
              defaultValue={dayjs(date)} // Set default value to the previous Sunday
            /> */}

            <PayoutDates  dateChnageFunction={handleDateChange}/>
          </Col>
        </Row>
        <Table columns={columns} dataSource={data} loading={loading} 
        scroll={{ x: 1000 }}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: 10  ,
          onChange: (page) => setCurrentPage(page),
        }}

        
        />
      </Card>
    </div>
  );
};

export default CurrentPayoutwiseIpList;
