import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Descriptions,
  Row,
  Col,
  Image,
  Divider,
} from "antd";

import { FaArrowLeft } from "react-icons/fa";
import { config } from "../../../utils/config";

const UserOrderDetail = () => {
  const location = useLocation();
  const { orderId: orderInfo } = location.state || {};
  const [orderItems, setOrderItems] = useState(orderInfo.orderItems);
  const imageUrl = process.env.REACT_APP_Image_URL + "/products/";
  const navigate = useNavigate();


  if (!orderInfo) {
    return <div className="text-center p-4">No order details available.</div>;
  }

  return (
    <>
      <div className="container mx-auto my-2 p-4">
        <Card className="shadow-lg">
          <div className="d-flex justify-between items-baseline">
            <div className="d-flex justify-around">
              <div className="pr-3">
                <h2 className="text-2xl font-bold">Order Details</h2>
              </div>
            </div>
            <div>
              <Link to={"/backend/manage-purchase"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <FaArrowLeft className="me-2" />
                  <div className="ms-2">Back</div>
                </button>
              </Link>
            </div>
          </div>

          <div className="p-2 bg-white shadow-md rounded-lg">
            <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-6">
              <div className="block w-full">
                <p className="text-base">Customer Name:</p>
                <p className="font-bold mb-3">
                  {`${orderInfo.userId?.firstName || ""} ${orderInfo.userId?.lastName || "No name provided"
                    }`}
                </p>
                <p className="text-base">Email:</p>
                <p className="font-bold mb-3">
                  {orderInfo.userId?.email || "No email provided"}
                </p>

                <p className="text-base">Total MRP:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol}
                  {orderInfo.totalMrp}
                </p>
                <p className="text-base">Total IP:</p>
                <p className="font-bold mb-3">{orderInfo.totalIp}</p>
              </div>
              <div>
                <p className="text-base">Amount Paid:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol} {orderInfo.amount}
                </p>
                <p className="text-base">Payment Method:</p>
                <p className="font-bold mb-3">{orderInfo.paymentMethod}</p>
                <p className="text-base">Delivery Address:</p>
                <p className="font-bold mb-3">{orderInfo.address}</p>
                <p className="text-base">Delivery Notes:</p>
                <p className="font-bold mb-3">{orderInfo.deliveryNotes}</p>
              </div>
              <div>
                <p className="text-base">Invoice Number:</p>
                <p className="font-bold mb-3">{orderInfo.invoiceNumber}</p>
                <p className="text-base">Recovery Amount:</p>
                <p className="font-bold mb-3">
                  {config.currencySymbol} {orderInfo.recoveryAmount}
                </p>
                <p className="text-base">Order Status :</p>
                <p className="font-bold mb-3 mt-2">
                {orderInfo.status == 0 && <span className="text-green-500">Pending</span>}
                {orderInfo.status == 1 && <span className="text-green-500">Dispatch</span>}
                {orderInfo.status == 2 && <span className="text-green-500">Delievered</span>}
                {orderInfo.status == 3 && <span className="text-green-500">Cancelled</span>}
                  
                </p>
              {
                orderInfo.status === 1 || orderInfo.status === 2 && (
                  <>
                    <p className="text-base">Delivery Method:</p>
                    <p className="font-bold mb-3">
                      {orderInfo.deliveryOption === 0 ? "By Hand" : "Courier"}
                    </p>
                    <p className="text-base">Tracking Number:</p>
                    <p className="font-bold mb-3">{orderInfo.trackingNo}</p>
                  </>
                )
              }
              </div>
            </div>
          </div>
          <Divider orientation="left" className="my-4">
            Order Items
          </Divider>

          {orderItems.map((item: any) => {
            return <div key={item._id} className="mb-4 shadow-sm">
              <Row gutter={16} align="middle">
                <Col xs={24} sm={24} md={4} className="d-flex justify-center">
                  <Image
                    width={100}
                    src={imageUrl + item?.productId?.images[0]}
                    alt="Product"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Descriptions column={1} size="small">
                    <Descriptions.Item label="Product Name">
                      {item.productId.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Price">
                      {config.currencySymbol} {item.productId.price}
                    </Descriptions.Item>
                    <Descriptions.Item label="Discounted Price">
                      {config.currencySymbol}
                      {item.productId.discountedPrice}
                    </Descriptions.Item>
                    <Descriptions.Item label="Quantity">
                      {item.quantity}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </div>
          }
          )}

        </Card>
      </div>
    </>
  );
};



export default UserOrderDetail;