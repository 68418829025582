import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Card, message, notification, Modal } from "antd";
import { Select } from "antd";
import { addCustomer, createRazorpayOrder, getallproducts, getCustomerByID, payonline, verifyPayment } from "../../../../utils/API";
import { useNavigate } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import logo from "../../../../assets/frontendassets/logo.jpg";
import ThankYou from "../../../frontend/Cart/ThankYou";

const { Option } = Select;

const Payonline: React.FC = () => {
  const [productData, setProductData] = React.useState<any>([]);
  const [form] = Form.useForm();
  const [sponsorId, setSponsorId] = React.useState<string | null>(null);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    const storedPincode = localStorage.getItem("pincode");

    if (storedAddress) setAddress(storedAddress);
    if (storedPincode) setPincode(storedPincode);
  }, []);

  useEffect(() => {
    // Fetch all products
    getallproducts()
      .then((response) => {
        setProductData(response);
      })
      .catch((error) => {
        message.error("Network error. Please try again.");
      });

    const customerId = localStorage.getItem("customerId");
    const userId = customerId?.replace(/"/g, "");

    if (userId) {
      getCustomerByID(userId)
        .then((response) => {
          const sponsorId = response.username;
          setSponsorId(sponsorId);
          form.setFieldsValue({ sponsorId });
        })
        .catch((error) => {
          message.error("Failed to fetch sponsor ID. Please try again.");
        });
    }
  }, [form]);

  const handlePayment = async (value: any) => {
    setIsLoading(true);
    const product = productData.find((p: any) => p._id === value.productId);

    if (!product) {
      setIsLoading(false);
      return message.error('Invalid product selected');
    }

    const orderData = {
      amount: parseFloat(value.amount) * 100,
      currency: 'INR',
      receipt: `receipt_${Date.now()}`,
      orderItems: [{
        productId: value.productId,
        quantity: 1,
        amount: parseFloat(product.price),
      }],
      sponsorId: value.sponsorId,
      userId: value.userId,
      totalMrp: product.price * 1,
      totalDiscount: product.discount,
      totalIp: product.ipQty,
      address: value.address,
      pincode: value.pincode,
      paymentMethod: "Razorpay",
      transactionId: transactionId,
      placement: value.placement,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      mobileNo: value.mobileNo
    };

    try {
      const order = await createRazorpayOrder(orderData);
      const { id: order_id, currency, amount } = order;

      const options = {
        key: process.env.RAZOR_PAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        image : logo,
        name: 'Rapidexie',
        description: 'Test Transaction',
        order_id: order_id,
        handler: async function (response: any) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature
          };

          try {
            const verifyResult = await verifyPayment(paymentData);
            if (verifyResult === "OK") {
              let transactionId = response.razorpay_payment_id;
              try {
                const response = await payonline({ ...orderData, transactionId });
                setTransactionId(transactionId);

                localStorage.removeItem("cart");
                localStorage.removeItem("cart_userid");

                setIsModalVisible(true);
              } catch (error) {
                message.error("An error occurred while processing your payment. Please try again.");
              }
            } else {
              alert("Payment verification failed. Please try again.");
            }
          } catch (error) {
            message.error('Error verifying payment. Please try again.');
          }
        },
        prefill: {
          name: 'Your Name',
          email: 'youremail@example.com',
          contact: '9999999999'
        },
        theme: {
          color: '#F37254'
        }
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
      setIsLoading(false);
    } catch (error) {
      setIsLoginModalVisible(true);
      notification.open({
        message: 'Error',
        icon: <BiCheck style={{ color: 'red' }} />,
        description: 'Please Login to continue!',
        placement: 'topRight',
        duration: 3,
      });
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = () => {
    setIsLoginModalVisible(false);
    handlePayment({});
  };

  return (
    <div className="lg:mt-52 xl:mt-46 md:mt-36 sm:mt-36 xs:mt-12 mt-12 md:pl-40 md:pr-40  pt-12 pb-3">
      <Modal
        footer={null}
        title=""
        closable={false}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <ThankYou transactionId={transactionId} />
      </Modal>
      <Form onFinish={handlePayment} form={form} className="bg-white">
        <Row className="m-2" align={"middle"}>
          <Col
            xs={24}
            sm={24}
            md={9}
            xl={6}
            xxl={6}
            className="d-flex justify-content-start font-bold"
          >
            <h2 className="text-2xl">Pay online</h2>
          </Col>
        </Row>
        <Row justify={"center"} className=" pt-5 border border-warning-2 bg-white rounded-md">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Sponsor ID
                </label>
              </Col>
              <Col span={12}>
                <Form.Item name="sponsorId">
                  <Input disabled size="large" className="rounded border border-1" placeholder="Sponsor ID" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  User ID
                </label>
              </Col>
              <Col span={12}>
                <Form.Item name="userId">
                  <Input size="large" className="rounded border border-1" placeholder="User ID" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Placement <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="placement"
                  rules={[
                    { required: true, message: "Please Select your Position!" },
                  ]}
                >
                  <Select size="large" className="rounded border border-1" placeholder="Select Position">
                    <Option value="1">Left</Option>
                    <Option value="2">Right</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  First Name <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "Please enter your First Name!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter First Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Last Name <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Please enter your Last Name!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Email <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your Email!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Email"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Mobile Number <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mobileNo"
                  rules={[
                    { required: true, message: "Please enter your Mobile Number!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Mobile Number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Amount <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="amount"
                  rules={[
                    { required: true, message: "Please enter the Amount!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Amount"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Product <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="productId"
                  rules={[
                    { required: true, message: "Please Select a Product!" },
                  ]}
                >
                  <Select size="large" className="rounded border border-1" placeholder="Select Product">
                    {productData.map((product: any) => (
                      <Option key={product._id} value={product._id}>
                        {product.name} - ₹{product.price}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Address <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address"
                  rules={[
                    { required: true, message: "Please enter your Address!" },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white" justify={"center"}>
              <Col span={4} className="d-flex justify-content-start me-5 bg-white mb-5">
                <label className="font-bold">
                  Pincode <span className="text-danger">*</span>
                </label>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pincode"
                  rules={[
                    { required: true, message: "Please enter your Pincode!" },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded border border-1"
                    placeholder="Enter Pincode"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

     

          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row justify={"center"} className="bg-white">
              <Col
                xs={18}
                sm={18}
                md={12}
                xl={8}
                xxl={6}
                className="bg-white mb-3 d-flex justify-content-center"
              >
                <Form.Item>
                  <button
                    type="submit"
                    className="bg-warning-2 bg-orange-500 p-2 text-white rounded-md w-40 font-semibold text-lg"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Pay"}
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Payonline;
