import React, { useEffect, useState } from "react";
import { Upload, message, Row, Col, Card, Button, Form, Spin } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BiUpload } from "react-icons/bi";
import { businesspptdownload, getbusinessppt, updatebusinessppt } from "../../../../../utils/API";
import { BsFilePdf } from "react-icons/bs";

const BusinessPPT: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [savedData, setSavedData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchppt = () => {
    getbusinessppt().then((response) => {
      const fileListData = response.ppt.map((fileData: any, index: any) => ({
        uid: String(index),
        name: fileData.ppt,
        status: "done",
        url: fileData.url,
        id: fileData._id, // Assuming each file has an ID property
      }));
      setFileList(fileListData);
      setSavedData(fileListData);
    });
  };

  useEffect(() => {
    fetchppt();
  }, []);

  const handledownloadPPt = async () => {
    setLoading(true);
    try {
      const pptURL = await businesspptdownload();
      window.open(pptURL.pptURL, '_blank');
      message.success('PPTX file downloaded successfully!');
    } catch (error) {
      message.error('Failed to initiate PPTX file download.');
    } finally {
      setLoading(false);
    }
  }

  const onFinish = async () => {
    setLoading(true);
    try {
      const file = fileList[0];
      const formData = new FormData();
      formData.append("ppt", file.originFileObj as any);

      // Check if file size is defined and within the allowed limit
      if (file.size && file.size > 40 * 1024 * 1024) {
        message.error(`File size too large. Maximum allowed size is 40 MB`);
        return;
      }
      if (savedData.length > 0) {
        const id = savedData[0]?.id;
        await updatebusinessppt(id, formData);
        message.success("PPT updated successfully");
      } else {
        await updatebusinessppt(null, formData);
        message.success("PPT uploaded successfully");
      }
      fetchppt();
    } catch (error) {
      message.error("Failed to upload/update PPT");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Business PPT</h2>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Row className="border border-warning-2 p-5 bg-white rounded-md">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col span={24}>
                <Row>
                  <Col xs={24} sm={24} md={4} xl={4} xxl={4} className=" bg-white mb-5">
                    <label className="font-bold">
                      Business PPT <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    xl={12}
                    xxl={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form form={form} onFinish={onFinish}>
                      <div className="d-flex justify-content-center">
                        <Form.Item name="file">
                          <Upload
                            name="ppt"
                            action=""
                            fileList={fileList}
                            onChange={({ fileList }) => setFileList(fileList)}
                            maxCount={1}
                            accept=".ppt, .pptx"
                            beforeUpload={() => false} // Prevent default upload behavior
                          >
                            <Button icon={<BiUpload />} disabled={loading}>Upload PPT</Button>
                          </Upload>
                        </Form.Item>
                        <BsFilePdf className={`text-3xl cursor-pointer ${loading ? 'pointer-events-none' : ''}`} onClick={handledownloadPPt} />
                      </div>    
                      <Form.Item>
                      <button
  disabled={fileList.length === 0 && loading}
  className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${loading ? 'bg-orange-300 text-gray-700' : 'bg-orange-500 text-white '}`}
  type="submit"
>
  {loading ? (
    <div className="flex items-center">
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0.5"
        ></path>
      </svg>
      Uploading
    </div>
  ) : (
    "Upload PPT"
  )}
</button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
     
    </div>
  );
};

export default BusinessPPT;
