import React, { useState, useEffect } from 'react';
import { Card, Col, DatePicker, Input, message, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { redEntry } from '../../../../utils/API';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RedEntry = () => {
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [searchText, setSearchText] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false); // Loading state for search
  const navigate = useNavigate();

  const handleEdit = (record: any) => {
    console.log(record);
    navigate(`/backend/red-entry/${record.id}`);
  };

  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Id No',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'Entry Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => new Date(text).toLocaleDateString()
    },
    {
      title: 'Entry Done By',
      dataIndex: 'entryBy',
      key: 'entryBy'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any) => (
        <FaEdit className='editMenuIcon' onClick={() => handleEdit(record)}>View</FaEdit>
      )
    }
  ];

  const handleViewList = async (page: number, searchusername: string | null = null) => {
    if (!fromDate || !toDate) {
      message.error("Please select both From Date and To Date.");
      return;
    }
    const customerId = localStorage.getItem("customerId");
    const userid = customerId?.replace(/"/g, "");
    try {
      setLoading(true);
      const response = await redEntry(searchusername, userid, fromDate, toDate, page);
      const { total, page: currentPage, totalPages, results } = response;

      // Map API data to table format
      const tableData = results.map((item: any, index: number) => ({
        id: item._id,
        index: (page - 1) * pageSize + index + 1,
        username: item.username,
        name: item.firstName,
        city: item.city || "-",
        createdAt: item.createdAt,
        entryBy: item.entryBy ? item.entryBy.username : "-"
      }));

      // Update state with new data
      setTotal(total);
      setCurrentPage(currentPage);
      setTableData(tableData);
      setFilteredData(tableData); // Initialize filteredData
    } catch (error) {
      message.error("Failed to fetch data from the API.");
      console.error("API Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSearchLoading(true);
    const timer = setTimeout(() => {
      const filtered = tableData.filter((entry) => {
        return (
          entry.username.toLowerCase().includes(searchText.toLowerCase()) ||
          entry.name.toLowerCase().includes(searchText.toLowerCase()) ||
          entry.city.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredData(filtered);
      setSearchLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchText, tableData]);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    handleViewList(1, e.target.value);
  };

  return (
    <div className="p-4">
  <Row className="mb-4" align="middle">
        <Col xs={24}>
          <h2 className="text-2xl font-bold">Red Entry</h2>
        </Col>
      </Row>
      <Card>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} md={4}>
            <label className="block text-sm font-bold mb-2">From Date (mm/dd/yyyy)*</label>
          </Col>
          <Col xs={24} md={6}>
            <DatePicker
              format="MM/DD/YYYY"
              size='large'
              value={fromDate ? dayjs(fromDate) : null}
              onChange={(date, dateString) => setFromDate(dateString)}
              className="w-full"
            />
          </Col>
          <Col xs={24} md={4}>
            <label className="block text-sm font-bold mb-2">To Date (mm/dd/yyyy)*</label>
          </Col>
          <Col xs={24} md={6}>
            <DatePicker
              size='large'
              format="MM/DD/YYYY"
              value={toDate ? dayjs(toDate) : null}
              onChange={(date, dateString) => setToDate(dateString)}
              className="w-full"
            />
          </Col>
          <Col xs={24} md={4} className="flex items-end justify-center md:justify-end">
            <button className='bg-orange-500 text-white px-3 py-2 w-full md:w-auto' onClick={() => handleViewList(currentPage)}>
              View List
            </button>
          </Col>
        </Row>
        <Row className="mt-4" gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} md={12}>
            <Input
              className='rounded border border-1 mb-2 w-full'
              placeholder="Search by Id, Name, or City"
              value={searchText}
              onChange={handleSearch}
            />
          </Col>
        </Row>
    
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey={record => record.key}
          scroll={{ x: 1000 }}
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            onChange: (page) => handleViewList(page),
          }}
          loading={loading || searchLoading}
        />
      </Card>
    </div>
  );
};

export default RedEntry;
