import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const TermConditions = () => {
  return (
    <>
    <Row
      className="lg:mt-60 xl:mt-60 md:mt-60 sm:mt-36 xs:mt-36 mt-36 pt-3 pb-3"
      align="middle"
      justify="space-between"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        className="text-2xl font-bold d-flex justify-center md:justify-start p-4"
      >
        Terms and Conditions
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Breadcrumb
          separator=">"
          className="d-flex justify-center md:justify-end font-semibold text-black p-2 m-4"
        >
          <Breadcrumb.Item className="">
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/aboutus">Terms and Conditions</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
    {/* <div className="space-y-6 max-w-4xl mx-auto px-4 py-8">
      {" "}
      <section className="space-y-4">
        <p className="text-gray-700 text-lg leading-relaxed">
          Dear Sir, we want to make you aware that Rapidexie is not a Pongy
          scheme or quick money-earning shortcut formula. This is a system
          where you, after becoming a part-time direct seller, can sell our
          valuable products & services and earn effort-based high income and
          rewards based on your dedication towards generating more sales.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed">
          Before you proceed with the business opportunity for making a
          parallel part-time income generation by selling our valuable
          products & services with the team of Rapidexie family, it is
          essential to understand and agree to our Code of Ethics. Our
          commitment to ethical behavior is the main pillar of our integrity
          and trustworthiness. By accepting our opportunity, you agree to
          adhere to the following code:
        </p>
      </section>
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Code of Ethics
        </h2>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          <li>Always conduct business honestly and with integrity.</li>
          <li>Treat all customers and clients with respect and fairness.</li>
          <li>
            Ensure that all representations made regarding products and
            services are accurate and truthful.
          </li>
          <li>
            Comply with all laws and regulations related to business
            activities.
          </li>
          <li>Maintain confidentiality of all customer information.</li>
          <li>
            Avoid any actions that could harm the reputation of Rapidexie.
          </li>
        </ul>
      </section>
    </div> */}

<div className="container mx-auto px-4 pt-8 pb-1">
      
      <section className="mb-6">
        <h1 className="text-4xl font-bold mb-6 text-center">Welcome to Rapidexie</h1>
        <p className="mb-4 text-base">
          We Rapidexie as a Company want to bring in your notice that these Terms and Conditions govern your use of our website, products, and services. By purchasing our products or participating in our RAPIDEXIE program, you agree to these Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4 text-base ml-6">
          By accessing or using our products, services, or website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our products or services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">2. Products and Services</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.1 Product Information</h3>
        <p className="mb-4 text-base ml-6">
          We strive to provide accurate product descriptions and pricing. However, we do not warrant that product descriptions or other content are accurate, complete, reliable, or error-free.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.2 Pricing and Payment</h3>
        <p className="mb-4 text-base ml-6">
          Prices for products are listed on our website and are subject to change without notice. Payment must be made through our accepted payment methods. We reserve the right to refuse or cancel any orders.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">3. RAPIDEXIE Participation</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.1 Eligibility</h3>
        <p className="mb-4 text-base ml-6">
          To participate in our RAPIDEXIE program, you must be at least 18 years old and comply with all applicable laws and regulations.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.2 Enrolment</h3>
        <p className="mb-4 text-base ml-6">
          You may enroll in our RAPIDEXIE program by completing the registration process and purchasing a starter kit or product package, as specified on our website.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.3 Compensation Plan</h3>
        <p className="mb-4 text-base ml-6">
          Details of our compensation plan are available on our website. Compensation is based on sales and recruitment activities as outlined in the plan.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">3.4 Independent Contractor</h3>
        <p className="mb-4 text-base ml-6">
          As a participant in our RAPIDEXIE program, you are an independent contractor and not an employee of the Company. You are responsible for your own taxes and business expenses.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">4. Conduct and Compliance</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">4.1 Prohibited Activities</h3>
        <p className="mb-4 text-base ml-6">
          You agree not to engage in any fraudulent, deceptive, or unethical practices. This includes, but is not limited to, false advertising, misleading statements, and unauthorized use of Company trademarks.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">4.2 Compliance with Laws</h3>
        <p className="mb-4 text-base ml-6">
          You must comply with all applicable laws and regulations in your conduct as an RAPIDEXIE participant.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">4.3 Termination</h3>
        <p className="mb-4 text-base ml-6">
          We reserve the right to terminate your participation in the RAPIDEXIE program or your access to our other products and services if you violate these Terms or engage in illegal or unethical conduct.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">5. Intellectual Property</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">5.1 Ownership</h3>
        <p className="mb-4 text-base ml-6">
          All intellectual property rights related to our products, services, and website, including trademarks, logos, and copyrights, are owned by the Company.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">5.2 Use of Materials</h3>
        <p className="mb-4 text-base ml-6">
          You may use Company materials only as authorized and for the purpose of promoting our products and services within the guidelines provided by us.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">6. Limitation of Liability</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">6.1 Disclaimer of Warranties</h3>
        <p className="mb-4 text-base ml-6">
          Our products and services are provided “as is” and “as available” without warranties of any kind, either express or implied.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">6.2 Limitation of Liability</h3>
        <p className="mb-4 text-base ml-6">
          To the fullest extent permitted by law, the Company is not liable for any indirect, incidental, special, or consequential damages arising from your use of our products or participation in our RAPIDEXIE program.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">7. Indemnification</h2>
        <p className="mb-4 text-base ml-6">
          You agree to indemnify and hold harmless the Company, its affiliates, officers, directors, and employees from any claims, liabilities, damages, losses, and expenses arising from your use of our products or services or your participation in our RAPIDEXIE program.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">8. Changes to Terms</h2>
        <p className="mb-4 text-base ml-6">
          We may update these Terms from time to time. Any changes will be posted on our website, and your continued use of our products or services constitutes your acceptance of the updated Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">9. Governing Law</h2>
        <p className="mb-4 text-base ml-6">
          These Terms are governed by and construed in accordance with the laws of [Noida Juridiction], without regard to its conflict of laws principles.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mt-2">10. Contact Information</h2>
        <p className="mb-6 text-base ml-6">
          For any questions about these Terms, please contact us at:
        </p>
        <address className="not-italic mt-6 pt-6 text-base">
          Rapidexie <br />
          D-9, Vyapar Marg, Sector-3 Noida <br />
          <a href="mailto:info@rapidexie.com" className="text-base text-blue-600">info@rapidexie.com</a> <br />
          <a href="tel:+919818755350" className="text-base text-blue-600">9818755350</a>
        </address>
      </section>
    </div>
  </>
  )
}

export default TermConditions