import React from 'react';
import { Col, Row } from 'antd';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import { useCart } from '../../../utils/Cartcontext';
import { useWishlist } from '../../../utils/WishListContextr';
import { Link } from 'react-router-dom';

const WishList = () => {
    const { wishlist, removeFromWishlist  } = useWishlist();
    const { addToCart } = useCart();
    const imageUrl = `${process.env.REACT_APP_Image_URL}/products/`;

    const handleMoveToCart = (item : any, size : any) => {
        addToCart(item.product, size);
        removeFromWishlist(item.product._id);   
    };

    return (
        <div className="lg:mt-40 xl:mt-46 md:mt-36 sm:mt-36 xs:mt-12  mt-12 md:pl-40 md:pr-40 pt-12 pb-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Col span={24} className='d-flex justify-between p-2 font-bold '>
                        <h1 className="text-xl font-semibold mb-4">My Basket ({wishlist.length} {wishlist.length === 1 ? 'item' : 'items'})</h1>
                    </Col>
                    {wishlist.length > 0 ? (
                        <>
                            {wishlist.map((item : any, index : any) => (
                                <Row className='bg-gray-100 mb-2' key={index}>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className='d-flex justify-center align-items-top mt-4 '>
                                        <img src={imageUrl + item.product.images[0]} alt={item.product.name} className="w-16 h-20 object-cover rounded-lg d-flex justify-center " />
                                    </Col>
                                    <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16}>
                                        <div className="text-lg font-semibold text-orange-500 hover:text-orange-500  pt-2 pb-2">
                                            <Link to={`/product/${item.product.slug}`} className="text-lg font-semibold text-orange-500 hover:text-orange-500" >{item.product.name}</Link>
                                        </div>
                                        <div className="text-gray-600 text-sm font-bold pb-2">₹{item.product.discountedPrice.toFixed(2)} <del>₹{item.product.price.toFixed(2)}</del> <span className="text-orange-500">You save</span> ₹{(item.product.price - item.product.discountedPrice).toFixed(2)}</div>
                                        <div className="text-gray-600 text-sm pt-2  pb-5">IP Points: <span className='font-bold'> {item.product.ipQty} </span> </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={3} lg={5} xl={5} xxl={5} className='d-flex text-lg justify-center align-items-center'>
                                        <div className="flex items-center justify-end mt-2">
                                            {!item.product.isUpcoming && (
                                                <button  onClick={() => handleMoveToCart(item, item.size)} className="ml-4 bg-orange-500 text-white  d-flex justify-center items-center">
                                                    <p className="text-sm p-2">Add to Cart</p>
                                                </button>
                                            )}
                                            <button onClick={() => removeFromWishlist(item.product._id)} className="ml-4 text-orange-500">
                                                <AiOutlineDelete size={24} />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    ) : (
                        <div className="p-4">WishList is empty</div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default WishList;
