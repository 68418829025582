import React, { useEffect, useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import { Switch, Table, Spin, Card, Col, Row, Input, message, Modal, Button } from 'antd';
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { getcolores, colordelete, coloreActive } from '../../../utils/API';

interface SliderData {
  _id: number;
  name: string;
  active: boolean;
  __v: number;
}

const ProductColore: React.FC = () => {
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchColors = () => {
    setLoading(true);
    getcolores()
      .then((response: any) => {
        setColors(response.colors);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleEditRedirect = (record: any) => {
    navigate(`/backend/color/${record._id}/edit`);
  };

  const showDeleteModal = (record: any) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedRecord) {
      colordelete(selectedRecord._id)
        .then((response: any) => {
          if (response.status === 200) {
            message.success(response.data.message);
            fetchColors();
          } else {
            message.error("Something went wrong");
          }
        })
        .catch((error: any) => {
          message.error(error.response.data.message);
        })
        .finally(() => {
          setIsModalVisible(false);
          setSelectedRecord(null);
        });
    }
  };

  const ColoreActiveInactive = (record: any, checked: boolean) => {
    coloreActive(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          fetchColors();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean, record: any) => (
        <Switch
          checked={isActive}
          className={isActive ? 'bg-orange-500' : 'bg-gray-500'}
          onChange={(checked: boolean) => ColoreActiveInactive(record, checked)}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: any) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon' 
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            onClick={() => showDeleteModal(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={16}
          sm={16}
          md={18}
          xl={20}
          xxl={21}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Manage Colors</h2>
        </Col>
        {/* <Col xs={24} sm={24} md={24} xl={18} xxl={18} className=""> */}
          {/* <Row gutter={16} className="float-center xs:m-2"> */}
            {/* <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col> */}
            {/* <Col xs={24} sm={24} md={10} xl={12} xxl={12} className=''> */}
              {/* <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-75"
                placeholder="Search..."
                allowClear
                autoComplete='off'
                // onChange={(e) => handleSearch(e.target.value)}
              /> */}
            {/* </Col> */}
            <Col xs={8} sm={8} md={6} xl={4} xxl={3} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/color/add"} >
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Color</div>
                </button>
              </Link>
            </Col>
          {/* </Row> */}
        {/* </Col> */}
      </Row>
      <Row>
        <Col style={{ backgroundColor: "#ffffff", borderRadius: "12px" }} xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={colors || []} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleDelete}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
      >
        <p>Are you sure you want to delete this color?</p>
        <div style={{ textAlign: 'end' }}>
          <Button
            onClick={handleDelete}
            type="primary"
            className="rounded"
            style={{ backgroundColor: "orange", color: "white", marginRight: "1rem" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setIsModalVisible(false);
              setSelectedRecord(null);
            }}
            className="rounded bg-red-500 text-white"
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProductColore;
