import { Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { UploadFile } from "antd/lib/upload";
import { BiUpload } from "react-icons/bi";
import { RcFile } from "antd/es/upload";
import { FaArrowLeft } from "react-icons/fa";
import {  addTestimonials } from "../../../utils/API";

interface Category {
  _id: string;
  name: string;
  categoryId: string | null;
}

export const AddTestimonials = () => {
//   const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
//   const [isHome, setIsHome] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const navigate = useNavigate();



  const handleAddCategory = (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("image", file.originFileObj as Blob);
      });
    }

    addTestimonials(formData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Testimonial added successfully");
        }
        navigate("/backend/testimonials");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleImage = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    // Display preview for the first file in fileList
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setPreviewImage(null as any); // Clear preview if fileList is empty
    }
    setFileList(newFileList); // Update fileList state
  };


  return (
    <div>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Testimonial</h2>
        </Col>
        {/* <Col span={12}></Col> */}
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/testimonials"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row align={"middle"} gutter={[16, 16]} >
        <Col span={24}>
         
            <Form
              onFinish={handleAddCategory}
              initialValues={{ categoryId: "null" }}
            >
               <Card className="bg-white" style={{ minHeight: "80vh" }}>
                <Row className="border border-warning-2 p-5 bg-white rounded-md"  style={{ marginLeft: 0, marginRight: 0 }}>
            <Col span={24}>
            <Row gutter={[16, 16]} >
                <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">Name </label>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item name="name">
                    <Input size="large"
                      className="rounded border border-1"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
             
          <Col span={24}>
             
          <Row gutter={[16, 16]}>
              <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col
                  xs={24} sm={8}
                  className="d-flex justify-content-start"
                >
                  <Upload
                    listType="picture-card"
                    name="image"
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    fileList={fileList}

                    onChange={handleImage}
                    maxCount={1}
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={()=> false}
                  >
                    {fileList.length === 0 ? (
                      <div>
                        <Button icon={<BiUpload />}>Upload</Button>
                      </div>
                    ) : (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </Upload>
                  <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancelPreview}
                  >
                    <img
                      alt="Preview"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>

              </Row>
          </Col>
                    <Col span={24}>
                    <Row gutter={[16, 16]}>
              <Col xs={24} sm={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Description <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={8} className="pt-2">
                  <Form.Item
                    name="description"
                    rules={[{ required: true, message: "Please input description!" }]}
                  >
                    <Input.TextArea
                      size="large"
                      className="rounded border border-1 pt-2"
                      name="description"
                    />
                  </Form.Item>
                </Col>

              </Row>
                    </Col>
                    <Col span={24}>
                    <Row gutter={[16, 16]} justify={"center"}>
                <Col span={24} className="d-flex justify-content-center">
                  <button
                    className="btn-brand"
                    type="submit"
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </Col>
              </Row>
                    </Col>
              </Row>
              </Card>
          
            </Form>
        
        </Col>
      </Row>
    </div>
  );
};