import React, { useState } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Input,
  Spin,
} from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { InboxOutlined } from "@ant-design/icons";
import { uploadImage } from "../../../utils/API";
import { Link, useNavigate } from "react-router-dom";
import { BiUpload } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";

const { Dragger } = Upload;

const AdminSliderAdd: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [loading , setLoading] = useState(false)
  const navigate = useNavigate();

  const handleCancelPreview = () => setPreviewVisible(false);

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);
  };

  const onPreview = async (file: UploadFile) => {
    try {
      const imageUrl = URL.createObjectURL(file.originFileObj as Blob);
      setPreviewImage(imageUrl);
      setPreviewVisible(true);
    } catch (error) {
      console.error("Error previewing image:", error);
      message.error("Failed to preview image");
    }
  };

  const handleUploadImage = async () => {
    setLoading(true)
    if (fileList.length === 0) {
      message.error("Please select an image first");
      return;
    }

    if (!name.trim()) {
      message.error("Please enter a name");
      return;
    }

    const file = fileList[0];
    try {
      const imageUrl = await uploadImage(
        file.originFileObj as Blob,
        name as string
      );
      message.success("Image uploaded successfully");
      setLoading(false)
      navigate("/backend/slider");
      // Do something with the imageUrl if needed
    } catch (error) {
      setLoading(false)
      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
    }
  };

  const handleImage = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    // Display preview for the first file in fileList
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setPreviewImage(null as any); // Clear preview if fileList is empty
    }
    setFileList(newFileList); // Update fileList state
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Slider</h2>
        </Col>

        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/slider"} >
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={24}
                  className="d-flex justify-content-start align-items-center"
                ></Col>
                <Col span={24}>
                  <Row>
                    <Col
                     xs={24} sm={24} md={4}
                      className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                    >
                      <label className="font-bold">
                        Slider Name <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={20}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Slider name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Slider Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)} // Update the name state when the input value changes
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="mb-5">
                  <Row>
                    <Col
                     xs={24} sm={24} md={4}
                      className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                    >
                      <label className="font-bold">
                        Slider Image <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col
                      xs={24} sm={24} md={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Upload
                        name="image"
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        fileList={fileList}
                        onChange={handleImage}
                        maxCount={1}
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={(file) => {
                          // Validate file type and size
                          const isJpgOrPng =
                            file.type === "image/jpeg" ||
                            file.type === "image/png";
                          if (!isJpgOrPng) {
                            message.error("You can only upload JPG/PNG file!");
                          }
                          const isLt2M = file.size / 1024 / 1024 < 1;
                          if (!isLt2M) {
                            message.error("Image must be smaller than 1MB!");
                          }
                          return isJpgOrPng && isLt2M;
                        }}
                      >
                        {fileList.length === 0 ? (
                          <div>
                            <Button className="w-full" icon={<BiUpload />}>Upload</Button>
                          </div>
                        ) : (
                          <img
                            src={previewImage}
                            alt="Preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        )}
                      </Upload>
                      <Modal
                        open={previewVisible}
                        footer={null}
                        onCancel={handleCancelPreview}
                      >
                        <img
                          alt="Preview"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={20}
                  style={{
                    textAlign: "right",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <button
                    className="btn-brand"
                    onClick={handleUploadImage}
                  >
                    {
                      loading ? <Spin  tip="Uploading..."  /> : "Upload"
                    }
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default AdminSliderAdd;
