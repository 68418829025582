import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductBySlug, submitReviewAPI } from "../../../utils/API";
import {
  Button,
  Card,
  Carousel,
  Col,
  Form,
  Input,
  message,
  notification,
  Pagination,
  Row,
  Tabs,
} from "antd";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineShopping } from "react-icons/ai";
import { config } from "../../../utils/config";
import { TabsProps } from "antd";
import { useCart } from "../../../utils/Cartcontext";
import { BiCheck } from "react-icons/bi";
import { AuthContext } from "../../Auth/AuthContext";

const ProductDetail = () => {
  const [records, setProductData] = useState<any>({});
  const { slug } = useParams<{ slug: string }>();
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const { addToCart } = useCart();
  const imageUrl = process.env.REACT_APP_Image_URL + "/products/";
  const [selectedRating, setSelectedRating] = useState(0);
  const { authData } = useContext(AuthContext);
  const isLoggedIn = authData?.token;
  const [form] = Form.useForm();
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);


  const handleAddToCart = (product: any) => {
    if (product.sizeId && product.sizeId.length > 0 && !selectedSize) {
      message.error('Please select size');
      return;
    }
    notification.open({
      message: "Success",
      icon: <BiCheck style={{ color: "#52c41a" }} />,
      description: "Cart updated successfully!",
      placement: "topRight",
      duration: 3,
    });
    addToCart(product, selectedSize);
  };

  useEffect(() => {
    if (records.images && records.images.length > 0) {
      setMainImage(records.images[0]);
    }
  }, [records]);

  const fetchProductDetail = async (slug: string) => {
    try {
      const response = await getProductBySlug(slug);
      // console.log(response);
      setProductData(response);
    } catch (error) {
      message.error("Error fetching product detail:");
    }
  };

  useEffect(() => {
    if (slug) {
      fetchProductDetail(slug);
    }
  }, [slug]);

  const handleImageClick = (image: string) => {
    setMainImage(image);
  };

  const calculateAverageRating = (reviews: any) => {
    if (reviews.length === 0) {
      return 0;
    }
    const totalRating = reviews.reduce((sum: any, review: any) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  useEffect(() => {
    if (records.reviews && records.reviews.length > 0) {
      setAverageRating(calculateAverageRating(records.reviews));
      setTotalReviews(records.reviews.length);
    }
  }, [records]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calculate the index for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentReviews = records?.reviews?.slice(startIndex, endIndex);

  const renderStars = (
    rating: number,
    onSelectRating?: (rating: number) => void
  ) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    const handleStarClick = (starIndex: number) => {
      if (onSelectRating) {
        onSelectRating(starIndex);
      }
    };


    return (
      <div className="flex">
        {Array.from({ length: fullStars }, (_, index) => (
          <FaStar
            size={20}
            key={index}
            className="text-orange-500 cursor-pointer"
            onClick={() => handleStarClick(index + 1)}
          />
        ))}
        {halfStars === 1 && (
          <FaStarHalfAlt
            size={20}
            className="text-orange-500 cursor-pointer"
            onClick={() => handleStarClick(fullStars + 0.5)}
          />
        )}
        {Array.from({ length: emptyStars }, (_, index) => (
          <FaRegStar
            size={20}
            key={fullStars + index + halfStars}
            className="text-orange-500 cursor-pointer"
            onClick={() => handleStarClick(fullStars + index + 1)}
          />
        ))}
      </div>
    );
  };

  const handleSubmitReview = async (values: any) => {
    const customerId = localStorage.getItem("customerId") || "";
    const userId = customerId?.replace(/"/g, "");

    try {
      const payload = {
        ...values,
        rating: selectedRating,
        productId: records._id,
        userId,
      };
      const response = await submitReviewAPI(payload);
      setSelectedRating(0);
      form.resetFields();
      message.success(response.message);
    } catch (error) {
      message.error("Error submitting review");
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <div className="font-bold">Description</div>,
      children: (
        <p className="font- text-base text-gray-500 pt-2">
          {<div dangerouslySetInnerHTML={{ __html: records.description }} />}
        </p>
      ),
    },
    {
      key: "2",
      label: <div className="font-bold">Reviews</div>,
      children: (
        <>
          {/* <Row>
            <Col span={12}>
              <div
                className=" justify-center items-center overflow-y-auto pt-2"
                style={{ maxHeight: "400px", height: "100%" }}
              >
                {records.reviews && records.reviews.length > 0 ? (
                  records.reviews.map((review: any, index: any) => (
                    <div key={index} className="w-full p-4 border-b">
                      <div className="flex items-center">
                        {renderStars(review.rating)}
                        <span className="pl-2">{review.rating} Stars</span>
                      </div>
                      <p className="pt-2">{review.review}</p>
                      <p className="text-gray-500 text-sm">
                        By {review.userId.firstName}
                      </p>
                      <p className="text-gray-400 text-xs">
                        {new Date(review.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No reviews yet.</p>
                )}
              </div>
            </Col>
            <Col span={12}>
              {isLoggedIn && (
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={handleSubmitReview}
                  className="w-full p-4 mt-4"
                >
                  <label className="block text-gray-700 font-bold mb-2">
                    <span className="text-2xl">Rate this product</span>
                  </label>
                  <label className="block text-gray-700 font-bold mb-2">
                    Rating:
                  </label>
                  <Form.Item>
                    <div className="flex items-center">
                      {renderStars(selectedRating, setSelectedRating)}
                      <span className="pl-2">{selectedRating} Stars</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="review"
                    label="Review"
                    rules={[
                      { required: true, message: "Please enter your review!" },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <button className="btn-brand">Submit </button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row> */}

          <Row>
            <Col span={12}>
              <div
                className=" justify-center items-center overflow-y-auto pt-2"
                style={{ maxHeight: "400px", height: "100%" }}
              >
                {currentReviews && currentReviews?.length > 0 ? (
                  currentReviews.map((review: any, index: any) => (
                    <div key={index} className="w-full p-4 border-b">
                      <div className="flex items-center">
                        {renderStars(review.rating)}
                        <span className="pl-2">{review.rating} Stars</span>
                      </div>
                      <p className="pt-2">{review.review}</p>
                      <p className="text-gray-500 text-sm">
                        By {review.userId.firstName}
                      </p>
                      <p className="text-gray-400 text-xs">
                        {new Date(review.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No reviews yet.</p>
                )}
              </div>

              {/* Pagination Component */}
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={records?.reviews?.length}
                onChange={handlePageChange}
                className=""
              />
            </Col>

            <Col span={12}>
              {isLoggedIn && (
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={handleSubmitReview}
                  className="w-full p-4 mt-4"
                >
                  <label className="block text-gray-700 font-bold mb-2">
                    <span className="text-2xl">Rate this product</span>
                  </label>
                  <label className="block text-gray-700 font-bold mb-2">Rating:</label>
                  <Form.Item>
                    <div className="flex items-center">
                      {renderStars(selectedRating, setSelectedRating)}
                      <span className="pl-2">{selectedRating} Stars</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="review"
                    label="Review"
                    rules={[
                      { required: true, message: "Please enter your review!" },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <button className="btn-brand">Submit</button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="lg:mt-60 xl:mt-52 md:mt-40 sm:mt-40 xs:mt-12 mt-12 md:pl-40 md:pr-40 pt-3 pb-3">
        {records && records.images && records.images.length > 0 ? (
          <div key={records._id}>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className=""
              >
                <div
                  className="relative overflow-hidden flex justify-center items-center" // Center the image horizontally and vertically
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {mainImage && (
                    <img
                      src={imageUrl + mainImage}
                      alt="Product Image"
                      className={`transition-transform duration-300 bg-gray-400 ${isHovered ? "scale-150" : "scale-100"
                        }`}
                      style={{
                        height: isHovered ? "70%" : "100%",
                        width: "70%",
                      }}
                    />
                  )}
                </div>

                {records.images && records.images.length > 3 ? (
                  <Carousel draggable autoplay slidesToShow={4} dots={false}>
                    {records.images.map((image: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => handleImageClick(image)}
                        className="cursor-pointer p-1 focus-visible:outline-none focus:ring-0"
                      >
                        <img
                          className={`h-36 rounded-lg ${image === mainImage ? "border-1 border-orange-500" : ""
                            }`}
                          src={imageUrl + image} // Use the complete image URL
                          alt={"Product Image " + index}
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className="flex space-x-2">
                    {records.images.map((image: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => handleImageClick(image)}
                        className="cursor-pointer p-1 focus-visible:outline-none focus:ring-0"
                      >
                        <img
                          className={`h-36 rounded-lg ${image === mainImage ? "border-1 border-orange-500" : ""
                            }`}
                          src={imageUrl + image} // Use the complete image URL
                          alt={"Product Image " + index}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
                className="p-2"
              >
                <p className="font-extrabold sm:text-sm md:text-md xl:text-3xl  text-5xl">
                  {records.name}
                </p>
                {!records.isUpcoming && (
                  <>
                    <p className="d-flex align-items-center pt-2 pb-2">
                      {renderStars(averageRating)}
                      <span className="pl-2">({totalReviews}) Reviews</span>
                    </p>



                    <p className="font-semibold text-red-900 text-lg">
                      Incentive Points (IP) {records.ipQty}
                    </p>
                    <p className="font-bold text-xl">
                      {config.currencySymbol} {records.discountedPrice}{" "}
                      <span
                        className="text-red-400 text-lg"
                        style={{ textDecoration: "line-through" }}
                      >
                        {config.currencySymbol} {records.price}{" "}
                      </span>{" "}
                      &nbsp;
                      <span className="text-green-400 text-sm">
                        ({records.discount}% OFF)
                      </span>{" "}
                    </p>
                  </>
                )}
                <p className="font- text-base text-gray-500 pt-2">
                  {
                    <div
                      dangerouslySetInnerHTML={{ __html: records.description }}
                    />
                  }
                </p>
                {!records.isUpcoming && (
                  <>
                    {/* {records.qty != null || records.qty != undefined
                    ?
                    <p
                      className="mt-2 text-base text-white bg-green-500 font-bold rounded-lg d-flex justify-center p-2 w-60"
                    >
                       {records.qty} Quantity Left
                    </p>
                    : ""} */}

                    <p className="mt-2">
                      {records.sizeId && records.sizeId.length > 0 ? (
                        <> Sizes </>
                      ) : null}
                      <div className="flex flex-wrap gap-2 mt-3">
                        {records.sizeId &&
                          records.sizeId.length > 0 &&
                          records.sizeId
                            .filter(
                              (size: any) =>
                                size.isDeleted === false &&
                                size.isActive === true
                            )
                            .map((size: any) => (
                              <p
                                key={size._id}
                                className={`border border-gray-400 p-1 ${selectedSize === size._id
                                  ? "bg-orange-500 text-white  cursor-pointer "
                                  : "border-1 border-black cursor-pointer"
                                  }`} // Highlight selected size
                                onClick={() => setSelectedSize(size._id)} // Update selectedSize
                              >
                                {size.name}
                              </p>
                            ))}
                      </div>
                    </p>
                    <>
                      <p className="btn pt-2 pb-2 w-60 pl-4 pr-4 m-2 mt-4 bg-orange-500 text-white d-flex justify-center  align-items-center  btn-lg">
                        <AiOutlineShopping
                          className="text-white  pr-1"
                          style={{ fontSize: "20px" }}
                        />{" "}
                        <p
                          className={`bg-orange-500 text-white p-1`}
                          onClick={() => {
                            handleAddToCart(records);
                          }}
                        >
                          Add To Cart
                        </p>
                      </p>
                    </>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs defaultActiveKey="1" items={items} />
              </Col>
            </Row>
          </div>
        ) : (
          "No records found"
        )}
      </div>
    </>
  );
};

export default ProductDetail;
