import React, { useState, ReactNode, useEffect } from 'react';
import { Layout } from 'antd';
import Footeruser from '../../Pages/common/Footer';
import HeaderUser from '../../Pages/common/Header';

const { Content } = Layout;

interface MasterProps {
    children: ReactNode;
}

const FrontendMaster: React.FC<MasterProps> = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false); // State to manage layout collapse
    const [hover, setHover] = useState(false); // State for hover effect, possibly for some menu or sidebar
    const [isSmallScreen, setIsSmallScreen] = useState(false); // State to track if the screen is small

    const SecondStyle = !isSmallScreen && hover ? '80px' : '';

    const headerStyle = {
        marginLeft: `${SecondStyle}`,
    };

    const handleResize = () => {
        if (window.innerWidth <= 900) {
            setIsSmallScreen(true); 
            setCollapsed(false); 
        } else {
            setIsSmallScreen(false); 
        }
    };

    useEffect(() => {
        handleResize(); // Initial check on mount
        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 
    

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [children]); 

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}> 
                <Layout style={{ ...headerStyle, backgroundColor: '#fff' }}>
                    <HeaderUser /> {/* Renders the header component */}
                   
                    <Content style={{ margin: '16px 16px', minHeight: '100vh' }}> 
                        {children} 
                    </Content>
                    <Footeruser /> 
                </Layout>
            </Layout>
        </>
    );
};

export default FrontendMaster;
