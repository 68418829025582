import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Row, Modal } from "antd";
import { FaStar } from "react-icons/fa";
import { Testimonials } from "../../utils/API";

const ClientReview = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const imageUrl = `${process.env.REACT_APP_Image_URL}/testimonial/`;
  const [reviews, setReviews] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1170 && window.innerWidth >= 1060) {
        setSlidesToShow(1);
      } else if (window.innerWidth > 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchTestimonialData = () => {
    Testimonials().then((res) => {
      setReviews(res.data);
    });
  };

  useEffect(() => {
    fetchTestimonialData();
  }, []);

  const showModal = (review : any ) => {
    setSelectedReview(review);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedReview(null);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          height: "auto",
          width: "100%",
        }}
      >
        <Row className="xl:pl-36 lg:px-0 lg:py-0">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            className="md:pl-12 md:pt-12 md:pb-12 text-center"
          >
            <p className="text-orange-500 font-bold text-md tracking-widest mb-3"></p>
            <p className="text-5xl font-extrabold tracking-tight">
              <p>Testimonials</p>
            </p>

          
            <div className="font-bold text-gray-500 pt-4" style={{ fontSize: "14px" }}>
              Trust score 4.5 (Based on 2,500 reviews)
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={16}
            xxl={16}
            className="md:pl-12 md:pt-12 md:pb-12"
          >
            <Carousel autoplay slidesToShow={slidesToShow} draggable dots={false}>
              {reviews.map((review : any , index : any) => (
                <div key={index} className="bg-white p-5 mr-24 ">
                  <Row gutter={16} justify="center" style={{ height: "200px" }}>
                    <Col span={6}>
                      <img
                        src={imageUrl + review.image}
                        className="mx-auto border-2 p-1 border-orange-500 w-16 h-16 rounded-full"
                        alt="Profile"
                      />
                    </Col>
                    <Col span={18} className="overflow-y-auto">
                      <p className="font-extrabold" style={{ fontSize: "20px" }}>
                        {review.name}
                      </p>
                      <p className="text-gray-500 pt-3" style={{ fontSize: "16px", textAlign: "justify" }}>
                        {review.description.slice(0, 100)}
                      </p>
                      <button onClick={() => showModal(review)} className="text-sm pb-2 text-orange-500 underline hover:text-orange-600 transition duration-300 ease-in-out">
                        Read More
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </div>

      <Modal
        title={selectedReview ? (
          <div className="font-bold">
{          selectedReview.name }
          </div>
        ) : null}
        visible={isModalVisible}
        
        onCancel={handleCancel}
        footer={null}
      >
        {selectedReview && (
          <div>
            <Row gutter={16}>
              {/* <Col span={6}>
                <img
                  src={imageUrl + selectedReview.image}
                  className="mx-auto border-2 p-1 border-orange-500 w-16 h-16 rounded-full"
                  alt="Profile"
                />
              </Col> */}
              <Col span={24}>
                {/* <p className="font-extrabold" style={{ fontSize: "20px" }}>
                  {selectedReview.name}
                </p> */}
                <p className="text-gray-500 pt-2" style={{ fontSize: "16px" , textAlign: "center" }}>
                  {selectedReview.description}
                </p>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ClientReview;
