import React, { useState, useEffect } from 'react';
import { Card, Col, DatePicker, Row, Spin, Table, message } from 'antd';
import { getAllTopEarners } from '../../../../utils/API';
import { Link } from 'react-router-dom';
import { RiAddBoxFill } from 'react-icons/ri';
import moment from 'moment';
import dayjs from 'dayjs';
import PayoutDates from '../../Geneology/Accuntdetails/PayoutDates';

const TopEarner = () => {

  const getLastSunday = () => {
    const today = moment();
    const lastSunday = today.day(today.day() === 0 ? -7 : 0).startOf('day');
    return lastSunday.format('YYYY-MM-DD');
  };



  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPages, setTotalPages] = useState(1);
  const [date, setDate] = useState(getLastSunday()); // State for selected date, defaulting to last Sunday

  const fetchData = (page: number, date: string) => {
   
    setLoading(true);
    getAllTopEarners( page, date)
      .then((response) => {
        setData(response.results);
        setTotalPages(response.totalPages);
        setCurrentPage(response.currentPage);
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Log the error for debugging
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  useEffect(() => {
    fetchData(currentPage, date);
  }, [date , currentPage]);


 



  // const handleDateChange = (date : any, dateString : any) => {
  //   setDate(dateString); // Update selected date
  // };
  const handleDateChange = (date: string) => {
    setDate(date); // Update selected date in state
  };

  const disabledDate = (current : any) => {
    // Disable future dates
    const today = moment().endOf('day');
    // Only allow Sundays
    return current && (current > today || current.day() !== 0);
  };


  const columns = [
    {
      title: 'Sr. No.',

      key: 'serialNo',
      render: (text : any, record : any, index : any) => index + 1,
    },
    {
      title: 'Id no',
      dataIndex: ['user', 'username'],
      key: 'username',
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: any, record: any) => (
        <span>{record.user.firstName} {record.user.lastName}</span>
      ),
    },
    {
      title: 'City',
      dataIndex: ['user', 'city'],
      key: 'city',
    },
    {
      title : "Earning Income",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
    }
  ];

  return (
    <div>
      <Row className="m-2" align={"middle"}>
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">TOP 50</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
        <Row gutter={16} className="m-2">
          <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
           
          </Col>
        </Row>
        </Col>
      </Row>
      <Row>
        <Card className="container w-100">
        <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
            {/* <DatePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              allowClear
              
              disabledDate={disabledDate}
              defaultValue={dayjs(date)} // Set default value to last Sunday
            /> */}
            <PayoutDates  dateChnageFunction={handleDateChange}/>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table
              scroll={{ x: 1000 }}
                columns={columns}
                dataSource={data}
                pagination={{
                  current: currentPage,
                  total: total,
                  pageSize: 10,
                  onChange: (page) => setCurrentPage(page),
                }}
                rowKey={record => record.userId}
              />
            </Spin>
          </Col>
        </Card>
      </Row>
    </div>
  );
};

export default TopEarner;