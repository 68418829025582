import { Card, Row, Table, Col, Input, message, Spin, Modal, Image, Button } from "antd";
import { AiFillDelete, AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import { RiAddBoxFill, RiImageEditFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { deleteProduct, getallproducts } from "../../utils/API";

interface Category {
  id: string;
  _id: string;
  name: string;
  category_id: string | null;
}

export const ProductTable = () => {
  const [datasouce, setDatasouce] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<any | null>(null);
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [colorFilter, setColorFilter] = useState('');
  const navigate = useNavigate();
  const imageLink = process.env.REACT_APP_Image_URL;

  const fetchProducts = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await getallproducts(filters);
      setDatasouce(response.reverse());
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/product/${record._id}/edit`);
  };
  const showDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        const response = await deleteProduct(deleteId).then((response: any) => {
          message.success(response.data.message);
          fetchProducts();
        })
      } catch (error) {
        message.error('Failed to delete category');
      } finally {
        setDeleteId(null);
      }
    }
  };

  const handleSearchChange = (e: any) => {
    setNameFilter(e.target.value);
    fetchProducts({ name: e.target.value, categoryId: categoryFilter, colorId: colorFilter });
  };

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Images
        </div>
      ),
      dataIndex: "images",
      key: "id",
      render: (images: string[]) => (
        <div>
          {images.length > 0 && (
            <Image
              src={imageLink + "/products/" + images[0]}
              alt={`Thumbnail 1`}
              style={{ width: "50px", height: "50px", marginRight: "5px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text: string) => text || "-",
      filter: (value: any, record: any) => record.name.includes(value),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Price
        </div>
      ),
      dataIndex: "price",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Discounted Price
        </div>
      ),
      dataIndex: "discountedPrice",
      render: (text: string) => text || "-",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Category
        </div>
      ),
      dataIndex: "categoryId",
      render: (category: any) => category ? category.name : "-",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Featured Product
        </div>
      ),
      dataIndex: "isFeatured",
      render: (text: boolean) => text ? "Yes" : "No"
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Top Rated
        </div>
      ),
      dataIndex: "isTopRated",
      render: (text: boolean) => text ? "Yes" : "No"
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Best Selling
        </div>
      ),
      dataIndex: "isBestSelling",
      render: (text: boolean) => text ? "Yes" : "No"
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Upcoming
        </div>
      ),
      dataIndex: "isUpcoming",
      render: (text: boolean) => text ? "Yes" : "No"
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Action
        </div>
      ),
      dataIndex: "action",
      render: (text: any, record: any) => (
        <div className="d-flex">
          <FaEdit className='editMenuIcon' onClick={() => handleEditRedirect(record)} />
          <AiFillDelete className='editMenuIcon' onClick={() => showDeleteConfirmation(record._id)} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={12} md={8} xl={12} xxl={12} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Manage Products</h2>
        </Col>
        <Col xs={24} sm={16} md={16} xl={12} xxl={12}>
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col> */}
            <Col xs={17} sm={17} md={16} xl={16} xxl={16} className="">
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-100 "
                placeholder="Search..."
                allowClear
                autoComplete="off"
                onChange={handleSearchChange}
              />
            </Col>
            <Col xs={7} sm={7} md={8} xl={8} xxl={8} style={{ paddingRight: "-8px !important" }}>
              <Link to={"/backend/product/add"}>
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Product</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row className="m-2" align={"middle"}>
        <Col 
          xs={24}
          sm={24}
          md={6}
          xl={6}
          xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Manage Products</h2>
        </Col>

        <Col xs={24} sm={24} md={18} xl={18} xxl={18} className="">
          <Row gutter={16} className="float-center xs:m-2">
          <Col xs={0} sm={0} md={7} xl={7} xxl={7}></Col>
        
            <Col xs={24} sm={24} md={12} xl={12} xxl={12} className=''>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-full"
                placeholder="Search..."
                allowClear
                onChange={handleSearchChange}
              />
            </Col>

            <Col xs={24} sm={24} md={5} xl={5} xxl={5} style={{ paddingRight: '-8px !important' }}>
            <Link to={"/backend/product/add"}>
                <button className="d-flex justify-content-center align-items-center w-100 btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Product</div>
                </button>
              </Link>
            </Col>

          </Row>
        </Col>
      </Row> */}
      <Row>
        <Card className="w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table scroll={{ x: 1000 }} columns={columns} dataSource={datasouce} />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        closable={false}
        footer={null}
      >
        <div style={{ textAlign: 'start', marginBottom: '1rem' }}>
          <p>Are you sure you want to delete this Product?</p>
        </div>
        <div style={{ textAlign: 'end' }}>
          <Button
            onClick={handleDelete}
            type="primary"
            className="rounded"
            style={{ backgroundColor: "orange", color: "white", marginRight: "1rem" }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
