import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Image, Input, Modal, Popover, Row, Table, message } from 'antd';
import { getRewardData, getusertree } from '../../../../utils/API';
import { AuthContext } from '../../../Auth/AuthContext';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';

const RewordStatus: React.FC = () => {
    const [PageData, setPageData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    interface Record { }
            
    const customerId = localStorage.getItem("customerId");
    const { authData } = useContext(AuthContext);
    const role_id = authData.roleId;
    const userId = customerId?.replace(/"/g, "");

    // useEffect(() => {
    //     if (userId) {
    //         getusertree(userId).then((res) => {
    //             const createPageData = (club: string, criteria: string, requiredPairs: number) => ({
    //                 club,
    //                 criteria,
    //                 eligibility: res.matchingPair > requiredPairs ? "Yes" : "No",
    //                 pairs: requiredPairs,
    //             });

    //             setPageData([
    //                 createPageData("Bike", "3 Months", 1000),
    //                 createPageData("Normal Car", "6 Months", 2000),
    //                 createPageData("Luxury Car", "9 Months", 3000),
    //                 createPageData("50L Flat", "12 Months", 4000),
    //             ]);
    //         });
    //     } else {
    //         setPageData([]);
    //     }
    // }, [userId]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await getRewardData();
            setPageData(response.eligibleRewards);
      console.log(response.eligibleRewards);
            setLoading(false);

        } catch (error) {
            message.error("Network error. Please try again.");
        } finally {
            setLoading(false);
        }
        // if (userId) {
        //     // getusertree(userId)
        //     getRewardData(userId)
        //         .then((res) => {
        //             setPageData(res.eligibleRewards);
        //         });
        //     setLoading(false);
        // } else {
        //     setPageData([]);
        //     setLoading(false);
        // }
    }


    useEffect(() => {
        getData();
        // setLoading(true);
        // if (userId) {
        //     // getusertree(userId)
        //     getRewardData(userId)
        //         .then((res) => {
        //             // console.log(res.eligibleRewards);
        //             // let inProgressShown = false; // Flag to track if "In Progress" has been shown

        //             // const createPageData = (club: string, criteria: string, requiredPairs: number) => {
        //             //     let eligibility;
        //             //     if (!inProgressShown && res.matchingPair < requiredPairs) {
        //             //         eligibility = "In Progress"; // Show "In Progress" for the first occurrence
        //             //         inProgressShown = true; // Set the flag so that subsequent rows get "Yes"
        //             //     } else {
        //             //         eligibility = "No"; // After the first "In Progress", show "Yes" for all rows
        //             //     }

        //             //     return {
        //             //         club,
        //             //         criteria,
        //             //         eligibility,
        //             //         pairs: requiredPairs,
        //             //     };
        //             // };

        //             setPageData(res.eligibleRewards);
        //             // setPageData([
        //             //     createPageData("Bike", "4 Months", 500),
        //             //     createPageData("Alto Car", "4 Months", 750),
        //             //     createPageData("Breeza", "4 Months", 1000),
        //             //     createPageData("Toyota Hycross", "6 Months", 2000),
        //             //     createPageData("Mercedes Benz", "9 Months", 3000),
        //             //     createPageData("Flat/Apartment(*In Delhi NCR Or 50L cash)", "12 Months", 4000),
        //             // ]);
        //         });
        //     setLoading(false);
        // } else {
        //     setPageData([]);
        //     setLoading(false);
        // }
    }, [userId]);

    const columns = [
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Club</div>,
            dataIndex: 'club',
            render: (text: any) => <span style={{ fontWeight: 'bold', fontSize: "15px" }}>{text}</span>
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Criteria</div>,
            dataIndex: 'criteria',
            render: (text: any) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Eligibility</div>,
            dataIndex: 'eligibility', // Corrected the key name
            render: (text: any) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "15px" }}>Pairs</div>,
            dataIndex: 'pairs',
            render: (text: any) => <span style={{ fontSize: "15px", color: "#a6a6a6" }}>{text}</span>
        },
        ...(role_id === 1 ? [{
                title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
                dataIndex: 'action',
                render: (text: any, record: any) => (
                    <div className='d-flex'>
                        <FaEdit
                            className='editMenuIcon'
                        />
                    </div>
                ),
            }] : []),
        
    ];

    const now = new Date();
    let previousMonth = now.getMonth() - 1;
    let previousYear = now.getFullYear();

    if (previousMonth === -1) {
        previousMonth = 11; // December
        previousYear--;
    }

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const currentMonth = monthNames[previousMonth];
    const currentYear = previousYear;

    const monthClosingTitle = `${currentMonth}-${currentYear} Month Closing`;

    return (
        <>
            <Row className="m-2" align={"middle"}>
                <Col
                    xs={24}
                    sm={24}
                    md={9}
                    xl={6}
                    xxl={6}
                    className="d-flex justify-content-start font-bold"
                >
                    <h2 className="text-2xl">Reward Status</h2>
                </Col>
            </Row>
            <Row>
                <Col
                    style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
                    xs={24}
                    sm={24}
                    md={24}
                    xl={24}
                    xxl={24}
                >
                    <Card className="bg-white border" style={{ borderRadius: "12px" }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <div style={{ overflowX: 'auto' }}>
                                <p className='font-bold'>Valid From The Date of Joining</p>
                                    <Table loading={loading} columns={columns} dataSource={PageData} scroll={{ x: true }} pagination={false} />
                            </div>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default RewordStatus;