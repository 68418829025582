// src/HelloWorld.tsxCopy code
import React from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { Select } from "antd";
import { addCustomer } from "../../utils/API";
import { FaArrowLeft } from "react-icons/fa";

const { Option } = Select;


interface Country {
  country_code: string;
  country_code3: string;
  country: string;
  capital: string;
  region: string;
  subregion: string;
  states: State[];
}

interface State {
  country_code: string;
  country: string;
  subdivision: string | null;
  cities: string[];
}
const CustomerMasterForm: React.FC = () => {
  const [form] = Form.useForm();


  const handleSubmit = (values: any) => {
    addCustomer(values)
      .then((response: any) => {
        message.success(response.data.message);
        navigate("/backend/users");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
  
  const navigate = useNavigate();



  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add User</h2>
        </Col>
        
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/users"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} className="bg-white">
      <Card style={{ backgroundColor: "#ffffff" }}>
      <Row
        
        className="border border-warning-2 p-2 md:p-5 bg-white rounded-md"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">Sponser Id  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="sponsorId"
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter Sponser Id"
                    }
                  ]
                }
                >
                  <Input size="large" className="rounded border border-1"  placeholder="Sponser Id" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">Position  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="placement"
                            rules={[{ required: true, message: "Please Select your Position!" }]}
                        >
                            <Select className="rounded" size="large" placeholder="Select Position">
                                <Option value="1">Left</Option>
                                <Option value="2">Right</Option>
                            </Select>
                        </Form.Item>
              </Col>
            </Row>
          </Col>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">First Name  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="firstName"
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter User name"
                    }
                  ]
                }
                >
                  <Input size="large" className="rounded border border-1"  placeholder="User Name" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">Last Name  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="lastName"
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter User name"
                    }
                  ]
                }
                >
                  <Input size="large" className="rounded border border-1"  placeholder="User Name" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl= {24} >
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">User Email <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="email"
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter user email"
                    },
                    {
                      type:"email",
                      message:"Please Enter valid email"
                    },
                  ]
                }
                >
                  <Input size="large" className="rounded border border-1"  placeholder="User email" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">Contact  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="mobileNo" 
                rules={
                  [
                    {
                      required:true,
                      message:"Please Enter Contact"
                    }
                  ]
                }
                >
                  <Input size="large" className="rounded border border-1"  placeholder="Contact" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white mb-2 sm:mb-5  ">
                <label className="font-bold">Password  <span className="text-danger">*</span></label>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="password" 
                rules={[
                  { required: true, message: "Please enter your Password!" },
                  { min: 6, message: "Password must be at least 6 characters" },
                  { max: 20, message: "Password must be less than 20 characters" },
                  {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message: "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                  },
              ]}
                >
                  <Input size="large" className="rounded border border-1"  placeholder="Password" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
     
   
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={24}
            xxl={24}
            className="d-flex justify-content-center"
          >
            <button className="btn-brand">Add User</button>
          </Col>
        </Row>
      </Card>
      </Form>
    </div>
  );
};
export default CustomerMasterForm;
