import React, { useState, useEffect, useContext } from 'react';
import { Col, Input, Modal, Row, Table, message } from 'antd';
import { getAllTripodData, updateTripodStatus } from '../../../../utils/API';
import { SearchOutlined } from "@ant-design/icons";
import { RiAddBoxFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../Auth/AuthContext';
import moment from 'moment';

const TripodData = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { authData } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [messageValue, setMessageValue] = useState('');
  const roleId = authData.roleId;

  const fetchData = (page : any) => {
    setLoading(true);
    getAllTripodData(page)
    .then((response: any) => {
      let data;
      const customerID = localStorage.getItem('customerId') || '';
      const userId = customerID.replace(/"/g, '');
      if (roleId === 1) {
        data = response.data.filter((item: any) => !item.isDeleted);
      } else {
        data = response.data.filter((item: any) => item.submitedId?._id === userId );
      }
      setData(data.reverse());
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
      setLoading(false);
    })
    .catch((error: any) => {
      message.error('Network error. Please try again.');
      setLoading(false);
    }); 
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleApprove = () => {
  

    if (selectedRecord) {
      updateTripodStatus(selectedRecord._id, 1, messageValue)
        .then(() => {
          message.success('Approved successfully');
          setIsModalVisible(false);
          fetchData(currentPage);
        })
        .catch(() => {
          message.error('Approval failed');
        });
    }
  };

  const handleReject = () => {
    if (selectedRecord == null) {
      message.error('Please select a record to reject');
      return;
    }

    if (selectedRecord) {
      updateTripodStatus(selectedRecord._id, 2, messageValue)
        .then(() => {
          message.success('Rejected successfully');
          setIsModalVisible(false);
          fetchData(currentPage);
        })
        .catch(() => {
          message.error('Rejection failed');
        });
    }
  };

  const columns = [
    {
      title: 'Sr. No',
      dataIndex: 'srno',
      key: 'id',
      render: (text : any, record : any, index : any) => <p>{index + 1}</p>,
    },
    {
      title: 'Tripod FirstId No.',
      dataIndex: ['mainId', 'username'],
      key: 'mainIdUsername',
      render: (text : any) => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Tripod SecondId No.',
      dataIndex: ['leftId', 'username'],
      key: 'leftIdUsername',
      render: (text : any) => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Tripod ThirdId No.',
      dataIndex: ['rightId', 'username'],
      key: 'rightIdUsername',
      render: (text : any) => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Submitted By',
      dataIndex: ['submitedId', 'username'],
      key: 'submitedIdUsername',
      // render: (text : any) => <p>{text ? text : '-'}</p>,
      render: (text : any , record : any) => {
        return(<p>{record.submitedId.username} {record.submitedId.roleId.includes(1) ?" - Admin" : " - Support Center"}</p>
      )},
    },
    {
      title: 'Submitted Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text : any) => <p>{text ? moment(text).format('DD-MM-YYYY') : '-'}</p>,
    },
  
        {
          title: 'Status',
          dataIndex: 'isApprove',
          key: 'isApprove',
          render: (text : any , record : any) => (
            <p>
            {roleId === 1 ? (
              <button
                className={`bg-orange-500 text-white rounded-md p-2 ${
                  text === 1 || text === 2 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={() => {
                  if (text !== 1 && text !== 2) {
                    setSelectedRecord(record);
                    setIsModalVisible(true);
                  }
                }}
                disabled={text === 1 || text === 2}
              >
                {
                  text === 0 ? 'Approve' : text === 1 ? 'Approved' : text === 2 ? 'Rejected' : 'Unknown Status'
                }
              </button>
            ) : text === 0 ? (
              'Pending from Admin  Approval'
            ) : text === 1 ? (
              'Approved'
            ) : text === 2 ? (
              'Rejected'
            ) : (
              'Unknown Status'
            )}
          </p>
          
          ),
        },
          {
            title: 'Admin Message',
            dataIndex: 'message',
            key: 'message',
            render: (text : any) => <p>{text ? text : '-'}</p>,
          },
       
        ];




  return (
    <div>
      <Row className="m-2" align={"middle"}>
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Tripod Declaration List</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={10} xxl={10}></Col>
            <Col xs={36} sm={36} md={14} xl={9} xxl={9} className="">
            </Col>
            {roleId == 3 && (
            <Col xs={24} sm={24} md={6} xl={5} xxl={5}>
              <Link to={"/backend/tripod-declaration"}>
                <button className="d-flex justify-content-center align-items-center w-100 btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Tripod</div>
                </button>
              </Link>
            </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Table
        scroll={{ x: 1000 }} 
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={record => record._id}
        pagination={{
          current: currentPage,
          total: totalPages * 5,
          pageSize: 5,
          onChange: (page) => setCurrentPage(page),
        }}
      />
         <Modal
        title=""
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <button key="reject" className="bg-red-500 mr-2 text-white px-3 py-2 rounded-md" 
          onClick={handleReject}
          >
            Reject
          </button>,
          <button key="approve" className="bg-orange-500 text-white px-3 py-2 rounded-md" 
          onClick={handleApprove}
          >
            Approve 
          </button>,
        ]}
      >
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">Message:</label>
            <Input.TextArea
            required
            aria-required
        
              id="message"
              rows={4}
              value={messageValue}
              onChange={(e) => setMessageValue(e.target.value)}
              placeholder="Enter your message here..."
            />
          </div>
        </Modal>
    </div>
  );
};

export default TripodData;
