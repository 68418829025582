import React, { useContext, useEffect, useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete, AiOutlineFilePdf } from 'react-icons/ai';
import { Button, Card, Col, Input, Modal, Row, Spin, Switch, Table, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FaEdit } from "react-icons/fa";
import { getalltds, tdsactive, tdsdelete } from '../../../../../utils/API';
import { AuthContext } from '../../../../../Pages/Auth/AuthContext';

const TdsCertificateTable: React.FC = () => {
  const { authData } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const imageLink = process.env.REACT_APP_Image_URL;
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const roleId = authData.roleId;
  const navigate = useNavigate();

  useEffect(() => {
    fetchTdsData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchText, dataSource]);

  const fetchTdsData = () => {
    setLoading(true);
    getalltds()
      .then((response: any) => {
        let data;
        const customerID = localStorage.getItem('customerId') || '';
        const userId = customerID.replace(/"/g, '');
        if (roleId === 1) {
          // If roleId is 2, show all data that is not deleted
          data = response.filter((item: any) => !item.isDeleted);
        } else {
          // For other roleIds, match userId with the record and ensure the record is not deleted
          data = response.filter((item: any) => item.userId?._id === userId && !item.isDeleted && item.active === true);
        }
        setDataSource(data);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };
  

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/tds-certificate/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        const response = await tdsdelete(deleteId);
        message.success(response.data.message);
        fetchTdsData();
      } catch (error) {
        message.error('Failed to delete TDS ');
      } finally {
        setDeleteId(null);
      }
    }
  };

  const tdsActiveInactive = (record: any) => {
    tdsactive(record._id)
      .then((response: any) => {
        if (response.status === 200) {
          message.success(response.data.message);
          fetchTdsData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error: any) => {
        message.error(error.response.data.message);
      });
  };


  const filterData = () => {
    const filtered = dataSource.filter((item : any) => {
      const user = item.userId; // Accessing the userId object
  
      // Check if user exists and has a username
      if (user && user.username) {
        return (
          user.username.toLowerCase().includes(searchText.toLowerCase()) ||
          (user.firstName && user.firstName.toLowerCase().includes(searchText.toLowerCase())) ||
          (user.lastName && user.lastName.toLowerCase().includes(searchText.toLowerCase()))
        );
      }
  
      return false; // Return false if user or username doesn't exist
    });
  
    setFilteredData(filtered);
  };
  
  const handleDownload = async (imageLink: any, file: any) => {
    try {
      const response = await fetch(`${imageLink}/tds-certificate/${file}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf', // Adjust as needed for your file type
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link); // Clean up

      // Revoke the URL object to free memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const columns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>Name</div>,
      dataIndex: 'userId',
      render: (user: any) => user ? user.firstName : "-",
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>TDS Year</div>,
      dataIndex: 'year',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>Pdf</div>,
      dataIndex: 'file',
      render: (file: any) => (
        <div>
      <button
        onClick={() => handleDownload(imageLink, file)}
        className="bg-orange-500 text-white rounded-md p-2"
      >
        Download
      </button>
    </div>
      ),
    },
    ...(roleId === 1
      ? [
        {
          title: <div style={{ fontWeight: 'bold', fontSize: '15px', color: '#a6a6a6' }}>Status</div>,
          dataIndex: 'active',
          key: 'active',
          render: (active: boolean, record: any) => (
            <Switch
              checked={active}
              className={active ? 'bg-orange-500' : 'bg-gray-500'}
              onChange={() => {
                tdsActiveInactive(record);
              }}
            />
          ),
        },
        {
          title: <div style={{ fontWeight: 'bold', fontSize: "15px", color: "#a6a6a6" }}>Action</div>,
          dataIndex: 'action',
          render: (text: any, record: any) => (
            <div className='d-flex'>
              <FaEdit
                className='editMenuIcon'
                onClick={() => handleEditRedirect(record)}
              />
              <AiFillDelete
                className='editMenuIcon'
                onClick={() => handleDeleteConfirmation(record._id)}
              />
            </div>
          ),
        },
      ]
      : []),
  ];

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={10}
          xl={10}
          xxl={12}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">TDS Certificates</h2>
        </Col>
        <Col xs={24} sm={24} md={14} xl={14} xxl={12} className="">
          <Row gutter={16} className="float-center xs:m-2">
            {/* <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col> */}
            <Col xs={16} sm={16} md={16} xl={18} xxl={20} className=''>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-full"
                placeholder="Search..."
                allowClear
                autoComplete='off'
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            {roleId === 1 && (<Col xs={8} sm={8} md={8} xl={6} xxl={4} style={{ paddingRight: '-8px !important' }}>
              <Link to={"/backend/tds-certificate/add"} >
                <button className="d-flex justify-content-center align-items-center w-100 btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Certificate</div>
                </button>
              </Link>
            </Col>)}

          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={filteredData} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>

      <div>
        <Modal
          title="Confirmation"
          visible={!!deleteId}
          footer={null}
          closable={false}
        >
          <div style={{ textAlign: 'start', marginBottom: '1rem' }}>
            <p >Are you sure you want to delete this Certificate?</p>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button
              onClick={handleDelete}
              type="primary"
              className="rounded" style={{ backgroundColor: "orange",color: "white", marginRight: "1rem" }}
            >
              Yes
            </Button>

            <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
              No
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TdsCertificateTable;
