import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const RefundCancellationPolicy = () => {
  return (
    <>
    <Row
      className="lg:mt-60 xl:mt-60 md:mt-60 sm:mt-36 xs:mt-36 mt-36 pt-3 pb-3"
      align="middle"
      justify="space-between"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        className="text-2xl font-bold d-flex justify-center md:justify-start p-4"
      >
      Refund & Cancellation Policy
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Breadcrumb
          separator=">"
          className="d-flex justify-center md:justify-end font-semibold text-black p-2 m-4"
        >
          <Breadcrumb.Item className="">
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/aboutus">Refund & Cancellation Policy</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
   

<div className="container mx-auto px-4 pt-8 pb-1">
      
      <section className="mb-6">
        <h1 className="text-4xl font-bold mb-6 text-center">Welcome to Rapidexie</h1>
        <p className="mb-4 text-base">
        At Rapidexie, we have full confidence in our varied range of services, 
products and e-business solutions. We leave no stone unturned in 
providing our esteemed clients fast, reliable and exceptional service 
guarantee every time we do business with them. All the active services 
available at Rapidexie website are carried out for the clients after 
extensive and thorough analysis using a complete Understanding of the 
Market demand/Analysis and research and industry relevancy documents. 
It ensures full understanding of the work and almost no possibilities of any
cancellation, reversal or dispute. However, any refund and service 
cancellation if raised it will be taken place abiding a set of Terms & 
Conditions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Cancellation Policy</h2>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 1.</span> A Customer/Direct seller Associate can place the cancellation order 
for a particular Product/service within 4 hours of Purchase via 
prescribed cancellation form available on our website
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 2.</span> No cancellation is possible for the services/Products offered with 
promotional discounts on special occasions festive offers like Diwali,
Independence Day, Christmas, New Year and international/Domestic
tours qualification offers. These are limited edition offers and hence 
any range of refund would not be possible
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 3.</span> In no case, Rapidexie will entertain cancellation requests made for 
        services once the Commission is distributed in Team
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 4.</span> Purchased Product or services will not be refunded for the cases 
where the Sales commissions already got distributed that will not be
refunded in any case even if the cancellation is posted within 2 
hours
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 5.</span> A service stands cancel if a person is passing on the bought Product 
or services to some one else without the approval &  notice  of the 
Company
        </p>
      </section>

      {/* <section className="mb-6">
        <h2 className="text-xl font-bold mb-2">2. Products and Services</h2>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.1 Product Information</h3>
        <p className="mb-4 text-base ml-6">
          We strive to provide accurate product descriptions and pricing. However, we do not warrant that product descriptions or other content are accurate, complete, reliable, or error-free.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-2">2.2 Pricing and Payment</h3>
        <p className="mb-4 text-base ml-6">
          Prices for products are listed on our website and are subject to change without notice. Payment must be made through our accepted payment methods. We reserve the right to refuse or cancel any orders.
        </p>
      </section> */}

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Refund Policy</h2>
      
        <p className="mb-4 text-base ml-6">
      <span className="font-bold"> 1.</span>Rapidexie offer Product & Services that takes different course of 
action to build and get accomplished from start to end thus refund 
policy differs from one service to other.
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 2.</span> There is always a certain set of stages that are involved in Business 
Operations like Distribution of sales incentives. Thus, a refund would
not be entertained for the scenarios where already sales incentive 
distribution Process is completed
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 3.</span> No refund is possible for the fee paid in the form of Course fee or 
DMIIT or Career counselling or any Product after 4 Hours and before 
sales incentive distribution
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 4.</span> Taxes paid on services levied by regulatory bodies would not be 
        refunded with fee refund
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 5.</span> No refund will be entertained in case service gets cancelled due to 
        any violation of terms stated in our Terms & Condition section
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 6.</span> There is no refund policy for the services placed under same day 
        delivery if Processed in any scenario
        </p>
        <p className="mb-4 text-base ml-6">
        <span className="font-bold"> 7.</span>Rapidexie is not liable to pay refund if delay or service disruption 
happens due to Negligence of Third Party like Low Internet speed at 
Receivers side in special case, He/she will be given a Hand holding 
from the Community Members
        </p>
      </section>

     
        <address className="not-italic mt-6 pt-6 text-base">
          Rapidexie <br />
          D-9, Vyapar Marg, Sector-3 Noida <br />
          <a href="mailto:info@rapidexie.com" className="text-base text-blue-600">info@rapidexie.com</a> <br />
          <a href="tel:+919818755350" className="text-base text-blue-600">9818755350</a>
        </address>
     
    </div>
  </>
  )
}

export default RefundCancellationPolicy 