import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import {getallproduct, getHomePageProducts} from "../../utils/API";
import { Link } from "react-router-dom";
interface Product {
    slug: any;
    isTopRated: boolean;
    isUpcoming: boolean;
    isBestSelling: boolean;
    images: any;
    isFeatured: unknown;
    name: string;
    imageUrl: string;
    price: string;
    star: number;
}





const AllProductsBottom: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const imageUrl = process.env.REACT_APP_Image_URL ;

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getHomePageProducts();
                setProducts(response.products);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };
    
        fetchProducts();
    }, []);
    
    return (
    
        <Row className="mt-5  xl:px-36 lg:px-10 xl:ps-36 lg:ps-10   md:px-10 md:ps-10 sm:ps-5 xs:ps-5" >

            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <p className="font-bold text-lg mb-3">Top Featured  Products</p>
                {products.filter(product => product.isFeatured === true).slice(0, 3).
                map((product : any , index) => (
                    <Link to={`/product/${product.slug}`} key={index}>
                    <Row key={index}>
                        <Col span={6} className="d-flex justify-content-center">
                            <img
                                src={ imageUrl + "/products/" + product.images[0]}
                                alt="Product Image"
                                width="100%"
                                style={{ height: "60px" , marginTop :"15%" }}
                            />
                        </Col>
                        <Col span={18} className="text-start">
                            <p className="text-start m-2 font-bold text-gray-700">{product.name}</p>
                            <p className="text-start m-2 font-bold d-flex">
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />  
                                    <FaStar  className="text-orange-500" />                            
                            </p>
                            <p className="text-start m-2 font-bold text-gray-700">&nbsp;</p>
                        </Col>
                    </Row>
                    </Link>
                ))}
            </Col>

            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <p className="font-bold text-lg mb-3">Top Selling Products</p>
                {products.filter(product => product.isBestSelling === true).slice(0, 3).
                map((product, index) => (
                    <Link to={`/product/${product.slug}`} key={index}>
                    <Row key={index}>
                        <Col span={6} className="d-flex justify-content-center ">
                            <img
                                src={ imageUrl + "/products/" + product.images[0]}
                                alt="Product Image"
                                width="100%"
                                style={{ height: "60px" , marginTop :"11%" }}
                            />
                        </Col>
                        <Col span={18} className="text-start">
                            <p className="text-start m-2 font-bold text-gray-700">{product.name}</p>
                            <p className="text-start m-2 font-bold d-flex">                               
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />    
                            </p>
                            <p className="text-start m-2 font-bold text-gray-700">&nbsp;</p>
                        </Col>
                    </Row>
                    </Link>
                ))}
            </Col>


            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <p className="font-bold text-lg mb-3">Upcoming Products</p>
                {products.filter(product => product.isUpcoming === true).slice(0, 3).
                map((product, index) => (
                    <Link to={`/product/${product.slug}`} key={index}>
                    <Row key={index}>
                        <Col span={6} className="d-flex justify-content-center ">
                            <img
                                src={ imageUrl + "/products/" + product.images[0]}
                                alt="Product Image"
                                width="100%"
                                style={{ height: "60px" , marginTop :"11%" }}
                            />
                        </Col>
                        <Col span={18} className="text-start">
                            <p className="text-start m-2 font-bold text-gray-700">{product.name}</p>
                            <p className="text-start m-2 font-bold d-flex">                               
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />    
                            </p>
                            <p className="text-start m-2 font-bold text-gray-700">&nbsp;</p>
                        </Col>
                    </Row>
                    </Link>
                ))}
            </Col>


            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <p className="font-bold text-lg mb-3">Additional Products</p>
                {products.filter(product => product.isTopRated === true).slice(0, 3).
                map((product, index) => (
                    <Link to={`/product/${product.slug}`} key={index}>
                    <Row key={index}>
                        <Col span={6} className="d-flex justify-content-center ">
                            <img
                                src={ imageUrl + "/products/" + product.images[0]}
                                alt="Product Image"
                                width="100%"
                                style={{ height: "60px" , marginTop :"11%" }}
                            />
                        </Col>
                        <Col span={18} className="text-start">
                            <p className="text-start m-2 font-bold text-gray-700">{product.name}</p>
                            <p className="text-start m-2 font-bold d-flex">
                                    <FaStar className="text-orange-500" />
                                    <FaStar className="text-orange-500" />
                                    <FaStar className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                                    <FaStar  className="text-orange-500" />
                            </p>
                            <p className="text-start m-2 font-bold text-gray-700">&nbsp;</p>
                        </Col>
                    </Row>
                    </Link>
                ))}
            </Col>
        </Row>
    
);}

export default AllProductsBottom;