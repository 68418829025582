import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Table, message } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { checkUsernameAvailability, getCustomers, getusertree } from "../../../../utils/API";
import TreeComponent from "./Tree";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { AuthContext } from "../../../Auth/AuthContext";

const SalesTree: React.FC = () => {
  const [productData, setProductData] = useState<any[]>([]);
  const [activeNode, setActiveNode] = useState<any | null>(null);
  const [history, setHistory] = useState<any[]>([]); // History stack
  const [nodeCache, setNodeCache] = useState<{ [key: string]: any }>({}); // Cache for nodes
  const { authData } = useContext(AuthContext);
  const roleid = authData?.roleId;

  useEffect(() => {
    const initializeUserId = async () => {
      try {
        let userId;

        if (roleid === 1) {
          // Fetch all customers and find the specific user
          const allUsers = await getCustomers("", "");
          // console.log('Fetched all users:', allUsers); // Debugging line

          const user = allUsers.data.find((user: any) => user.username === "RPDXA00001");
          userId = user ? user._id : null;
          // console.log('Found userId:', userId); // Debugging line
        } else {
          const customerId = localStorage.getItem("customerId");
          // console.log('Retrieved customerId from localStorage:', customerId); // Debugging line
          userId = customerId ? customerId.replace(/"/g, "") : null;
        }

        if (userId) {
          // console.log('User ID:', userId); // Debugging line
          fetchNodeData(userId, true);
        } else {
          console.error('User ID is not defined');
        }
      } catch (error) {
        console.error('Error initializing user ID:', error);
      }
    };

    initializeUserId();
  }, [roleid]);



  const fetchNodeData = (nodeId: string, isRoot: boolean = false) => {
    if (nodeCache[nodeId]) {
      // If the node data is in the cache, use it
      setProductData([nodeCache[nodeId]]);
      if (isRoot) {
        setActiveNode(null);
      }
    } else {
      getusertree(nodeId)
        .then((response) => {
          setNodeCache((prevCache) => ({
            ...prevCache,
            [nodeId]: response,
          }));
          setProductData([response]);
          if (isRoot) {
            setActiveNode(null);
          }
        })
        .catch((error) => {
          message.error("Network error. Please try again.");
        });
    }
  };

  const handleNodeClick = (node: any) => {
    setHistory((prevHistory) => [...prevHistory, activeNode]);
    setActiveNode(node);
    fetchNodeData(node._id);
  };

  const getPreviousNode = () => {
    const previousNode = history[history.length - 1];
    setHistory((prevHistory) => prevHistory.slice(0, -1));
    return previousNode;
  };

  const handleBackClick = async () => {

    const previousNode = getPreviousNode();
    if (previousNode) {
      setActiveNode(previousNode);
      fetchNodeData(previousNode._id);
    } else {
      let userId = "RPDXA00001";

      if (roleid != 1) {
        const customerId = localStorage.getItem("customerId");
        if (customerId) {
          userId = customerId.replace(/"/g, ""); // Remove quotes from customerId
        }

      } else {
        const allUsers = await getCustomers("", "");
        // console.log('Fetched all users:', allUsers); // Debugging line

        const user = allUsers.data.find((user: any) => user.username === "RPDXA00001");
        userId = user ? user._id : null;
      }

      if (userId) {
        fetchNodeData(userId, true);
      }

    }
  };

  const data = [
    {
      title: "👨‍✈️ CROWN AMBASSADOR",
      points: "37500 Pair points",
    },
    {
      title: "👑 AMBASSADOR",
      points: "25000 Pair points",
    },
    {
      title: "💠 SAPPHIRE",
      points: "12500 Pair points",
    },
    {
      title: "🌻 SUNFLOWER",
      points: "6750 Pair points",
    },
    {
      title: "🌷 LOTUS",
      points: "5000 Pair points",
    },
    {
      title: "🌹 ROSE",
      points: "2500 Pair points",
    },
    {
      title: "🟪 LAVENDER",
      points: "1250 Pair points",
    },
    {
      title: "🌺 LILY",
      points: "250 Pair points",
    },
    {
      title: "🏵️ MARIGOLD",
      points: "125 Pair points",
    },
    {
      title: "🌸 SAFRON",
      points: "50 Pair points",
    },
    {
      title: "💮 JASMINE",
      points: "25 Pair points",
    },
    {
      title: "⚡ Active",
      points: "0-24 Pair points",
    },
  ];

  const DS_data = [
    {
      title: " Without Payment ",
      label: "🔴",
    },
    {
      title: "Blocked",
      label: "❌",
    },
    {
      title: "Payment done",
      label: "🟢",
    },
    {
      title: "Tripod",
      label: "🏰",
    },
    {
      title: "Sevenpod ",
      label: "👨🏻‍👩🏻‍👦🏻‍👦🏻",
    },
  ];
  const isMobileView = () => window.innerWidth <= 768;
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 900) {
      setIsSmallScreen(true);
    }
    else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(isSmallScreen);
  return (
    <>
      <Row className="m-2" align="middle">
        <Col span={10}>
          <h2 className="text-2xl font-bold">Sales tree</h2>
        </Col>
      </Row>
      <div className="flex flex-wrap justify-between p-3">
        {data.map((item, index) => (
          <div key={index} className="flex-1">
            <div className="bg-white border p-4 text-center h-full rounded-lg shadow-md">
              <span className="font-bold">{item.title}</span> <br /> {item.points}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-wrap  justify-between p-3">
        {DS_data.map((item, index) => (
          <div key={index} className="flex-1">
            <div className="bg-white border p-2 text-center h-full">
              <span className="font-bold">{item.title}</span> <br /> {item.label}
            </div>
          </div>
        ))}
      </div>

      {productData[0] && productData[0].financial !== null ? (
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Incentive Point(IP) Old Balance</th>
                <th colSpan={2}>Incentive Point(IP) Current Closing</th>
                <th colSpan={2}>Incentive Point(IP) Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{productData[0].financial.oldPoint.left || 0}</td>
                <td>{productData[0].financial.oldPoint.right || 0}</td>
                <td>{productData[0].financial.newPoint.left || 0}</td>
                <td>{productData[0].financial.newPoint.right || 0}</td>
                <td>{(productData[0].financial.oldPoint.left || 0) + (productData[0].financial.newPoint.left || 0)}</td>
                <td>{(productData[0].financial.oldPoint.right || 0) + (productData[0].financial.newPoint.right || 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Incentive Point(IP) Old Balance</th>
                <th colSpan={2}>Incentive Point(IP) Current Closing</th>
                <th colSpan={2}>Incentive Point(IP) Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="custom-class">
        <TreeComponent
          data={productData}
          isFirst={true}
          onNodeClick={handleNodeClick}
          handleBackClick={handleBackClick}
          activeNode={activeNode}
        />
      </div>
    </>
  );
};

export default SalesTree;
