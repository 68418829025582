import React, { useState, useEffect } from 'react';
import { Collapse, Row, Col, Card, Table, message, Spin } from 'antd';
import moment from 'moment'; // for date formatting
import { direactStatus } from '../../../../utils/API';

const { Panel } = Collapse;

const DirectIndividualReport: React.FC = () => {
    const [financialData, setFinancialData] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<string | string[]>([]);
    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const customerId = localStorage.getItem("customerId") || "";
    const userId = customerId.replace(/"/g, '');

    useEffect(() => {
        fetchData(); // Fetch data on component mount
    }, []);

    const fetchData = async () => {
        setLoading(true); // Start loading
        try {
            const res = await direactStatus(userId); // Assuming direactStatus fetches the financial data
            setUsername(res[0]?.totalChildren?.username || ''); // Set the username

            const updatedFinancialData = res.map((item: any) => ({
                date: item.date,
                ...item.totalChildren,
            }));

            setFinancialData(updatedFinancialData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // End loading
        }
    };

    const findTitle = (matchingPoints: number) => {
        if (matchingPoints >= 37500) {
            return "👨‍✈️";
        } else if (matchingPoints >= 25000) {
            return "👑";
        } else if (matchingPoints >= 12500) {
            return "💠";
        } else if (matchingPoints >= 6750) {
            return "🌻";
        } else if (matchingPoints >= 5000) {
            return "🌷";
        } else if (matchingPoints >= 2500) {
            return "🌹";
        } else if (matchingPoints >= 1250) {
            return "🟪";
        } else if (matchingPoints >= 250) {
            return "🌺";
        } else if (matchingPoints >= 125) {
            return "🏵️";
        } else if (matchingPoints >= 50) {
            return "🌸";
        } else if (matchingPoints >= 25) {
            return "💮";
        } else if (matchingPoints >= 0) {
            return "⚡";
        }
    };

    const pointsForNextLevel = (matchingPoints: number) => {
        if (matchingPoints < 25) return 25 - matchingPoints;
        if (matchingPoints < 50) return 50 - matchingPoints;
        if (matchingPoints < 125) return 125 - matchingPoints;
        if (matchingPoints < 250) return 250 - matchingPoints;
        if (matchingPoints < 1250) return 1250 - matchingPoints;
        if (matchingPoints < 2500) return 2500 - matchingPoints;
        if (matchingPoints < 5000) return 5000 - matchingPoints;
        if (matchingPoints < 6750) return 6750 - matchingPoints;
        if (matchingPoints < 12500) return 12500 - matchingPoints;
        if (matchingPoints < 25000) return 25000 - matchingPoints;
        if (matchingPoints < 37500) return 37500 - matchingPoints;
        return 0;
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Total Left ID',
            dataIndex: 'leftchildcount',
            key: 'leftchildcount',
        },
        {
            title: 'Total Right ID',
            dataIndex: 'rightchildcount',
            key: 'rightchildcount',
        },
        {
            title: 'Current Level',
            dataIndex: 'currentlevel',
            key: 'currentlevel',
            render: (currentlevel: number) => (
                <span>
                    {findTitle(currentlevel)} {currentlevel}
                </span>
            ),
        },
        {
            title: 'Pair Need for Next Level',
            dataIndex: 'currentlevel',
            key: 'nextLevel',
            render: (currentlevel: number) => (
                <span>
                    {pointsForNextLevel(currentlevel)}
                </span>
            ),
        },
    ];

    const handleAccordionChange = (keys: string | string[]) => {
        setActiveKey(keys);
    };


    return (
        <>
            <Row className="m-2" align="middle">
                <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
                    <h2 className="text-2xl">Direct Individual Report</h2>
                </Col>
            </Row>
            <Row>
                <Col style={{ backgroundColor: '#ffffff', borderRadius: '12px' }} xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Card className="bg-white border " style={{ borderRadius: '12px' }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            {loading ? (
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                    <Spin tip="Loading..." />
                                </div>
                            ) : (
                                <Collapse accordion activeKey={activeKey} onChange={handleAccordionChange}>
                                    {financialData.length === 0 ? (
                                        <Panel key="nodata" header={<span style={{ fontSize: '15px', color: 'white' }}>No data available</span>} className="bg-orange-500">
                                            <p>No data available</p>
                                        </Panel>
                                    ) : (
                                        financialData.map((item) => (
                                            <Panel
                                                key={item._id}
                                                header={<span style={{ fontSize: '15px', color: 'white' }}>{`Payout Date - ${moment(item.date).format('DD/MM/YYYY')}`}</span>}
                                                className="bg-orange-500"
                                            >
                                                <Table dataSource={[item]} columns={columns} scroll={{x: 1000}} pagination={false} rowKey="_id" />
                                            </Panel>
                                        ))
                                    )}
                                </Collapse>
                            )}
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default DirectIndividualReport;