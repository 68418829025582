import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { RiAddBoxFill } from 'react-icons/ri';
import moment from 'moment'; // Import moment for date formatting
import { getDirectReportClosingWise } from '../../../../utils/API';
import dayjs from 'dayjs';
import PayoutDates from '../../Geneology/Accuntdetails/PayoutDates';

const DirectReportClosingWise = () => {
  const getLastSunday = () => {
    const today = moment();
    const lastSunday = today.day(today.day() === 0 ? -7 : 0).startOf('day');
    return lastSunday.format('YYYY-MM-DD');
  };
  

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(getLastSunday()); // State for selected date, defaulting to last Sunday
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0); // Total number of items
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [totalPages, setTotalPages] = useState(1);
  
  const fetchData = (page: number, date: string) => {
    const customerId = localStorage.getItem('customerId') || '';
    const userId = customerId.replace(/"/g, '');
    setLoading(true);
    getDirectReportClosingWise(userId, date, page)
      .then((response) => {
        setData(response.results);
        setTotalPages(response.totalPages);
        setCurrentPage(response.currentPage);
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Log the error for debugging
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  useEffect(() => {
    fetchData(currentPage, date);
  }, [date , currentPage]);

  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'index',
      key: 'index',
      render: (text : any, record : any, index : any) => index + 1,
    },
    {
      title: 'ID No',
      dataIndex: 'username',
      key: 'idNo',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Paid Date',
      dataIndex: 'financialData',
      key: 'paidDate',
      render: (financialData : any ) => {
        const neftDate = financialData?.[0]?.neftDate;
        return neftDate ? dayjs(neftDate).format('DD-MM-YYYY') : '-';
      },
    },
    {
      title: 'Left Point',
      dataIndex: 'leftchildcount',
      key: 'leftchildcount',
    },
    {
      title: 'Right Point',
      dataIndex: 'rightchildcount',
      key: 'rightchildcount',
    },
    {
      title: 'Total',
      dataIndex: 'totalchild',
      key: 'totalchild',
    },
  ];

  // const handleDateChange = (date : any, dateString : any) => {
  //   setDate(dateString); // Update selected date
  // };

  const handleDateChange = (date: string) => {
    setDate(date); // Update selected date in state
  };

  const disabledDate = (current : any) => {
    // Disable future dates
    const today = moment().endOf('day');
    // Only allow Sundays
    return current && (current > today || current.day() !== 0);
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Direct Report Closing Wise</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={10} xxl={10}></Col>
            <Col xs={36} sm={36} md={14} xl={9} xxl={9}></Col>
            <Col xs={24} sm={24} md={6} xl={5} xxl={5}>
            
            </Col>
          </Row>
        </Col>
      </Row>
      <Card>
        <Row gutter={16} className="m-2">
          <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
            {/* <DatePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              allowClear
              
              disabledDate={disabledDate}
              defaultValue={dayjs(date)} // Set default value to last Sunday
            /> */}
            <PayoutDates  dateChnageFunction={handleDateChange}/>
          </Col>
        </Row>
        <Table
            scroll={{x: 1000}}
            columns={columns} dataSource={data}  pagination={{
            current: currentPage,
            total: total,
            pageSize: 10  ,
            onChange: (page) => setCurrentPage(page),
          }}
          loading={loading} />
      </Card>
    </div>
  );
};

export default DirectReportClosingWise;
