import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  message,
  Card,
  Checkbox,
  Select,
  Button,
  Upload,
  Spin,
  InputNumber,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { getAllcategories, getproductsize, removeProductImage, updateProduct, editProduct, backendallCategories, getcolores } from "../../utils/API"; // Import the necessary API functions
import { BsUpload } from "react-icons/bs";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { FaArrowLeft } from "react-icons/fa";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { color } from "echarts";
const { Option } = Select;

const EditProductForm: React.FC = () => {
  const [product, setProduct] = useState<any>(null);
  const [form] = Form.useForm();
  const [productsizedata, setproductsize] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [colorData, setColorData] = useState<any[]>([]);
  const { id } = useParams();
  const [colors, setColors] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [category, setCategory] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [discount, setDiscount] = useState<string>("");
  const [size_id, setsize_id] = useState<any[]>([]);
  const [qty, setQty] = useState<string>("");
  const [ipQty, setipQty] = useState<number>(0);
  const [is_bestseller, setIsBestSeller] = useState<boolean>(false);
  const [is_featured, setIsFeatured] = useState<boolean>(false);
  const [is_upcoming, setIsUpcoming] = useState<boolean>(false);
  const [is_top_rated, setIsTopRated] = useState<boolean>(false);
  const [discountedPrice, setDiscountedPrice] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [updating , setUpdating] = useState<boolean>(false);
  const imageLink = process.env.REACT_APP_Image_URL;

  const validateDiscount = (_ : any, value : any) => {
    if (value > 100) {
      return Promise.reject(new Error('Discount cannot be more than 100%'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (id) {
      editProduct(id)
        .then((response: any) => {
          setProduct(response);
         console.log(response);
          form.setFieldsValue({
            name: response.name,
           
            description: response.description,
            price: response.price,
            discount: response.discount,
            discountedPrice: response.discountedPrice,
            categoryId: response.categoryId?._id, // Ensure the category ID is set correctly
            // colorId : response.colorId[0]?._id ,
            colorId: response.colorId && response.colorId.length > 0 ? response.colorId[0]?._id : null,
            
            sizeId: response.sizeId || response.sizeId !== null ? response.sizeId.filter(
          (size: any) => !size.isDeleted && size.isActive
        ).map((size: any) => size._id) : "", // Ensure the size IDs are set correctly
            
            qty: response.qty,
            ipQty: response.ipQty,
          
            isBestSelling: response.isBestSelling,
            isFeatured: response.isFeatured,
            isUpcoming: response.isUpcoming,
            isTopRated: response.isTopRated
          });
          
          const imageFiles = response.images.map((filename: string, index: number) => ({
            uid: index.toString(), 
            name: filename,
            status: 'done',
            url: `${process.env.REACT_APP_Image_URL}/products/${filename}`,
          }));
          setFileList(imageFiles);
        })
        .catch((error: any) => {
          alert(error);
          message.error("Network error. Please try again.");
        });
    } 
  }, [id]);
  
  
  const fetchColores = async () => {
    try {
      const response = await getcolores(); 
      const colorsData = response.colors;
      setColorData(colorsData);
    } catch (error) {
      message.error("Error fetching colors:");
    }
  };


  const fetchSizeData = () => {
    setLoading(true);
    getproductsize()
      .then((response: any) => {
        console.log(response.data.sizes)
        const filteredSizes = response.data.sizes && response.data?.sizes?.filter(
          (size: any) => !size.isDeleted && size.isActive
        );
        setproductsize(filteredSizes);
      })
      .catch((error: any) => {
        message.error("Network error. Please try again.");
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  const fetchCategories = async () => {
    try {
      const categoriesData = await backendallCategories("");
      setCategoryData(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  
  
  React.useEffect(() => {
    fetchSizeData();
    fetchCategories();
    fetchColores();
  }, []);


  const handleImage = ({ fileList }: UploadChangeParam<UploadFile>) => {
    setFileList(fileList);
  };


  const handleSubmit = async () => {
    setUpdating(true);
    try {
      const formData = new FormData();
      formData.append("name", form.getFieldValue("name"));
      formData.append("description", form.getFieldValue("description"));
      formData.append("price", form.getFieldValue("price"));
      formData.append("discount", form.getFieldValue("discount"));
      formData.append("discountedPrice", form.getFieldValue("discountedPrice"));
      size_id.forEach((item, index) => {
        formData.append("sizeId", size_id[index]);
      })
      formData.append("qty", form.getFieldValue("qty"));
      formData.append("ipQty", form.getFieldValue("ipQty"));

      formData.append("categoryId", form.getFieldValue("categoryId"));
      formData.append("colorId", form.getFieldValue("colorId"));
      formData.append("isBestSelling", form.getFieldValue("isBestSelling"));
      formData.append("isFeatured", form.getFieldValue("isFeatured"));
      formData.append("isUpcoming", form.getFieldValue("isUpcoming"));
      formData.append("isTopRated", form.getFieldValue("isTopRated"));

      if (fileList.length > 0 || undefined) {
        fileList.forEach((file, index) => {
          if (fileList[index].originFileObj) {
            formData.append("images", fileList[index].originFileObj as RcFile);
          }
        });
      }

      const response = await updateProduct(id, formData);
      message.success(response.message);
        setUpdating(false);
      navigate("/backend/product");
    } catch (error) {
      setUpdating(false);
      message.error("Failed to update product. Please try again.");
    }
  };


  const handleRemoveImage = async (imageId: string) => {
    const index = parseInt(imageId, 10);
    const productId = product?._id;
    const imageFile = fileList.find(file => file.uid === imageId);
    if (imageFile && !imageFile.originFileObj) {
      // If the image is not new, remove it from the server
      try {
        await removeProductImage(productId, imageId);
        const updatedFileList = fileList.filter((file, idx) => idx !== index);
        setFileList(updatedFileList);
        message.success("Image removed successfully");
      } catch (error) {
        console.error("Error removing image:", error);
        message.error("Failed to remove image. Please try again.");
      }
    }
  };

  return (
    <div className="">
  <Row className="m-2" align="middle">
    <Col span={16}>
      <h2 className="text-2xl font-bold">Edit Product</h2>
    </Col>
    {/* <Col xs={0} sm={0} md={12}></Col> */}
    <Col span={8} className="d-flex justify-end">
      <Link to={"/backend/product"}>
        <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
          <FaArrowLeft className="me-2" />
          <div className="ms-2">Back</div>
        </button>
      </Link>
    </Col>
  </Row>
  <Form className="bg-white" form={form} onFinish={handleSubmit}>
    <Card style={{ backgroundColor: "#ffffff" }}>
      <Row className="border border-warning-2 p-5 bg-white rounded-md" style={{ marginLeft: 0, marginRight: 0 }}>
        
        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Name <span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="name" rules={[{ required: true, message: "Please Enter Product name" }]}>
                <Input size="large" className="rounded border border-1" value={name} placeholder="Product Name" onChange={(e) => setName(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Product Image <span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12} className="d-flex justify-content-center align-items-center">
              <Upload multiple onRemove={(file) => handleRemoveImage(file.uid)} name="images" listType="picture-card" beforeUpload={() => false} fileList={fileList} onChange={handleImage} maxCount={5} accept=".png, .jpg, .jpeg">
                <div>
                  <BsUpload style={{ fontSize: "20px" }} />
                </div>
              </Upload>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Description<span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="description" rules={[{ required: true, message: "Please Enter Description" }]}>
                <ReactQuill theme="snow" value={description} onChange={setDescription} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">MRP<span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="price" rules={[{ required: true, message: "Please Enter Price" }]}>
                <Input size="large" className="rounded border border-1" placeholder="Price" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Discount % <span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="discount" rules={[{ required: true, message: "Please Enter Discount" }, { validator: validateDiscount }]}>
                <Input size="large" className="rounded border border-1" placeholder="Discount" type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Selling price<span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="discountedPrice" rules={[{ required: true, message: "Please Enter Discount price" }]}>
                <Input type="number" size="large" className="rounded border border-1" placeholder="Discount price" value={discountedPrice} onChange={(e) => setDiscountedPrice(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Category<span className="text-danger">*</span></label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="categoryId" rules={[{ required: true, message: "Please Enter Category" }]}>
                <Select size="large" allowClear showSearch placeholder="Select a category" optionFilterProp="children" filterOption={(input : any, option : any) => option.children.toLowerCase().includes(input.toLowerCase())} onChange={(value) => setCategory(value)}>
                  {categoryData.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Color</label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="colorId">
                <Select size="large" allowClear showSearch placeholder="Select a color" optionFilterProp="children" 
                  filterOption={(input : any, option : any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  } onChange={(value) => setColors(value)}>
                  {colorData.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.color}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row className="bg-white">
            <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white">
              <label className="font-bold">Sizes</label>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="sizeId">
                <Select size="large" mode="multiple" allowClear placeholder="Choose Size" showSearch optionFilterProp="children" filterOption={(input : any, option : any) => option.children.toLowerCase().includes(input.toLowerCase()) } onChange={(value) => setsize_id(value)}>
                  {productsizedata.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={24} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    {" "}
                    Quantity 
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="qty"
                  >
                    <Input
                    type="number"
                      size="large"
                      className="rounded border border-1"
                      value={qty}
                      placeholder="Quantity"
                      onChange={(e) => setQty(e.target.value)}
                    />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={24} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    {" "}
                    IP Quantity <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="ipQty"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Ip Quantity",
                      },
                    ]}
                  >
                    <InputNumber
                    type="number"
                      size="large"
                      className="rounded border border-1 w-full lg:w-1/4"
                      value={ipQty}
                      placeholder="Ip Quantity"
                      onChange={(value) => setipQty(value as unknown as number)}
                    />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Featured Product
                  </label>
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Form.Item name="isFeatured" valuePropName="checked">
                    <Checkbox
                      value={is_featured}
                      checked={is_featured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Best Selling
                  </label>
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Form.Item name="isBestSelling" valuePropName="checked">
                    <Checkbox
                      value={is_bestseller}
                      checked={is_bestseller}
                      onChange={(e) => setIsBestSeller(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    Upcoming Product
                  </label>
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Form.Item name="isUpcoming" valuePropName="checked">
                    <Checkbox
                      value={is_upcoming}
                      checked={is_upcoming}
                      onChange={(e) => setIsUpcoming(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  xs={15} sm={10} md={4}
                  className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                >
                  <label className="font-bold">
                    Top Rated
                  </label>
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Form.Item name="isTopRated" valuePropName="checked">
                    <Checkbox
                      value={is_top_rated}
                      checked={is_top_rated}
                      onChange={(e) => setIsTopRated(e.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn-brand">
                {
                    updating ? 
                      (
                        <div className="d-flex">
                          <Spin /> 
                          <span className="ms-2">Updating...</span>
                        </div>
                      )
                    : "Update Product"
                }
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
</div>

  );
};

export default EditProductForm;