import React, { useState, useEffect } from "react";
import { Row, Col, Carousel, message } from "antd";
import { getallproduct, getHomePageProducts } from "../../utils/API";

const Upcomingproducts: React.FC = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [products, setProducts] = useState<any[]>([]);
  const imageUrl = process.env.REACT_APP_Image_URL;
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getHomePageProducts();
          setProducts(response.products)
      } catch (error) {
        console.error("Error fetching products:", error);
        message.error("Error fetching products");
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Row style={{ backgroundColor: "#F2F2F2" }}>
        <Col span={24} className="text-center mt-5 font-bold text-xl">
          Upcoming Products
        </Col>
        <Col span={24} className="mt-2 p-2 lg:px-0 xl:px-36 lg:ps-0 xl:ps-36 md:px-0 md:ps-0">
          <div className="flex flex-col md:flex-row items-center justify-center mb-3">
            <hr className="w-full m-auto border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
            <span className="text-gray-400 text-center tracking-wide md:pr-5 md:pl-5 mt-3 md:mt-0 pb-2">
              Get ready to elevate your lifestyle with our exciting lineup of upcoming products, designed to enhance your well-being and exceed your expectations
            </span>
            <hr className="w-full m-auto" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="lg:px-0 xl:px-36 lg:ps-0 xl:ps-36 md:px-0 md:ps-0">
          <Carousel autoplay slidesToShow={slidesToShow} draggable dots={false}>
            {products
              .filter(product => product.isUpcoming)
              .map((product, index) => (
                <div key={index} className="p-2">
                  <img
                    src={`${imageUrl}/products/${product.images[0]}`}
                    alt={`Product Image ${index + 1}`}
                    width="100%"
                  />
                </div>
              ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default Upcomingproducts;
