import { Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { addAward} from "../../../utils/API";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { UploadFile } from "antd/lib/upload";
import { BiUpload } from "react-icons/bi";
import { RcFile } from "antd/es/upload";
import { FaArrowLeft } from "react-icons/fa";

export const AddAward = () => {
 
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewfile, setPreviewfile] = useState<string>("");
  const navigate = useNavigate();

  

  const handleAddAwards = (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", values.title);
    
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file.originFileObj as Blob);
      });
    }

    addAward(formData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Category added successfully");
        }
        navigate("/backend/awards");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handlefile = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    // Display preview for the first file in fileList
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewfile(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setPreviewfile(null as any); // Clear preview if fileList is empty
    }
    setFileList(newFileList); // Update fileList state
  };


  return (
    <div>
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Add Awards</h2>
        </Col>
        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/awards"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      <Row align={"middle"} gutter={[16, 16]}>
        <Col span={24}>
          <Card className="bg-white" style={{ minHeight: "80vh" }}>
            <Form
              onFinish={handleAddAwards}
              initialValues={{ categoryId: "null" }}
            >
          
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Name <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: "Please input name!" }]}
                  >
                    <Input
                      size="large"
                      className="rounded border border-1"
                      name="title"
                    />
                  </Form.Item>
                </Col>

              </Row>
            
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={4} className="d-flex justify-content-start">
                  <label className="font-bold">
                    Image <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col
                  xs={24} sm={24} md={8}
                  className="d-flex justify-content-start"
                >
                  <Upload
                    listType="picture-card"
                    name="file"
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    fileList={fileList}
                    onChange={handlefile}
                    maxCount={1}
                    accept=".png, .jpg, .jpeg"
                    beforeUpload={(file: { type: string; size: number; }) => {
                      // Validate file type and size
                      const isJpgOrPng =
                        file.type === "file/jpeg" ||
                        file.type === "file/png";
                      // if (!isJpgOrPng) {
                      //   message.error("Add image");
                      // }
                      // const isLt2M = file.size / 1024 / 1024 < 1;
                      // if (!isLt2M) {
                      //   message.error("Image must be smaller than 1MB!");
                      // }
                      // return isJpgOrPng && isLt2M;
                    }}
                  >
                    {fileList.length === 0 ? (
                      <div>
                        <BiUpload />
                      </div>
                    ) : (
                      <img
                        src={previewfile}
                        alt="Preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </Upload>
                  <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancelPreview}
                  >
                    <img
                      alt="Preview"
                      style={{ width: "100%" }}
                      src={previewfile}
                    />
                  </Modal>
                </Col>

              </Row>
              <Row>
                <Col span={24} className="d-flex justify-content-center">
                  <button
                    className="btn-brand"
                    type="submit"
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};