import React, { useEffect, useState } from "react";
import { Upload, message, Row, Col, Card, Button, Form } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BiUpload } from "react-icons/bi";
import { businesspptdownload, getbusinessppt, updatebusinessppt } from "../../../../../utils/API";

const BusinessPPT: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [savedData, setSavedData] = useState<UploadFile[]>([]);
  const [loading , setLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchppt = () => {
    getbusinessppt().then((response) => {
      const fileListData = response.ppt.map((fileData: any, index: number) => ({
        uid: String(index),
        name: fileData.ppt,
        status: "done",
        url: fileData.url,
        id: fileData._id, // Assuming each file has an ID property
      }));
      setFileList(fileListData);
      setSavedData(fileListData);
    });
  };

  useEffect(() => {
    fetchppt();
  }, []);

  const handledownloadPPt = async () => {
    setLoading(true);
    try {
      const pptURL = await businesspptdownload();
     window.open(pptURL.pptURL, '_blank');
      message.success('PPTX file download initiated successfully!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Failed to initiate PPTX file download.');
    }
  }

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Business PPT</h2>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Row className="border border-warning-2 p-5 bg-white rounded-md">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Row className="bg-white">
              <Col span={24}>
                <Row>
                  <Col xs={24} sm={24} md={4} className="me-4 bg-white mb-2 lg:mb-5">
                    <label className="font-bold">
                      Business PPT 
                    </label>
                  </Col>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form form={form} >
                      {/* <Form.Item name="file">
                        <Upload
                          name="ppt"
                          action=""
                          fileList={fileList}
                          onChange={({ fileList }) => setFileList(fileList)}
                          maxCount={1}
                          accept=".ppt, .pptx"
                          beforeUpload={() => false} // Prevent default upload behavior
                        >
                        
                        </Upload>
                      </Form.Item> */}
                      <Form.Item>
                        <button
                          className={`    ${loading ? 'bg-orange-200' : 'bg-orange-500'}  text-white font-bold py-2 px-4 rounded`}
                          onClick={handledownloadPPt}
                          disabled={loading}
                        >
                          {
                            loading ? 'Downloading' : 'Download'
                          }
                        </button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BusinessPPT;
