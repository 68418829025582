// utils/WishListContextr.js
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { AuthContext } from '../Pages/Auth/AuthContext';

interface WishlistItem {
  product: any;
  size: any | null;
}

interface WishlistContextType {
  wishlist: WishlistItem[];
  addToWishlist: (product: any, size: any | null) => void;
  removeFromWishlist: (productId: string) => void;
}

const WishlistContext = createContext<WishlistContextType | undefined>(undefined);

export const useWishlist = () => {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error('useWishlist must be used within a WishlistProvider');
  }
  return context;
};

export const WishlistProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);



  useEffect(() => {
    const storedWishlist = localStorage.getItem('wishlist');
    if (storedWishlist) {
      setWishlist(JSON.parse(storedWishlist));
    }
  }, []);

  const addToWishlist = (product: any, size: any | null) => {
    const existingProductIndex = wishlist.findIndex(
      item => item.product._id === product._id && item.size === size
    );

    let newWishlist;
    if (existingProductIndex !== -1) {
      newWishlist = [...wishlist];
    } else {
      newWishlist = [...wishlist, { product, size }];
    }

    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
  };

  const removeFromWishlist = (productId: string) => {
    const newWishlist = wishlist.filter(item => item.product._id !== productId);
    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
  };

  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistContext;
