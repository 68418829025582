import React, { useState } from 'react';
import { RiAddBoxFill, RiImageEditFill } from 'react-icons/ri';
import { AiFillDelete, AiOutlineExport, AiOutlineImport } from 'react-icons/ai';
import { Button, Card, Col, DatePicker, Form, Image, Input, Modal, Popover, Row, Select, Spin, Switch, Table, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FaEdit, FaKey } from "react-icons/fa";
import { DeleteSlider, getSliders } from '../../../utils/API';
import { title } from 'process';
const { Option } = Select;

interface SlidersProps {
  _id: number;
  name: string;
  image: string;
  __v: number;
}



const SliderTable: React.FC = () => {
  // const customerData: Customer[] = require('./CustomerMasterTable.json');
  const [slidersData, setslidersData] = useState<SlidersProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const imageLink = process.env.REACT_APP_Image_URL;

  const navigate = useNavigate();
  const FetchCustomerData = () => {
    setLoading(true);
    getSliders()
      .then((response: any) => {
        setslidersData(response.data.sliders);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Network error. Please try again.');
        setLoading(false);
      });
  };


  React.useEffect(() => {
    FetchCustomerData();
  }, []);

  const handleEditRedirect = (record: any) => {
    navigate(`/backend/sliders/${record._id}/edit`);
  };

  const handleDelete = (record: any) => {
    DeleteSlider(record._id).then((response: any) => {
      if (response.status === 200) { // Check if the response status is 200
        message.success(response.data.message);
        FetchCustomerData();
      } else {
        message.error("Something went wrong");
      }
    }).catch((error: any) => {
      message.error(error.response.data.message);
    });
  };


  const columns = [

    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Name</div>,
      dataIndex: 'name',
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Image</div>,
      dataIndex: 'image',
      render: (text: any) => <Image src={
        imageLink + "/sliders/" + text
      } style={{ width: "50px", height: "50px" }} />
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className='d-flex'>
          <FaEdit
            className='editMenuIcon'
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className='editMenuIcon'
            onClick={() => handleDelete(record)}

          />
        </div>
      ),
    },
  ]





  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={16}
          sm={16}
          md={18}
          xl={20}
          xxl={21}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Sliders </h2>
        </Col>
        <Col xs={8} sm={8} md={6} xl={4} xxl={3} className="">
        <Link to={"/backend/slider/add"} >
          <button className="d-flex justify-content-center align-items-center w-100 border btn-brand" >
            <RiAddBoxFill style={{ fontSize: "15px" }} />
            <div className="ms-2">Add Slider</div>
          </button>
        </Link>
        </Col>
      </Row>
      <Row>
        <Col
          style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
        >
          <Card className="bg-white border " style={{ borderRadius: "12px" }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Spin spinning={loading}>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={slidersData} scroll={{ x: true }} />
                </div>
              </Spin>
            </Col>
          </Card>
        </Col>
      </Row>

      <div>

      </div>
    </>
  );
};

export default SliderTable;
