import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import pdfMake, { createPdf } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { getCurrentYear } from '../../../../../utils/API';

const { Option } = Select;

// Register fonts
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface User {
    _id: string;
    roleId: number;
    sponsorId: string;
    email: string;
    placement: string;
    productId: string;
    firstName: string;
    lastName: string;
    mobileNo: string;
    pan: string;
    dob: Date | null;
    bankName: string;
    bankAddress: string;
    bankAccountNo: string;
    ifsc: string;
    state: string;
    city: string;
    town: string;
    address: string;
    pincode: number;
    isDeleted: boolean;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
    username: string;
    aadhar: string;
    isActive: boolean;
    nomineeName: string;
    nomineeRelation: string;
}

interface Record {
    _id: string;
    toDate: string;
    totalEarning: number;
    tds: number;
    otherCharges: number;
    recovery: number;
    netEarning: number;
    userId: User;
}

const Previous = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear() - 1);

    const getData = (year: number) => {
        const customerId = localStorage.getItem("customerId");
        const userId = customerId?.replace(/"/g, "");

        getCurrentYear( year).then((res: any) => {
            setData(res.financial);
        });
    };

    useEffect(() => {
        getData(selectedYear);
    }, [selectedYear]);

    const handleYearChange = (value: number) => {
        setSelectedYear(value);
        getData(value);
    };

    const mainColumns = [
        {
            title: 'S.No',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'Incentive Date',
            dataIndex: 'IncentiveDate',
            key: 'IncentiveDate',
            render: (text: string | number | Date) => new Date(text).toLocaleDateString(),
        },
        {
            title: 'Total Incentive',
            dataIndex: 'totalEarning',
            key: 'totalEarning',
        },
        {
            title: 'TDS(%)',
            dataIndex: 'tds',
            key: 'tds',
        },
        {
            title: 'Other Charges',
            dataIndex: 'otherCharges',
            key: 'otherCharges',
        },
        {
            title: 'Balance Recovery',
            dataIndex: 'recovery',
            key: 'recovery',
        },
        {
            title: 'Net Incentive',
            dataIndex: 'netEarning',
            key: 'netEarning',
        },
        {
            title: 'Incentive Statement',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <button
                    className='bg-orange-500 pt-2 pb-2 pl-4 pr-4 text-white'
                    onClick={() => downloadPDF(record as Record)}
                >
                    Download
                </button>
            ),
        },
    ];

    const handleRowClick = (record: { _id: any }) => {
        navigate(`/backend/financial-year/${record._id}`, { state: { userData: record } });
    };

    const downloadPDF = (record: Record) => {
        const documentDefinition: TDocumentDefinitions = {
            content: [
                {
                    text: 'Incentive Report',
                    style: 'header'
                },
                {
                    text: 'Username: ' + record.userId.firstName + ' ' + record.userId.lastName,
                    alignment: 'right',
                    style: 'subheader'
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 'auto'],
                        body: [
                            [{
                                fillColor: '#f97316',
                                text: 'S.No',
                            }, {}],
                            ['Total Incentive', record.totalEarning],
                            ['TDS', record.tds],
                            ['Other Charges', record.otherCharges],
                            ['Balance Recovery', record.recovery],
                            ['Net Incentive', record.netEarning]
                        ]
                    },
                    layout: 'bordered',
                    style: 'table'
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 10, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    margin: [0, 5, 0, 5]
                },
                table: {
                    margin: [0, 15, 0, 15]
                }
            }
        };

        const pdfDocGenerator = createPdf(documentDefinition);
        pdfDocGenerator.download('Incentive Report.pdf');
    };

    return (
        <>
            <Row className="m-2" align={"middle"}>
                <Col
                    xs={24}
                    sm={24}
                    md={9}
                    xl={9}
                    xxl={9}
                    className="d-flex justify-content-start font-bold align-items-center "
                >
                    <h2 className="text-2xl">Account Statement</h2>
                </Col>
            </Row>
            <Row>
                <Col
                    style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
                    xs={24}
                    sm={24}
                    md={24}
                    xl={24}
                    xxl={24}
                >
                    <Card className="bg-white border " style={{ margin: "1%" }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <div className='d-flex justify-content-start align-items-center '>
                                <div className='pl-2'>Select Year : - &nbsp; </div>
                                <Select
                                    defaultValue={selectedYear}
                                    style={{ width: 120 }}
                                    onChange={handleYearChange}
                                >
                                    <Option value={new Date().getFullYear() - 1}>
                                        {new Date().getFullYear() - 1}
                                    </Option>
                                    <Option value={new Date().getFullYear() - 2}>
                                        {new Date().getFullYear() - 2}
                                    </Option>
                                    <Option value={new Date().getFullYear() - 3}>
                                        {new Date().getFullYear() - 3}
                                    </Option>
                                </Select>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={mainColumns}
                                    dataSource={data}
                                    rowKey={(record) => record._id}
                                    onRow={(record) => ({
                                        onClick: () => handleRowClick(record),
                                    })}
                                    scroll={{ x: true }}
                                />
                            </div>
                        </Col>
                    </Card>
                    <Row className='font-bold text-l m-6'>
                        Looking forward to creating a mutually beneficial long term working relationship with you and we assure you of our full cooperation in making this tie up a success for both of us.
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Previous;
