import React, { useEffect, useState } from "react";
import { Row, Col, Image, message } from "antd";
import { getAllcategories, getallproduct, getHomePageProducts } from "../../utils/API";

const BrowseCategories: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [productCounts, setProductCounts] = useState<{ [key: string]: number }>({});
  const imageUrl = process.env.REACT_APP_Image_URL;

  const fetchCategories = async () => {
    try {
      const response = await getAllcategories("");
      const homeCategories = response.filter((category: { isHome: boolean; }) => category.isHome === true);
      setCategories(homeCategories);
       // Fetch all products
       const productsResponse = await getHomePageProducts();
       const pres = productsResponse.products
      
       const counts: { [key: string]: number } = {};
       for (const category of response) {
         const categoryId = category._id;
         let count = 0;
         for (const product of pres) {
           if (product.categoryId?._id === categoryId) {
             count++;
           }
         }
         counts[categoryId] = count;
       }
       setProductCounts(counts);
    } catch (error) {
      message.error("Error fetching categories");
    }
  };
  
  

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <Row
        className="mt-16 xl:px-20 xl:ps-20 lg:px-0 lg:ps-0  md:px-10 md:ps-10 sm:ps-5 xs:ps-5"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col span={24} className="text-center  font-bold text-xl">
          BROWSE CATEGORIES
        </Col>
        <Col span={24} className="mt-3 xl:px-20 xl:ps-20 lg:px-0 lg:ps-0  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
          <div className="flex flex-col md:flex-row items-center justify-center mb-3">
            <hr className="w-full m-auto  border-gray-400 flex-grow md:flex-grow-0 h-0.5 bg-gray-400" />
            <span className="text-gray-400 text-center tracking-wide md:pr-5 md:pl-5 mt-3 md:mt-0 pb-2">
              Discover our diverse range of product categories tailored to meet your every need and preference
            </span>
            <hr className="w-full" />
          </div>
        </Col>

        <div className="mt-3 slider text-center " style={{ width: "100%", height: "auto", margin: "10px 0px" }}>
          <Row style={{ display: "flex", justifyContent: "space-between" }} className="mt-3 xl:px-20 xl:ps-20 lg:px-0 lg:ps-0  md:px-10 md:ps-10 sm:ps-5 xs:ps-5">
            {categories.map((category, index) => (
              <Col key={index} xs={12} sm={8} md={4} lg={4} xl={4} xxl={4} className="pl-3">
                <img
                  src={imageUrl + "/categories/" + category.image}
                  alt="SliderImage"
                  width="100%"
                  style={{ height: "auto", zIndex: -1 }}
                  className="rounded-full"
                />
                <div className="bg-white" style={{ position: "relative", marginTop: "-55px", justifyContent: "space-between" }}>
                  <h2 className="text-center font-bold text-black mt-3 " style={{ fontSize: "18px" }}>{category.name}</h2>
                  <h2 className="text-center  text-gray" style={{ marginTop: "3px", marginBottom: "10px" }}>
                  {productCounts[category._id]} Products
                  </h2>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Row>
    </>
  );
};

export default BrowseCategories;