import { Form, Input, message } from "antd";
import React, { useContext } from "react";
import { Label } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import { login } from "../../../utils/API";
import Logo from "../../../assets/backendassets/logo.jpg";

interface LoginProps {
  onSuccess: () => void;
  onClose: () => void;
}

const Login: React.FC<LoginProps> = ({ onSuccess, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setIsLoading(true);
    login(values)
      .then((res : any ) => {
        if (res.status === 200) {
          setAuthData({ token: res.data.token, roleId: res.data.others.roleId });
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.others.firstName));
          localStorage.setItem("customerId", JSON.stringify(res.data.others._id));
          const cartData = JSON.parse(localStorage.getItem("cart") || '[]');
          const cartUserIdData = JSON.parse(localStorage.getItem("cart_userid") || '[]');
          localStorage.setItem("cart_userid", JSON.stringify(cartData));

          message.success(res.data.message);

          // Close the login modal
          onClose();

          // Call the onSuccess function to indicate a successful login
          onSuccess();

          setIsLoading(false);
        }
      })
      .catch((error : any ) => {
        setIsLoading(false);
        message.error(error.response?.data?.message || "An error occurred");
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center ">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl  rounded-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl d-flex justify-center">
          <img src={Logo} alt="" style={{ width: "250px", height: "50px", marginTop: "20px" }} />
        </div>
        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-xl">
          <Form initialValues={{ remember: true }} onFinish={onFinish} className="m-4">
            <label className="font-bold m-2">Username</label>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please enter your username!" }]}
            >
              <Input placeholder="Enter Username" className="antInputClass rounded" />
            </Form.Item>
            <Label className="font-bold m-2">Password</Label>
            <Form.Item
              className="mt-2 mb-2"
              name="password"
              rules={[{ required: true, message: "Please enter your password!" }]}
            >
              <Input.Password placeholder="Enter Password" size="large" className="antInputClass rounded" />
            </Form.Item>
            <Form.Item>
              <button
                style={{ width: "100%", marginTop: "10px" }}
                disabled={isLoading}
                className="flex w-full p-3 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isLoading ? 'Sign in...' : 'Sign in'}
              </button>
            </Form.Item>
          </Form>
          <p className="mb-2 pr-6 text-end">
            Don't have an account? <Link to="/register" className="text-orange-500">Register</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
