// src/components/LastWeeekSuportCenterPerformance.tsx
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'antd';
import { fetchReceivedPayments } from '../../../../utils/API';
import dayjs from 'dayjs';
import { render } from '@testing-library/react';

interface Payment {
    _id: string;
    sponsorId: string;
    username: string;
    firstName: string;
    country: string;
    city: string;
    orderStatus: number;
    createdAt: string;
}

const LastWeeekSuportCenterPerformance: React.FC = () => {

    const calculatePreviousSunday = (): string => {
        const today = dayjs();
        const previousSunday = today.subtract(today.day(), 'days');
        return previousSunday.format('YYYY-MM-DD');
    };
    
    const [data, setData] = useState<Payment[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState<number>(0); // Total number of items
    const [pageSize, setPageSize] = useState<number>(10); // Page size
    const [totalPages, setTotalPages] = useState(1);
    
    const defaultDate = calculatePreviousSunday(); // Calculate default last Sunday date

    useEffect(() => {
        const getPayments = async (page : any) => {
            setLoading(true);
            try {
                const result = await fetchReceivedPayments(defaultDate , page);
                setData(result.results);
                setTotalPages(result.totalPages);
                setCurrentPage(result.page);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        getPayments(currentPage);
    }, [currentPage]);

    const columns = [
            {
                title: 'Sr. No.',
                dataIndex: 'key',
                key: 'key',
                render  : (text : any, record : any, index : any) => index + 1
            },
        {
            title: 'Sponsor ID',
            dataIndex: 'sponsorId',
            key: 'sponsorId',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Payment Received',
            key: 'paymentReceived',
            render: (_: any, record: Payment) => (record.orderStatus === 1 ? 'Yes' : 'No'),
        },
        {
            title: 'Payment Not Received',
            key: 'paymentNotReceived',
            render: (_: any, record: Payment) => (record.orderStatus === 0 ? 'Yes' : 'No'),
        },
    ];

    return (
        <div>
            <Row className="mb-4" align="middle">
                <Col xs={24}>
                    <h2 className="text-2xl font-bold">Last Closing Week {defaultDate} Support Center Performance</h2>
                </Col>
            </Row>
            <Card>
                <Table columns={columns} dataSource={data} rowKey="_id" scroll={{ x: 1000 }}  pagination={{
            current: currentPage,
            total: total,
            pageSize: 10  ,
            onChange: (page) => setCurrentPage(page),
          }}
          loading={loading}  />
            </Card>          
        </div>
    );
};

export default LastWeeekSuportCenterPerformance;
