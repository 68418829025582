import { Form, FormInstance, Input, Modal, Select, message } from "antd";
import React, { useContext, useEffect } from "react";
import { register } from "../../utils/API";
import { Label } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import "./Auth.css"
import Logo from "../../assets/backendassets/logo.jpg";

const { Option } = Select;
const Register: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [country, setCountry] = React.useState([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [modalData, setModalData] = React.useState({ username: "", password: "" });
    const navigate = useNavigate();
  
    const onFinish = (values: any) => {
        setIsLoading(true);
        register(values)
            .then((res) => {
                if (res.status === 200) {
                    message.success(res.data.message);
                    setModalData({ username: res.data.EmailData.username, password: res.data.EmailData.password });
                    setIsModalVisible(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.response?.data?.message || "An error occurred");
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/uploads/country.json");
                if (!response.ok) {
                    throw new Error("Failed to fetch");
                }
                const data = await response.json();
                setCountry(data); // Ensure data is set correctly
            } catch (error) {
                console.error("Error fetching country data:", error);
            }
        };

        fetchData();
    }, []);

    const handleOk = () => {
        setIsModalVisible(false);
        navigate("/login");
    };
    
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="register sm:mx-auto w-full sm:w-full md:max-w-3xl border border-blue-500 rounded-lg">

            <div className="sm:mx-auto sm:w-full md:max-w-2xl d-flex justify-center">
            <Link to="/" className="hover:text-orange-500">
          
                <img
                    src={Logo}
                    alt=""
                    style={{ width: "250px", height: "50px", marginTop: "20px" }}
                />
                </Link>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full md:max-w-2xl">
                <Form
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    className="m-4"
                >
                    <label className="font-bold m-2">REFERRAL USERNAME<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="sponsorId"
                        rules={[{ required: true, message: "Please enter your Referral!" }]}
                    >
                        <Input placeholder="Enter Referral" className="antInputClass rounded " />
                    </Form.Item>
                    <label className="font-bold m-2">POSITION<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="placement"
                        rules={[{ required: true, message: "Please Select your Position!" }]}
                    >
                        <select className="antInputClass"  placeholder="Select Position" defaultValue="0">
                            <option className="antInputClass" value="0">Select Position</option>
                            <option className="antInputClass" value="1">Left</option>
                            <option className="antInputClass" value="2">Right</option>
                        </select>
                    </Form.Item>
                    <label className="font-bold m-2">FIRST NAME<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: "Please enter your First Name!" }]}
                    >
                        <Input placeholder="Enter First Name" className="antInputClass rounded" />
                    </Form.Item>
                    <label className="font-bold m-2">LAST NAME<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: "Please enter your Last Name!" }]}
                    >
                        <Input placeholder="Enter Last Name" className="antInputClass rounded" />
                    </Form.Item>
                    <label className="font-bold m-2">EMAIL<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please enter your email!" }]}
                    >
                        <Input placeholder="Enter Email" type="email" className="antInputClass rounded" />
                    </Form.Item>
                    <label className="font-bold m-2">COUNTRY<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="country"
                        className="antInputClass-select rounded p-0"
                        rules={[{ required: true, message: "Please select your Country!" }]}
                    >
                        <Select size="large" showSearch placeholder="Select Country">
                            {
                                country.map((c: any) => <Option key={c.code} value={c.name}>{c.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <label className="font-bold m-2">PHONE<span className="text-red-500">*</span></label>
                    <Form.Item
                        name="mobileNo"
                        rules={[{ required: true, message: "Please enter your Phone!" }, 
                        {
                            pattern: /^[0-9]+$/,
                            message: "Please enter valid phone number (only numbers allowed)"
                        },
                            {
                                min: 10,
                                message: "Phone number must be at least 10 digits"
                            } ,
                            {
                                max: 10,
                                message: "Phone number must be less than 10 digits"
                            }
                        
                    ]}
                    >
                        <Input placeholder="Enter Phone" className="antInputClass rounded" />
                    </Form.Item>
                    <Label className="font-bold m-2">PASSWORD<span className="text-red-500">*</span></Label>
                    <Form.Item
                        className="mt-2 mb-2"
                        name="password"
                        rules={[
                            { required: true, message: "Please enter your Password!" },
                            { min: 6, message: "Password must be at least 6 characters" },
                            { max: 20, message: "Password must be less than 20 characters" },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                message: "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter Password" size="large" className="antInputClass rounded" />
                    </Form.Item>
                    <Form.Item>
                        <button
                            style={{ width: "100%", marginTop: "10px" }}
                            disabled={isLoading}
                            className="flex w-full p-3 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >{isLoading ? 'Registering...' : 'Create Account'}</button>
                    </Form.Item>
                </Form>
              <div className="d-flex p-4 justify-between">
              <p>
                    For More Details Contact us :- +91 9818755350
                </p>
                <p className="mb-2  text-end">
                    Already have an account?{" "}
                    <Link to="/login" className="text-orange-500">
                        Login
                    </Link>
                </p>
              </div>
            </div>
        </div>

        <Modal
            title="Registration Successful"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <button key="ok" onClick={handleOk} className="flex w-full p-3 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Login
                </button>
            ]}
        >
            <p>Username: {modalData.username}</p>
            <p>Password: {modalData.password}</p>
        </Modal>
    </div>
    );
};

export default Register;