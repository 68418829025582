import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table, TableColumnsType } from "antd";
import { getContacts } from "../../../utils/API";

interface Contact {
  firstName: string;
  phone: string;
  email: string;
  subject: string;
  message: string;
}

const columns: TableColumnsType<Contact> = [
  {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>First Name</div>,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Last Name</div>,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Phone</div>,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Email</div>,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Message</div>,
    dataIndex: "message",
    key: "message",
  },
];

const Contact: React.FC = () => {
  const [dataSource, setDataSource] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleContacts = async () => {
    setLoading(true);
    try {
      const response = await getContacts();
      setDataSource(response.data);
    } catch (error) {
      console.error("Failed to fetch contacts", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleContacts();
  }, []);

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Manage Contacts</h2>
        </Col>
      </Row>

      <div className="flex flex-col items-center w-full min-h-screen">
        <div className="rounded-lg w-full">
          <Card className="bg-white border" style={{ borderRadius: "12px" }}>
            <Table<Contact>
              scroll={{ x: 800 }}
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              style={{ width: '100%' }}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Contact;
