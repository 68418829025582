import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Col, Row, Form, Input, Card, Upload, Button, message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { IoArrowBackOutline } from "react-icons/io5";
import { Select } from "antd";
import type { UploadProps } from "antd";
import { getSliderByID, UpdateSlider } from "../../../utils/API";
import axios from "axios";
import { BsUpload } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";

interface ArtFormValues {
  name: string;
  description: string;
  price: number;
  image: string;
}

const AdminSliderEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get ID from URL
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm(); // Initialize form instance

  useEffect(() => {
    if (id) {
      // Fetch art data by ID
      setLoading(true);
      getArtData();
    }
  }, [id]);

  const getArtData = async () => {
    const imageLink = process.env.REACT_APP_Image_URL;
    try {
      const response = await getSliderByID(id);
      const { name, description, price, image } = response.data.slider;
      form.setFieldsValue({ name, description, price });
      const fetchedImage = await axios.get(imageLink + "/sliders/" + image);
      const blob = await fetchedImage.data;
      const file = new File([blob], image);
      setFileList([file]);
      setImageUrl(imageLink + "/sliders/" + image);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
      setLoading(false);
    }
  };

  const handleAddImage: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target?.result as string);
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setImageUrl(null);
    }
    setFileList(newFileList);
  };

  const handleReplaceImage: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    if (newFileList.length > 0) {
      const file = newFileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target?.result as string);
      };
      reader.readAsDataURL(file.originFileObj as RcFile);
    } else {
      setImageUrl(null);
    }
    setFileList(newFileList);
  };

  const handleUpdateArt = async () => {
    try {
      const formData = new FormData();
      formData.append("name", form.getFieldValue("name"));
      formData.append("description", form.getFieldValue("description"));
      formData.append("price", form.getFieldValue("price"));
      if (fileList.length > 0) {
        formData.append("image", fileList[0].originFileObj);
      }
      const response = await UpdateSlider(id, formData);
      message.success(response.message);
      navigate("/backend/slider");
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={16}>
          <h2 className="text-2xl font-bold">Edit Slider</h2>
        </Col>

        <Col span={8} className="d-flex justify-end">
          <Link to={"/backend/slider"}>
            <button className="d-flex justify-content-center align-items-center w-full border btn-brand">
              <FaArrowLeft className="me-2" />
              <div className="ms-2">Back</div>
            </button>
          </Link>
        </Col>
      </Row>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <Form<ArtFormValues>
          form={form}
          className="bg-white"
          onFinish={handleUpdateArt}
        >
          <Card style={{ backgroundColor: "#ffffff" }}>
            <Row
              className="border border-warning-2 p-5 bg-white rounded-md"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Row className="bg-white">
                  <Col
                   xs={24} sm={24} md={4}
                    className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                  >
                    <label className="font-bold">
                      Name <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col sm={24} xs={24} md={12}>
                    <Form.Item
                      name="name"

                    >
                      <Input
                        size="large"
                        className="rounded border border-1"
                        placeholder="Enter Name"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Row className="bg-white">
                  <Col xs={24} sm={24} md={4} className="d-flex justify-content-start me-4 bg-white lg:mb-5">
                    <label className="font-bold">
                      Image <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col sm={24} xs={24} md={12}>
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Row className="bg-white">
                  <Col
                   xs={24} sm={24} md={4}
                    className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                  >
                    <label className="font-bold">
                      Upload new Image <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col sm={24} xs={24} md={12}>
                    <Upload
                      name="image"
                      fileList={fileList}
                      onChange={handleReplaceImage}
                      maxCount={1}
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      accept=".png, .jpg, .jpeg"
                      beforeUpload={(file) => {
                        const isJpgOrPng =
                          file.type === "image/jpeg" ||
                          file.type === "image/png";
                        if (!isJpgOrPng) {
                          message.error("You can only upload JPG/PNG file!");
                        }
                        const isLt2M = file.size / 3072 / 3072 < 1;
                        if (!isLt2M) {
                          message.error("Image must be smaller than 1MB!");
                        }
                        return isJpgOrPng && isLt2M;
                      }}
                    >
                      <Button icon={<PlusOutlined />}>Upload new Image</Button>
                    </Upload>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Row className="bg-white">
                  <Col
                   xs={24} sm={24} md={4}
                    className="d-flex justify-content-start me-4 bg-white lg:mb-5"
                  ></Col>
                  <Col sm={24} xs={24} md={12}>
                    <button
                      className="btn-brand"
                      type="submit"
                    >
                      Update
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
    </div>
  );
};

export default AdminSliderEdit;