import React, { createContext, useState, useContext, useEffect } from "react";
import { ReactNode } from "react";
import { AuthContext } from "../Pages/Auth/AuthContext";

interface CartItem {
  product: any;
  sizeId: string | null;
  quantity: number;
}

interface CartContextType {
  cart: CartItem[];
  addToCart: (product: any, sizeId: string | null) => void;
  updateQuantity: (productId: string, sizeId: string | null, quantity: number) => void;
  removeFromCart: (productId: string, sizeId: string | null) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const { authData } = useContext(AuthContext);

  useEffect(() => {
    const storedCartKey = authData.roleId ? "cart_userid" : "cart";
    const storedCart = localStorage.getItem(storedCartKey);
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, [authData.roleId]);

  const addToCart = (product: any, sizeId: string | null) => {
    const existingProductIndex = cart.findIndex(
      (item) => item.product._id === product._id && item.sizeId === sizeId
    );

    let newCart;
    if (existingProductIndex !== -1) {
      newCart = cart.map((item, index) =>
        index === existingProductIndex
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      newCart = [...cart, { product, sizeId, quantity: 1 }];
    }

    setCart(newCart);
    const storedCartKey = authData.roleId ? "cart_userid" : "cart";
    localStorage.setItem(storedCartKey, JSON.stringify(newCart));
  };

  const updateQuantity = (productId: string, sizeId: string | null, quantity: number) => {
    const newCart = cart.map((item) =>
      item.product._id === productId && item.sizeId === sizeId ? { ...item, quantity } : item
    );
    setCart(newCart);
    const storedCartKey = authData.roleId ? "cart_userid" : "cart";
    localStorage.setItem(storedCartKey, JSON.stringify(newCart));
  };

  const removeFromCart = (productId: string, sizeId: string | null) => {
    const newCart = cart.filter((item) => !(item.product._id === productId && item.sizeId === sizeId));
    setCart(newCart);
    const storedCartKey = authData.roleId ? "cart_userid" : "cart";
    localStorage.setItem(storedCartKey, JSON.stringify(newCart));
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, updateQuantity, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
