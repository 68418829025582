import { Card, Row, Table, Col, Input, message, Spin, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllOrder, orderdelete } from "../../../utils/API";
import { FaEdit } from "react-icons/fa";

export const OrderTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchText, dataSource]);

  const fetchOrders = async () => {
    const customerId = localStorage.getItem("customerId");
    const userId = customerId?.replace(/"/g, "");
    setLoading(true);
    try {
      const response = await getAllOrder(userId);
      const reversedOrders = response.orders.reverse();
      setDataSource(reversedOrders);
    } catch (error) {
      message.error("Failed to fetch orders. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    const filtered = dataSource.filter((order : any) =>
      (order.userId && order.userId.username && order.userId.username.toLowerCase().includes(searchText.toLowerCase())) ||
      (order.firstName && order.firstName.toLowerCase().includes(searchText.toLowerCase())) ||
      (order.lastName && order.lastName.toLowerCase().includes(searchText.toLowerCase()))
    );
    setFilteredData(filtered);
  };
  

  const handleEditRedirect = (record: { _id: any; }) => {
    navigate(`/backend/order/${record._id}/view`, { state: { orderId: record } });
  };

  const showDeleteConfirmation = (id:any) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await orderdelete(deleteId);
        message.success("Order deleted successfully.");
        fetchOrders();
      } catch (error) {
        message.error("Failed to delete order.");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const columns = [
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (date: string | number | Date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        return formattedDate;
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text: any, record: { firstName: any; lastName: any; }) => `${record.firstName} ${record.lastName ? record.lastName : ""}`,
    },
    {
      title: "UserName",
      dataIndex: ["userId", "username"],
      key: "username",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <div className="d-flex">
          <FaEdit className="me-4 editMenuIcon" onClick={() => handleEditRedirect(record)} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Manage Orders</h2>
        </Col>
        <Col xs={24} sm={24} md={15} xl={18} xxl={18}>
          <Input
            prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
            size="large"
            className="float-end w-full"
            placeholder="Search..."
            allowClear
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Card className=" w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table scroll={{ x: 1000 }}  columns={columns} dataSource={filteredData} rowKey="_id" />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        footer={null}
        onCancel={() => setDeleteId(null)}
      >
        <div style={{ textAlign: "start", marginBottom: "1rem" }}>
          <p>Are you sure you want to delete this order?</p>
        </div>
        <div style={{ textAlign: "end" }}>
          <Button
            onClick={handleDelete}
            type="primary"
            className="rounded"
            style={{ borderColor: "#dc3545", color: "inherit", marginRight: "1rem" }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} type="primary" ghost>
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
